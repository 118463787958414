import React from "react";
import { BrowserRouter, Route, Link, HashRouter } from "react-router-dom";
import "./assets/button.css";
import "./assets/phone_button.css";
import {
  createMuiTheme,
  withStyles,
  responsiveFontSizes,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import FrontAppBar from "../FrontAppBar/FrontAppBar.js";
import { Card, CardMedia, Divider, Hidden, Modal } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import useMediaQuery from "@material-ui/core/useMediaQuery";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 0, 0),
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  title: {
    fontWeight: "950",
    fontSize: "1.2rem",
    "@media (min-width:600px)": {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.8rem",
    },
  },
  newLink: {
    fontWeight: "bold",
    textDecoration: "none",
    color: "#000000",
  },
  media: {
    height: 140,
  },
  seeall: {
    textDecoration: "none",
    color: "#7f7f7f",
  },
  themeImg: {
    width: "100%",
    display: "block",
    aspectRatio: "1/1",
  },

  themeImghover: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    opacity: "0",

    "&:hover": {
      backgroundColor: "#ff7348a8",
      transition: ".5s ease",
      opacity: "1",
      //borderColor: '#0062cc',
    },
  },
  themeImghovertext: {
    color: "black",
    fontSize: "30px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
}));

// let theme = createMuiTheme();
// theme = responsiveFontSizes(theme);
//MapPage
const HomePage = (props) => {
  const [article, setArticle] = useState([{ type_id: 4 }]);
  const matches = useMediaQuery("(min-width:1565px)");

  const fetchView_log = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/view_log", {
      method: "POST",

      headers: {
        Authorization: `Bearer ${props.access_token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        page: "home",
      }),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };
  const [type, setType] = useState([
    {
      id: 1,
      name: "正在載入",
      thubnail: null,
      is_themes: 1,
      operator_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
      created_at: null,
      updated_at: null,
      rules: {
        id: 1,
        type_id: 1,
        need_name: 1,
        need_era: 1,
        need_summary: 1,
        need_url: 0,
        need_relation: 1,
        need_tag: 1,
        need_recommend: 0,
        need_visible: 0,
        need_content: 1,
      },
    },
  ]);

  const classes = useStyles();
  //run once after first render for check login
  useEffect(() => {
    const fetchData = async () => {
      const thedata = await Promise.all([
        fetchTagsData(),
        fetchCategoriesData(),
        fetchThemesData(),
        fetchArticlesData(),
        fetchView_log(),
      ]);
      //console.log(thedata[0].tags);
      //console.log(thedata[3].articles);
      // tags = thedata[0].tags;
      // categories = thedata[1].categories;
      // types = thedata[2].types;
      thedata[3].articles = thedata[3].articles.map((tag) => {
        //tag.uploadedby = tag.creator.account;
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        const cd = new Date(tag.created_at);
        const ud = new Date(tag.updated_at);
        tag.created_at =
          months[cd.getMonth()] + " " + cd.getDate() + " , " + cd.getFullYear();
        tag.updated_at =
          months[ud.getMonth()] + " " + ud.getDate() + " , " + ud.getFullYear();
        return tag;
      });
      setArticle(thedata[3].articles);
      setType(thedata[2].types);
      //setTagChip(TagChip);
    };
    fetchData();
  }, []);
  //all fetch function
  const fetchArticlesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/articles")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };
  const fetchThemesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/types")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { types: myJson };
      });
  };
  const fetchTagsData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/tags")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { tags: myJson };
        //setTagsData(temp);
      });
  };
  const fetchCategoriesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/categories")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { categories: myJson };
        //setTagsData(temp);
      });
  };

  return (
    <>
      <div
        style={matches ? { padding: " 8px  40px" } : { padding: " 8px  8px" }}
      >
        <FrontAppBar />

        {/* <ThemeProvider theme={theme}> */}
        {/* <Modal
          className={classes.modal}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"

          open={true}
        >
          <img style={{ width: '60vw',}} src={require('./assets/ad.png')}></img> */}
        {/* 
      </Modal> */}
        {matches ? (
          <Grid
            container
            spacing={1}
            direction="row"
            md={12}
            sm={12}
            xs={12}
            lg={12}
            alignItems="stretch"
            alignContent="flex-start"
          >
            {matches && (
              <Grid container item md={1} sm={1} xs={1} lg={1}></Grid>
            )}
            <Grid
              container
              item
              lg={5}
              md={12}
              sm={12}
              xs={12}
              style={{ paddingRight: "16px" }}
            >
              <Grid item md={12} lg={12} sm={12} xs={12}>
                <Typography
                  variant="h4"
                  className={classes.title}
                  style={{ paddingBottom: "12px" }}
                >
                  地圖探索! Explore on Map!
                </Typography>

                <Link to="/MapPage" style={{ paddingTop: "20px" }}>
                  {" "}
                  <div style={{ position: "relative" }}>
                    <img src={require("./assets/home_map.png")} width="100%" />
                    <div className={classes.themeImghover}>
                      <div className={classes.themeImghovertext}>
                        {/*Going!*/}
                      </div>
                    </div>
                  </div>
                </Link>
              </Grid>
              <Grid item md={12} lg={12} sm={12} xs={12}>
                <Typography
                  variant="h4"
                  style={{ paddingBottom: "8px", paddingTop: "16px" }}
                  className={classes.title}
                >
                  達人帶路! Explore other's paths!
                </Typography>

                <Link to="/MapPond">
                  {" "}
                  <div style={{ position: "relative" }}>
                    <img
                      src={require("./assets/planMap.png")}
                      width="100%"
                      height="100%"
                    />
                    <div className={classes.themeImghover}>
                      <div className={classes.themeImghovertext}></div>
                    </div>
                  </div>
                </Link>
              </Grid>
            </Grid>

            <Grid
              container
              item
              md={12}
              lg={5}
              sm={12}
              xs={12}
              style={{ paddingLeft: "8px" }}
              alignItems="flex-start"
              alignContent="flex-start"
              justifyContent="flex-start"
            >
              <Grid container item md={12} lg={12} sm={12} xs={12}>
                <div />
                <div style={{ display: "flex" }}>
                  <Typography
                    variant="h4"
                    className={classes.title}
                    style={{ paddingBottom: "12px" }}
                  >
                    主題探索 Explore from themes
                  </Typography>
                  <div style={{ flexGrow: 1 }} />
                </div>
                <Grid container alignItems="flex-start" item>
                  {type
                    .filter((item) => {
                      return item.is_themes;
                    })
                    .sort(() => Math.random() - 0.5)
                    .slice(0, 9)
                    .map((item) => (
                      <Grid item md={4} sm={4} xs={4}>
                        <div style={{ position: "relative" }}>
                          <Link
                            style={{ textDecorationThickness: "none" }}
                            to={"/themes/" + item.id}
                          >
                            {" "}
                            {item.thumbnail ? (
                              <img
                                src={
                                  "https://uisur.ad.ntust.edu.tw/backend/storage/" +
                                  item.thumbnail
                                }
                                className={classes.themeImg}
                              />
                            ) : (
                              <img
                                src={require("./assets/exploreMap.png")}
                                className={classes.themeImg}
                              />
                            )}
                            <div
                              style={{
                                position: "absolute",
                                color: "white",
                                bottom: "0%",
                                left: "50%",
                                transform: "translateX(-50%) ",
                                background: "#000000",
                                opacity: ".7",
                                width: "100%",
                                textAlign: "center",
                                fontSize: "22px",
                              }}
                            >
                              {item.name}
                            </div>
                            <div className={classes.themeImghover}>
                              <div className={classes.themeImghovertext}>
                                {/*Going!*/}
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
              {matches && (
                <Grid item md={1} sm={1} xs={1} lg={1}>
                  {" "}
                </Grid>
              )}
            </Grid>
            <Grid container item md={1} lg={1} sm={1} xs={1}>
              <Link to="/themes/115">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<div class="wrapper">
          <a class="cta" href="#">
            <span>稻埕醬走</span>
            <span>
              <svg
                width="66px"
                height="23px"
                viewBox="0 0 66 43"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  id="arrow"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <path
                    class="one"
                    d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z"
                    fill="#FFFFFF"
                  ></path>
                  <path
                    class="two"
                    d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z"
                    fill="#FFFFFF"
                  ></path>
                  <path
                    class="three"
                    d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z"
                    fill="#FFFFFF"
                  ></path>
                </g>
              </svg>
            </span>
          </a>
        </div>`,
                  }}
                ></div>
              </Link>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            spacing={1}
            direction="row"
            md={12}
            sm={12}
            xs={12}
            lg={12}
            alignItems="stretch"
            alignContent="flex-start"
          >
            {matches && (
              <Grid container item md={1} sm={1} xs={1} lg={1}></Grid>
            )}
            <Grid container item lg={12} md={12} sm={12} xs={12} style={{}}>
              <Grid item md={12} lg={12} sm={12} xs={12}>
                <Typography
                  variant="h4"
                  className={classes.title}
                  style={{ paddingBottom: "12px" }}
                >
                  地圖探索! Explore on Map!
                </Typography>

                <Link to="/MapPage" style={{ paddingTop: "20px" }}>
                  {" "}
                  <div style={{ position: "relative" }}>
                    <img src={require("./assets/home_map.png")} width="100%" />
                    <div className={classes.themeImghover}>
                      <div className={classes.themeImghovertext}>
                        {/*Going!*/}
                      </div>
                    </div>
                  </div>
                </Link>
              </Grid>
              <Grid item md={12} lg={12} sm={12} xs={12}>
                <Typography
                  variant="h4"
                  style={{ paddingBottom: "8px", paddingTop: "16px" }}
                  className={classes.title}
                >
                  達人帶路! Explore other's paths!
                </Typography>

                <Link to="/MapPond">
                  {" "}
                  <div style={{ position: "relative" }}>
                    <img
                      src={require("./assets/planMap.png")}
                      width="100%"
                      height="100%"
                    />
                    <div className={classes.themeImghover}>
                      <div className={classes.themeImghovertext}>
                        {/*Going!*/}
                      </div>
                    </div>
                  </div>
                </Link>
              </Grid>
            </Grid>

            <Grid
              container
              item
              md={12}
              lg={12}
              sm={12}
              xs={12}
              style={{}}
              alignItems="flex-start"
              alignContent="flex-start"
              justifyContent="flex-start"
            >
              <Grid container item md={12} lg={12} sm={12} xs={12}>
                <div />
                <Typography
                  variant="h4"
                  className={classes.title}
                  style={{ paddingBottom: "12px" }}
                >
                  主題探索 Explore from themes
                </Typography>

                <Grid container alignItems="flex-start" item>
                  {type
                    .filter((item) => {
                      return item.is_themes;
                    })
                    .sort(() => Math.random() - 0.5)
                    .slice(0, 9)
                    .map((item) => (
                      <Grid item md={4} sm={4} xs={4}>
                        <div style={{ position: "relative" }}>
                          <Link
                            style={{ textDecorationThickness: "none" }}
                            to={"/themes/" + item.id}
                          >
                            {" "}
                            {item.thumbnail ? (
                              <img
                                src={
                                  "https://uisur.ad.ntust.edu.tw/backend/storage/" +
                                  item.thumbnail
                                }
                                className={classes.themeImg}
                              />
                            ) : (
                              <img
                                src={require("./assets/exploreMap.png")}
                                className={classes.themeImg}
                              />
                            )}
                            <div
                              style={{
                                position: "absolute",
                                color: "white",
                                bottom: "0",
                                left: "50%",
                                transform: "translateX(-50%)",
                                background: "#000000",
                                opacity: ".7",
                                width: "100%",
                                textAlign: "center",
                                fontSize: "14px",
                              }}
                            >
                              {item.name}
                            </div>
                            <div className={classes.themeImghover}>
                              <div className={classes.themeImghovertext}>
                                {/*Going!*/}
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
              {matches && (
                <Grid item md={1} sm={1} xs={1} lg={1}>
                  {" "}
                </Grid>
              )}
            </Grid>
          </Grid>
        )}

        {matches ? (
          <Grid
            container
            spacing={1}
            direction="row"
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            alignItems="stretch"
            alignContent="flex-start"
          >
            {matches && (
              <Grid container item md={1} sm={1} xs={1} lg={1}></Grid>
            )}
            <Grid
              container
              item
              xl={5}
              lg={5}
              md={12}
              sm={12}
              xs={12}
              style={{ paddingRight: "16px" }}
            >
              {article.filter((item) => {
                return item.type_id === 5;
              }).length != 0 && (
                <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="h6" className={classes.title}>
                      稻埕故事&nbsp;&nbsp;Stories&nbsp;
                      <Typography
                        variant="body1"
                        component={Link}
                        to="/searchPage/themes=5"
                        className={classes.seeall}
                      >
                        see all
                      </Typography>
                    </Typography>
                  </Grid>
                  {article
                    .filter((item) => {
                      return item.type_id === 5;
                    })
                    .slice(0, 2)
                    .map((item) => (
                      <>
                        <Grid
                          item
                          md={7}
                          lg={7}
                          sm={7}
                          xs={7}
                          style={{
                            paddingRight: "20px",
                            paddingBottom: "10px",
                          }}
                        >
                          <Link to={"/Article/" + item.name}>
                            <div style={{ position: "relative" }}>
                              <img
                                src={
                                  "https://uisur.ad.ntust.edu.tw/backend/storage/" +
                                  item.thumbnail
                                }
                                style={{ aspectRatio: "1.6/1", width: "100%" }}
                              />
                              <div className={classes.themeImghover}>
                                <div className={classes.themeImghovertext}>
                                  {/*Going!*/}
                                </div>
                              </div>
                            </div>
                          </Link>
                        </Grid>
                        <Grid
                          style={{
                            position: "relative",
                            paddingBottom: "10px",
                          }}
                          item
                          md={5}
                          lg={5}
                          sm={5}
                          xs={5}
                        >
                          <Typography
                            variant="h6"
                            component={Link}
                            to={"/Article/" + item.name}
                            className={classes.newLink}
                            display="block"
                          >
                            {item.name}
                          </Typography>
                          <Typography
                            variant="body"
                            component={Link}
                            display="block"
                            to={"/Article/" + item.name}
                            style={{ lineHeight: "2" }}
                            className={classes.newLink}
                          >
                            {item.created_at}
                          </Typography>
                          <Typography
                            variant="body"
                            component={Link}
                            to={"/Article/" + item.name}
                            className={classes.newLink}
                          >
                            {item.summary}
                          </Typography>
                          <Link
                            style={{ textDecoration: "none" }}
                            to={"/Article/" + item.name}
                          >
                            <Button
                              style={{
                                position: "absolute",
                                bottom: "20px",
                                right: "20px",
                                wordBreak: "keep-all",
                                wordWrap: "normal",
                                color: "white",
                                background: "black",
                              }}
                            >
                              閱讀更多
                            </Button>
                          </Link>
                        </Grid>
                      </>
                    ))}
                </Grid>
              )}
            </Grid>
            <Grid
              container
              item
              lg={5}
              md={5}
              sm={12}
              xs={12}
              style={{ paddingRight: "16px" }}
              alignContent="flex-start"
            >
              {article.filter((item) => {
                return (item.type_id === 6) | (item.type_id === 7);
              }).length != 0 && (
                <Grid item container md={12} lg={12} sm={12} xs={12}>
                  <Typography variant="h6" className={classes.title}>
                    消息與活動 &nbsp;&nbsp;News & Events &nbsp;
                    <Typography
                      variant="body1"
                      component={Link}
                      to="/searchPage/themes=6,7"
                      className={classes.seeall}
                    >
                      see all
                    </Typography>
                  </Typography>

                  <Divider />

                  {article
                    .filter((item) => {
                      return (item.type_id === 6) | (item.type_id === 7);
                    })
                    .slice(0, 8)
                    .map((item) => (
                      <Grid
                        item
                        md={12}
                        lg={12}
                        sm={12}
                        xs={12}
                        justifyContent="center"
                        style={{
                          paddingTop: "16px",
                          paddingBottom: "16px",
                          borderBottom: "1px solid black",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {/* <Avatar
                              style={{
                                display: "flex",
                                width: "40px",
                                height: "40px",
                                color: "black",
                                background: "white",
                                border: "1px solid black",
                              }}
                            >
                              {item.type_id === 6
                                ? "消息"
                                : item.type_id === 5
                                  ? "stories"
                                  : "活動"}
                            </Avatar> */}

                          <Typography
                            display="inline"
                            variant="body"
                            component={Link}
                            to={"/Article/" + item.name}
                            style={{ marginLeft: "8px", fontSize: "20px" }}
                            className={classes.newLink}
                          >
                            {item.type_id === 6
                              ? "消息" + " ｜ "
                              : item.type_id === 5
                              ? "stories" + " ｜ "
                              : "活動" + " ｜ "}
                            {item.name + " " + item.created_at}
                          </Typography>
                          <div style={{ flexGrow: 1 }} />
                          <Link
                            style={{ textDecoration: "none" }}
                            to={"/Article/" + item.name}
                          >
                            <Button
                              style={{ color: "white", background: "black" }}
                            >
                              閱讀更多
                            </Button>
                          </Link>
                        </div>
                      </Grid>
                    ))}
                </Grid>
              )}
            </Grid>
            {matches && (
              <Grid container item md={1} sm={1} xs={1} lg={1}></Grid>
            )}
          </Grid>
        ) : (
          <Grid
            container
            spacing={1}
            direction="row"
            md={12}
            sm={12}
            xs={12}
            lg={12}
            alignItems="stretch"
            alignContent="flex-start"
          >
            {matches && (
              <Grid container item md={1} sm={1} xs={1} lg={1}></Grid>
            )}
            <Grid
              container
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ paddingRight: "16px" }}
            >
              {article.filter((item) => {
                return item.type_id === 5;
              }).length != 0 && (
                <Grid
                  item
                  container
                  style={{ paddingBottom: "10px", paddingTop: "10px" }}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="h6" className={classes.title}>
                      稻埕故事&nbsp;&nbsp;Stories&nbsp;
                      <Typography
                        variant="body1"
                        component={Link}
                        to="/searchPage/themes=5"
                        className={classes.seeall}
                      >
                        see all
                      </Typography>
                    </Typography>
                  </Grid>
                  {article
                    .filter((item) => {
                      return item.type_id === 5;
                    })
                    .slice(0, 2)
                    .map((item) => (
                      <>
                        <Grid item md={7} lg={7} sm={12} xs={12}>
                          <Link to={"/Article/" + item.name}>
                            <div style={{ position: "relative" }}>
                              <img
                                src={
                                  "https://uisur.ad.ntust.edu.tw/backend/storage/" +
                                  item.thumbnail
                                }
                                style={{ aspectRatio: "1.6/1", width: "100%" }}
                              />
                              <div className={classes.themeImghover}>
                                <div className={classes.themeImghovertext}>
                                  {/*Going!*/}
                                </div>
                              </div>
                            </div>
                          </Link>
                        </Grid>
                        <Grid
                          style={{ position: "relative" }}
                          item
                          md={5}
                          lg={5}
                          sm={12}
                          xs={12}
                        >
                          <Typography
                            variant="h6"
                            component={Link}
                            to={"/Article/" + item.name}
                            className={classes.newLink}
                            display="inline"
                          >
                            {item.name + "　　"}
                          </Typography>
                          <Typography
                            variant="body"
                            component={Link}
                            display="block"
                            to={"/Article/" + item.name}
                            style={{ lineHeight: "2" }}
                            className={classes.newLink}
                          >
                            {item.created_at}
                          </Typography>
                          <Typography
                            variant="body"
                            component={Link}
                            to={"/Article/" + item.name}
                            className={classes.newLink}
                          >
                            {item.summary}
                          </Typography>
                          <Hidden smDown>
                            <Link
                              style={{ textDecoration: "none" }}
                              to={"/Article/" + item.name}
                            >
                              <Button
                                style={{
                                  position: "absolute",
                                  bottom: "10px",
                                  right: "20px",
                                  wordBreak: "keep-all",
                                  wordWrap: "normal",
                                  color: "white",
                                  background: "black",
                                }}
                              >
                                閱讀更多
                              </Button>
                            </Link>
                          </Hidden>
                        </Grid>
                      </>
                    ))}
                </Grid>
              )}
            </Grid>
            <Grid
              container
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ paddingRight: "16px" }}
            >
              {article.filter((item) => {
                return (item.type_id === 6) | (item.type_id === 7);
              }).length != 0 && (
                <Grid item container md={12} lg={12} sm={12} xs={12}>
                  <Typography variant="h6" className={classes.title}>
                    消息與活動 &nbsp;&nbsp;News & Events &nbsp;
                    <Typography
                      variant="body1"
                      component={Link}
                      to="/searchPage/themes=6,7"
                      className={classes.seeall}
                    >
                      see all
                    </Typography>
                  </Typography>

                  <Divider />

                  {article
                    .filter((item) => {
                      return (item.type_id === 6) | (item.type_id === 7);
                    })
                    .slice(0, 8)
                    .map((item) => (
                      <Grid
                        item
                        md={12}
                        lg={12}
                        sm={12}
                        xs={12}
                        justifyContent="center"
                        style={{
                          paddingTop: "16px",
                          paddingBottom: "16px",
                          borderBottom: "1px solid black",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {/* <Avatar
                              style={{
                                display: "flex",
                                width: "40px",
                                height: "40px",
                                color: "black",
                                background: "white",
                                border: "1px solid black",
                              }}
                            >
                              {item.type_id === 6
                                ? "消息"
                                : item.type_id === 5
                                  ? "stories"
                                  : "活動"}
                            </Avatar> */}

                          <Typography
                            display="inline"
                            variant="body"
                            component={Link}
                            to={"/Article/" + item.name}
                            style={{ marginLeft: "8px" }}
                            className={classes.newLink}
                          >
                            {item.type_id === 6
                              ? "消息" + " ｜ "
                              : item.type_id === 5
                              ? "stories" + " ｜ "
                              : "活動" + " ｜ "}
                            {item.name + " " + item.created_at}
                          </Typography>
                          <div style={{ flexGrow: 1 }} />
                          <Link
                            style={{ textDecoration: "none" }}
                            to={"/Article/" + item.name}
                          >
                            <Button
                              style={{
                                wordBreak: "keep-all",
                                wordWrap: "normal",
                                color: "white",
                                background: "black",
                              }}
                            >
                              閱讀更多
                            </Button>
                          </Link>
                        </div>
                      </Grid>
                    ))}
                </Grid>
              )}
            </Grid>
            {matches && (
              <Grid container item md={1} sm={1} xs={1} lg={1}></Grid>
            )}
          </Grid>
        )}
        {/* </ThemeProvider> */}
      </div>
    </>
  );
};

export default HomePage;
