import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import RoomIcon from "@material-ui/icons/Room";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import Cookies from "js-cookie";
import Avatar from "@material-ui/core/Avatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import Collapse from "@material-ui/core/Collapse";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import RestoreIcon from "@material-ui/icons/Restore";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PinDropIcon from "@material-ui/icons/PinDrop";
import NoteIcon from "@material-ui/icons/Note";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import DirectionsIcon from "@material-ui/icons/Directions";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import FrontAppBar from "../FrontAppBar/FrontAppBar.js";
import Map from "./MapPondConponent/Map";
import { BrowserRouter, Route, Link, HashRouter } from "react-router-dom";
import {
  Box,
  Chip,
  Grid,
  Tab,
  Tabs,
  Typography,
  Modal,
  Hidden,
} from "@material-ui/core";
import { BorderBottom } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
    height: "30px",
  },
  list: {
    width: 250,
  },
  contestChosebtn: {
    [theme.breakpoints.up("md")]: {
      fontSize: "25px",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  fullList: {
    width: "auto",
  },

  DrawerIndex: {
    zIndex: theme.zIndex.modal - 100,
  },
  topSelectButton: {
    fontSize: "20px",
    fontWeight: "900",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  MapPondstr: {
    fontWeight: "900",
    paddingBottom: "25px",
    paddingTop: "10px",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
  },
  ava: {
    fontSize: "10px",
    position: "absolute",
    bottom: "5px",
    right: "43px",
    background: "white",

    color: "black",
    border: "1px black solid",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      position: "absolute",
      bottom: "5px",
      right: "20px",
    },
  },
  contestingStr: {
    fontWeight: "900",
    fontSize: "30px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  ava1: {
    fontSize: "10px",
    position: "absolute",
    bottom: "5px",
    right: "90px",
    background: "white",

    color: "red",
    border: "1px red solid",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      position: "absolute",
      bottom: "5px",
      right: "67px",
    },
  },
}));

export default function MapPond(props) {
  const classes = useStyles();
  const [type, setType] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [tabValue, setTabValue] = React.useState("官方");
  const [contestsEvents, setContestsEvents] = React.useState([]);
  const [contests, setContests] = useState([]);
  const [mymap, setMymap] = React.useState([
    {
      id: 20,
      type_id: 11,
      name: "正在載入",
      places: [],
      summary: "b",
      title: "titleHERE",
      url: null,
      relations_uuid: "2283f55b-36e7-4247-95d1-e6f6cf6e21e3",
      content: "7",
      weight: 0,
      thumbnail: "thumbnails/EeCrgGDGr9SIBVi1q8B9lm9iwqbtKcBPKS63bPId.png",
      views: 0,
      is_visible: 0,
      is_drafted: 0,
      tags: [],
      is_allowed: 0,
      creator: {
        account: "admin",
        created_at: "2021-02-05T09:16:47.000000Z",
        type: "admin",
        updated_at: "2021-02-05T09:16:47.000000Z",
        uuid: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
        creator_id: "42652e3b-b746-47c4-a3b7-a9962c0c6eac",
      },
      checker_id: null,
      available_at: null,
      created_at: "2021-03-01T07:05:10.000000Z",
      updated_at: "2021-03-01T07:05:10.000000Z",
      start_year: 1911,
      end_year: 1912,
    },
  ]);
  let [chooseContestsEvents, setChooseContestsEvents] = useState(3);
  let [access_token, setAccess_token] = React.useState("");
  let [isLogin, setIslogin] = React.useState(false);
  const DateToString = (inputDate) => {
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    var theDate = new Date(inputDate);
    return (
      months[theDate.getMonth()].substr(0, 3) +
      " " +
      theDate.getDate() +
      " , " +
      theDate.getFullYear()
    );
  };
  React.useEffect(() => {
    access_token = Cookies.get("access_token");
    if (access_token === undefined) {
      isLogin = false;
    } else {
      isLogin = true;

      // accountName = Cookies.get("accountName");
    }

    const fetchData = async () => {
      var thedata = await Promise.all([
        fetchMyMap(),
        fetchThemesData(),
        fetchView_log(),
        fetchContestsEvents(),
        fetchContests(),
      ]);
      // setMymap(thedata[0].articles);
      thedata[1].types = thedata[1].types.map((item) => {
        item.check = false;
        return item;
      });
      setType(thedata[1].types);
      setContestsEvents(thedata[3].types);
      setContests(thedata[4].types);
      console.log(thedata);
    };
    fetchData();
    setIslogin(isLogin);
    setAccess_token(access_token);
    map_filter(tabValue);
    console.log(isLogin, access_token);
  }, []);
  const map_filter = async (input) => {
    const query = {};
    if (input === "最新") {
      query["order"] = "desc";
      query["orderBy"] = "created_at";
    } else if (input === "官方") {
      query["type"] = "manager";
      // query['order'] = 'desc'
    } else if (input === "熱門") {
      query["order"] = "desc";
      query["orderBy"] = "views";
    } else if (input === "比賽") {
      query["order"] = "desc";
      query["orderBy"] = "views";
    } else if (input === "精選") {
      query["order"] = "desc";
      query["orderBy"] = "views";
      query["chosen"] = "1";
    }
    var thedata = await Promise.all([fetchMyMap(query)]);
    setMymap(thedata[0].articles);
  };

  const fetchView_log = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/view_log", {
      method: "POST",

      headers: {
        Authorization: `Bearer ${props.access_token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        page: "map_pond",
      }),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };
  const fetchContests = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/contests")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { types: myJson };
      });
  };
  const fetchContestsEvents = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/contests/events")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { types: myJson };
      });
  };
  const fetchThemesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/types")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { types: myJson };
      });
  };
  const fetchMyMap = (q = {}) => {
    return fetch(
      "https://uisur.ad.ntust.edu.tw/backend/api/maps?" +
      new URLSearchParams(q).toString(),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };

  return (
    <>
      {/* <Paper component="form" className={classes.root}>
        <InputBase
          className={classes.input}
          placeholder="Search Map"
          fullWidth
          onChange={(e) => {
            setMymap(
              originData.filter((item) => {
                console.log(item.name.search(e.target.value));
                return item.name.search(e.target.value) != -1;
              })
            );
          }}
          inputProps={{ "aria-label": "search google maps" }}
        />
      
      </Paper> */}
      <FrontAppBar />

      <Modal
        className={classes.modal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      >
        <Paper className={classes.paper}>
          <h2 id="transition-modal-title">請選擇主題</h2>
          <Divider />
          {type
            .filter((item) => {
              return item.is_themes === 1;
            })
            .map((item) => (
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={async (e) => {
                      const query = {};
                      query["thene"] = item.id;
                      var thedata = await Promise.all([fetchMyMap(query)]);
                      setMymap(thedata[0].articles);
                      setOpen(false);
                    }}
                    checked={item.check === true}
                  />
                }
                label={item.name}
              />
            ))}
          <Divider />
        </Paper>
      </Modal>

      <Grid
        container
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          paddingLeft: "8.3%",
          paddingRight: "8.3%",
        }}
      >
        <Grid
          container
          item
          alignContent="flex-start"
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ paddingBottom: "20px" }}
        >
          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.MapPondstr} variant="h4">
              地圖分享池 MapPond
            </Typography>
          </Grid>
          <Grid
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            style={
              tabValue === "官方"
                ? {
                  borderLeft: "10px solid black",
                  background: "#eeeceb",
                  marginRight: "10px",
                  paddingRight: "10px",
                }
                : { marginRight: "10px" }
            }
          >
            <Link
              onClick={() => {
                setTabValue("官方");
                map_filter("官方");
              }}
              onMouseOut={(e) => {
                e.target.style.textDecoration = "none";
              }}
              onMouseOver={(e) => {
                e.target.style.textDecoration = "underline";
              }}
              style={
                tabValue === "官方"
                  ? {
                    textDecoration: "none",
                    fontWeight: "550",
                    color: "black",
                  }
                  : { textDecoration: "none", color: "black" }
              }
            >
              <Button fullWidth>
                <Typography className={classes.topSelectButton}>
                  官方
                </Typography>
              </Button>
            </Link>
          </Grid>
          <Grid
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            style={
              tabValue === "精選"
                ? {
                  borderLeft: "10px solid black",
                  background: "#eeeceb",
                  marginRight: "10px",
                  paddingRight: "10px",
                }
                : { marginRight: "10px" }
            }
          >
            <Link
              onClick={() => {
                setTabValue("精選");
                map_filter("精選");
              }}
              onMouseOut={(e) => {
                e.target.style.textDecoration = "none";
              }}
              onMouseOver={(e) => {
                e.target.style.textDecoration = "underline";
              }}
              style={
                tabValue === "精選"
                  ? {
                    textDecoration: "none",
                    fontWeight: "550",
                    color: "black",
                  }
                  : { textDecoration: "none", color: "black" }
              }
            >
              <Button fullWidth>
                <Typography className={classes.topSelectButton}>
                  精選
                </Typography>
              </Button>
            </Link>
          </Grid>
          <Grid
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            style={
              tabValue === "熱門"
                ? {
                  borderLeft: "10px solid black",
                  background: "#eeeceb",
                  marginRight: "10px",
                  paddingRight: "10px",
                }
                : { marginRight: "10px" }
            }
          >
            <Link
              onClick={() => {
                setTabValue("熱門");
                map_filter("熱門");
              }}
              onMouseOut={(e) => {
                e.target.style.textDecoration = "none";
              }}
              onMouseOver={(e) => {
                e.target.style.textDecoration = "underline";
              }}
              style={
                tabValue === "熱門"
                  ? {
                    textDecoration: "none",
                    fontWeight: "550",
                    color: "black",
                  }
                  : { textDecoration: "none", color: "black" }
              }
            >
              <Button fullWidth>
                <Typography className={classes.topSelectButton}>
                  熱門
                </Typography>
              </Button>
            </Link>
          </Grid>
          <Grid
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            style={
              tabValue === "最新"
                ? {
                  borderLeft: "10px solid black",
                  background: "#eeeceb",
                  marginRight: "10px",
                  paddingRight: "10px",
                }
                : { marginRight: "10px" }
            }
          >
            <Link
              onClick={() => {
                setTabValue("最新");
                map_filter("最新");
              }}
              onMouseOut={(e) => {
                e.target.style.textDecoration = "none";
              }}
              onMouseOver={(e) => {
                e.target.style.textDecoration = "underline";
              }}
              style={
                tabValue === "最新"
                  ? {
                    textDecoration: "none",
                    fontWeight: "550",
                    color: "black",
                  }
                  : { textDecoration: "none", color: "black" }
              }
            >
              <Button fullWidth>
                <Typography className={classes.topSelectButton}>
                  最新
                </Typography>
              </Button>
            </Link>
          </Grid>
          <Grid
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            style={
              tabValue === "比賽"
                ? {
                  borderLeft: "10px solid black",
                  background: "#eeeceb",
                  marginRight: "10px",
                  paddingRight: "10px",
                }
                : { marginRight: "10px" }
            }
          >
            <Link
              onClick={() => {
                setTabValue("比賽");
                map_filter("比賽");
              }}
              onMouseOut={(e) => {
                e.target.style.textDecoration = "none";
              }}
              onMouseOver={(e) => {
                e.target.style.textDecoration = "underline";
              }}
              style={
                tabValue === "比賽"
                  ? {
                    textDecoration: "none",
                    fontWeight: "550",
                    color: "black",
                  }
                  : { textDecoration: "none", color: "black" }
              }
            >
              <Button fullWidth>
                <Typography className={classes.topSelectButton}>
                  比賽
                </Typography>
              </Button>
            </Link>
          </Grid>
          <Link to="/themes/115">
            <Hidden mdUp>
              <div
                style={{ paddingLeft: "10px" }}
                dangerouslySetInnerHTML={{
                  __html: `<div class="wrapper">
          <a class="ctaphone" href="#">
            <span>參賽說明</span>
            <span>
              <svg
                width="66px"
                height="23px"
                viewBox="0 0 66 43"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  id="arrow"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <path
                    class="one"
                    d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z"
                    fill="#FFFFFF"
                  ></path>
                  <path
                    class="two"
                    d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z"
                    fill="#FFFFFF"
                  ></path>
                  <path
                    class="three"
                    d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z"
                    fill="#FFFFFF"
                  ></path>
                </g>
              </svg>
            </span>
          </a>
        </div>`,
                }}
              ></div>
            </Hidden>
            <Hidden smDown>
              <div
                style={{ paddingLeft: "10px" }}
                dangerouslySetInnerHTML={{
                  __html: `<div class="wrapper">
          <a class="cta" href="#">
            <span>參賽說明</span>
            <span>
              <svg
                width="66px"
                height="23px"
                viewBox="0 0 66 43"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  id="arrow"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <path
                    class="one"
                    d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z"
                    fill="#FFFFFF"
                  ></path>
                  <path
                    class="two"
                    d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z"
                    fill="#FFFFFF"
                  ></path>
                  <path
                    class="three"
                    d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z"
                    fill="#FFFFFF"
                  ></path>
                </g>
              </svg>
            </span>
          </a>
        </div>`,
                }}
              ></div>
            </Hidden>
          </Link>
          {/* <Grid xl={2} lg={2} md={2} sm={2} xs={2} style={tabValue === "主題" ? {
            borderLeft: "10px solid black",
            background: '#eeeceb'

          } : {}}>
            <Link
              onClick={() => {
                setTabValue("主題");
                setOpen(true);
              }}
              onMouseOut={(e) => {
                e.target.style.textDecoration = "none";
              }}
              onMouseOver={(e) => {
                e.target.style.textDecoration = "underline";
              }}
              style={
                tabValue === "主題"
                  ? {
                    textDecoration: "none",
                    fontWeight: "550",
                    color: "black",
                  }
                  : { textDecoration: "none", color: "black" }
              }
            >
              <Button fullWidth>
                <Typography variant={tabValue === "主題" ? "h6" : "h6"}>
                  主題
                </Typography>
              </Button>
            </Link>
          </Grid> */}
        </Grid>
        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
          {tabValue != "比賽" &&
            mymap.map((item) => (
              <Grid
                style={{
                  paddingLeft: "2.5%",
                  paddingRight: "2.5%",
                }}
                item
                xl={4}
                lg={4}
                md={4}
                sm={6}
                xs={12}
              >
                {/* <Link to ={"/Mapbox/"+item.id.toString()}> */}
                <Paper
                  square
                  style={{
                    height: "300px",

                    marginTop: "8px",
                  }}
                >
                  <Map points={item.places}></Map>
                </Paper>
                {/* </Link> */}
                <Link
                  style={{ textDecoration: "none" }}
                  to={"/Mapbox/" + item.id.toString()}
                >
                  <Paper
                    elevation={0}
                    square
                    style={{
                      position: "relative",
                    }}
                  >
                    <div style={{ padding: "10px" }}>
                      <div style={{ display: "flex" }}>
                        <Typography style={{ display: "inline-block" }}>
                          {item.name +
                            "  /  " +
                            item.places.length.toString() +
                            " places"}{" "}
                        </Typography>
                        <RoomIcon fontSize="10px"></RoomIcon>
                      </div>

                      <Typography>
                        {(item.creator.name != null
                          ? item.creator.name
                          : item.creator.account) +
                          " 發布於 " +
                          DateToString(item.created_at)}{" "}
                      </Typography>

                      {item.tags.map((sitem) => (
                        <Link
                          style={{ textDecoration: "none" }}
                          to={"/SearchPage/tags=" + encodeURI(sitem.name.toString())}
                        >
                          <Chip
                            style={{ background: "#ff7348", marginLeft: 2 }}
                            label={sitem.name}
                          />
                        </Link>
                      ))}
                      <Link to={"/Mapbox/" + item.id.toString()}>
                        {/* <IconButton
                    aria-label="delete"
                    style={{
                      position: "absolute",
                      right: "30px",
                      bottom: "10px",
                    }}
                  >
                    <ArrowRightAltIcon fontSize="inherit" />
                  </IconButton> */}
                      </Link>
                      <div style={{ display: "flex" }}>
                        <div style={{ flexGrow: 1 }}></div>
                        <Typography>
                          {"收藏數 " + item.favorites_count + "　"}
                        </Typography>
                        <Typography>{"瀏覽數 " + item.views + "　"}</Typography>
                      </div>
                    </div>
                  </Paper>
                </Link>
              </Grid>
            ))}
          {tabValue === "比賽" &&
            -1 !=
            contests.findIndex((item) => {
              return item.is_visible === 1;
            }) && (
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.contestingStr}>
                  {"✺進行中✺ " +
                    contests.find((item) => {
                      return item.is_visible === 1;
                    }).name}
                </Typography>
              </Grid>
            )}
          {tabValue === "比賽" && (
            <div style={{ display: "flex", paddingTop: "10px" }}>
              {contestsEvents.map((item) => (
                <>
                  <Hidden smDown>
                    <Chip
                      onClick={() => {
                        setChooseContestsEvents(item.id);
                        // document
                        //   .getElementById("cid" + item.id.toString())
                        //   .scrollIntoView({
                        //     behavior: "smooth",
                        //     block: "center",
                        //     inline: "start",
                        //   });
                      }}
                      style={
                        item.id === chooseContestsEvents
                          ? {
                            background: "#ff7348",
                            color: "black",
                            marginLeft: "10px",
                            display: "flex",
                            fontSize: "20px",
                            fontWeight: "900",
                            padding: "10px",
                          }
                          : {
                            marginLeft: "10px",
                            display: "flex",
                            fontSize: "20px",
                            fontWeight: "900",
                            padding: "10px",
                          }
                      }
                      label={item.name}
                    ></Chip>
                  </Hidden>
                  <Hidden mdUp>
                    <Chip
                      onClick={() => {
                        setChooseContestsEvents(item.id);
                        // document
                        //   .getElementById("cid" + item.id.toString())
                        //   .scrollIntoView({
                        //     behavior: "smooth",
                        //     block: "center",
                        //     inline: "start",
                        //   });
                      }}
                      style={
                        item.id === chooseContestsEvents
                          ? {
                            background: "#ff7348",
                            color: "black",
                            marginLeft: "10px",
                            display: "flex",
                          }
                          : {
                            marginLeft: "10px",
                            display: "flex",
                          }
                      }
                      label={item.name}
                    ></Chip>
                  </Hidden>
                </>
              ))}
            </div>
          )}
          {tabValue === "比賽" &&
            contestsEvents
              .filter((id) => {
                return id.id === chooseContestsEvents;
              })
              .map((id) => (
                <>
                  <Grid
                    style={{
                      paddingLeft: "2.5%",
                      paddingRight: "2.5%",
                    }}
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    {/* <Typography style={{ fontSize: "24px", fontWeight: "900" }}>
                      
                      {id.name}
                    </Typography>
                    <div id={"cid" + id.id}></div> */}
                  </Grid>

                  {mymap
                    .filter((temp) => {
                      return temp.contests_event_id === id.id;
                    })
                    .map((item) => (
                      <Grid
                        style={{
                          paddingLeft: "2.5%",
                          paddingRight: "2.5%",
                        }}
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                      >
                        {/* <Link to ={"/Mapbox/"+item.id.toString()}> */}
                        <Paper
                          square
                          style={{
                            height: "300px",

                            marginTop: "8px",
                          }}
                        >
                          <Map points={item.places}></Map>
                        </Paper>
                        {/* </Link> */}
                        <Link
                          style={{ textDecoration: "none" }}
                          to={"/Mapbox/" + item.id.toString()}
                        >
                          <Paper
                            elevation={0}
                            square
                            style={{
                              position: "relative",
                            }}
                          >
                            <div style={{ padding: "10px" }}>
                              <div style={{ display: "flex" }}>
                                <Typography style={{ display: "inline-block" }}>
                                  {item.name +
                                    "  /  " +
                                    item.places.length.toString() +
                                    " places"}{" "}
                                </Typography>
                                <RoomIcon fontSize="10px"></RoomIcon>
                              </div>

                              <Typography>
                                {(item.creator.name != null
                                  ? item.creator.name
                                  : item.creator.account) +
                                  " 發布於 " +
                                  DateToString(item.created_at)}{" "}
                              </Typography>

                              {item.tags.map((sitem) => (
                                <Link
                                  style={{ textDecoration: "none" }}
                                  to={
                                    "/SearchPage/tags=" + encodeURI(sitem.name.toString())
                                  }
                                >
                                  <Chip
                                    style={{
                                      background: "#ff7348",
                                      marginLeft: 2,
                                    }}
                                    label={sitem.name}
                                  />
                                </Link>
                              ))}
                              <Link to={"/Mapbox/" + item.id.toString()}></Link>
                            </div>
                            <div style={{ display: "flex" }}>
                              <div style={{ flexGrow: 1 }}></div>
                              <Typography>
                                {"收藏數 " + item.favorites_count + "　"}
                              </Typography>
                              <Typography>
                                {"瀏覽數 " + item.views + "　"}
                              </Typography>
                            </div>
                          </Paper>
                        </Link>
                      </Grid>
                    ))}
                </>
              ))}
        </Grid>
      </Grid>
    </>
  );
}
