import * as React from "react";
import {
  DataGrid,
  ColDef,
  ValueGetterParams,
  CellParams,
  GridApi,
} from "@material-ui/data-grid";
import Cookies from "js-cookie";
import Typography from "@material-ui/core/Typography";
import {
  createMuiTheme,
  withStyles,
  makeStyles,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import {
  HashRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 0, 0),
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },

  cardbutton: {
    marginLeft: "auto",
  },
  firstaccordion: {
    margin: theme.spacing(1, 0, 0, 0),
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  dividercolor: {
    backgroundColor: "#424242",

    width: "600 px",
    height: "600 px",
  },
  shareButton: {
    margin: theme.spacing(0, 0, 2, 2),
    color: "#ffffff",
    backgroundColor: "#424242",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#000000",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
  imgformat: {
    margin: theme.spacing(1, 2, 1, 1),
    width: "100%"
  },
  noteButton: {
    margin: theme.spacing(0, 0, 2, 0),
    color: "#ffffff",
    backgroundColor: "#424242",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#000000",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
  gridFirst: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  phoneGridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    boxShadow: 1,
    //height: "20vh",
    width: "100vw",
    //padding: theme.spacing(3)
  },
  gridList: {
    //width: 500,
    boxShadow: 1,
    height: "80vh",
  },

  chipitem: {
    color: "#ffffff",
    backgroundColor: "#424242",
    margin: theme.spacing(1, 1, 0, 0),
  },
  showAllPlacebutton: {
    width: "100%",
    color: "#ffffff",
    backgroundColor: "#424242",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#000000",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
}));
export default function DataTable(props) {
  var [access_token, setAccess_token] = React.useState("");
  const classes = useStyles();
  const [data, setData] = React.useState([

  ]);
  React.useEffect(() => {
    const fetchData = async () => {
      var thedata = await Promise.all([fetchSightData()]);
      console.log(thedata[0].tags);



      setData(thedata[0].tags);
    };
    fetchData();
  }, []);
  const fetchSightData = () => {
    access_token = Cookies.get("access_token");
    setAccess_token(access_token);
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/images", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { tags: myJson };
        //setTagsData(temp);
      });
  };
  return (
    <div style={{ height: 400, width: "100%" }}>
      <Typography variant="h4"> image</Typography>
      <Grid container  >
        {data.map((item) => (<Grid item

          wrap="nowrap"
          xl={3}
          lg={3}
          md={3}
          sm={3}
          xs={3}><a href={"https://uisur.ad.ntust.edu.tw/backend/storage/" + item}><img className={classes.imgformat} src={"https://uisur.ad.ntust.edu.tw/backend/storage/" + item}></img></a> </Grid>))}
      </Grid>
    </div>
  );
}
