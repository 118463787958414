import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import Cookies from "js-cookie";
import Avatar from "@material-ui/core/Avatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import Collapse from "@material-ui/core/Collapse";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import RestoreIcon from "@material-ui/icons/Restore";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PinDropIcon from "@material-ui/icons/PinDrop";
import NoteIcon from "@material-ui/icons/Note";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { BrowserRouter, Route, Link, HashRouter } from "react-router-dom";
import { Grid, Tab, Tabs, Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },

  DrawerIndex: {
    zIndex: theme.zIndex.modal - 100,
  },
}));

export default function AddCon(props) {
  const classes = useStyles();
  const { drawerState, setDrawerState } = props;
  const [open, setOpen] = React.useState(false);
  const [tabValue, setTabValue] = React.useState("Note");
  const [mypin, setMypin] = React.useState([
    {
      id: 20,
      type_id: 11,
      name: "AAA",
      summary: "b",
      title: "titleHERE",
      url: null,
      relations_uuid: "2283f55b-36e7-4247-95d1-e6f6cf6e21e3",
      content: "7",
      weight: 0,
      thumbnail: "thumbnails/EeCrgGDGr9SIBVi1q8B9lm9iwqbtKcBPKS63bPId.png",
      views: 0,
      is_visible: 0,
      is_drafted: 0,
      is_allowed: 0,
      creator_id: "42652e3b-b746-47c4-a3b7-a9962c0c6eac",
      checker_id: null,
      available_at: null,
      created_at: "2021-03-01T07:05:10.000000Z",
      updated_at: "2021-03-01T07:05:10.000000Z",
      start_year: 1911,
      end_year: 1912,
    },
  ]);
  let [access_token, setAccess_token] = React.useState("");
  let [isLogin, setIslogin] = React.useState(false);
  React.useEffect(() => {
    access_token = Cookies.get("access_token");
    if (access_token === undefined) {
      isLogin = false;
    } else {
      isLogin = true;

      // accountName = Cookies.get("accountName");
    }

    const fetchData = async () => {
      var thedata = await Promise.all([fetchMyPin()]);
      setMypin(thedata[0].articles);
    };
    if (isLogin) fetchData();
    setIslogin(isLogin);
    setAccess_token(access_token);

    console.log(isLogin, access_token);
  }, []);

  const fetchMyPin = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/favorites/articles", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };

  return (
    <>
      <Grid container justify="center" style={{ paddingTop: '30px' }} lg={12} md={12} xs={12} xl={12} sm={12}>
        <Grid item lg={3} md={12} xs={12} xl={3} sm={12}>
          <Link to="/CreateMap">
            <img
              style={{ width: "100%" }}
              src={require("./createMap.png")}
            ></img>
          </Link>{" "}
        </Grid>
        <Grid item lg={3} md={12} xs={12} xl={3} sm={12}>
          <img
            style={{ width: "100%" }}
            src={require("./shareStory.png")}
          ></img>
        </Grid>
        <Grid item lg={3} md={12} xs={12} xl={3} sm={12}>
          <img
            style={{ width: "100%" }}
            src={require("./createNewPlace.png")}
          ></img>
        </Grid>
      </Grid>
    </>
  );
}
