import React from "react";
import { BrowserRouter, Route, Link, HashRouter } from "react-router-dom";

import {
  createMuiTheme,
  withStyles,
  makeStyles,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import GridList from "@material-ui/core/GridList";
import Chip from "@material-ui/core/Chip";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import FrontAppBar from "../FrontAppBar/FrontAppBar.js";
import { Divider, Hidden, Paper, TextField } from "@material-ui/core";
import { set } from "js-cookie";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 0, 0),
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  marginTheTop: {
    marginTop: "20px",
  },
  cardbutton: {
    marginLeft: "auto",
  },
  firstaccordion: {
    margin: theme.spacing(1, 0, 0, 0),
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  dividercolor: {
    backgroundColor: "#424242",

    width: "600 px",
    height: "600 px",
  },
  shareButton: {
    margin: theme.spacing(0, 0, 2, 2),
    color: "#ffffff",
    backgroundColor: "#424242",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#000000",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
  noteButton: {
    margin: theme.spacing(0, 0, 2, 0),
    color: "#ffffff",
    backgroundColor: "#424242",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#000000",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
  gridFirst: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  phoneGridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    boxShadow: 1,
    //height: "20vh",
    width: "100vw",
    //padding: theme.spacing(3)
  },
  gridList: {
    //width: 500,
    boxShadow: 1,
    height: "80vh",
  },
  thepaper: {
    width: "400px",
    height: "420",
    padding: theme.spacing(0, 5, 0),
  },
  imgFormat: {
    margin: theme.spacing(1, 1, 0, 0),
    width: "100%",
  },
  chipitem: {
    color: "#ffffff",
    backgroundColor: "#424242",
    margin: theme.spacing(1, 1, 0, 0),
  },
  registerButton: {
    color: "#1a73e8",
  },
  loginbutton: {
    //margin: theme.spacing(1),
    backgroundColor: "#1a73e8",
    color: "#ffffff",
    textTransform: "none",

    "&:hover": {
      backgroundColor: "#1a73e8",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
  },
  third_party_login_button: {
    //margin: theme.spacing(1),
    backgroundColor: "#ffffff",
    color: "#000000",
    fontSize: '16px',
    textTransform: "none",
    border: "1px solid black",

    "&:hover": {
      backgroundColor: "#ff7348",
      //borderColor: '#0062cc',
      boxShadow: "none",

    },

  },
  showAllPlacebutton: {
    width: "100%",
    color: "#ffffff",
    backgroundColor: "#424242",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#000000",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
let textTheme = createMuiTheme();
textTheme = responsiveFontSizes(textTheme);
//MapPage
const LoginPage = (props) => {
  const [data, setData] = useState({
    is_themes: 0,
    content: [{ content: "<p></p>", startYear: "", endYear: "", title: "" }],
    name: "dsadas66666",
    thumbnail: "",
  });
  const [open, setOpen] = useState(false);
  var [account, setAccount] = useState("");
  var [password, setPassword] = useState("");
  var [access_token, setAccess_token] = useState("");
  var [refrash, SetRefrash] = useState(false);
  const [theAlert, setTheAlert] = useState({ text: "", severity: "error" });
  const classes = useStyles();
  const handleOnClick = () => {
    //SetRefrash(true);
  };
  useEffect(() => {
    if (refrash) {
      window.location.reload();
    } else {
      SetRefrash(true);
    }

    const fetchData = async () => {
      var thedata = await Promise.all([
        fetchTagsData(),
        fetchCategoriesData(),
        fetchThemesData(),
        fetchArticlesData(),
        //fetchEditData(),
      ]);
      thedata[2].types.content = JSON.parse(thedata[2].types.content);
      console.log(thedata[2].types);
      setData(thedata[2].types);
    };
    //fetchData();
  }, [props.id]);
  //all fetch function
  const fetchArticlesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/articles")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };
  const fetchThemesData = () => {
    return fetch(
      "https://uisur.ad.ntust.edu.tw/backend/api/types/" + props.id.toString()
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { types: myJson };
      });
  };
  const fetchTagsData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/tags")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { tags: myJson };
        //setTagsData(temp);
      });
  };
  const fetchCategoriesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/categories")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { categories: myJson };
        //setTagsData(temp);
      });
  };
  const fetchEditData = () => {
    return fetch(
      "https://uisur.ad.ntust.edu.tw/backend/api/articles/" + props.id.toString()
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { editData: myJson };
        //setTagsData(temp);
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const fetchLogin = () => {
    return new Promise(function (resolve, reject) {
      fetch("https://uisur.ad.ntust.edu.tw/backend/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          account: account,
          password: password,
        }),
      })
        .then(function (response) {
          if (response.ok) {
            console.log(response);
            setTheAlert({ text: "登入成功", severity: "success" });
          } else {
            setTheAlert({
              text: "登入失敗，請檢查帳號及密碼",
              severity: "error",
            });
          }
          setOpen(true);
          return response.json();
        })
        .then(function (myJson) {
          console.log(myJson);
          if (myJson.access_token !== undefined) {
            access_token = myJson.access_token;
            setAccess_token(myJson.access_token);
          }
        })
        .then(() => {
          resolve("");
        });
    });
  };
  return (
    <>
      <ThemeProvider theme={textTheme}>
        <FrontAppBar />
        <div>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={theAlert.severity}>
              {theAlert.text}
            </Alert>
          </Snackbar>
          <Grid container className={classes.root}>
            <Grid xl={1} lg={1} md={1} sm={1} xs={1} item></Grid>

            <Grid item container xl={10} lg={10} md={10} sm={10} xs={10}>
              <Grid
                container
                justify="center"
                alignItems="center"
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <Paper variant="outlined" className={classes.thepaper}>
                  <Grid container justify="center" alignItems="center">
                    <Typography variant="h4" style={{ marginTop: "40px" }}>
                      登入
                    </Typography>
                  </Grid>
                  <Grid container justify="center" alignItems="center">
                    <Typography style={{ marginTop: "10px" }} variant="body2">
                      使用您的大稻埕導覽帳戶
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    className={classes.marginTheTop}
                    justify="center"
                    alignItems="center"
                  >
                    <TextField
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        setAccount(e.target.value);
                      }}
                      name="account"
                      label="登入帳號"
                    ></TextField>
                  </Grid>
                  <Grid
                    className={classes.marginTheTop}
                    container
                    justify="center"
                    alignItems="center"
                  >
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="password"
                      type="password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      label="登入密碼"
                    ></TextField>
                  </Grid>
                  <Grid
                    style={{
                      flex: "1",
                      marginTop: " 40px",
                      marginBottom: "40px",
                    }}
                    container
                  >
                    <Button
                      component={Link}
                      to={"/register"}
                      className={classes.registerButton}
                    >
                      建立帳號
                    </Button>

                    <div style={{ flexGrow: "1" }} />

                    <Button
                      className={classes.loginbutton}
                      onClick={(e) => {
                        fetchLogin().then(() => {
                          console.log(access_token);
                          if (access_token !== "") {
                            props.handleLogin(true, account, access_token);
                          }
                        });
                      }}
                    >
                      登入
                    </Button>
                  </Grid>
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      flex: "1",
                      marginTop: " 40px",
                      marginBottom: "40px",
                      paddingLeft: "40px",
                      paddingRight: '40px'
                    }}
                    container
                  >
                    <a herf="https://uisur.ad.ntust.edu.tw/backend/api/auth/redirect/facebook" style={{ width: "100%" }}>

                      <Button
                        fullWidth
                        className={classes.third_party_login_button}
                        onClick={() => {
                          window.location.href = 'https://uisur.ad.ntust.edu.tw/backend/api/auth/redirect/facebook';
                        }}
                      >
                        Facebook
                      </Button>
                    </a>
                  </Grid>
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{

                      marginTop: " 40px",
                      marginBottom: "40px",
                      paddingLeft: "40px",
                      paddingRight: '40px'
                    }}
                    container
                  >
                    <a herf="https://uisur.ad.ntust.edu.tw/backend/api/auth/redirect/google" style={{ width: '100%' }}>

                      <Button
                        fullWidth
                        className={classes.third_party_login_button}
                        onClick={() => {
                          window.location.href = 'https://uisur.ad.ntust.edu.tw/backend/api/auth/redirect/google';
                        }}
                      >
                        Google
                      </Button>
                    </a>

                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}></Grid>
          </Grid>
        </div>
      </ThemeProvider>
    </>
  );
};

export default LoginPage;
