import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import Cookies from "js-cookie";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import Collapse from "@material-ui/core/Collapse";
import DraftsIcon from "@material-ui/icons/Drafts";
import CloseIcon from "@material-ui/icons/Close";
import SendIcon from "@material-ui/icons/Send";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import { BrowserRouter, Route, Link, HashRouter } from "react-router-dom";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import { IconButton } from "@material-ui/core";
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default function DrawerAppBar(props) {
  const classes = useStyles();
  const { drawerState, setDrawerState } = props;
  const [open, setOpen] = React.useState(false);
  let [access_token, setAccess_token] = React.useState("");
  let [isLogin, setIslogin] = React.useState(false);
  React.useEffect(() => {
    access_token = Cookies.get("access_token");
    if (access_token === undefined) {
      isLogin = false;
    } else {
      isLogin = true;

      // accountName = Cookies.get("accountName");
    }

    setIslogin(isLogin);
    setAccess_token(access_token);

    console.log(isLogin, access_token);
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState(open);
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      //onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button key={"Home"}>
          {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
          <ListItemText
            disableTypography
            onClick={() => {
              document.location.href = "/#/home";
            }}
            component={Link}
            to="/Home"
            primary={"首頁 Home"}
            style={{ flexGrow: "1" }}
          />
          <IconButton>
            <CloseIcon
              onClick={(e) => {
                setDrawerState(false);
                e.stopPropagation();
              }}
            />
          </IconButton>
        </ListItem>
      </List>
      <List>
        <ListItem button key={"gocontest"} to="/themes/115" component={Link}>
          <div
            style={{ paddingLeft: "10px", paddingBottom: "40px" }}
            dangerouslySetInnerHTML={{
              __html: `<div class="wrapper">
          <a class="ctaphone" href="#">
            <span>稻埕醬走</span>
            <span>
              <svg
                width="66px"
                height="23px"
                viewBox="0 0 66 43"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  id="arrow"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <path
                    class="one"
                    d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z"
                    fill="#FFFFFF"
                  ></path>
                  <path
                    class="two"
                    d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z"
                    fill="#FFFFFF"
                  ></path>
                  <path
                    class="three"
                    d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z"
                    fill="#FFFFFF"
                  ></path>
                </g>
              </svg>
            </span>
          </a>
        </div>`,
            }}
          ></div>
        </ListItem>
      </List>
      <List>
        <ListItem button key={"Search"}>
          {/* <ListItemText disableTypography onClick={() => { document.location.href = "/#/SearchPage" }} component={Link} to="/SearchPage" primary={"搜尋"} style={{ flexGrow: '1' }} /> */}
          {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
          <Link style={{ textDecoration: "none" }} to={"/SearchPage/"}>
            <Paper square className={classes.seacrhfield}>
              <InputBase
                className={classes.input}
                placeholder="search..."
                inputProps={{ "aria-label": "search" }}
                // value={searchValue}
                onChange={(e) => {
                  // setSearchValue(e.target.value);
                }}
              />

              <IconButton
                type="submit"
                className={classes.iconButton}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Link>
        </ListItem>
      </List>

      <List>
        <ListItem button component={Link} to="/MapPage" key={"Map"}>
          {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
          <ListItemText disableTypography primary={"地圖探索 Map"} />
        </ListItem>
      </List>

      <List>
        <ListItem button component={Link} to="/MapPond" key={"MapPond"}>
          {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
          <ListItemText disableTypography primary={"地圖分享池 MapPond"} />
        </ListItem>
      </List>

      <List>
        <ListItem button onClick={handleClick}>
          <ListItemText disableTypography primary="主題探索 Themes" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {props.type
              .filter((item) => {
                return item.is_themes;
              })
              .map((item) => (
                <ListItem
                  button
                  component={Link}
                  to={"/themes/" + item.id}
                  className={classes.nested}
                >
                  <ListItemIcon></ListItemIcon>
                  {item.name}
                </ListItem>
              ))}
          </List>
        </Collapse>
      </List>

      <List>
        <ListItem
          button
          component={Link}
          to="/searchPage/themes=5"
          key={"Stories"}
        >
          {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
          <ListItemText disableTypography primary={"稻埕故事 Stories"} />
        </ListItem>
      </List>

      <List>
        <ListItem button key={"About"} component={Link} to="/About">
          {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
          <ListItemText disableTypography primary={"關於 About"} />
        </ListItem>
      </List>

      <Divider />

      {!isLogin && (
        <List>
          <Link style={{ color: "black", textDecoration: "none" }} to="/Login">
            <ListItem button key={"Login"}>
              {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
              <ListItemText
                style={{ fontWeight: "900" }}
                disableTypography
                primary={"登入 Login"}
              />
            </ListItem>
          </Link>
        </List>
      )}
      {!isLogin && (
        <List>
          <Link
            style={{ color: "black", textDecoration: "none" }}
            to="/Register"
          >
            <ListItem button key={"Register"}>
              {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
              <ListItemText
                style={{ fontWeight: "900" }}
                disableTypography
                primary={"註冊 Register"}
              />
            </ListItem>
          </Link>
        </List>
      )}
      {isLogin && (
        <List>
          <Link style={{ color: "black", textDecoration: "none" }} to="/MyMenu">
            <ListItem button key={"我的個人頁面"}>
              {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
              <ListItemText
                style={{ fontWeight: "900" }}
                disableTypography
                primary={"我的個人頁面"}
              />
            </ListItem>
          </Link>
        </List>
      )}
      {isLogin && (
        <List>
          <Link style={{ color: "black", textDecoration: "none" }}>
            <ListItem button key={"Logout"}>
              {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
              <ListItemText
                style={{ fontWeight: "900" }}
                disableTypography
                primary={"登出 Logout"}
              />
            </ListItem>
          </Link>
        </List>
      )}
    </div>
  );

  return (
    <div>
      <React.Fragment key={"top"}>
        {/* <Button onClick={toggleDrawer('left', true)}>{'left'}</Button> */}
        <Drawer
          anchor={"top"}
          open={drawerState}
          onClose={toggleDrawer("top", false)}
        >
          {list("top")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
