import React, { Component, useEffect, useState } from "react";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import { Hidden, List } from "@material-ui/core";

//const { Map: LeafletMap, TileLayer, Marker, Popup } = ReactLeaflet
export const pointerIcon1 = new L.Icon({
  iconUrl: require("../../../assets/marker-icon.png"),
  iconRetinaUrl: require("../../../assets/marker-icon.png"),
  iconAnchor: [12.5, 55],
  popupAnchor: [12.5, -55],
  iconSize: [25, 55],
  //shadowUrl: require('./assets/marker-shadow.png'),
  //shadowSize: [68, 95],
  //shadowAnchor: [20, 92],
});
export const pointerIcon2 = new L.Icon({
  iconUrl: require("../../../assets/marker-icon-red.png"),
  iconRetinaUrl: require("../../../assets/marker-icon-red.png"),
  iconAnchor: [12.5, 55],
  popupAnchor: [12.5, -55],
  iconSize: [25, 55],
  //shadowUrl: require('./assets/marker-shadow.png'),
  //shadowSize: [68, 95],
  //shadowAnchor: [20, 92],
});

const useStyles = makeStyles((theme) => ({
  map: {
    width: "100%",
    height: "300px",

  },
}));

const ControlMaker = (props) => {
  var removeflag = false;
  var point = props.point;
  var [size, setSize] = useState([30, 30])

  const markerIcon = [
    (
      new L.Icon({
        iconUrl: require("../../../assets/markerIcon/" + (point.type_id.toString() + ".png")),
        iconRetinaUrl: require("../../../assets/markerIcon/" + (point.type_id.toString() + ".png")),
        iconAnchor: [15, 15],
        popupAnchor: [0, -15],
        iconSize: [30, 30],
      })),
    (
      new L.Icon({
        iconUrl: require("../../../assets/markerIcon/" + (point.type_id.toString() + "_h.png")),
        iconRetinaUrl: require("../../../assets/markerIcon/" + (point.type_id.toString() + "_h.png")),
        iconAnchor: [22.5, 22.5],
        popupAnchor: [0, -22.5],
        iconSize: [45, 45],
      })),
    (
      new L.Icon({
        iconUrl: require("../../../assets/markerIcon/" + (point.type_id.toString() + "_s.png")),
        iconRetinaUrl: require("../../../assets/markerIcon/" + (point.type_id.toString() + "_s.png")),
        iconAnchor: [15, 15],
        popupAnchor: [0, -15],
        iconSize: [30, 30],
      })
    )];
  return (
    <Marker
      position={[
        point.latitude === null ? 0 : point.latitude,
        point.longitude === null ? 0 : point.longitude,

      ]}
      icon={point.note === true ? markerIcon[2] : markerIcon[0]}
      onMouseOver={(e) => {
        e.target.openPopup();
      }}
      onMouseOut={(e) => {
        e.target.closePopup();
      }}
      onClick={(e) => {
        e.target.openPopup();
        document
          .getElementById("data" + props.listin.toString())
          .scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          });
        //console.log(e.target.key);
        //   if (!removeflag) {
        //     document
        //       .getElementById("data" + props.listin.toString())
        //       .scrollIntoView({
        //         behavior: 'smooth', block: 'nearest', inline: 'start'
        //       });
        //     removeflag = !removeflag;
        //     e.target.setIcon(pointerIcon2);
        //   } else {
        //     removeflag = !removeflag;
        //     e.target.setIcon(pointerIcon1);
        //   }
        //   e.target.openPopup();
      }}
    >
      <Hidden smDown>
        <Popup>
          <h2> {point.name} </h2>
        </Popup>
      </Hidden>
      <Hidden mdUp>
        <Popup>
          <h4> {point.name} </h4>
        </Popup>
      </Hidden>
    </Marker>
  );
};
const TheMap = (props) => {
  const classes = useStyles();
  const Thestate = {
    lat: 37.7749,
    lng: -122.4194,
    zoom: 13,
  };
  // var [point, setPoint] = useState([
  //   {
  //     id: 46,
  //     type_id: 4,
  //     name: "dsadsd",
  //     summary: "dsds",
  //     title: "dsd",
  //     thumbnail: null,
  //     is_visible: 1,
  //     is_drafted: 1,
  //     is_allowed: 1,
  //     views: 72,
  //     created_at: "2021-02-06T15:45:24.000000Z",
  //     updated_at: "2021-03-02T14:17:43.000000Z",
  //     creator_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
  //     latitude: null,
  //     longitude: null,
  //     creator: {
  //       uuid: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
  //       account: "admin",
  //       type: "admin",
  //       created_at: "2021-02-05T09:16:47.000000Z",
  //       updated_at: "2021-02-05T09:16:47.000000Z",
  //     },
  //   },
  // ]);
  const points = props.points;
  // useEffect(() => {
  //   const fetchData = async () => {
  //     var thedata = await Promise.all([
  //       fetchTagsData(),
  //       fetchCategoriesData(),
  //       fetchThemesData(),
  //       fetchArticlesData(),
  //       // fetchEditData(),
  //     ]);
  //     point = [];
  //     for (let i = 0; i < thedata[3].articles.length; i++) {
  //       if (thedata[3].articles[i].latitude !== null) {
  //         if (thedata[3].articles[i].latitude > thedata[3].articles[i].longitude) {
  //           var temp = thedata[3].articles[i].latitude;
  //           thedata[3].articles[i].latitude = thedata[3].articles[i].longitude;
  //           thedata[3].articles[i].longitude = temp;
  //         }
  //         point = [thedata[3].articles[i], ...point];
  //         //setPoint([thedata[3].articles[i],...point]);
  //       }
  //     }
  //     setPoint([...point]);
  //     console.log(point);
  //   };
  //   fetchData();
  // }, []);
  //all fetch function
  const fetchArticlesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/articles")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };
  const fetchThemesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/types")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { types: myJson };
      });
  };
  const fetchTagsData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/tags")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { tags: myJson };
        //setTagsData(temp);
      });
  };
  const fetchCategoriesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/categories")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { categories: myJson };
        //setTagsData(temp);
      });
  };
  const fetchEditData = () => {
    return fetch(
      "https://uisur.ad.ntust.edu.tw/backend/api/articles/" + props.id.toString()
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { editData: myJson };
        //setTagsData(temp);
      });
  };

  var i = 0;
  var markerList = points.map((item) => (
    <ControlMaker point={item} listin={item.id.toString()} key={item.id.toString()}></ControlMaker>
  ));

  return (
    <Map
      center={[25.0731023, 121.5154135]}
      zoom={14.2}
      className={classes.map}
      on={(e) => {
        //console.log("dsdsd");
      }}
    >
      {markerList}

      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        id="mapbox/streets-v11" //light-v9
        attribution='Map data: © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: © <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
      />
    </Map>
  );
};

export default TheMap;
