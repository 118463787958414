import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Cookies from "js-cookie";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import _ from "underscore";
import "./NewDataPage.css";
class NewDataPage extends React.Component {
  state = {
    sightname: "",
    longitude: "",
    latitude: "",
    content: "",
    weight: 1,
    url: "",
    address: "",
    era: "",
    relation: "1",
    categories: "1",
    categoriesInfo: ["ds"],
    categoriesCheck: [false],
    tags: "1",
    tagsInfo: ["ds"],
    tagsCheck: [false],
  };
  constructor(props) {
    super(props);
    this.handleSubmitNewData = this.handleSubmitNewData.bind(this);
  }
  componentDidMount() {
    var that = this;
    fetch("http://uisur.ad.ntust.edu.tw:8000/api/tags")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        var tagsCheckcopy = [false];
        tagsCheckcopy.length = myJson.length;
        for (var i = 0; i < tagsCheckcopy.length; i++) {
          tagsCheckcopy[i] = false;
        }
        that.setState({
          tagsCheck: tagsCheckcopy,
          tagsInfo: myJson,
        });
      });
    fetch("http://uisur.ad.ntust.edu.tw:8000/api/categories")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        var categoriesCheckcopy = [false];
        categoriesCheckcopy.length = myJson.length;
        for (var i = 0; i < categoriesCheckcopy.length; i++) {
          categoriesCheckcopy[i] = false;
        }
        that.setState({
          categoriesCheck: categoriesCheckcopy,
          categoriesInfo: myJson,
        });
      });
  }

  handleSubmitNewData() {
    var that = this;
    //event.preventDefault();
    var status_Number = 0;
    console.log(that.state);
    var tempTag = "";
    for (var i = 0; i < that.state.tagsCheck.length; i++) {
      if (that.state.tagsCheck[i]) {
        tempTag += i.toString() + ",";
      }
    }
    var tempCat = "";
    for (var i = 0; i < that.state.categoriesCheck.length; i++) {
      if (that.state.categoriesCheck[i]) {
        tempCat += i.toString() + ",";
      }
    }
    if (tempCat != "") tempCat = tempCat.substring(0, tempCat.length - 1);
    if (tempTag != "") tempTag = tempTag.substring(0, tempTag.length - 1);
    console.log(
      "輸出資料" +
        JSON.stringify({
          name: that.state.sightname,
          longitude: that.state.longitude,
          latitude: that.state.latitude,
          content: that.state.content,
          weight: that.state.weight,
          url: that.state.url,
          address: that.state.address,
          era: that.state.era,
          relation: that.state.relation,
          categories: tempCat,
          tags: tempTag,
        })
    );
    fetch("http://uisur.ad.ntust.edu.tw:8000/api/sight", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${that.props.access_token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: that.state.sightname,
        longitude: that.state.longitude,
        latitude: that.state.latitude,
        content: that.state.content,
        weight: that.state.weight,
        url: that.state.url,
        address: that.state.address,
        era: that.state.era,
        relation: that.state.relation,
        categories: tempCat,
        tags: tempTag,
      }),
    })
      .then(function (response) {
        console.log(response);
        status_Number = response.status;
        return response.json();
      })
      .then(function (myJson) {
        if (status_Number == 201) {
          //console.log(myJson);
          //console.log(that.props.access_token);
        } else {
          alert("新建資料失敗");
        }
      });
  }
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };
  handleTagCheckChange = (event) => {
    var copy = [...this.state.tagsCheck];
    copy[event.target.value] = !copy[event.target.value];
    console.log(event.target.value);
    console.log(copy);
    this.setState({
      tagsCheck: copy,
    });
  };
  handleCategoryCheckChange = (event) => {
    var copy = [...this.state.categoriesCheck];
    copy[event.target.value] = !copy[event.target.value];
    console.log(event.target.value);
    console.log(copy);
    this.setState({
      categoriesCheck: copy,
    });
  };
  //onSubmit={this.handleSubmitNewData}
  render() {
    var that = this;
    var CategoriesSelection = that.state.categoriesInfo.map((tag, i = 0) => (
      <FormControlLabel
        control={
          <Checkbox
            checked={that.state.categoriesCheck[i]}
            onChange={this.handleCategoryCheckChange}
            name={tag}
            id={tag}
            value={(i++).toString()}
          />
        }
        label={tag}
      />
    ));
    var TagSelection = that.state.tagsInfo.map((tag, i = 0) => (
      <FormControlLabel
        control={
          <Checkbox
            checked={that.state.tagsCheck[i]}
            onChange={this.handleTagCheckChange}
            name={tag}
            id={tag}
            value={(i++).toString()}
          />
        }
        label={tag}
      />
    ));
    return (
      <div>
        <div className="top" />
        <h5>管理員: {that.props.username + " "} 您好</h5>
        <form className="leftNewDataFrom" onSubmit={this.handleSubmitNewData}>
          新建資料
          <React.Fragment>
            <Grid item xs={12} sm={6}>
              <TextField
                id="sightname"
                label="景點名稱  ex : 雕像"
                value={this.state.sightname}
                margin="normal"
                onChange={this.handleChange("sightname")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="longitude"
                label="經度 ex : 145.1"
                value={this.state.longitude}
                margin="normal"
                onChange={this.handleChange("longitude")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="latitude"
                label="緯度 ex : 25.1"
                value={this.state.latitude}
                margin="normal"
                onChange={this.handleChange("latitude")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="content"
                label="介紹內容"
                value={this.state.content}
                margin="normal"
                onChange={this.handleChange("content")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="weight"
                label="權重"
                type="number"
                value={this.state.weight}
                margin="normal"
                onChange={this.handleChange("weight")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="url"
                label="網址"
                value={this.state.url}
                margin="normal"
                onChange={this.handleChange("url")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="address"
                label="地址"
                value={this.state.address}
                margin="normal"
                onChange={this.handleChange("address")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="era"
                label="年代"
                value={this.state.era}
                margin="normal"
                onChange={this.handleChange("era")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="relation"
                label="關聯性"
                value={this.state.relation}
                margin="normal"
                onChange={this.handleChange("relation")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleSubmitNewData}
                position="center"
              >
                建新資料
              </Button>
            </Grid>
          </React.Fragment>
        </form>
        <div className="tagSelect">
          <FormControl component="fieldset">
            <FormLabel component="legend">標籤(Tag)</FormLabel>
            <FormGroup>{TagSelection}</FormGroup>
            <FormHelperText>勾選該資料的標籤</FormHelperText>
          </FormControl>
        </div>
        <div className="categorySelect">
          <FormControl component="fieldset">
            <FormLabel component="legend">分類(Categories)</FormLabel>
            <FormGroup>{CategoriesSelection}</FormGroup>
            <FormHelperText>勾選該資料的分類</FormHelperText>
          </FormControl>
        </div>
      </div>
    );
  }
}

export default NewDataPage;
