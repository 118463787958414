import React, { Component, useEffect, useState } from "react";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import Control from './CenterControl'
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import { Button, Hidden, List } from "@material-ui/core";






//const { Map: LeafletMap, TileLayer, Marker, Popup } = ReactLeaflet
export const pointerIcon1 = new L.Icon({
  iconUrl: require("../../../assets/marker-icon.png"),
  iconRetinaUrl: require("../../../assets/marker-icon.png"),
  iconAnchor: [12.5, 55],
  popupAnchor: [12.5, -55],
  iconSize: [25, 55],
  //shadowUrl: require('./assets/marker-shadow.png'),
  //shadowSize: [68, 95],
  //shadowAnchor: [20, 92],
});
export const pointerIcon2 = new L.Icon({
  iconUrl: require("../../../assets/marker-icon-red.png"),
  iconRetinaUrl: require("../../../assets/marker-icon-red.png"),
  iconAnchor: [12.5, 55],
  popupAnchor: [12.5, -55],
  iconSize: [25, 55],
  //shadowUrl: require('./assets/marker-shadow.png'),
  //shadowSize: [68, 95],
  //shadowAnchor: [20, 92],
});

const useStyles = makeStyles((theme) => ({
  map: {
    width: "100%",
    height: "80vh",
    [theme.breakpoints.down("sm")]: {
      height: "55vh",
    },
  },
}));

const ControlMaker = (props) => {
  var removeflag = false;
  var point = props.point;
  const markerDOM = React.useRef(null);



  useEffect(() => {
    const card = document.getElementById("marker" + point.id.toString());
    if (card != null)
      card.addEventListener('mouseenter', () => { markerDOM.current.leafletElement.openPopup(); })

    return function cleanup() {

    };
  }, []);
  var [size, setSize] = useState([30, 30])
  try {
    require("../../../assets/markerIcon/" + (point.type_id.toString() + ".png"))
  } catch (error) {
    point.type_id = 1
  }
  const markerIcon = [
    (
      new L.Icon({
        iconUrl: require("../../../assets/markerIcon/" + (point.type_id.toString() + ".png")),
        iconRetinaUrl: require("../../../assets/markerIcon/" + (point.type_id.toString() + ".png")),
        iconAnchor: [15, 15],
        popupAnchor: [0, -15],
        iconSize: [30, 30],
      })),
    (
      new L.Icon({
        iconUrl: require("../../../assets/markerIcon/" + (point.type_id.toString() + "_h.png")),
        iconRetinaUrl: require("../../../assets/markerIcon/" + (point.type_id.toString() + "_h.png")),
        iconAnchor: [22.5, 22.5],
        popupAnchor: [0, -22.5],
        iconSize: [45, 45],
      })),
    (
      new L.Icon({
        iconUrl: require("../../../assets/markerIcon/" + (point.type_id.toString() + "_s.png")),
        iconRetinaUrl: require("../../../assets/markerIcon/" + (point.type_id.toString() + "_s.png")),
        iconAnchor: [15, 15],
        popupAnchor: [0, -15],
        iconSize: [30, 30],
      })
    )];
  return (

    <Marker
      position={[
        point.latitude === null ? 0 : point.latitude,
        point.longitude === null ? 0 : point.longitude,

      ]}
      ref={markerDOM}
      icon={point.note === true ? markerIcon[2] : markerIcon[0]}
      onMouseOver={(e) => {
        e.target.openPopup();
      }}
      onMouseOut={(e) => {
        e.target.closePopup();
      }}
      onClick={(e) => {
        //setSize([45, 45]);
        console.log(document
          .getElementById("marker" + point.id.toString()));
        // document
        //   .getElementById("marker" + props.listin.toString()).addEventListener('mouseenter', () => {
        //     ref.current.leafletElement.openPopup();
        //     console.log(123);
        //   })
        // console.log(markerDOM.current.leafletElement);
        // markerDOM.current.leafletElement.openPopup();
        // e.target.openPopup();

        console.log(e.target);
        document
          .getElementById("data" + props.listin.toString())
          .scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start",
          });
        //console.log(e.target.key);
        //   if (!removeflag) {
        //     document
        //       .getElementById("data" + props.listin.toString())
        //       .scrollIntoView({
        //         behavior: 'smooth', block: 'nearest', inline: 'start'
        //       });
        //     removeflag = !removeflag;
        //     e.target.setIcon(pointerIcon2);
        //   } else {
        //     removeflag = !removeflag;
        //     e.target.setIcon(pointerIcon1);
        //   }
        //   e.target.openPopup();
      }}
    >
      <Hidden smDown>
        <Popup className={"pop" + point.id}>
          <h2> {point.name} </h2>
        </Popup>
      </Hidden>
      <Hidden mdUp >
        <Popup className={"pop" + point.id}>
          <h4> {point.name} </h4>
        </Popup>
      </Hidden>
    </Marker>

  );
};
const TheMap = (props) => {
  const classes = useStyles();
  const [map, setMap] = useState();
  const [userLocation, setUserLocation] = useState({ "coords": { "latitude": null, "longitude": null } });
  const Thestate = {
    lat: 37.7749,
    lng: -122.4194,
    zoom: 13,
  };
  const onSuccess = (position) => {
    console.log(position)
    setUserLocation(position)
  }
  const onError = (error) => {
    console.log(error)
  }
  useEffect(() => {
    let get_loc_timer = setInterval(() => {

      navigator.geolocation.getCurrentPosition(onSuccess, onError)

    }, 5000)
    return () => { window.clearInterval(get_loc_timer) }
  }, [])
  // var marker_ref = React.useRef({});
  // ref={(r) => { marker_ref.current[item.id] = r }}
  const point = props.point;
  //all fetch function
  const fetchArticlesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/articles")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };
  const fetchThemesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/types")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { types: myJson };
      });
  };
  const fetchTagsData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/tags")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { tags: myJson };
        //setTagsData(temp);
      });
  };
  const fetchCategoriesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/categories")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { categories: myJson };
        //setTagsData(temp);
      });
  };
  const fetchEditData = () => {
    return fetch(
      "https://uisur.ad.ntust.edu.tw/backend/api/articles/" + props.id.toString()
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { editData: myJson };
        //setTagsData(temp);
      });
  };

  var i = 0;

  return (
    <div>

      <Map
        center={[25.0596137, 121.5126518]}
        zoom={16.3}
        className={classes.map}
        whenCreated={setMap}


      >
        {point.map((item, index) => (
          <ControlMaker point={item} listin={index} key={(index).toString()} map={map}></ControlMaker>
        ))}
        {userLocation.coords.latitude != null &&
          <Marker
            icon={pointerIcon1}
            position={[
              userLocation.coords.latitude,
              userLocation.coords.longitude

            ]} >
            <Hidden smDown>
              <Popup className={"pop" + point.id}>
                <h2> {"您的位置"} </h2>
              </Popup>
            </Hidden>
            <Hidden mdUp >
              <Popup className={"pop" + point.id}>
                <h4> {"您的位置"}</h4>
              </Popup>
            </Hidden>


          </Marker>
        }


        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          id="mapbox/streets-v11" //light-v9
          attribution='Map data: © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: © <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
        />
        <Hidden mdUp>
          <Control position="topleft" >
            <IconButton onClick={() => { props.setModal(true); }} style={{ color: 'black', background: 'white' }} aria-label="filter">
              <FilterListIcon />
            </IconButton>
          </Control>
        </Hidden>
      </Map>
    </div >
  );
};

export default TheMap;
