import "braft-editor/dist/index.css";
import React, { useState, useEffect } from "react";
import BraftEditor from "braft-editor";
import Cookies from "js-cookie";

const HtmlEditor = (props) => {
  const [editorState, setEditorState] = useState(
    BraftEditor.createEditorState("<p></p>")
  );
  const [outputHtml, setOutputHtml] = useState("");
  /*
  componentDidMount () {
    this.isLivinig = true
    
    setTimeout(this.setEditorContentAsync, 3000)
  }

  componentWillUnmount () {
    this.isLivinig = false
  }

  handleChange = (editorState) => {
    this.setState({
      editorState: editorState,
      outputHTML: editorState.toHTML()
    })
  }*/
  useEffect(() => {
    if (props.editMode) {
      const fetchEditData = () => {
        const access_token = Cookies.get("access_token");
        return fetch(
          "https://uisur.ad.ntust.edu.tw/backend/api/articles/" +
            props.id.toString() +
            "?edit=true",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${access_token}`,
              "Content-Type": "application/json",
            },
          }
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (myJson) {
            return { editData: myJson };
          });
      };
      const fetchData = async () => {
        const thedata = await Promise.all([fetchEditData()]);
        var temp = BraftEditor.createEditorState(thedata[0].editData.content);
        setEditorState(temp);
      };
      fetchData();
    }
  }, []);

  return (
    <div>
      <div className="editor-wrapper">
        <BraftEditor
          value={editorState}
          onChange={(editorState) => {
            setEditorState({ editorState });
            //setOutputHtml(editorState.toHTML());
            props.SetHtmlData(editorState.toHTML());
            //console.log(editorState, "/n", outputHtml, editorState.toHTML());
          }}
        />
      </div>
    </div>
  );
};
export default HtmlEditor;
