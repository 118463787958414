import React from "react";
import { VictoryStack, VictoryBar, VictoryLabel } from "victory";


const barLabelStyles = {
    fontSize: 14,
    fill: "#000000",
    textAnchor: "middle"
};

const testData = [
    {
        y: 1150
    },
    {
        y: 250
    },
    { y: 655 },
    { y: 455 }
];

const CenteredLabel = (props) => {
    const { datum, scale } = props;
    const centerPos = scale.y((datum._y1 - datum._y0) / 2 + datum._y0);

    return (
        <VictoryLabel {...props} style={[barLabelStyles]} x={centerPos} dx={0} dy={30} />
    );
};

export default function Horizontal_line(props) {
    const testData = props.data;
    const get_testData_sum = () => {
        var sum = 0;
        for (var i = 0; i < testData.length; i++) {
            sum += testData[i].y
        }
        return sum;
    }
    const testData_sum = get_testData_sum()
    const myColorScales = props.color;

    return (
        < >

            <VictoryStack

                padding={0}
                domainPadding={0}
                horizontal
                height={100}

            >
                {testData.map((dataValue, index) => {
                    if (dataValue["y"]) {
                        return (
                            <VictoryBar
                                key={index}
                                labels={() => Math.round((dataValue["y"] / testData_sum) * 100) + "%"}
                                barWidth={40}
                                data={[{ x: 1, y: dataValue?.["y"] || 0 }, {}]}
                                labelComponent={<CenteredLabel />}
                                style={{
                                    data: { fill: myColorScales[index] }
                                }}
                            />
                        );
                    }
                    return null;
                })}
            </VictoryStack>

        </>
    );
}
