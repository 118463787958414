import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import IconButton from "@material-ui/core/IconButton";
import { BrowserRouter, Route, Link, HashRouter } from "react-router-dom";
import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import { point } from "leaflet";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
    width: "100%",
  },
  cardbutton: {
    marginLeft: "auto",
  },
  media: {
    height: 140,
  },
}));
const SightCard = (props) => {
  const classes = useStyles();
  const [starStatus, setStarStatus] = useState(false);
  let point = props.point;
  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.media}
        image={
          point.thumbnail === null
            ? "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/%E6%9A%82%E6%97%A0%E5%9B%BE%E7%89%87.svg/1200px-%E6%9A%82%E6%97%A0%E5%9B%BE%E7%89%87.svg.png"
            : "https://uisur.ad.ntust.edu.tw/backend/storage/" + point.thumbnail
        }
        title=""
      />
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          style={{ fontWeight: "900" }}
          component="h2"
        >
          {props.index + " ➤ " + point.name}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {point.summary}
        </Typography>
      </CardContent>

      <CardActions disableSpacing>
        {/* <IconButton
          aria-label="StarBorderIcon"
          onClick={() => {
            props.handlePointClick();
          }}
          className={classes.margin}
        >
          {point.note ? <StarIcon /> : <StarBorderIcon />} 
        </IconButton> */}

        <Button
          component={Link}
          to={"/Article/" + point.name}
          className={classes.cardbutton}
          size="meduim"
          color="black"
        >
          More→
        </Button>
      </CardActions>
    </Card>
  );
};
export default SightCard;
