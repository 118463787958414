import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import Cookies from "js-cookie";
import Avatar from "@material-ui/core/Avatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import Collapse from "@material-ui/core/Collapse";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import RestoreIcon from "@material-ui/icons/Restore";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PinDropIcon from "@material-ui/icons/PinDrop";
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import NoteIcon from "@material-ui/icons/Note";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Paper from "@material-ui/core/Paper";
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import DirectionsIcon from "@material-ui/icons/Directions";
import { BrowserRouter, Route, Link, HashRouter } from "react-router-dom";
import { Box, Grid, Tab, Tabs, Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    root: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: 400,
        height: "30px",
    },
    list: {
        width: 250,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    fullList: {
        width: "auto",
    },

    DrawerIndex: {
        zIndex: theme.zIndex.modal - 100,
    },
}));

export default function Changepassword(props) {
    const classes = useStyles();
    const { drawerState, setDrawerState } = props;
    const [open, setOpen] = React.useState(false);
    const [tabValue, setTabValue] = React.useState("Note");
    const [oldpassword, setOldpassword] = React.useState("")
    const [newpassword, setNewpassword] = React.useState("")
    const [checkpassword, setCheckpassword] = React.useState("")
    const [mypin, setMypin] = React.useState([
        {
            id: 20,
            type_id: 11,
            name: "AAA",
            summary: "b",
            title: "titleHERE",
            url: null,
            relations_uuid: "2283f55b-36e7-4247-95d1-e6f6cf6e21e3",
            content: "7",
            weight: 0,
            thumbnail: "thumbnails/EeCrgGDGr9SIBVi1q8B9lm9iwqbtKcBPKS63bPId.png",
            views: 0,
            is_visible: 0,
            is_drafted: 0,
            is_allowed: 0,
            creator_id: "42652e3b-b746-47c4-a3b7-a9962c0c6eac",
            checker_id: null,
            available_at: null,
            created_at: "2021-03-01T07:05:10.000000Z",
            updated_at: "2021-03-01T07:05:10.000000Z",
            start_year: 1911,
            end_year: 1912,
        },
    ]);
    let [access_token, setAccess_token] = React.useState("");
    let [isLogin, setIslogin] = React.useState(false);
    React.useEffect(() => {
        access_token = Cookies.get("access_token");
        if (access_token === undefined) {
            isLogin = false;
        } else {
            isLogin = true;

            // accountName = Cookies.get("accountName");
        }


        setIslogin(isLogin);
        setAccess_token(access_token);

        console.log(isLogin, access_token);
    }, []);



    return (
        <>
            <Grid container
                item
                lg={3}
                md={3}
                xs={3}
                xl={3}
                sm={3}></Grid>
            <Grid
                container
                item
                lg={6}
                md={6}
                xs={6}
                xl={6}
                sm={6}
                justifyContent="center"
                style={{ border: "1px outset black ", borderRadius: "25px", padding: "35px" }}>
                <Grid
                    style={{ display: "flex" }}
                    item lg={12}
                    md={12}
                    xs={12}
                    xl={12}

                    sm={12}>
                    <div style={{ flexGrow: "1" }} />
                    <Typography variant="h3">密碼更改</Typography>
                    <div style={{ flexGrow: "1" }} />
                </Grid>
                <Grid item
                    style={{ display: "flex" }} lg={12}
                    md={12}
                    xs={12}
                    xl={12}

                    sm={12}>
                    <div style={{ flexGrow: "1" }} />
                    <Typography variant="h6" style={{ marginTop: "16px" }}>請輸入當前的密碼</Typography>
                    <div style={{ flexGrow: "1" }} />
                </Grid>
                <Grid item
                    style={{ display: "flex" }} lg={12}
                    md={12}
                    xs={12}
                    xl={12}
                    sm={12}>
                    <div style={{ flexGrow: "1" }} />
                    <TextField
                        id="outlined-password-input"
                        label="舊密碼"
                        type="password"
                        autoComplete="current-password"
                        variant="outlined"
                        onChange={(e) => { setOldpassword(e.target.value) }}
                    />
                    <div style={{ flexGrow: "1" }} />
                </Grid>
                <Grid item
                    style={{ display: "flex" }} lg={12}
                    md={12}
                    xs={12}
                    xl={12}
                    sm={12}>
                    <div style={{ flexGrow: "1" }} />
                    <Typography style={{ marginTop: "16px" }} variant="h6">請輸入新的密碼</Typography>
                    <div style={{ flexGrow: "1" }} />
                </Grid>
                <Grid item
                    style={{ display: "flex" }} lg={12}
                    md={12}
                    xs={12}
                    xl={12}
                    sm={12}>
                    <div style={{ flexGrow: "1" }} />
                    <TextField
                        id="outlined-password-input"
                        label="新的密碼"
                        type="password"
                        autoComplete="current-password"
                        variant="outlined"
                        onChange={(e) => { setNewpassword(e.target.value) }}
                    />
                    <div style={{ flexGrow: "1" }} />
                </Grid>
                <Grid item
                    style={{ display: "flex" }} lg={12}
                    md={12}
                    xs={12}
                    xl={12}
                    sm={12}>
                    <div style={{ flexGrow: "1" }} />
                    <Typography style={{ marginTop: "16px" }} variant="h6">請再次確認新的密碼</Typography>
                    <div style={{ flexGrow: "1" }} />
                </Grid>
                <Grid item
                    style={{ display: "flex" }} lg={12}
                    md={12}
                    xs={12}
                    xl={12}
                    sm={12}>
                    <div style={{ flexGrow: "1" }} />
                    <TextField
                        id="outlined-password-input"
                        label="再次確認新密碼"
                        type="password"
                        autoComplete="current-password"
                        variant="outlined"
                        onChange={(e) => { setCheckpassword(e.target.value) }}
                    />
                    <div style={{ flexGrow: "1" }} />
                </Grid>
                <Grid item
                    style={{ display: "flex" }} lg={12}
                    md={12}
                    xs={12}
                    xl={12}
                    sm={12}>
                    <div style={{ flexGrow: "1" }} />
                    <Button style={{ marginTop: "16px" }} variant="contained" color="primary" onClick={() => {
                        const access_token = Cookies.get("access_token");
                        if (oldpassword != "" && newpassword != "" && checkpassword != "" && (newpassword === checkpassword)) {
                            var myHeaders = new Headers();
                            myHeaders.append("Authorization", "Bearer " + access_token);
                            myHeaders.append("Accept", "application/json")
                            myHeaders.append("Content-Type", "application/x-www-form-urlencoded;charset=utf-8")
                            var uploadData = new URLSearchParams();

                            //uploadData.append("is_visible", JSON.stringify(1));
                            uploadData.append("old_paasword", oldpassword);
                            uploadData.append("new_paasword", newpassword);
                            uploadData.append("new_comfirm_paasword", checkpassword);
                            fetch("https://uisur.ad.ntust.edu.tw/backend/api/me", {
                                method: "PATCH",
                                headers: myHeaders,
                                body: uploadData
                            })
                                .then(function (response) {
                                    return response.json();
                                })
                                .then(function (myJson) {
                                    return { tags: myJson };
                                    //setTagsData(temp);
                                });
                        }

                    }}>更改</Button>
                    <div style={{ flexGrow: "1" }} />
                </Grid>
            </Grid>
            <Grid container
                item
                lg={3}
                md={3}
                xs={3}
                xl={3}
                sm={3}>

            </Grid>

        </>
    );
}
