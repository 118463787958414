import React from "react";
import { BrowserRouter, Route, Link, HashRouter } from "react-router-dom";
import Cookies from "js-cookie";
import DatePicker, { CalendarContainer } from "react-datepicker";
import {
  VictoryLabel,
  VictoryBar,
  VictoryPie,
  VictoryLine,
  VictoryTheme,
  VictoryScatter,
  createContainer,
  VictoryChart,
} from "victory";
import "react-datepicker/dist/react-datepicker.css";
import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import LeftAppBar from "../LeftAppBar/LeftAppBar.js";
import ManageAppBar from "../ManageAppBar/ManageAppBar.js";
import { Card, CardMedia, Divider, Hidden } from "@material-ui/core";

import Paper from "@material-ui/core/Paper";
import Map from "./InsightsPageComponent/Map";
//Fix css
import "./InsightsPageComponent/fix.css";
//line chart
import Horizontal_line from "./InsightsPageComponent/Horizontal_line.js";
//for icons
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import RemoveIcon from "@material-ui/icons/Remove";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { Chip } from "@material-ui/core";
import { get } from "underscore";
const Overall_divide = (props) => {
  const value =
    props.data.filtered_avg /
    (props.data.all_avg === 0 ? 1 : props.data.all_avg);
  if (value > 1) {
    return (
      <>
        <Typography style={{ color: "red" }}>
          <TrendingUpIcon style={{ color: "red" }} />{" "}
          {"+" + (value * 100).toFixed(1) + "%"}
        </Typography>
      </>
    );
  } else {
    return (
      <>
        <Typography style={{ color: "green" }}>
          <TrendingDownIcon style={{ color: "green" }} />{" "}
          {"-" + (value * 100).toFixed(1) + "%"}
        </Typography>
      </>
    );
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 0, 0),
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  title: {
    fontWeight: "bold",
    fontSize: "1.2rem",
    "@media (min-width:600px)": {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.4rem",
    },
  },
  newLink: {
    fontWeight: "bold",
    textDecoration: "none",
    color: "#000000",
  },
  media: {
    height: 140,
  },
  seeall: {
    textDecoration: "none",
    color: "#7f7f7f",
  },
  clickedButton: {
    color: "#ffffff",
    borderColor: "#ffffff",
    background: "#000000",
    marginRight: theme.spacing(1),
    marginLight: theme.spacing(1),
    textTransform: "none",
    paddingRight: "8px",
    paddingLeft: "8px",
    //margin: theme.spacing(0, 2),
    "&:hover": {
      backgroundColor: "#ffffff",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
  previewButton: {
    color: "#000000",
    borderColor: "#000000",
    marginRight: theme.spacing(1),
    marginLight: theme.spacing(1),
    textTransform: "none",
    paddingRight: "8px",
    paddingLeft: "8px",
    //margin: theme.spacing(0, 2),
    "&:hover": {
      backgroundColor: "#ffffff",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
}));

const InsightsPage = (props) => {
  function SumDatareduce(arr) {
    return arr.reduce((a, b) => a + b);
  }
  const get_percent = (num1, num2) => {
    if (num2 == 0) {
      num2 = 1;
    }
    return Math.round((num1 / num2) * 100);
  };
  const api_guest_convert = (input) => {
    const old_set = [
      "under_eighteen",
      "eighteen_to_twentyfive",
      "twentysix_to_thirdtythree",
      "thirdtyfour_to_fortyone",
      "fortytwo_to_fortynine",
      "fifty_to_fiftyseven",
      "fiftyeight_to_sixtyfive",
      "sixtysix_to_seventynine",
      "above_eighty",
    ];
    const name_set = [
      "male_dadaocheng_",
      "male_other_area_",
      "male_other_country_",
      "female_dadaocheng_",
      "female_other_area_",
      "female_other_country_",
    ];

    const output = [
      {
        all: input["guest_number"],
        members: 0,
        guest: input["guest_number"],
        member_percent: "0%",
        guest_percent: "0%",
      },
      {
        other_area_female: 0,
        other_area_male: 0,
        other_country_female: 0,
        other_country_male: 0,
        dadaocheng_female: 0,
        dadaocheng_male: 0,
        male: 0,
        female: 0,
        male_set: [0, 0, 0, 0, 0, 0, 0, 0, 0],
        female_set: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        all: 0,
        dadaocheng: 0,
        other_country: 0,
        other_country_female: 0,
        other_country_male: 0,
        other_area_male: 0,
        other_area_female: 0,
        other_area: 0,
        dadaocheng_male: 0,
        dadaocheng_female: 0,
        dadaocheng_female_set: [],
        dadaocheng_male_set: [],
        other_country_female_set: [],
        other_country_male_set: [],
        other_area_female_set: [],
        other_area_male_set: [],
      },
      { old_set: [] },
    ];

    for (var i = 0; i < name_set.length; i++) {
      for (var j = 0; j < old_set.length; j++) {
        output[0]["all"] += input["members"][name_set[i] + old_set[j]];
        output[0]["members"] += input["members"][name_set[i] + old_set[j]];
      }
    }
    output[0]["member_percent"] = get_percent(
      output[0]["members"],
      output[0]["all"]
    );
    output[0]["guest_percent"] = get_percent(
      output[0]["guest"],
      output[0]["all"]
    );

    for (var i = 0; i < 3; i++) {
      for (var j = 0; j < old_set.length; j++) {
        output[1]["male"] += input["members"][name_set[i] + old_set[j]];
        output[1]["male_set"][j] += input["members"][name_set[i] + old_set[j]];
      }
    }
    for (var i = 3; i < 6; i++) {
      for (var j = 0; j < old_set.length; j++) {
        output[1]["female"] += input["members"][name_set[i] + old_set[j]];
        output[1]["female_set"][j] +=
          input["members"][name_set[i] + old_set[j]];
      }
    }
    for (var j = 0; j < old_set.length; j++) {
      output[2]["dadaocheng"] += input["members"][name_set[0] + old_set[j]];
      output[2]["dadaocheng_male_set"].push(
        input["members"][name_set[0] + old_set[j]]
      );
      output[2]["other_area"] += input["members"][name_set[1] + old_set[j]];
      output[2]["other_area_male_set"].push(
        input["members"][name_set[1] + old_set[j]]
      );
      output[2]["other_country"] += input["members"][name_set[2] + old_set[j]];
      output[2]["other_country_male_set"].push(
        input["members"][name_set[2] + old_set[j]]
      );
      output[2]["dadaocheng"] += input["members"][name_set[3] + old_set[j]];
      output[2]["dadaocheng_female_set"].push(
        input["members"][name_set[3] + old_set[j]]
      );
      output[2]["other_area"] += input["members"][name_set[4] + old_set[j]];
      output[2]["other_area_female_set"].push(
        input["members"][name_set[4] + old_set[j]]
      );
      output[2]["other_country"] += input["members"][name_set[5] + old_set[j]];
      output[2]["other_country_female_set"].push(
        input["members"][name_set[5] + old_set[j]]
      );
    }
    output[2]["dadaocheng_male"] = SumDatareduce(
      output[2]["dadaocheng_male_set"]
    );
    output[2]["other_area_male"] = SumDatareduce(
      output[2]["other_area_male_set"]
    );
    output[2]["other_country_male"] = SumDatareduce(
      output[2]["other_country_male_set"]
    );
    output[2]["dadaocheng_female"] = SumDatareduce(
      output[2]["dadaocheng_female_set"]
    );
    output[2]["other_area_female"] = SumDatareduce(
      output[2]["other_area_female_set"]
    );
    output[2]["other_country_female"] = SumDatareduce(
      output[2]["other_country_female_set"]
    );
    output[1]["dadaocheng_male"] = SumDatareduce(
      output[2]["dadaocheng_male_set"]
    );
    output[1]["other_area_male"] = SumDatareduce(
      output[2]["other_area_male_set"]
    );
    output[1]["other_country_male"] = SumDatareduce(
      output[2]["other_country_male_set"]
    );
    output[1]["dadaocheng_female"] = SumDatareduce(
      output[2]["dadaocheng_female_set"]
    );
    output[1]["other_area_female"] = SumDatareduce(
      output[2]["other_area_female_set"]
    );
    output[1]["other_country_female"] = SumDatareduce(
      output[2]["other_country_female_set"]
    );
    output[2]["all"] = output[0]["all"];
    for (var i = 0; i < old_set.length; i++) {
      var count = 0;
      for (var j = 0; j < name_set.length; j++) {
        count += input["members"][name_set[j] + old_set[i]];
      }
      output[3]["old_set"].push(count);
    }
    console.log(output);
    setGuestdata(output);
  };

  const weekdays_text = "一二三四五六七";
  const get_weekdays = () => {
    var output = "&weekdays=";
    var check = false;
    for (var i = 0; i <= new_weekdays.length; i++) {
      if (new_weekdays[i] == true) {
        if (check == false) {
          check = true;
          output += i.toString();
        } else {
          output += "," + i.toString();
        }
      }
    }
    return output;
  };
  const classes = useStyles();
  const [trends_selection, setTrends_selection] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
  ]);
  const [trends_time_selection, setTrends_time_selection] = useState("Days");
  const [trends_data, setTrends_data] = useState({
    traffics: [
      {
        date: "2021-07-06",
        times: 5,
      },
    ],
    viewers: [
      {
        date: "2021-07-06",
        times: 2,
      },
    ],
    map_created: [
      {
        date: "2021-05-03",
        times: 3,
      },
    ],
    favorite_articles: [],
    favorite_locations: [
      {
        date: "2021-07-23",
        times: 1,
        article: null,
      },
    ],
    favorite_maps: [],
  });
  const trends_time_select = (input) => {
    if (input === "Days") {
      setTrends_data(trends);
      return true;
    } else if (input === "Weeks") {
      var output = {};
      for (const [key, value] of Object.entries(trends)) {
        output[key] = [];
        var times = 0;
        var monday = value[0]["date"];
        for (var i = 0; i < value.length; i++) {
          times += value[i]["times"];
          if (new Date(value[i]["date"]).getDay() === 0) {
            output[key].push({ times: times, date: monday });
            times = 0;
          }
          if (new Date(value[i]["date"]).getDay() === 1) {
            monday = value[i]["date"];
          }
          if (
            i === value.length - 1 &&
            new Date(value[i]["date"]).getDay() != 0
          ) {
            output[key].push({ times: times, date: monday });
            times = 0;
          }
        }
      }
      setTrends_data(output);
      return true;
    } else if (input === "Months") {
      var output = {};
      for (const [key, value] of Object.entries(trends)) {
        output[key] = [];
        var times = 0;
        var month = value[0]["date"];
        for (var i = 0; i < value.length; i++) {
          if (value[i]["date"].substring(0, 7) === month.substring(0, 7)) {
            times += value[i]["times"];
          }
          if (value[i]["date"].substring(0, 7) != month.substring(0, 7)) {
            output[key].push({ times: times, date: month });
            times = 0;
            month = value[i]["date"];
          } else if (i === value.length - 1) {
            output[key].push({ times: times, date: month });
            times = 0;
          }
        }
      }
      setTrends_data(output);
      return true;
    } else if (input === "Years") {
      var output = {};
      for (const [key, value] of Object.entries(trends)) {
        output[key] = [];
        var times = 0;
        var year = value[0]["date"];
        for (var i = 0; i < value.length; i++) {
          if (value[i]["date"].substring(0, 5) === year.substring(0, 5)) {
            times += value[i]["times"];
          }
          if (value[i]["date"].substring(0, 5) != year.substring(0, 5)) {
            output[key].push({ times: times, date: monday });
            times = 0;
            year = value[i]["date"];
          } else if (i === value.length - 1) {
            output[key].push({ times: times, date: monday });
            times = 0;
          }
        }
      }
      setTrends_data(output);
      return true;
    }
    return false;
  };
  // for weekdays
  const [weekdays, setWeekdays] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);
  const [new_weekdays, setNew_weekdays] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);
  //for select date
  const [start_date, setStart_date] = useState(new Date("2021-01-01"));
  const [end_date, setEnd_date] = useState(new Date("2021-07-17"));
  const [new_start_date, setNew_start_date] = useState(new Date("2021-01-01"));
  const [new_end_date, setNew_end_date] = useState(new Date("2021-07-17"));
  // for trends chart
  const VictoryZoomVoronoiContainer = createContainer("zoom", "voronoi");

  //for guest VictoryChart
  const old_color = [
    "#7bc037",
    "#5a8220",
    "#c03c25",
    "#ba9229",
    "#8f7228",
    "#cb7424",
    "#aa6732",
    "#754314",
    "#bdbab9",
  ];
  const old_text = [
    "位 0~17　歲 ",
    "位 18~25 歲 ",
    "位 26~33 歲 ",
    "位 34~41 歲 ",
    "位 42~49 歲 ",
    "位 50~57 歲 ",
    "位 58~65 歲 ",
    "位 66~79 歲 ",
    "位 80+ 　歲 ",
  ];
  const [guest_selsect, setGuest_selsect] = useState("sex");
  const [guestdata, setGuestdata] = useState([
    { all: 1, members: 1, guest: 1, member_percent: "1", guest_percent: "1" },
    {
      other_area_female: 1,
      other_area_male: 1,
      other_country_female: 1,
      other_country_male: 1,
      dadaocheng_female: 1,
      dadaocheng_male: 1,
      male: 1,
      female: 1,
      male_set: [1, 1],
      female_set: [1, 1],
    },
    {
      all: 1,
      dadaocheng: 1,
      other_country: 1,
      other_country_female: 1,
      other_country_male: 1,
      other_area_male: 1,
      other_area_female: 1,
      other_area: 1,
      dadaocheng_male: 1,
      dadaocheng_female: 1,
      dadaocheng_female_set: [],
      dadaocheng_male_set: [],
      other_country_female_set: [],
      other_country_male_set: [],
      other_area_female_set: [],
      other_area_male_set: [],
    },
    { old_set: [1, 1, 1, 1, 1, 1, 1, 1, 1] },
  ]);
  //five api default data
  const [ranks, setRanks] = useState({
    keywords: [
      {
        keyword: "23",
        times: 1,
      },
    ],
    pages: [
      {
        loggable_type: "App\\Models\\Type",
        loggable_id: 21,
        times: 60,
        loggable: {
          id: 21,
          name: "home",
        },
      },
    ],
    favorite_articles: [
      {
        article_id: 7,
        times: 1,
        article: {
          id: 7,
          name: "azzza1",
        },
      },
    ],
    favorite_maps: [
      {
        article_id: 7,
        times: 1,
        article: {
          id: 7,
          name: "azzza1",
        },
      },
    ],
  });
  const [trends, setTrends] = useState({
    traffics: [
      {
        date: "2021-07-06",
        times: 5,
      },
    ],
    viewers: [
      {
        date: "2021-07-06",
        times: 2,
      },
    ],
    map_created: [
      {
        date: "2021-05-03",
        times: 3,
      },
    ],
    favorite_articles: [],
    favorite_locations: [
      {
        date: "2021-07-23",
        times: 1,
        article: null,
      },
    ],
    favorite_maps: [],
  });
  const [map_hotspots, setMap_hotspots] = useState({
    favorites_times: [
      {
        article_id: 7,
        times: 1,
        article: {
          id: 7,
          name: "azzza1",
          latitude: 12.123111,
          map_filter_type: null,
          longitude: 25.255,
        },
      },
    ],
    articles_in_map_times: [
      {
        times: 6,
        article: {
          id: 87,
          name: "蘇家店屋",
          latitude: 121.509572,
          map_filter_type_id: null,
          longitude: 25.058727,
        },
      },
    ],
  });
  const [viewers, setViewers] = useState({
    guest_number: 8,
    members: {
      male_dadaocheng_under_eighteen: 0,
      male_dadaocheng_eighteen_to_twentyfive: 0,
      male_dadaocheng_twentysix_to_thirdtythree: 0,
      male_dadaocheng_thirdtyfour_to_fortyone: 0,
      male_dadaocheng_fortytwo_to_fortynine: 0,
      male_dadaocheng_fifty_to_fiftyseven: 0,
      male_dadaocheng_fiftyeight_to_sixtyfive: 1,
      male_dadaocheng_sixtysix_to_seventynine: 0,
      male_dadaocheng_above_eighty: 0,
      male_other_area_under_eighteen: 0,
      male_other_area_eighteen_to_twentyfive: 0,
      male_other_area_twentysix_to_thirdtythree: 0,
      male_other_area_thirdtyfour_to_fortyone: 0,
      male_other_area_fortytwo_to_fortynine: 0,
      male_other_area_fifty_to_fiftyseven: 0,
      male_other_area_fiftyeight_to_sixtyfive: 0,
      male_other_area_sixtysix_to_seventynine: 0,
      male_other_area_above_eighty: 0,
      male_other_country_under_eighteen: 0,
      male_other_country_eighteen_to_twentyfive: 0,
      male_other_country_twentysix_to_thirdtythree: 0,
      male_other_country_thirdtyfour_to_fortyone: 0,
      male_other_country_fortytwo_to_fortynine: 0,
      male_other_country_fifty_to_fiftyseven: 0,
      male_other_country_fiftyeight_to_sixtyfive: 0,
      male_other_country_sixtysix_to_seventynine: 0,
      male_other_country_above_eighty: 0,
      female_dadaocheng_under_eighteen: 0,
      female_dadaocheng_eighteen_to_twentyfive: 0,
      female_dadaocheng_twentysix_to_thirdtythree: 0,
      female_dadaocheng_thirdtyfour_to_fortyone: 0,
      female_dadaocheng_fortytwo_to_fortynine: 0,
      female_dadaocheng_fifty_to_fiftyseven: 0,
      female_dadaocheng_fiftyeight_to_sixtyfive: 0,
      female_dadaocheng_sixtysix_to_seventynine: 0,
      female_dadaocheng_above_eighty: 0,
      female_other_area_under_eighteen: 0,
      female_other_area_eighteen_to_twentyfive: 0,
      female_other_area_twentysix_to_thirdtythree: 0,
      female_other_area_thirdtyfour_to_fortyone: 0,
      female_other_area_fortytwo_to_fortynine: 0,
      female_other_area_fifty_to_fiftyseven: 0,
      female_other_area_fiftyeight_to_sixtyfive: 0,
      female_other_area_sixtysix_to_seventynine: 0,
      female_other_area_above_eighty: 0,
      female_other_country_under_eighteen: 0,
      female_other_country_eighteen_to_twentyfive: 0,
      female_other_country_twentysix_to_thirdtythree: 0,
      female_other_country_thirdtyfour_to_fortyone: 0,
      female_other_country_fortytwo_to_fortynine: 0,
      female_other_country_fifty_to_fiftyseven: 0,
      female_other_country_fiftyeight_to_sixtyfive: 0,
      female_other_country_sixtysix_to_seventynine: 0,
      female_other_country_above_eighty: 0,
    },
  });
  const [overall, setOverall] = useState({
    traffics: { filtered: 223, filtered_avg: 10.62, all_avg: 23.36 },
    viewers: { filtered: 11, filtered_avg: 0.52, all_avg: 1.45 },
    map_created: { filtered: 0, filtered_avg: 0, all_avg: 1 },
    favorites_articles: { filtered: 0, filtered_avg: 0, all_avg: 0.18 },
    favorites_locations: { filtered: 1, filtered_avg: 0.05, all_avg: 2.64 },
    favorites_maps: { filtered: 0, filtered_avg: 0, all_avg: 0.27 },
  });
  //for api access_token
  let [access_token, setAccess_token] = useState("");
  // for fetch change date api
  const change_date = () => {
    access_token = Cookies.get("access_token");
    if (access_token === undefined) {
      access_token = "";
    }
    setAccess_token(access_token);

    const fetchData = async () => {
      var thedata = await Promise.all([
        fetchRanks(),
        fetchTrends(),
        fetchMap_hotspots(),
        fetchViewers(),
        fetchOverall(),
      ]);
      for (var i = 0; i < thedata[0].editData["pages"].length; i++) {
        if (thedata[0].editData["pages"][i]["loggable"] == null) {
          thedata[0].editData["pages"][i]["loggable"] = { name: "" };
        }
      }
      const isSameDay = (a, b) => {
        return (
          a.getFullYear() === b.getFullYear() &&
          a.getMonth() === b.getMonth() &&
          a.getDate() === b.getDate()
        );
      };
      var trends_temp = {};
      for (const [key, value] of Object.entries(thedata[1].editData)) {
        trends_temp[key] = [];
        var start_day = new Date(new_start_date);
        var end_day = new Date(new_end_date);
        console.log("thedata", start_day, end_day);
        end_day.setDate(end_day.getDate() + 1);
        while (!isSameDay(start_day, end_day)) {
          var check = true;
          for (var i = 0; i < value.length; i++) {
            if (isSameDay(new Date(value[i]["date"]), start_day)) {
              check = false;
              trends_temp[key].push(value[i]);
              break;
            }
          }
          if (check === true) {
            trends_temp[key].push({
              date: string_format_date(start_day),
              times: 0,
            });
          }
          start_day.setDate(start_day.getDate() + 1);
        }
      }
      thedata[1].editData = trends_temp;
      console.log(thedata[1].editData);
      setWeekdays(new_weekdays);
      setRanks(thedata[0].editData);
      setTrends(thedata[1].editData);
      setTrends_data(thedata[1].editData);
      setMap_hotspots({ ...thedata[2].editData });
      setViewers(thedata[3].editData);
      setOverall(thedata[4].editData);
      console.log(thedata);
      setStart_date(new_start_date);
      setTrends_time_selection("Days");
      setEnd_date(new_end_date);
      api_guest_convert(thedata[3].editData);
    };

    fetchData();
  };
  //for Map_hotspots select  favorite or in the box
  const [map_hotspots_data_select, setMap_hotspots_data_select] =
    useState("favorites_times");
  // for fetch change map_hotspots selection
  const change_map_filter = () => {
    const fetchData = async () => {
      var thedata = await Promise.all([fetchMap_hotspots_selected()]);
      setMap_hotspots(thedata[0].editData);
    };

    fetchData();
  };
  const fetchMap_hotspots_selected = () => {
    return fetch(
      "https://uisur.ad.ntust.edu.tw/backend/api/statistics/map_hotspots?" +
        "start_date=" +
        string_format_date(new_start_date) +
        "&" +
        "end_date=" +
        string_format_date(new_end_date) +
        get_weekdays() +
        get_map_hotspots_string(),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { editData: myJson };
        //setTagsData(temp);
      });
  };
  //for Map_hotspots_selection
  var [user_filter, setUser_filter] = useState({
    male: true,
    female: true,
    dadaocheng: true,
    other_area: true,
    other_country: true,
    under_eighteen: true,
    eighteen_to_twentyfive: true,
    twentysix_to_thirdtythree: true,
    thirdtyfour_to_fortyone: true,
    fortytwo_to_fortynine: true,
    fifty_to_fiftyseven: true,
    fiftyeight_to_sixtyfive: true,
    sixtysix_to_seventynine: true,
    above_eighty: true,
  });
  var [locations, setLocations] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
  ]);
  const locations_id = [1, 2, 3, 4, 5, 6];
  const get_map_hotspots_string = () => {
    var output = "&user_filter=";
    var check = true;
    for (const [key, value] of Object.entries(user_filter)) {
      if (value == true) {
        if (check == true) {
          output += "" + key;
          check = false;
        } else {
          output += "," + key;
        }
      }
    }
    var check = true;
    output += "&locations=";
    for (var i = 0; i < locations.length; i++) {
      if (locations[i] === true) {
        if (check == true) {
          output += "" + locations_id[i].toString();
          check = false;
        } else {
          output += "," + locations_id[i].toString();
        }
      }
    }
    return output;
  };

  //at the first fetch data
  useEffect(() => {
    access_token = Cookies.get("access_token");
    if (access_token === undefined) {
      access_token = "";
    }
    setAccess_token(access_token);
    const fetchData = async () => {
      var thedata = await Promise.all([
        fetchRanks(),
        fetchTrends(),
        fetchMap_hotspots(),
        fetchViewers(),
        fetchOverall(),
      ]);
      console.log(thedata);
      for (var i = 0; i < thedata[0].editData["pages"].length; i++) {
        if (thedata[0].editData["pages"][i]["loggable"] == null) {
          thedata[0].editData["pages"][i]["loggable"] = { name: "" };
        }
      }
      const isSameDay = (a, b) => {
        return (
          a.getFullYear() === b.getFullYear() &&
          a.getMonth() === b.getMonth() &&
          a.getDate() === b.getDate()
        );
      };
      var trends_temp = {};
      for (const [key, value] of Object.entries(thedata[1].editData)) {
        trends_temp[key] = [];
        var start_day = new Date(new_start_date);
        var end_day = new Date(new_end_date);
        console.log("thedata", start_day, end_day);
        end_day.setDate(end_day.getDate() + 1);
        while (!isSameDay(start_day, end_day)) {
          var check = true;
          for (var i = 0; i < value.length; i++) {
            if (isSameDay(new Date(value[i]["date"]), start_day)) {
              check = false;
              trends_temp[key].push(value[i]);
              break;
            }
          }
          if (check === true) {
            trends_temp[key].push({
              date: string_format_date(start_day),
              times: 0,
            });
          }
          start_day.setDate(start_day.getDate() + 1);
        }
      }
      thedata[1].editData = trends_temp;
      setRanks(thedata[0].editData);
      setTrends(thedata[1].editData);
      setMap_hotspots(thedata[2].editData);
      setViewers(thedata[3].editData);
      setOverall(thedata[4].editData);
      setTrends_data(thedata[1].editData);
      api_guest_convert(thedata[3].editData);
    };

    fetchData();
  }, []);
  //format date for view
  const stringData = () => {
    return (
      start_date.getFullYear() +
      "/" +
      (start_date.getMonth() + 1) +
      "/" +
      start_date.getDate() +
      " - " +
      end_date.getFullYear() +
      "-" +
      (end_date.getMonth() + 1) +
      "-" +
      end_date.getDate()
    );
  };
  //format date for api
  const string_format_date = (input) => {
    var output = "";
    output += input.getFullYear() + "-";
    if (input.getMonth() + 1 < 10) output += "0" + (input.getMonth() + 1) + "-";
    else output += "" + (input.getMonth() + 1) + "-";
    if (input.getDate() < 10) output += "0" + input.getDate();
    else output += "" + input.getDate();
    return output;
  };
  //five api fetch
  const fetchRanks = () => {
    return fetch(
      "https://uisur.ad.ntust.edu.tw/backend/api/statistics/ranks?" +
        "start_date=" +
        string_format_date(new_start_date) +
        "&" +
        "end_date=" +
        string_format_date(new_end_date) +
        get_weekdays(),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { editData: myJson };
        //setTagsData(temp);
      });
  };
  const fetchMap_hotspots = () => {
    return fetch(
      "https://uisur.ad.ntust.edu.tw/backend/api/statistics/map_hotspots?" +
        "start_date=" +
        string_format_date(new_start_date) +
        "&" +
        "end_date=" +
        string_format_date(new_end_date) +
        get_weekdays() +
        get_map_hotspots_string(),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { editData: myJson };
        //setTagsData(temp);
      });
  };
  const fetchTrends = () => {
    return fetch(
      "https://uisur.ad.ntust.edu.tw/backend/api/statistics/trends?" +
        "start_date=" +
        string_format_date(new_start_date) +
        "&" +
        "end_date=" +
        string_format_date(new_end_date) +
        get_weekdays(),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { editData: myJson };
        //setTagsData(temp);
      });
  };
  const fetchViewers = () => {
    return fetch(
      "https://uisur.ad.ntust.edu.tw/backend/api/statistics/viewers?" +
        "start_date=" +
        string_format_date(new_start_date) +
        "&" +
        "end_date=" +
        string_format_date(new_end_date) +
        get_weekdays(),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { editData: myJson };
        //setTagsData(temp);
      });
  };
  const fetchOverall = () => {
    return fetch(
      "https://uisur.ad.ntust.edu.tw/backend/api/statistics/overall?" +
        "start_date=" +
        string_format_date(new_start_date) +
        "&" +
        "end_date=" +
        string_format_date(new_end_date) +
        get_weekdays(),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { editData: myJson };
        //setTagsData(temp);
      });
  };
  return (
    <>
      <div style={{ backgroundColor: "#f4f4f4" }}>
        <ManageAppBar />
        <Grid container>
          <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
            <LeftAppBar />
          </Grid>
          <Grid
            item
            container
            style={{ paddingLeft: "32px", paddingTop: "32px" }}
            xl={11}
            lg={11}
            md={11}
            sm={11}
            xs={11}
          >
            <Grid container item xl={6} lg={6} md={6} sm={6} xs={6}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography style={{ fontWeight: "bold" }} variant="h4">
                  篩選器
                </Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography style={{ fontWeight: "bold" }} variant="h6">
                  請選擇時間區段
                </Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography
                  style={{ fontWeight: "bold", marginRight: "16px" }}
                  display="inline"
                >
                  快選
                </Typography>
                <Button
                  size={"small"}
                  variant="outlined"
                  onClick={() => {
                    setNew_start_date(new Date("2021-01-01"));
                    setNew_end_date(new Date());
                  }}
                  className={classes.previewButton}
                >
                  全部{" "}
                </Button>
                <Button
                  size={"small"}
                  variant="outlined"
                  onClick={() => {
                    setNew_start_date(new Date());
                    setNew_end_date(new Date());
                  }}
                  className={classes.previewButton}
                >
                  當天{" "}
                </Button>
                <Button
                  size={"small"}
                  variant="outlined"
                  onClick={() => {
                    for (var i = 0; ; i++) {
                      var a = new Date();
                      a.setDate(a.getDate() - i);
                      console.log(a.getDay() == 1);
                      if (a.getDay() == 1) {
                        setNew_start_date(a);
                        break;
                      }
                    }
                    for (var i = 0; ; i++) {
                      var a = new Date();
                      a.setDate(a.getDate() + i);
                      if (a.getDay() == 0) {
                        setNew_end_date(a);
                        break;
                      }
                    }
                  }}
                  className={classes.previewButton}
                >
                  當週{" "}
                </Button>
                <Button
                  size={"small"}
                  variant="outlined"
                  onClick={() => {
                    var date = new Date(),
                      y = date.getFullYear(),
                      m = date.getMonth();
                    var firstDay = new Date(y, m, 1);
                    var lastDay = new Date(y, m + 1, 0);
                    setNew_start_date(firstDay);
                    setNew_end_date(lastDay);
                  }}
                  className={classes.previewButton}
                >
                  當月{" "}
                </Button>
                <Button
                  size={"small"}
                  variant="outlined"
                  onClick={() => {
                    var currentDate = new Date();
                    var theFirst = new Date(currentDate.getFullYear(), 0, 1);
                    var theLast = new Date(new Date().getFullYear(), 11, 31);
                    setNew_start_date(theFirst);
                    setNew_end_date(theLast);
                  }}
                  className={classes.previewButton}
                >
                  當年{" "}
                </Button>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{}}>
                <div style={{ position: "relative" }}>
                  <Typography
                    style={{ fontWeight: "bold", marginRight: "16px" }}
                    display="inline"
                  >
                    自訂
                  </Typography>
                  <Grid
                    style={{ position: "relative", left: 34, top: -20 }}
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                  >
                    <DatePicker
                      style={{ display: "inline" }}
                      selected={new_start_date}
                      onChange={(date) => setNew_start_date(date)}
                      selectsStart
                      inline
                      popoverAttachment="top left"
                      popoverTargetAttachment="bottom left"
                      startDate={new_start_date}
                      endDate={new_end_date}
                      popperContainer={<span></span>}
                    />
                  </Grid>
                  <Grid
                    style={{ position: "absolute", left: 277, top: 4 }}
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                  >
                    <DatePicker
                      selected={new_end_date}
                      style={{ display: "inline" }}
                      inline
                      onChange={(date) => setNew_end_date(date)}
                      selectsEnd
                      popoverAttachment="top right"
                      popoverTargetAttachment="bottom right"
                      startDate={new_start_date}
                      endDate={new_end_date}
                      minDate={new_start_date}
                      popperContainer={<span></span>}
                    />
                  </Grid>
                  {/*少日期選擇*/}
                </div>
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography
                  style={{ fontWeight: "bold", marginRight: "16px" }}
                  display="inline"
                >
                  {"進階"}
                </Typography>
                <Typography style={{ marginLeft: "10px" }} display="inline">
                  {"星期"}
                </Typography>
                {/*少日期選擇*/}
                {new_weekdays.map((item, index) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={new_weekdays[index]}
                        onClick={() => {
                          var temp = new_weekdays;
                          temp[index] = !temp[index];
                          setNew_weekdays([...temp]);
                          console.log(temp);
                        }}
                        labelStyle={{ color: "white" }}
                        name={weekdays_text[index]}
                        color="primary"
                      />
                    }
                    label={
                      (index === 5) | (index === 6) ? (
                        <Typography
                          variant="h6"
                          style={{
                            color: "red",
                          }}
                        >
                          {weekdays_text[index]}
                        </Typography>
                      ) : (
                        <Typography
                          variant="h6"
                          style={{
                            color: "#000000",
                          }}
                        >
                          {weekdays_text[index]}
                        </Typography>
                      )
                    }
                  />
                ))}
              </Grid>
              <Grid
                style={{ display: "flex" }}
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <div style={{ flex: 1, display: "inline" }} />
                <Chip
                  variant="outlined"
                  onClick={change_date}
                  label="確定"
                  style={{
                    color: "white",
                    marginRight: "8px",
                    background: "black",
                  }}
                  clickable
                />
                <Chip
                  variant="outlined"
                  onClick={() => {
                    setNew_weekdays([true, true, true, true, true, true, true]);
                  }}
                  label="清除設定"
                  style={{
                    color: "black",
                    background: "white",
                    border: "black 1px solid",
                  }}
                  clickable
                />
                <div style={{ flex: 1, display: "inline" }} />
              </Grid>
            </Grid>
            <Grid
              alignContent="flex-start"
              container
              item
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
            >
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography style={{ fontWeight: "bold" }} variant="h4">
                  數據總覽
                </Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography variant="caption">
                  {"時間區段：" + stringData()}
                </Typography>
              </Grid>
              <Grid
                container
                justifyContent="center"
                style={{
                  width: "100%",
                  paddingTop: "16px",
                  paddingBottom: "16px",
                }}
                item
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={4}
              >
                <Paper
                  style={{
                    width: "60%",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingRight: "16px",
                    paddingLeft: "16px",
                  }}
                >
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography style={{ fontWeight: "bold" }}>
                      網站總瀏覽數
                    </Typography>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography style={{ fontWeight: "bold" }} variant="h3">
                      {overall["traffics"].filtered}
                    </Typography>
                    {/* {overall["traffics"].filtered_avg/overall["traffics"].all_avg<1?(<>):()} */}
                    <Overall_divide data={overall["traffics"]} />
                  </Grid>
                </Paper>
              </Grid>
              <Grid
                container
                item
                style={{
                  width: "100%",
                  paddingTop: "16px",
                  paddingBottom: "16px",
                }}
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={4}
              >
                <Paper
                  style={{
                    width: "60%",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingRight: "16px",
                    paddingLeft: "16px",
                  }}
                >
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography style={{ fontWeight: "bold" }}>
                      網站總訪客數
                    </Typography>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="h3" style={{ fontWeight: "bold" }}>
                      {overall["viewers"].filtered}
                    </Typography>
                    <Overall_divide data={overall["viewers"]} />
                  </Grid>
                </Paper>
              </Grid>
              <Grid
                container
                style={{
                  width: "100%",
                  paddingTop: "16px",
                  paddingBottom: "16px",
                }}
                item
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={4}
              >
                <Paper
                  style={{
                    width: "60%",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingRight: "16px",
                    paddingLeft: "16px",
                  }}
                >
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography style={{ fontWeight: "bold" }}>
                      地圖創建數
                    </Typography>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="h3" style={{ fontWeight: "bold" }}>
                      {overall["map_created"].filtered}
                    </Typography>
                    <Overall_divide data={overall["map_created"]} />
                  </Grid>
                </Paper>
              </Grid>
              <Grid
                container
                style={{
                  width: "100%",
                  paddingTop: "16px",
                  paddingBottom: "16px",
                }}
                item
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={4}
              >
                <Paper
                  style={{
                    width: "60%",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingRight: "16px",
                    paddingLeft: "16px",
                  }}
                >
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography style={{ fontWeight: "bold" }}>
                      {" "}
                      頁面總蒐集數
                    </Typography>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography style={{ fontWeight: "bold" }} variant="h3">
                      {overall["favorites_articles"].filtered}
                    </Typography>
                    <Overall_divide data={overall["favorites_articles"]} />
                  </Grid>
                </Paper>
              </Grid>
              <Grid
                container
                style={{
                  width: "100%",
                  paddingTop: "16px",
                  paddingBottom: "16px",
                }}
                item
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={4}
              >
                <Paper
                  style={{
                    width: "60%",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingRight: "16px",
                    paddingLeft: "16px",
                  }}
                >
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography style={{ fontWeight: "bold" }}>
                      地點總蒐集數
                    </Typography>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography style={{ fontWeight: "bold" }} variant="h3">
                      {overall["favorites_locations"].filtered}
                    </Typography>
                    <Overall_divide data={overall["favorites_locations"]} />
                  </Grid>
                </Paper>
              </Grid>
              <Grid
                container
                style={{
                  width: "100%",
                  paddingTop: "16px",
                  paddingBottom: "16px",
                }}
                item
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={4}
              >
                <Paper
                  style={{
                    width: "60%",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingRight: "16px",
                    paddingLeft: "16px",
                  }}
                >
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography style={{ fontWeight: "bold" }}>
                      地圖總蒐集數
                    </Typography>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography style={{ fontWeight: "bold" }} variant="h3">
                      {overall["favorites_maps"].filtered}
                    </Typography>
                    <Overall_divide data={overall["favorites_maps"]} />
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}></Grid>
            <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography variant="h4" style={{ fontWeight: "bold" }}>
                  網站動態
                </Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography variant="caption">
                  {"時間區段：" + stringData()}
                </Typography>
              </Grid>
              <Grid
                item
                alignContent="center"
                alignItems="center"
                style={{ display: "flex" }}
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <Typography style={{ fontWeight: "bold" }} display="inline">
                  {"篩選顯示"}
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={trends_selection[0]}
                      onChange={() => {
                        var temp = trends_selection;
                        console.log(trends_selection[0]);
                        temp[0] = !temp[0];
                        setTrends_selection([...temp]);
                      }}
                      name="瀏覽數"
                      color="primary"
                    />
                  }
                  label="瀏覽數"
                />
                <RemoveIcon
                  style={{
                    fontSize: "40px",
                    color: "#4d4d4d",
                    marginLeft: "-20px",
                  }}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={trends_selection[1]}
                      onChange={() => {
                        var temp = trends_selection;
                        temp[1] = !temp[1];
                        setTrends_selection([...temp]);
                      }}
                      name="訪客數"
                      color="primary"
                    />
                  }
                  label="訪客數"
                />
                <RemoveIcon
                  style={{
                    fontSize: "40px",
                    color: "#b62323",
                    marginLeft: "-20px",
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={trends_selection[2]}
                      onChange={() => {
                        var temp = trends_selection;
                        temp[2] = !temp[2];
                        setTrends_selection([...temp]);
                      }}
                      name="地圖創建"
                      color="primary"
                    />
                  }
                  label="地圖創建"
                />
                <RemoveIcon
                  style={{
                    fontSize: "40px",
                    color: "#bc920b",
                    marginLeft: "-20px",
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={trends_selection[3]}
                      onChange={() => {
                        var temp = trends_selection;
                        temp[3] = !temp[3];
                        setTrends_selection([...temp]);
                      }}
                      name="頁面蒐集"
                      color="primary"
                    />
                  }
                  label="頁面蒐集"
                />
                <RemoveIcon
                  style={{
                    fontSize: "40px",
                    color: "#4f820c",
                    marginLeft: "-20px",
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={trends_selection[4]}
                      onChange={() => {
                        var temp = trends_selection;
                        temp[4] = !temp[4];
                        setTrends_selection([...temp]);
                      }}
                      name="地點蒐集"
                      color="primary"
                    />
                  }
                  label="地點蒐集"
                />
                <RemoveIcon
                  style={{
                    fontSize: "40px",
                    color: "#ce7410",
                    marginLeft: "-20px",
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={trends_selection[5]}
                      onChange={() => {
                        var temp = trends_selection;
                        temp[5] = !temp[5];
                        setTrends_selection([...temp]);
                      }}
                      name="地圖蒐集"
                      color="primary"
                    />
                  }
                  label="地圖蒐集"
                />
                <RemoveIcon
                  style={{
                    fontSize: "40px",
                    color: "#77430b",
                    marginLeft: "-20px",
                  }}
                />
                <div style={{ display: "inline", flex: 1 }} />
                <Typography
                  style={{ fontWeight: "bold", marginRight: "10px" }}
                  display="inline"
                >
                  {"地圖顯示格式"}
                </Typography>
                {trends_time_selection === "Days" ? (
                  <Button
                    size={"small"}
                    variant="outlined"
                    className={classes.clickedButton}
                  >
                    Days
                  </Button>
                ) : (
                  <Button
                    size={"small"}
                    variant="outlined"
                    onClick={() => {
                      setTrends_time_selection("Days");
                      trends_time_select("Days");
                    }}
                    className={classes.previewButton}
                  >
                    Days
                  </Button>
                )}
                {trends_time_selection === "Weeks" ? (
                  <Button
                    size={"small"}
                    variant="outlined"
                    className={classes.clickedButton}
                  >
                    Weeks
                  </Button>
                ) : (
                  <Button
                    size={"small"}
                    variant="outlined"
                    onClick={() => {
                      setTrends_time_selection("Weeks");
                      trends_time_select("Weeks");
                    }}
                    className={classes.previewButton}
                  >
                    Weeks
                  </Button>
                )}
                {trends_time_selection === "Months" ? (
                  <Button
                    size={"small"}
                    variant="outlined"
                    className={classes.clickedButton}
                  >
                    Months
                  </Button>
                ) : (
                  <Button
                    size={"small"}
                    variant="outlined"
                    onClick={() => {
                      setTrends_time_selection("Months");
                      trends_time_select("Months");
                    }}
                    className={classes.previewButton}
                  >
                    Months
                  </Button>
                )}
                {trends_time_selection === "Years" ? (
                  <Button
                    size={"small"}
                    variant="outlined"
                    className={classes.clickedButton}
                  >
                    Years
                  </Button>
                ) : (
                  <Button
                    size={"small"}
                    variant="outlined"
                    onClick={() => {
                      setTrends_time_selection("Years");
                      trends_time_select("Years");
                    }}
                    className={classes.previewButton}
                  >
                    Years
                  </Button>
                )}
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                {/*將動態圖放這 */}
                <VictoryChart
                  padding={{ top: 0, bottom: 50, left: 50, right: 60 }}
                  domainPadding={50}
                  height={200}
                  width={600}
                  theme={VictoryTheme.material}
                  containerComponent={
                    <VictoryZoomVoronoiContainer
                      zoomDimension="x"
                      voronoiBlacklist={["scatter"]}
                      labels={({ datum }) => `${datum.times}`}
                    />
                  }
                >
                  {trends_selection[0] === true && (
                    <VictoryLine
                      style={{
                        data: { stroke: "#4d4d4d" },
                        parent: { border: "1px solid #ccc" },
                      }}
                      x="date"
                      y="times"
                      data={trends_data["traffics"]}
                    />
                  )}

                  {trends_selection[0] === true && (
                    <VictoryScatter
                      name="scatter"
                      style={{
                        data: {
                          stroke: "#4d4d4d",
                          fillOpacity: 0,
                          strokeWidth: 1.5,
                        },
                        parent: { border: "1px solid #ccc" },
                      }}
                      x="date"
                      y="times"
                      size={2}
                      data={trends_data["traffics"]}
                    />
                  )}

                  {trends_selection[1] === true && (
                    <VictoryLine
                      style={{
                        data: { stroke: "#b62323" },
                        parent: { border: "1px solid #ccc" },
                      }}
                      x="date"
                      y="times"
                      data={trends_data["viewers"]}
                    />
                  )}
                  {trends_selection[1] === true && (
                    <VictoryScatter
                      name="scatter"
                      style={{
                        data: {
                          stroke: "#b62323",
                          fillOpacity: 0,
                          strokeWidth: 1.5,
                        },
                        parent: { border: "1px solid #ccc" },
                      }}
                      x="date"
                      y="times"
                      size={2}
                      data={trends_data["viewers"]}
                    />
                  )}

                  {trends_selection[2] === true && (
                    <VictoryLine
                      style={{
                        data: { stroke: "#bc920b" },
                        parent: { border: "1px solid #ccc" },
                      }}
                      x="date"
                      y="times"
                      data={trends_data["map_created"]}
                    />
                  )}
                  {trends_selection[2] === true && (
                    <VictoryScatter
                      name="scatter"
                      style={{
                        data: {
                          stroke: "#bc920b",
                          fillOpacity: 0,
                          strokeWidth: 1.5,
                        },
                        parent: { border: "1px solid #ccc" },
                      }}
                      x="date"
                      y="times"
                      size={2}
                      data={trends_data["map_created"]}
                    />
                  )}

                  {trends_selection[3] === true && (
                    <VictoryLine
                      style={{
                        data: { stroke: "#4f820c" },
                        parent: { border: "1px solid #ccc" },
                      }}
                      x="date"
                      y="times"
                      data={trends_data["favorite_articles"]}
                    />
                  )}
                  {trends_selection[3] === true && (
                    <VictoryScatter
                      name="scatter"
                      style={{
                        data: {
                          stroke: "#4f820c",
                          fillOpacity: 0,
                          strokeWidth: 1.5,
                        },
                        parent: { border: "1px solid #ccc" },
                      }}
                      x="date"
                      y="times"
                      size={2}
                      data={trends_data["favorite_articles"]}
                    />
                  )}

                  {trends_selection[4] === true && (
                    <VictoryLine
                      style={{
                        data: { stroke: "#ce7410" },
                        parent: { border: "1px solid #ccc" },
                      }}
                      x="date"
                      y="times"
                      data={trends_data["favorite_locations"]}
                    />
                  )}
                  {trends_selection[4] === true && (
                    <VictoryScatter
                      name="scatter"
                      style={{
                        data: {
                          stroke: "#ce7410",
                          fillOpacity: 0,
                          strokeWidth: 1.5,
                        },
                        parent: { border: "1px solid #ccc" },
                      }}
                      x="date"
                      y="times"
                      size={2}
                      data={trends_data["favorite_locations"]}
                    />
                  )}

                  {trends_selection[5] === true && (
                    <VictoryLine
                      style={{
                        data: { stroke: "#77430b" },
                        parent: { border: "1px solid #ccc" },
                      }}
                      x="date"
                      y="times"
                      data={trends_data["favorite_maps"]}
                    />
                  )}
                  {trends_selection[5] === true && (
                    <VictoryScatter
                      name="scatter"
                      style={{
                        data: {
                          stroke: "#77430b",
                          fillOpacity: 0,
                          strokeWidth: 1.5,
                        },
                        parent: { border: "1px solid #ccc" },
                      }}
                      x="date"
                      y="times"
                      size={2}
                      data={trends_data["favorite_maps"]}
                    />
                  )}
                </VictoryChart>
                );
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="flex-start"
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography style={{ fontWeight: "bold" }} variant="h4">
                  {"地圖熱點"}
                </Typography>
              </Grid>
              <Grid container item xl={7} lg={7} md={7} sm={7} xs={7}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="caption">
                    {"時間區段：" + stringData()}
                  </Typography>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  {/*地圖 */}
                  <Map points={map_hotspots[map_hotspots_data_select]} />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                style={{ paddingLeft: "4px", paddingRight: "4px" }}
                item
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
              >
                <Grid
                  style={{ display: "flex" }}
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <div style={{ display: "inline", flex: 1 }} />
                  <Typography style={{ fontWeight: "bold" }} display="inline">
                    {"篩選顯示"}
                  </Typography>
                  <div style={{ display: "inline", flex: 1 }} />
                </Grid>
                <Paper>
                  <Grid
                    style={{ display: "flex" }}
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <div style={{ display: "inline", flex: 1 }} />
                    <Typography style={{ marginTop: "10px" }} display="inline">
                      {"地點類型"}
                    </Typography>
                    <div style={{ display: "inline", flex: 1 }} />
                  </Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Checkbox
                      checked={locations[0]}
                      onChange={() => {
                        var temp = locations;
                        temp[0] = !temp[0];
                        setLocations([...temp]);
                        change_map_filter();
                      }}
                    />
                    <FiberManualRecordIcon style={{ color: "#2196f3" }} />
                    <Typography display="inline">{"人物"}</Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Checkbox
                      checked={locations[1]}
                      onChange={() => {
                        var temp = locations;
                        temp[1] = !temp[1];
                        setLocations([...temp]);
                        change_map_filter();
                      }}
                    />
                    <FiberManualRecordIcon style={{ color: "#673ab7" }} />
                    <Typography display="inline">{"產業"}</Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Checkbox
                      checked={locations[2]}
                      onChange={() => {
                        var temp = locations;
                        temp[2] = !temp[2];
                        setLocations([...temp]);
                        change_map_filter();
                      }}
                    />
                    <FiberManualRecordIcon style={{ color: "#f44336" }} />
                    <Typography display="inline">{"建築"}</Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Checkbox
                      checked={locations[3]}
                      onChange={() => {
                        var temp = locations;
                        temp[3] = !temp[3];
                        setLocations([...temp]);
                        change_map_filter();
                      }}
                    />
                    <FiberManualRecordIcon style={{ color: "#ff9800" }} />
                    <Typography display="inline">{"美食"}</Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Checkbox
                      checked={locations[4]}
                      onChange={() => {
                        var temp = locations;
                        temp[4] = !temp[4];
                        setLocations([...temp]);
                        change_map_filter();
                      }}
                    />
                    <FiberManualRecordIcon style={{ color: "#ffeb3b" }} />
                    <Typography display="inline">{"特色"}</Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <Checkbox
                      checked={locations[5]}
                      onChange={() => {
                        var temp = locations;
                        temp[5] = !temp[5];
                        setLocations([...temp]);
                        change_map_filter();
                      }}
                    />
                    <FiberManualRecordIcon style={{ color: "#59b95c" }} />
                    <Typography display="inline">{"交通"}</Typography>
                  </Grid>
                  <Grid
                    item
                    style={{ display: "flex" }}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <div style={{ display: "inline", flex: 1 }} />
                    <Typography display="inline">{"收藏者"}</Typography>
                    <div style={{ display: "inline", flex: 1 }} />
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Checkbox
                      checked={user_filter["female"]}
                      onChange={() => {
                        var temp = user_filter;
                        temp["female"] = !temp["female"];
                        setUser_filter({ ...temp });
                        change_map_filter();
                      }}
                    />
                    <Typography display="inline">{"女性用戶"}</Typography>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Checkbox
                      checked={user_filter["male"]}
                      onChange={() => {
                        var temp = user_filter;
                        temp["male"] = !temp["male"];
                        setUser_filter({ ...temp });
                        change_map_filter();
                      }}
                    />
                    <Typography display="inline">{"男性用戶"}</Typography>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    {/* <Typography display="inline">{"--"}</Typography> */}
                    <Divider />
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Checkbox
                      checked={user_filter["dadaocheng"]}
                      onChange={() => {
                        var temp = user_filter;
                        temp["dadaocheng"] = !temp["dadaocheng"];
                        setUser_filter({ ...temp });
                        change_map_filter();
                      }}
                    />
                    <Typography display="inline">{"大稻埕人"}</Typography>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Checkbox
                      checked={user_filter["other_area"]}
                      onChange={() => {
                        var temp = user_filter;
                        temp["other_area"] = !temp["other_area"];
                        setUser_filter({ ...temp });
                        change_map_filter();
                      }}
                    />
                    <Typography display="inline">{"其他地區"}</Typography>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Checkbox
                      checked={user_filter["other_country"]}
                      onChange={() => {
                        var temp = user_filter;
                        temp["other_country"] = !temp["other_country"];
                        setUser_filter({ ...temp });
                        change_map_filter();
                      }}
                    />
                    <Typography display="inline">{"其他國家"}</Typography>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Divider />
                    {/* <Typography display="inline">{"--"}</Typography> */}
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Checkbox
                      checked={user_filter["under_eighteen"]}
                      onChange={() => {
                        var temp = user_filter;
                        temp["under_eighteen"] = !temp["under_eighteen"];
                        setUser_filter({ ...temp });
                        change_map_filter();
                      }}
                    />
                    <Typography display="inline">{"未滿18"}</Typography>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Checkbox
                      checked={user_filter["eighteen_to_twentyfive"]}
                      onChange={() => {
                        var temp = user_filter;
                        temp["eighteen_to_twentyfive"] =
                          !temp["eighteen_to_twentyfive"];
                        setUser_filter({ ...temp });
                        change_map_filter();
                      }}
                    />
                    <Typography display="inline">{"18~25 歲"}</Typography>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Checkbox
                      checked={user_filter["twentysix_to_thirdtythree"]}
                      onChange={() => {
                        var temp = user_filter;
                        temp["twentysix_to_thirdtythree"] =
                          !temp["twentysix_to_thirdtythree"];
                        setUser_filter({ ...temp });
                        change_map_filter();
                      }}
                    />
                    <Typography display="inline">{"26~33 歲"}</Typography>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Checkbox
                      checked={user_filter["thirdtyfour_to_fortyone"]}
                      onChange={() => {
                        var temp = user_filter;
                        temp["thirdtyfour_to_fortyone"] =
                          !temp["thirdtyfour_to_fortyone"];
                        setUser_filter({ ...temp });
                        change_map_filter();
                      }}
                    />
                    <Typography display="inline">{"34~41 歲"}</Typography>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Checkbox
                      checked={user_filter["fortytwo_to_fortynine"]}
                      onChange={() => {
                        var temp = user_filter;
                        temp["fortytwo_to_fortynine"] =
                          !temp["fortytwo_to_fortynine"];
                        setUser_filter({ ...temp });
                        change_map_filter();
                      }}
                    />
                    <Typography display="inline">{"42~49 歲"}</Typography>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Checkbox
                      checked={user_filter["fifty_to_fiftyseven"]}
                      onChange={() => {
                        var temp = user_filter;
                        temp["fifty_to_fiftyseven"] =
                          !temp["fifty_to_fiftyseven"];
                        setUser_filter({ ...temp });
                        change_map_filter();
                      }}
                    />
                    <Typography display="inline">{"50~57 歲"}</Typography>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Checkbox
                      checked={user_filter["fiftyeight_to_sixtyfive"]}
                      onChange={() => {
                        var temp = user_filter;
                        temp["fiftyeight_to_sixtyfive"] =
                          !temp["fiftyeight_to_sixtyfive"];
                        setUser_filter({ ...temp });
                        change_map_filter();
                      }}
                    />
                    <Typography display="inline">{"58~65 歲"}</Typography>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Checkbox
                      checked={user_filter["sixtysix_to_seventynine"]}
                      onChange={() => {
                        var temp = user_filter;
                        temp["sixtysix_to_seventynine"] =
                          !temp["sixtysix_to_seventynine"];
                        setUser_filter({ ...temp });
                        change_map_filter();
                      }}
                    />
                    <Typography display="inline">{"66~79 歲"}</Typography>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Checkbox
                      checked={user_filter["above_eighty"]}
                      onChange={() => {
                        var temp = user_filter;
                        temp["above_eighty"] = !temp["above_eighty"];
                        setUser_filter({ ...temp });
                        change_map_filter();
                      }}
                    />
                    <Typography display="inline">{"80+"}</Typography>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography display="inline">{"    "}</Typography>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Button
                      size="small"
                      onClick={() => {
                        user_filter = {
                          male: true,
                          female: true,
                          dadaocheng: true,
                          other_area: true,
                          other_country: true,
                          under_eighteen: true,
                          eighteen_to_twentyfive: true,
                          twentysix_to_thirdtythree: true,
                          thirdtyfour_to_fortyone: true,
                          fortytwo_to_fortynine: true,
                          fifty_to_fiftyseven: true,
                          fiftyeight_to_sixtyfive: true,
                          sixtysix_to_seventynine: true,
                          above_eighty: true,
                        };
                        locations = [true, true, true, true, true, true];
                        setUser_filter({
                          male: true,
                          female: true,
                          dadaocheng: true,
                          other_area: true,
                          other_country: true,
                          under_eighteen: true,
                          eighteen_to_twentyfive: true,
                          twentysix_to_thirdtythree: true,
                          thirdtyfour_to_fortyone: true,
                          fortytwo_to_fortynine: true,
                          fifty_to_fiftyseven: true,
                          fiftyeight_to_sixtyfive: true,
                          sixtysix_to_seventynine: true,
                          above_eighty: true,
                        });
                        setLocations([true, true, true, true, true, true]);
                        change_map_filter();
                      }}
                      style={{ paddingLeft: "-64px", paddingRight: "-64px" }}
                    >
                      全選
                    </Button>
                    <Typography display="inline">|</Typography>
                    <Button
                      size="small"
                      onClick={() => {
                        user_filter = {
                          male: false,
                          female: false,
                          dadaocheng: false,
                          other_area: false,
                          other_country: false,
                          under_eighteen: false,
                          eighteen_to_twentyfive: false,
                          twentysix_to_thirdtythree: false,
                          thirdtyfour_to_fortyone: false,
                          fortytwo_to_fortynine: false,
                          fifty_to_fiftyseven: false,
                          fiftyeight_to_sixtyfive: false,
                          sixtysix_to_seventynine: false,
                          above_eighty: false,
                        };
                        locations = [false, false, false, false, false, false];
                        setUser_filter({
                          male: false,
                          female: false,
                          dadaocheng: false,
                          other_area: false,
                          other_country: false,
                          under_eighteen: false,
                          eighteen_to_twentyfive: false,
                          twentysix_to_thirdtythree: false,
                          thirdtyfour_to_fortyone: false,
                          fortytwo_to_fortynine: false,
                          fifty_to_fiftyseven: false,
                          fiftyeight_to_sixtyfive: false,
                          sixtysix_to_seventynine: false,
                          above_eighty: false,
                        });
                        setLocations([
                          false,
                          false,
                          false,
                          false,
                          false,
                          false,
                        ]);

                        change_map_filter();
                      }}
                    >
                      全不選
                    </Button>
                  </Grid>
                </Paper>
              </Grid>
              <Grid container item xl={4} lg={4} md={4} sm={4} xs={4}>
                <Grid
                  style={{ display: "flex" }}
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <div style={{ display: "inline", flex: 1 }} />
                  <Typography
                    display="inline"
                    style={{ fontWeight: "bold" }}
                    onClick={() => {
                      setMap_hotspots_data_select("favorites_times");
                    }}
                    onMouseOut={(e) => {
                      e.target.style.textDecoration = "none";
                    }}
                    onMouseOver={(e) => {
                      e.target.style.textDecoration = "underline";
                    }}
                  >
                    被收藏次數
                  </Typography>
                  <Typography display="inline">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography
                    display="inline"
                    style={{ fontWeight: "bold" }}
                    onClick={() => {
                      setMap_hotspots_data_select("articles_in_map_times");
                    }}
                    onMouseOut={(e) => {
                      e.target.style.textDecoration = "none";
                    }}
                    onMouseOver={(e) => {
                      e.target.style.textDecoration = "underline";
                    }}
                  >
                    被編入地圖次數
                  </Typography>
                  <div style={{ display: "inline", flex: 1 }} />
                </Grid>
                {map_hotspots[map_hotspots_data_select].map((item) => (
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ paddingRight: "16px", paddingLeft: "16px" }}
                  >
                    {item["article"]["map_filter_type_id"] === null && (
                      <Paper
                        style={{
                          display: "flex",
                          paddingRight: "16px",
                          marginBottom: "10px",
                          paddingLeft: "16px",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          borderLeft: "5px solid black",
                        }}
                      >
                        <Typography
                          style={{ fontWeight: "bold" }}
                          display="inline"
                        >
                          {item["article"]["name"]}
                        </Typography>
                        <div style={{ display: "inline", flexGrow: 1 }} />
                        <Typography
                          style={{ fontWeight: "bold" }}
                          display="inline"
                        >
                          {item["times"] + "次"}
                        </Typography>
                      </Paper>
                    )}
                    {item["article"]["map_filter_type_id"] === 1 && (
                      <Paper
                        style={{
                          display: "flex",
                          paddingRight: "16px",
                          marginBottom: "10px",
                          paddingLeft: "16px",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          borderLeft: "5px solid #3496ef",
                        }}
                      >
                        <Typography
                          style={{ fontWeight: "bold" }}
                          display="inline"
                        >
                          {item["article"]["name"]}
                        </Typography>
                        <div style={{ display: "inline", flexGrow: 1 }} />
                        <Typography
                          style={{ fontWeight: "bold" }}
                          display="inline"
                        >
                          {item["times"] + "次"}
                        </Typography>
                      </Paper>
                    )}
                    {item["article"]["map_filter_type_id"] === 2 && (
                      <Paper
                        style={{
                          display: "flex",
                          paddingRight: "16px",
                          marginBottom: "10px",
                          paddingLeft: "16px",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          borderLeft: "5px solid #6738b3",
                        }}
                      >
                        <Typography
                          style={{ fontWeight: "bold" }}
                          display="inline"
                        >
                          {item["article"]["name"]}
                        </Typography>
                        <div style={{ display: "inline", flexGrow: 1 }} />
                        <Typography
                          style={{ fontWeight: "bold" }}
                          display="inline"
                        >
                          {item["times"] + "次"}
                        </Typography>
                      </Paper>
                    )}
                    {item["article"]["map_filter_type_id"] === 3 && (
                      <Paper
                        style={{
                          display: "flex",
                          paddingRight: "16px",
                          marginBottom: "10px",
                          paddingLeft: "16px",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          borderLeft: "5px solid #ef423b",
                        }}
                      >
                        <Typography
                          style={{ fontWeight: "bold" }}
                          display="inline"
                        >
                          {item["article"]["name"]}
                        </Typography>
                        <div style={{ display: "inline", flexGrow: 1 }} />
                        <Typography
                          style={{ fontWeight: "bold" }}
                          display="inline"
                        >
                          {item["times"] + "次"}
                        </Typography>
                      </Paper>
                    )}
                    {item["article"]["map_filter_type_id"] === 4 && (
                      <Paper
                        style={{
                          display: "flex",
                          paddingRight: "16px",
                          marginBottom: "10px",
                          paddingLeft: "16px",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          borderLeft: "5px solid #fb9829",
                        }}
                      >
                        <Typography
                          style={{ fontWeight: "bold" }}
                          display="inline"
                        >
                          {item["article"]["name"]}
                        </Typography>
                        <div style={{ display: "inline", flexGrow: 1 }} />
                        <Typography
                          style={{ fontWeight: "bold" }}
                          display="inline"
                        >
                          {item["times"] + "次"}
                        </Typography>
                      </Paper>
                    )}
                    {item["article"]["map_filter_type_id"] === 5 && (
                      <Paper
                        style={{
                          display: "flex",
                          paddingRight: "16px",
                          marginBottom: "10px",
                          paddingLeft: "16px",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          borderLeft: "5px solid #fdeb54",
                        }}
                      >
                        <Typography
                          style={{ fontWeight: "bold" }}
                          display="inline"
                        >
                          {item["article"]["name"]}
                        </Typography>
                        <div style={{ display: "inline", flexGrow: 1 }} />
                        <Typography
                          style={{ fontWeight: "bold" }}
                          display="inline"
                        >
                          {item["times"] + "次"}
                        </Typography>
                      </Paper>
                    )}
                    {item["article"]["map_filter_type_id"] === 6 && (
                      <Paper
                        style={{
                          display: "flex",
                          paddingRight: "16px",
                          marginBottom: "10px",
                          paddingLeft: "16px",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          borderLeft: "5px solid #5fb963",
                        }}
                      >
                        <Typography
                          style={{ fontWeight: "bold" }}
                          display="inline"
                        >
                          {item["article"]["name"]}
                        </Typography>
                        <div style={{ display: "inline", flexGrow: 1 }} />
                        <Typography
                          style={{ fontWeight: "bold" }}
                          display="inline"
                        >
                          {item["times"] + "次"}
                        </Typography>
                      </Paper>
                    )}
                  </Grid>
                ))}
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  {/*地點流覽統計圖 */}
                </Grid>
              </Grid>
            </Grid>

            <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography
                  style={{ marginTop: "20px", fontWeight: "bold" }}
                  variant="h4"
                >
                  訪客
                </Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography variant="caption">
                  {"時間區段：" + stringData()}
                </Typography>
              </Grid>
              <Grid item container xl={3} lg={3} md={3} sm={3} xs={3}>
                {/*訪客左列圖 */}
                <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Paper
                    style={{
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      display: "flex",
                    }}
                  >
                    <Grid item container xl={3} lg={3} md={3} sm={3} xs={3}>
                      <VictoryPie
                        colorScale={[
                          "#c03c25",
                          "#bdbab9",
                          "#ba9227",
                          "cyan",
                          "navy",
                        ]}
                        labels={() => null}
                        innerRadius={75}
                        data={[
                          { y: guestdata[0]["members"] },
                          { y: guestdata[0]["guest"] },
                        ]}
                      />
                    </Grid>
                    <Grid container item xl={9} lg={9} md={9} sm={9} xs={9}>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="h5" style={{ fontWeight: "bold" }}>
                          {"總訪客數 " + guestdata[0]["all"] + " 人"}
                        </Typography>
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography
                          display="inline"
                          style={{ color: "#c03c25" }}
                        >
                          {guestdata[0]["members"]}
                        </Typography>
                        <Typography display="inline">
                          {"　人為已登入會員　　　"}
                        </Typography>
                        <Typography
                          display="inline"
                          style={{ color: "#c03c25" }}
                        >
                          {guestdata[0]["member_percent"]}
                        </Typography>
                        <Typography display="inline">{" %"}</Typography>
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography
                          display="inline"
                          style={{ color: "#bdbab9" }}
                        >
                          {guestdata[0]["guest"]}
                        </Typography>
                        <Typography display="inline">
                          {"　人為未登入訪客　　　"}
                        </Typography>
                        <Typography
                          display="inline"
                          style={{ color: "#bdbab9" }}
                        >
                          {guestdata[0]["guest_percent"]}
                        </Typography>
                        <Typography display="inline">{" %"}</Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    {guestdata[0]["members"] + "位已登入會員中"}
                  </Typography>
                </Grid>
                <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Paper
                    onClick={() => {
                      setGuest_selsect("sex");
                    }}
                    style={
                      guest_selsect === "sex"
                        ? {
                            position: "relative",
                            paddingTop: "20px",
                            paddingBottom: "20px",
                            borderLeft: "5px solid #bc3b2a",
                            marginBottom: "10px",
                            display: "flex",
                          }
                        : {
                            position: "relative",
                            paddingTop: "20px",
                            paddingBottom: "20px",
                            marginBottom: "10px",
                            display: "flex",
                          }
                    }
                  >
                    {guest_selsect === "sex" && (
                      <PlayArrowIcon
                        style={{
                          color: "#c03c25",
                          position: "absolute",
                          left: "100%",
                          top: "45%",
                        }}
                      />
                    )}
                    <Grid item container xl={3} lg={3} md={3} sm={3} xs={3}>
                      <VictoryPie
                        colorScale={[
                          "#c03c25",
                          "#bdbab9",
                          "#ba9227",
                          "cyan",
                          "navy",
                        ]}
                        labels={() => null}
                        innerRadius={75}
                        data={[
                          { y: guestdata[1]["female"] },
                          { y: guestdata[1]["male"] },
                        ]}
                      />
                    </Grid>
                    <Grid container item xl={9} lg={9} md={9} sm={9} xs={9}>
                      {/*  { "other_area_female": 0, "other_area_male": 0, "other_country_female": 0, "other_country_male": 0, "dadaocheng_female": 0, "dadaocheng_male": 0, "male": 0, "female": 0, "male_set": [], "female_set": [] }, { 'all': 0, "dadaocheng": 0, "other_country": 0, "other_country_female": 0, "other_country_male": 0, "other_area_male": 0, "other_area_female": 0, "other_area": 0, "dadaocheng_male": 0, "dadaocheng_female": 0, "dadaocheng_female_set": [], "dadaocheng_male_set": [], "other_country_female_set": [], "other_country_male_set": [], "other_area_female_set": [], "other_area_male_set": [] }, { "old_set": [] }] */}
                      <Grid
                        item
                        container
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <Typography
                          display="inline"
                          style={{ color: "#c03c25" }}
                        >
                          {guestdata[1]["female"]}
                        </Typography>
                        <Typography display="inline">
                          {"　人為女性" + "　　　　　　"}
                        </Typography>
                        <Typography
                          display="inline"
                          style={{ color: "#c03c25" }}
                        >
                          {" "}
                          {get_percent(
                            guestdata[1]["female"],
                            guestdata[0]["members"]
                          )}
                        </Typography>
                        <Typography display="inline">{" %"}</Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <Typography
                          display="inline"
                          style={{ color: "#bdbab9" }}
                        >
                          {guestdata[1]["male"]}
                        </Typography>
                        <Typography display="inline">
                          {"　人為男性" + "　　　　　　"}
                        </Typography>
                        <Typography
                          display="inline"
                          style={{ color: "#bdbab9" }}
                        >
                          {" "}
                          {get_percent(
                            guestdata[1]["male"],
                            guestdata[0]["members"]
                          )}
                        </Typography>
                        <Typography display="inline">{" %"}</Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Paper
                    onClick={() => {
                      setGuest_selsect("place");
                    }}
                    style={
                      guest_selsect === "place"
                        ? {
                            position: "relative",
                            paddingTop: "20px",
                            paddingBottom: "20px",
                            borderLeft: "5px solid #bc3b2a",
                            marginBottom: "10px",
                            display: "flex",
                          }
                        : {
                            position: "relative",
                            paddingTop: "20px",
                            paddingBottom: "20px",
                            marginBottom: "10px",
                            display: "flex",
                          }
                    }
                  >
                    {guest_selsect === "place" && (
                      <PlayArrowIcon
                        style={{
                          color: "#c03c25",
                          position: "absolute",
                          left: "100%",
                          top: "45%",
                        }}
                      />
                    )}
                    <Grid item container xl={3} lg={3} md={3} sm={3} xs={3}>
                      <VictoryPie
                        colorScale={[
                          "#c03c25",
                          "#bdbab9",
                          "#ba9227",
                          "cyan",
                          "navy",
                        ]}
                        labels={() => null}
                        innerRadius={75}
                        data={[
                          { y: guestdata[2]["dadaocheng"] },
                          { y: guestdata[2]["other_area"] },
                          { y: guestdata[2]["other_country"] },
                        ]}
                      />
                    </Grid>
                    <Grid container item xl={9} lg={9} md={9} sm={9} xs={9}>
                      {/*  { 'all': 0, "dadaocheng": 0, "other_country": 0, "other_country_female": 0, "other_country_male": 0, "other_area_male": 0, "other_area_female": 0, "other_area": 0, "dadaocheng_male": 0, "dadaocheng_female": 0, "dadaocheng_female_set": [], "dadaocheng_male_set": [], "other_country_female_set": [], "other_country_male_set": [], "other_area_female_set": [], "other_area_male_set": [] }, { "old_set": [] }] */}
                      <Grid
                        item
                        container
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <Typography
                          display="inline"
                          style={{ color: "#c03c25" }}
                        >
                          {guestdata[2]["dadaocheng"]}
                        </Typography>
                        <Typography display="inline">
                          {"　人來自大稻埕 " + "　　　　"}
                        </Typography>
                        <Typography
                          display="inline"
                          style={{ color: "#c03c25" }}
                        >
                          {get_percent(
                            guestdata[2]["dadaocheng"],
                            guestdata[0]["members"]
                          )}
                        </Typography>
                        <Typography display="inline">{" %"}</Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <Typography
                          display="inline"
                          style={{ color: "#bdbab9" }}
                        >
                          {guestdata[2]["other_area"]}
                        </Typography>
                        <Typography display="inline">
                          {"　人來自其他地區 " + "　　　"}
                        </Typography>
                        <Typography
                          display="inline"
                          style={{ color: "#bdbab9" }}
                        >
                          {get_percent(
                            guestdata[2]["other_area"],
                            guestdata[0]["members"]
                          )}
                        </Typography>
                        <Typography display="inline">{" %"}</Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <Typography
                          display="inline"
                          style={{ color: "#ba9227" }}
                        >
                          {guestdata[2]["other_country"]}
                        </Typography>
                        <Typography display="inline">
                          {"　人來自其他國家 " + "　　　"}
                        </Typography>
                        <Typography
                          display="inline"
                          style={{ color: "#ba9227" }}
                        >
                          {get_percent(
                            guestdata[2]["other_country"],
                            guestdata[0]["members"]
                          )}
                        </Typography>
                        <Typography display="inline">{" %"}</Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
                  {/*   { "old_set": [] }] */}
                  <Paper
                    onClick={() => {
                      setGuest_selsect("old");
                    }}
                    style={
                      guest_selsect === "old"
                        ? {
                            position: "relative",
                            paddingTop: "20px",
                            paddingBottom: "20px",
                            borderLeft: "5px solid #bc3b2a",
                            marginBottom: "10px",
                            display: "flex",
                          }
                        : {
                            position: "relative",
                            paddingTop: "20px",
                            paddingBottom: "20px",
                            marginBottom: "10px",
                            display: "flex",
                          }
                    }
                  >
                    {guest_selsect === "old" && (
                      <PlayArrowIcon
                        style={{
                          color: "#c03c25",
                          position: "absolute",
                          left: "100%",
                          top: "45%",
                        }}
                      />
                    )}
                    <Grid item container xl={3} lg={3} md={3} sm={3} xs={3}>
                      <VictoryPie
                        colorScale={[
                          "#7bc037",
                          "#5a8220",
                          "#c03c25",
                          "#ba9229",
                          "#8f7228",
                          "#cb7424",
                          "#aa6732",
                          "#754314",
                          "#bdbab9",
                        ]}
                        labels={() => null}
                        innerRadius={75}
                        data={guestdata[3]["old_set"].map((item) => {
                          return { y: item };
                        })}
                      />
                    </Grid>
                    <Grid container item xl={9} lg={9} md={9} sm={9} xs={9}>
                      <Grid
                        item
                        container
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        {" "}
                        <Typography
                          display="inline"
                          style={{ color: "#7bc037" }}
                        >
                          {guestdata[3]["old_set"][0]}
                        </Typography>
                        <Typography display="inline">
                          {" "}
                          {" 人為 0~17　歲 " + "　　　　"}{" "}
                        </Typography>
                        <Typography style={{ color: "#7bc037" }}>
                          {get_percent(
                            guestdata[3]["old_set"][0],
                            guestdata[0]["members"]
                          )}
                        </Typography>
                        <Typography display="inline">{" %"}</Typography>{" "}
                      </Grid>
                      <Grid
                        item
                        container
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        {" "}
                        <Typography display="inline" style={{ color: "" }}>
                          {guestdata[3]["old_set"][1]}
                        </Typography>
                        <Typography display="inline">
                          {" "}
                          {" 人為 18~25 歲 " + "　　　　"}
                        </Typography>
                        <Typography style={{ color: "#5a8220" }}>
                          {get_percent(
                            guestdata[3]["old_set"][1],
                            guestdata[0]["members"]
                          )}
                        </Typography>
                        <Typography display="inline"> {" %"}</Typography>{" "}
                      </Grid>
                      <Grid
                        item
                        container
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        {" "}
                        <Typography
                          display="inline"
                          style={{ color: "#c03c25" }}
                        >
                          {guestdata[3]["old_set"][2]}
                        </Typography>
                        <Typography display="inline">
                          {" "}
                          {" 人為 26~33 歲 " + "　　　　"}
                        </Typography>
                        <Typography style={{ color: "#c03c25" }}>
                          {get_percent(
                            guestdata[3]["old_set"][2],
                            guestdata[0]["members"]
                          )}
                        </Typography>
                        <Typography display="inline"> {" %"}</Typography>{" "}
                      </Grid>
                      <Grid
                        item
                        container
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        {" "}
                        <Typography
                          display="inline"
                          style={{ color: "#ba9229" }}
                        >
                          {guestdata[3]["old_set"][3]}
                        </Typography>
                        <Typography display="inline">
                          {" "}
                          {" 人為 34~41 歲 " + "　　　　"}
                        </Typography>
                        <Typography style={{ color: "#ba9229" }}>
                          {get_percent(
                            guestdata[3]["old_set"][3],
                            guestdata[0]["members"]
                          )}
                        </Typography>
                        <Typography display="inline"> {" %"}</Typography>{" "}
                      </Grid>
                      <Grid
                        item
                        container
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        {" "}
                        <Typography
                          display="inline"
                          style={{ color: "#8f7228" }}
                        >
                          {guestdata[3]["old_set"][4]}
                        </Typography>
                        <Typography display="inline">
                          {" "}
                          {" 人為 42~49 歲 " + "　　　　"}
                        </Typography>
                        <Typography style={{ color: "#8f7228" }}>
                          {get_percent(
                            guestdata[3]["old_set"][4],
                            guestdata[0]["members"]
                          )}
                        </Typography>
                        <Typography display="inline"> {" %"}</Typography>{" "}
                      </Grid>
                      <Grid
                        item
                        container
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        {" "}
                        <Typography
                          display="inline"
                          style={{ color: "#cb7424" }}
                        >
                          {guestdata[3]["old_set"][5]}
                        </Typography>
                        <Typography display="inline">
                          {" "}
                          {" 人為 50~57 歲 " + "　　　　"}
                        </Typography>
                        <Typography style={{ color: "#cb7424" }}>
                          {get_percent(
                            guestdata[3]["old_set"][5],
                            guestdata[0]["members"]
                          )}
                        </Typography>
                        <Typography display="inline"> {" %"}</Typography>{" "}
                      </Grid>
                      <Grid
                        item
                        container
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        {" "}
                        <Typography
                          display="inline"
                          style={{ color: "#aa6732" }}
                        >
                          {guestdata[3]["old_set"][6]}
                        </Typography>
                        <Typography display="inline">
                          {" "}
                          {" 人為 58~65 歲 " + "　　　　"}
                        </Typography>
                        <Typography style={{ color: "#aa6732" }}>
                          {get_percent(
                            guestdata[3]["old_set"][6],
                            guestdata[0]["members"]
                          )}
                        </Typography>
                        <Typography display="inline"> {" %"}</Typography>{" "}
                      </Grid>
                      <Grid
                        item
                        container
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        {" "}
                        <Typography
                          display="inline"
                          style={{ color: "#754314" }}
                        >
                          {guestdata[3]["old_set"][7]}
                        </Typography>
                        <Typography display="inline">
                          {" "}
                          {" 人為 66~79 歲 " + "　　　　"}
                        </Typography>
                        <Typography style={{ color: "#754314" }}>
                          {get_percent(
                            guestdata[3]["old_set"][7],
                            guestdata[0]["members"]
                          )}
                        </Typography>
                        <Typography display="inline"> {" %"}</Typography>{" "}
                      </Grid>
                      <Grid
                        item
                        container
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        {" "}
                        <Typography
                          display="inline"
                          style={{ color: "#bdbab9" }}
                        >
                          {guestdata[3]["old_set"][8]}
                        </Typography>
                        <Typography display="inline">
                          {" "}
                          {" 人為 80+ 　歲 " + "　　　　"}{" "}
                        </Typography>
                        <Typography style={{ color: "#bdbab9" }}>
                          {get_percent(
                            guestdata[3]["old_set"][8],
                            guestdata[0]["members"]
                          )}
                        </Typography>
                        <Typography display="inline">{" %"}</Typography>{" "}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <Grid
                style={{ paddingRight: "30px", paddingLeft: "30px" }}
                item
                xl={9}
                lg={9}
                md={9}
                sm={9}
                xs={9}
              >
                <Paper style={{ paddingRight: "20px", paddingLeft: "20px" }}>
                  {guest_selsect === "place" && (
                    <>
                      <VictoryPie
                        colorScale={[
                          "#c03c25",
                          "#ba9227",
                          "#bdbab9",
                          "cyan",
                          "navy",
                        ]}
                        height={240}
                        padding={50}
                        innerRadius={90}
                        data={[
                          {
                            x:
                              get_percent(
                                guestdata[2]["dadaocheng"],
                                guestdata[0]["members"]
                              ) + " %",
                            y: guestdata[2]["dadaocheng"],
                          },
                          {
                            x:
                              get_percent(
                                guestdata[2]["other_area"],
                                guestdata[0]["members"]
                              ) + " %",
                            y: guestdata[2]["other_area"],
                          },
                          {
                            x:
                              get_percent(
                                guestdata[2]["other_country"],
                                guestdata[0]["members"]
                              ) + " %",
                            y: guestdata[2]["other_country"],
                          },
                        ]}
                      />
                      <Grid container xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid
                          item
                          container
                          style={{ paddingRight: "20px", paddingLeft: "20px" }}
                          xl={4}
                          lg={4}
                          md={4}
                          sm={4}
                          xs={4}
                        >
                          {/*  { "other_area_female": 0, "other_area_male": 0, "other_country_female": 0, "other_country_male": 0, "dadaocheng_female": 0, "dadaocheng_male": 0, "male": 0, "female": 0, "male_set": [], "female_set": [] }, { 'all': 0, "dadaocheng": 0, "other_country": 0, "other_country_female": 0, "other_country_male": 0, "other_area_male": 0, "other_area_female": 0, "other_area": 0, "dadaocheng_male": 0, "dadaocheng_female": 0, "dadaocheng_female_set": [], "dadaocheng_male_set": [], "other_country_female_set": [], "other_country_male_set": [], "other_area_female_set": [], "other_area_male_set": [] }, { "old_set": [] }] */}
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Typography
                              variant="h5"
                              style={{ fontWeight: "bold", color: "#bdbab9" }}
                              display="inline"
                            >
                              {guestdata[2]["other_country"]}
                            </Typography>
                            <Typography
                              variant="h5"
                              display="inline"
                              style={{ fontWeight: "bold" }}
                            >
                              {" "}
                              {" 位來自其他國家當中"}
                            </Typography>
                          </Grid>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Typography>
                              {guestdata[2]["other_country_female"] +
                                " 人為女性 - 佔 " +
                                get_percent(
                                  guestdata[2]["other_country_female"],
                                  guestdata[2]["other_country"]
                                ) +
                                " %"}
                            </Typography>
                          </Grid>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Horizontal_line
                              data={guestdata[2][
                                "other_country_female_set"
                              ].map((item) => {
                                return { y: item };
                              })}
                              color={[
                                "#7bc037",
                                "#5a8220",
                                "#c03c25",
                                "#ba9229",
                                "#8f7228",
                                "#cb7424",
                                "#aa6732",
                                "#754314",
                                "#bdbab9",
                              ]}
                            />
                          </Grid>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Typography>
                              {guestdata[2]["other_country_male"] +
                                " 人為男性 - 佔 " +
                                get_percent(
                                  guestdata[2]["other_country_male"],
                                  guestdata[2]["other_country"]
                                ) +
                                " %"}
                            </Typography>
                          </Grid>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Horizontal_line
                              data={guestdata[2]["other_country_male_set"].map(
                                (item) => {
                                  return { y: item };
                                }
                              )}
                              color={[
                                "#7bc037",
                                "#5a8220",
                                "#c03c25",
                                "#ba9229",
                                "#8f7228",
                                "#cb7424",
                                "#aa6732",
                                "#754314",
                                "#bdbab9",
                              ]}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          container
                          style={{ paddingRight: "20px", paddingLeft: "20px" }}
                          xl={4}
                          lg={4}
                          md={4}
                          sm={4}
                          xs={4}
                        >
                          {/*  { "other_area_female": 0, "other_area_male": 0, "other_country_female": 0, "other_country_male": 0, "dadaocheng_female": 0, "dadaocheng_male": 0, "male": 0, "female": 0, "male_set": [], "female_set": [] }, { 'all': 0, "dadaocheng": 0, "other_country": 0, "other_country_female": 0, "other_country_male": 0, "other_area_male": 0, "other_area_female": 0, "other_area": 0, "dadaocheng_male": 0, "dadaocheng_female": 0, "dadaocheng_female_set": [], "dadaocheng_male_set": [], "other_country_female_set": [], "other_country_male_set": [], "other_area_female_set": [], "other_area_male_set": [] }, { "old_set": [] }] */}
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Typography
                              variant="h5"
                              style={{ fontWeight: "bold", color: "#ba9227" }}
                              display="inline"
                            >
                              {guestdata[2]["other_area"]}
                            </Typography>
                            <Typography
                              variant="h5"
                              display="inline"
                              style={{ fontWeight: "bold" }}
                            >
                              {" "}
                              {" 位來自其他地區當中"}
                            </Typography>
                          </Grid>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Typography>
                              {guestdata[2]["other_area_female"] +
                                " 人為女性 - 佔 " +
                                get_percent(
                                  guestdata[2]["other_area_female"],
                                  guestdata[2]["other_area"]
                                ) +
                                " %"}
                            </Typography>
                          </Grid>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Horizontal_line
                              data={guestdata[2]["other_area_female_set"].map(
                                (item) => {
                                  return { y: item };
                                }
                              )}
                              color={[
                                "#7bc037",
                                "#5a8220",
                                "#c03c25",
                                "#ba9229",
                                "#8f7228",
                                "#cb7424",
                                "#aa6732",
                                "#754314",
                                "#bdbab9",
                              ]}
                            />
                          </Grid>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Typography>
                              {guestdata[2]["other_area_male"] +
                                " 人為男性 - 佔 " +
                                get_percent(
                                  guestdata[2]["other_area_male"],
                                  guestdata[2]["other_area"]
                                ) +
                                " %"}
                            </Typography>
                          </Grid>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Horizontal_line
                              data={guestdata[2]["other_area_male_set"].map(
                                (item) => {
                                  return { y: item };
                                }
                              )}
                              color={[
                                "#7bc037",
                                "#5a8220",
                                "#c03c25",
                                "#ba9229",
                                "#8f7228",
                                "#cb7424",
                                "#aa6732",
                                "#754314",
                                "#bdbab9",
                              ]}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          container
                          style={{ paddingRight: "20px", paddingLeft: "20px" }}
                          xl={4}
                          lg={4}
                          md={4}
                          sm={4}
                          xs={4}
                        >
                          {/*  { "other_area_female": 0, "other_area_male": 0, "other_country_female": 0, "other_country_male": 0, "dadaocheng_female": 0, "dadaocheng_male": 0, "male": 0, "female": 0, "male_set": [], "female_set": [] }, { 'all': 0, "dadaocheng": 0, "other_country": 0, "other_country_female": 0, "other_country_male": 0, "other_area_male": 0, "other_area_female": 0, "other_area": 0, "dadaocheng_male": 0, "dadaocheng_female": 0, "dadaocheng_female_set": [], "dadaocheng_male_set": [], "other_country_female_set": [], "other_country_male_set": [], "other_area_female_set": [], "other_area_male_set": [] }, { "old_set": [] }] */}
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Typography
                              variant="h5"
                              style={{ fontWeight: "bold", color: "#c03c25" }}
                              display="inline"
                            >
                              {guestdata[2]["dadaocheng"]}
                            </Typography>
                            <Typography
                              variant="h5"
                              display="inline"
                              style={{ fontWeight: "bold" }}
                            >
                              {" "}
                              {" 位來自大稻埕當中"}
                            </Typography>
                          </Grid>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Typography>
                              {guestdata[2]["dadaocheng_female"] +
                                " 人為女性 - 佔 " +
                                get_percent(
                                  guestdata[2]["dadaocheng_female"],
                                  guestdata[2]["dadaocheng"]
                                ) +
                                " %"}
                            </Typography>
                          </Grid>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Horizontal_line
                              data={guestdata[2]["dadaocheng_female_set"].map(
                                (item) => {
                                  return { y: item };
                                }
                              )}
                              color={[
                                "#7bc037",
                                "#5a8220",
                                "#c03c25",
                                "#ba9229",
                                "#8f7228",
                                "#cb7424",
                                "#aa6732",
                                "#754314",
                                "#bdbab9",
                              ]}
                            />
                          </Grid>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Typography>
                              {guestdata[2]["dadaocheng_male"] +
                                " 人為男性 - 佔 " +
                                get_percent(
                                  guestdata[2]["dadaocheng_male"],
                                  guestdata[2]["dadaocheng"]
                                ) +
                                " %"}
                            </Typography>
                          </Grid>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Horizontal_line
                              data={guestdata[2]["dadaocheng_male_set"].map(
                                (item) => {
                                  return { y: item };
                                }
                              )}
                              color={[
                                "#7bc037",
                                "#5a8220",
                                "#c03c25",
                                "#ba9229",
                                "#8f7228",
                                "#cb7424",
                                "#aa6732",
                                "#754314",
                                "#bdbab9",
                              ]}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {guest_selsect === "sex" && (
                    <>
                      {" "}
                      <Grid container xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid
                          item
                          style={{ paddingLeft: "75px", paddingRight: "75px" }}
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                        >
                          <VictoryPie
                            colorScale={[
                              "#c03c25",
                              "#bdbab9",
                              "#bdbab9",
                              "cyan",
                              "navy",
                            ]}
                            height={240}
                            padding={50}
                            innerRadius={90}
                            data={[
                              {
                                x:
                                  get_percent(
                                    guestdata[1]["female"],
                                    guestdata[0]["members"]
                                  ) + " %",
                                y: guestdata[1]["female"],
                              },
                              {
                                x:
                                  get_percent(
                                    guestdata[1]["male"],
                                    guestdata[0]["members"]
                                  ) + " %",
                                y: guestdata[1]["male"],
                              },
                            ]}
                          />
                        </Grid>
                      </Grid>
                      <Grid container xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid
                          item
                          container
                          style={{ paddingRight: "40px", paddingLeft: "40px" }}
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xs={6}
                        >
                          {/*  { "other_area_female": 0, "other_area_male": 0, "other_country_female": 0, "other_country_male": 0, "dadaocheng_female": 0, "dadaocheng_male": 0, "male": 0, "female": 0, "male_set": [], "female_set": [] }, { 'all': 0, "dadaocheng": 0, "other_country": 0, "other_country_female": 0, "other_country_male": 0, "other_area_male": 0, "other_area_female": 0, "other_area": 0, "dadaocheng_male": 0, "dadaocheng_female": 0, "dadaocheng_female_set": [], "dadaocheng_male_set": [], "other_country_female_set": [], "other_country_male_set": [], "other_area_female_set": [], "other_area_male_set": [] }, { "old_set": [] }] */}
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Typography
                              variant="h5"
                              style={{ color: "#bdbab9", fontWeight: "bold" }}
                              display="inline"
                            >
                              {guestdata[1]["male"]}
                            </Typography>{" "}
                            <Typography
                              variant="h5"
                              style={{ fontWeight: "bold" }}
                              display="inline"
                            >
                              {" " + "位男性當中"}
                            </Typography>
                          </Grid>
                          <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                            <VictoryPie
                              colorScale={[
                                "#c03c25",
                                "#ba9227",
                                "#bdbab9",
                                "cyan",
                                "navy",
                              ]}
                              labels={() => null}
                              innerRadius={75}
                              data={[
                                { y: guestdata[1]["dadaocheng_male"] },
                                { y: guestdata[1]["other_area_male"] },
                                { y: guestdata[1]["other_country_male"] },
                              ]}
                            />
                          </Grid>
                          <Grid
                            container
                            item
                            xl={9}
                            lg={9}
                            md={9}
                            sm={9}
                            xs={9}
                          >
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              <Typography
                                style={{ color: "#c03c25", fontWeight: "bold" }}
                                display="inline"
                              >
                                {guestdata[1]["dadaocheng_male"]}
                              </Typography>
                              <Typography display="inline">
                                {" " + "位來自大稻埕"}
                              </Typography>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              <Typography
                                style={{ color: "#ba9227", fontWeight: "bold" }}
                                display="inline"
                              >
                                {guestdata[1]["other_area_male"]}
                              </Typography>
                              <Typography display="inline">
                                {" " + "位來自其他地區"}
                              </Typography>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              <Typography
                                style={{ color: "#bdbab9", fontWeight: "bold" }}
                                display="inline"
                              >
                                {guestdata[1]["other_country_male"]}
                              </Typography>
                              <Typography display="inline">
                                {" " + "位來自其他國家"}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Horizontal_line
                              color={[
                                "#7bc037",
                                "#5a8220",
                                "#c03c25",
                                "#ba9229",
                                "#8f7228",
                                "#cb7424",
                                "#aa6732",
                                "#754314",
                                "#bdbab9",
                              ]}
                              data={guestdata[1]["male_set"].map((item) => {
                                return { y: item };
                              })}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          container
                          style={{ paddingRight: "40px", paddingLeft: "40px" }}
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xs={6}
                        >
                          {/*  { "other_area_female": 0, "other_area_male": 0, "other_country_female": 0, "other_country_male": 0, "dadaocheng_female": 0, "dadaocheng_male": 0, "male": 0, "female": 0, "male_set": [], "female_set": [] }, { 'all': 0, "dadaocheng": 0, "other_country": 0, "other_country_female": 0, "other_country_male": 0, "other_area_male": 0, "other_area_female": 0, "other_area": 0, "dadaocheng_male": 0, "dadaocheng_female": 0, "dadaocheng_female_set": [], "dadaocheng_male_set": [], "other_country_female_set": [], "other_country_male_set": [], "other_area_female_set": [], "other_area_male_set": [] }, { "old_set": [] }] */}
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Typography
                              display="inline"
                              variant="h5"
                              style={{ fontWeight: "bold", color: "#c03c25" }}
                            >
                              {guestdata[1]["female"]}
                            </Typography>
                            <Typography variant="h5" display="inline">
                              {" " + "位女性當中"}
                            </Typography>
                          </Grid>
                          <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                            <VictoryPie
                              colorScale={[
                                "#c03c25",
                                "#bdbab9",
                                "#ba9227",
                                "cyan",
                                "navy",
                              ]}
                              labels={() => null}
                              innerRadius={75}
                              data={[
                                { y: guestdata[1]["dadaocheng_female"] },
                                { y: guestdata[1]["other_area_female"] },
                                { y: guestdata[1]["other_country_female"] },
                              ]}
                            />
                          </Grid>
                          <Grid
                            container
                            item
                            xl={9}
                            lg={9}
                            md={9}
                            sm={9}
                            xs={9}
                          >
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              <Typography
                                style={{ color: "#c03c25", fontWeight: "bold" }}
                                display="inline"
                              >
                                {guestdata[1]["dadaocheng_female"]}
                              </Typography>
                              <Typography display="inline">
                                {" " + "位來自大稻埕"}
                              </Typography>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              <Typography
                                style={{ color: "#ba9227", fontWeight: "bold" }}
                                display="inline"
                              >
                                {guestdata[1]["other_area_female"]}
                              </Typography>
                              <Typography display="inline">
                                {" " + "位來自其他地區"}
                              </Typography>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              <Typography
                                style={{ color: "#bdbab9", fontWeight: "bold" }}
                                display="inline"
                              >
                                {guestdata[1]["other_country_female"]}
                              </Typography>
                              <Typography display="inline">
                                {" " + "位來自其他國家"}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Horizontal_line
                              data={guestdata[1]["female_set"].map((item) => {
                                return { y: item };
                              })}
                              color={[
                                "#7bc037",
                                "#5a8220",
                                "#c03c25",
                                "#ba9229",
                                "#8f7228",
                                "#cb7424",
                                "#aa6732",
                                "#754314",
                                "#bdbab9",
                              ]}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div />
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {guest_selsect === "old" && (
                    <>
                      <VictoryPie
                        colorScale={[
                          "#7bc037",
                          "#5a8220",
                          "#c03c25",
                          "#ba9229",
                          "#8f7228",
                          "#cb7424",
                          "#aa6732",
                          "#754314",
                          "#bdbab9",
                        ]}
                        height={250}
                        innerRadius={90}
                        data={guestdata[3]["old_set"].map((item) => {
                          return {
                            x: get_percent(item, guestdata[0]["members"]),
                            y: item,
                          };
                        })}
                      />
                      <Grid container xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid
                          item
                          container
                          style={{ paddingRight: "20px", paddingLeft: "20px" }}
                          xl={4}
                          lg={4}
                          md={4}
                          sm={4}
                          xs={4}
                        >
                          {/*  { "other_area_female": 0, "other_area_male": 0, "other_country_female": 0, "other_country_male": 0, "dadaocheng_female": 0, "dadaocheng_male": 0, "male": 0, "female": 0, "male_set": [], "female_set": [] }, { 'all': 0, "dadaocheng": 0, "other_country": 0, "other_country_female": 0, "other_country_male": 0, "other_area_male": 0, "other_area_female": 0, "other_area": 0, "dadaocheng_male": 0, "dadaocheng_female": 0, "dadaocheng_female_set": [], "dadaocheng_male_set": [], "other_country_female_set": [], "other_country_male_set": [], "other_area_female_set": [], "other_area_male_set": [] }, { "old_set": [] }] */}
                          <Grid
                            item
                            style={{ display: "flex" }}
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                          >
                            <div style={{ flexGrow: 1 }} />
                            <Typography>{"性別比"}</Typography>
                            <div style={{ flexGrow: 1 }} />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          container
                          style={{ paddingRight: "20px", paddingLeft: "20px" }}
                          xl={4}
                          lg={4}
                          md={4}
                          sm={4}
                          xs={4}
                        ></Grid>
                        <Grid
                          item
                          container
                          style={{ paddingRight: "20px", paddingLeft: "20px" }}
                          xl={4}
                          lg={4}
                          md={4}
                          sm={4}
                          xs={4}
                        >
                          <Grid
                            item
                            style={{ display: "flex" }}
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                          >
                            <div style={{ flexGrow: 1 }} />
                            <Typography>{"身分別"}</Typography>
                            <div style={{ flexGrow: 1 }} />
                          </Grid>
                        </Grid>
                        {/*  { "other_area_female": 0, "other_area_male": 0, "other_country_female": 0, "other_country_male": 0, "dadaocheng_female": 0, "dadaocheng_male": 0, "male": 0, "female": 0, "male_set": [], "female_set": [] }, { 'all': 0, "dadaocheng": 0, "other_country": 0, "other_country_female": 0, "other_country_male": 0, "other_area_male": 0, "other_area_female": 0, "other_area": 0, "dadaocheng_male": 0, "dadaocheng_female": 0, "dadaocheng_female_set": [], "dadaocheng_male_set": [], "other_country_female_set": [], "other_country_male_set": [], "other_area_female_set": [], "other_area_male_set": [] }, { "old_set": [] }] */}
                        {/* 
                      <Typography  >{guestdata[3]["old_set"][0] + " 位 0~17　歲 " + "　　" + get_percent(guestdata[3]["old_set"][0], guestdata[0]["members"]) + " %"}</Typography>
                      <Typography  >{guestdata[3]["old_set"][1] + " 位 18~25 歲 " + "　　" + get_percent(guestdata[3]["old_set"][1], guestdata[0]["members"]) + " %"}</Typography>
                      <Typography  >{guestdata[3]["old_set"][2] + " 位 26~33 歲 " + "　　" + get_percent(guestdata[3]["old_set"][2], guestdata[0]["members"]) + " %"}</Typography>
                      <Typography  >{guestdata[3]["old_set"][3] + " 位 34~41 歲 " + "　　" + get_percent(guestdata[3]["old_set"][3], guestdata[0]["members"]) + " %"}</Typography>
                      <Typography  >{guestdata[3]["old_set"][4] + " 位 42~49 歲 " + "　　" + get_percent(guestdata[3]["old_set"][4], guestdata[0]["members"]) + " %"}</Typography>
                      <Typography  >{guestdata[3]["old_set"][5] + " 位 50~57 歲 " + "　　" + get_percent(guestdata[3]["old_set"][5], guestdata[0]["members"]) + " %"}</Typography>
                      <Typography  >{guestdata[3]["old_set"][6] + " 位 58~65 歲 " + "　　" + get_percent(guestdata[3]["old_set"][6], guestdata[0]["members"]) + " %"}</Typography>
                      <Typography  >{guestdata[3]["old_set"][7] + " 位 66~79 歲 " + "　　" + get_percent(guestdata[3]["old_set"][7], guestdata[0]["members"]) + " %"}</Typography>
                      <Typography  >{guestdata[3]["old_set"][8] + " 位 80+ 　歲 " + "　　" + get_percent(guestdata[3]["old_set"][8], guestdata[0]["members"]) + " %"}</Typography> */}
                        {guestdata[2]["dadaocheng_female_set"].map(
                          (item, index) => (
                            <Grid
                              item
                              container
                              alignItems="center"
                              justifyContent="center"
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                            >
                              <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                                <Horizontal_line
                                  data={[
                                    { y: guestdata[1]["male_set"][index] },
                                    { y: guestdata[1]["female_set"][index] },
                                  ]}
                                  color={["#b3b3b3", "#c03c25"]}
                                />
                              </Grid>
                              <Grid
                                style={{ display: "flex" }}
                                item
                                xl={4}
                                lg={4}
                                md={4}
                                sm={4}
                                xs={4}
                              >
                                <div style={{ flex: 1 }} />
                                <Typography
                                  display="inline"
                                  style={{ color: old_color[index] }}
                                >
                                  {guestdata[3]["old_set"][index]}{" "}
                                </Typography>
                                <Typography display="inline">
                                  {" "}
                                  {" " + old_text[index] + "　　"}
                                </Typography>
                                <div style={{ flex: 1 }} />
                              </Grid>
                              <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                                <Horizontal_line
                                  data={[
                                    {
                                      y:
                                        guestdata[2]["dadaocheng_female_set"][
                                          index
                                        ] +
                                        guestdata[2]["dadaocheng_male_set"][
                                          index
                                        ],
                                    },
                                    {
                                      y:
                                        guestdata[2]["other_area_female_set"][
                                          index
                                        ] +
                                        guestdata[2]["other_area_female_set"][
                                          index
                                        ],
                                    },
                                    {
                                      y:
                                        guestdata[2][
                                          "other_country_female_set"
                                        ][index] +
                                        guestdata[2][
                                          "other_country_female_set"
                                        ][index],
                                    },
                                  ]}
                                  color={["#c03c25", "#ba9227", "#b3b3b3"]}
                                />
                              </Grid>
                            </Grid>
                          )
                        )}
                      </Grid>
                    </>
                  )}
                </Paper>
              </Grid>
            </Grid>

            <Grid
              container
              item
              alignContent="flex-start"
              alignItems="flex-start"
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography
                  variant="h4"
                  style={{ fontWeight: "bold" }}
                  display="inline"
                >
                  數據排行榜 &nbsp;
                </Typography>

                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to={
                    "/Viewoverall/" +
                    string_format_date(start_date) +
                    "&" +
                    string_format_date(end_date)
                  }
                >
                  {" "}
                  <Typography
                    vatiant="subtitle2"
                    style={{ fontWeight: "bold" }}
                    display="inline"
                  >
                    View all
                  </Typography>
                </Link>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography variant="caption">
                  {"時間區段" + stringData()}
                </Typography>
              </Grid>
              <Grid
                item
                container
                style={{ paddingLeft: "8px", paddingRight: "8px" }}
                xl={3}
                lg={3}
                md={3}
                sm={3}
                xs={3}
              >
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="h5">{"搜尋關鍵字"}</Typography>
                </Grid>
                {ranks["keywords"].map((item) => (
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Paper
                      elevation={0}
                      style={{
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        display: "flex",
                        marginBottom: "6px",
                      }}
                    >
                      <Typography display="inline">{item.keyword}</Typography>
                      <div style={{ display: "inline", flex: "1" }} />
                      <Typography display="inline">
                        {item.times + " 次 "}
                      </Typography>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
              <Grid
                item
                container
                style={{ paddingLeft: "8px", paddingRight: "8px" }}
                xl={3}
                lg={3}
                md={3}
                sm={3}
                xs={3}
              >
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="h5">{"頁面瀏覽數"}</Typography>
                </Grid>
                {ranks["pages"].map((item) => (
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Paper
                      elevation={0}
                      style={{
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        display: "flex",
                        marginBottom: "6px",
                      }}
                    >
                      <Typography display="inline">
                        {item["loggable"].name}
                      </Typography>
                      <div style={{ display: "inline", flex: "1" }} />
                      <Typography display="inline">
                        {item.times + " 次 "}
                      </Typography>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
              <Grid
                item
                container
                style={{ paddingLeft: "8px", paddingRight: "8px" }}
                xl={3}
                lg={3}
                md={3}
                sm={3}
                xs={3}
              >
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="h5">{"頁面蒐藏數"}</Typography>
                </Grid>
                {ranks["favorite_articles"]
                  .filter((item) => {
                    return item.article != null;
                  })
                  .map((item) => (
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Paper
                        elevation={0}
                        style={{
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          paddingLeft: "8px",
                          paddingRight: "8px",
                          display: "flex",
                          marginBottom: "6px",
                        }}
                      >
                        <Typography display="inline">
                          {item["article"].name}
                        </Typography>
                        <div style={{ display: "inline", flex: "1" }} />
                        <Typography display="inline">
                          {item.times + " 次 "}
                        </Typography>
                      </Paper>
                    </Grid>
                  ))}
              </Grid>
              <Grid
                item
                container
                style={{ paddingLeft: "8px", paddingRight: "8px" }}
                xl={3}
                lg={3}
                md={3}
                sm={3}
                xs={3}
              >
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="h5">{"地圖蒐藏數"}</Typography>
                </Grid>
                {ranks["favorite_maps"]
                  .filter((item) => {
                    return item.article != null;
                  })
                  .map((item) => (
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Paper
                        elevation={0}
                        style={{
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          paddingLeft: "8px",
                          paddingRight: "8px",
                          display: "flex",
                          marginBottom: "6px",
                        }}
                      >
                        <Typography display="inline">
                          {item["article"].name}
                        </Typography>
                        <div style={{ display: "inline", flex: "1" }} />
                        <Typography display="inline">
                          {item.times + " 次 "}
                        </Typography>
                      </Paper>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default InsightsPage;
