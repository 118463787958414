import * as React from "react";
import {
  DataGrid,
  ColDef,
  ValueGetterParams,
  CellParams,
  GridApi,
} from "@material-ui/data-grid";
import Cookies from "js-cookie";
import { Button, Modal } from "@material-ui/core";
import {
  HashRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";
import CreateEvent from "./CreateEvent";

import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 0, 0),
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  modal: {
    overflow: "scroll",

    //display:'block'
  },
  fab1: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fab2: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(16),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  fab3: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(28),
  },
  title: {
    fontWeight: "bold",
    fontSize: "1.2rem",
    "@media (min-width:600px)": {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.4rem",
    },
  },
  newLink: {
    fontWeight: "bold",
    textDecoration: "none",
    color: "#000000",
  },
  media: {
    height: 140,
  },
  PopperIndex: {
    zIndex: theme.zIndex.modal + 1,
  },

  seeall: {
    textDecoration: "none",
    color: "#7f7f7f",
  },
}));
export default function DataTable(props) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [editid, setEditid] = React.useState(0);
  const columns = [
    { field: "id", headerName: "id", width: 100 },
    { field: "name", headerName: "event name", width: 160 },
    //{ field: "name", headerName: "edited by", width: 130 },
    { field: "created_at", headerName: "launched date", width: 170 },
    {
      field: "updated_at",
      headerName: "last edited",
      //type: "number",
      width: 170,
    },

    // {
    //   field: "views",
    //   headerName: "views",
    //   //type: "number",
    //   width: 150,
    // },
    // {
    //   field: "fullName",
    //   headerName: "Full name",
    //   description: "This column has a value getter and is not sortable.",
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.getValue("firstName") || ""} ${
    //       params.getValue("lastName") || ""
    //     }`,
    // },
    {
      field: "edit",
      headerName: "編輯",
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const thisRow = {};

        const api = params.api;
        const fields = api
          .getAllColumns()
          .map((c) => c.field)
          .filter((c) => c !== "__check__" && !!c);

        fields.forEach((f) => {
          thisRow[f] = params.getValue(f);
        });
        return (
          <Button
            //component={Link}
            //to={"/ManageInterface/EditDataPage/" + thisRow.id}
            onClick={() => {
              setEditid(thisRow.id);
              setOpen(true);
            }}
          >
            編輯
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "刪除",
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const thisRow = {};

        const api = params.api;
        const fields = api
          .getAllColumns()
          .map((c) => c.field)
          .filter((c) => c !== "__check__" && !!c);

        fields.forEach((f) => {
          thisRow[f] = params.getValue(f);
        });
        return (
          <Button
            onClick={() => {
              const access_token = Cookies.get("access_token");

              var check = window.confirm("確認要刪除嗎?");
              if (!check) {
                return;
              }
              var myHeaders = new Headers();
              myHeaders.append("Authorization", "Bearer " + access_token);
              myHeaders.append("Accept", "application/json");
              var uploadData = new FormData();

              //uploadData.append("is_visible", JSON.stringify(1));
              //uploadData.append("is_drafted", JSON.stringify(0));

              var requestOptions = {
                method: "DELETE",
                headers: myHeaders,
                body: uploadData,
                redirect: "follow",
              };

              fetch(
                "https://uisur.ad.ntust.edu.tw/backend/api/contests/events/" + thisRow.id,
                requestOptions
              )
                .then((response) => {
                  if (response.ok) {
                    alert("刪除成功");
                    const fetchData = async () => {
                      const thedata = await Promise.all([fetchTypeData()]);
                      console.log(thedata[0].tags);
                      window.location.reload();
                      setRows(thedata[0].tags);
                    };
                    fetchData();
                  } else {
                    alert("刪除失敗");
                  }
                  return response.text();
                })
                .then((result) => {
                  console.log(result);
                })
                .catch((error) => console.log("error", error));
            }}
          >
            刪除
          </Button>
        );
      },
    },
  ];
  const [rows, setRows] = React.useState([
    {
      id: 1,
      name: "history",
      thumbnail: null,
      is_themes: 1,
      operator_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
      created_at: null,
      end_at: null,
      start_at: null,
      updated_at: null,
      views: 0,
      rules: {
        id: 1,
        type_id: 1,
        need_name: 1,
        need_era: 1,
        need_summary: 1,
        need_url: 0,
        need_relation: 1,
        need_tag: 1,
        need_recommend: 0,
        need_visible: 0,
        need_content: 1,
      },
    },
  ]);
  React.useEffect(() => {
    const fetchData = async () => {
      var thedata = await Promise.all([fetchTypeData()]);
      console.log(thedata[0].tags);
      thedata[0].tags = thedata[0].tags.map((tag) => {
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        console.log(tag.created_at === null);
        const cd = new Date(tag.created_at === null ? null : tag.created_at);
        const ud = new Date(tag.updated_at === null ? null : tag.updated_at);

        tag.created_at =
          months[cd.getMonth()] + " " + cd.getDate() + " , " + cd.getFullYear();
        tag.updated_at =
          months[ud.getMonth()] + " " + ud.getDate() + " , " + ud.getFullYear();

        return tag;
      });

      console.log(props.typeID);
      setRows(thedata[0].tags);
    };
    fetchData();
  }, []);
  const fetchTypeData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/contests/events")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { tags: myJson };
        //setTagsData(temp);
      });
  };
  return (
    <div style={{ height: 650, width: "100%" }}>
      <DataGrid rows={rows} columns={columns} rowsPerPageOptions={[10, 20, 50]} pageSize={10} pagination />
      <Modal
        className={classes.modal}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <CreateEvent
          editMode={true}
          contest_id={props.id}
          id={editid}
          setModalopen={() => {
            setOpen(false);
          }}
        />
      </Modal>
    </div>
  );
}
