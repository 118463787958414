import React from "react";
import { BrowserRouter, Route, Link, HashRouter } from "react-router-dom";
import "./MapPage.css";
import TheMap from "./MapConponent/Map.js";
import SightCard from "./MapConponent/SightCard.js";
import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import FrontAppBar from "../FrontAppBar/FrontAppBar.js";
import { Divider, Hidden, Paper } from "@material-ui/core";
import PhoneSightCard from "./MapConponent/PhoneSightCard.js";
import Cookies, { set } from "js-cookie";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { Modal } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 0, 0),
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  cardbutton: {
    marginLeft: "auto",
  },
  firstaccordion: {
    margin: theme.spacing(1, 0, 0, 0),
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  gridFirst: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  thediv: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "60px",
      paddingRight: "60px",
    },
  },
  phoneGridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    boxShadow: 1,
    //height: "20vh",
    width: "100vw",
    //padding: theme.spacing(3)
  },
  MapPadding: {
    paddingLeft: "28",
    paddingRight: "28",
  },
  gridList: {
    //width: 500,
    boxShadow: 1,
    height: "80vh",
  },
  closefilterbutton: {
    width: "100%",
    color: "#ffffff",
    backgroundColor: "#424242",
    boxShadow: "none",
    textTransform: "none",
    marginBottom: "10px",
    "&:hover": {
      backgroundColor: "#000000",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
  showAllPlacebutton: {
    width: "100%",
    color: "#ffffff",
    backgroundColor: "#424242",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#000000",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
}));

//MapPage
const MapPage = (props) => {
  var [point, setPoint] = useState([
    {
      id: 46,
      note: false,
      type_id: 4,
      name: "正在載入",
      summary: "",
      title: "",
      thumbnail: null,
      is_visible: 1,
      is_drafted: 1,
      is_allowed: 1,
      views: 72,
      created_at: "2021-02-06T15:45:24.000000Z",
      updated_at: "2021-03-02T14:17:43.000000Z",
      creator_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
      latitude: null,
      longitude: null,
      creator: {
        uuid: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
        account: "admin",
        type: "admin",
        created_at: "2021-02-05T09:16:47.000000Z",
        updated_at: "2021-02-05T09:16:47.000000Z",
      },
    },
  ]);
  var [categories, setCategories] = useState([
    {
      name: "清朝時期",
      id: 0,
      check: false,
    },
    {
      name: "日治時期",
      id: 0,
      check: false,
    },
    {
      name: "民國時期",
      id: 0,
      check: false,
    },
    {
      name: "不明年代",
      id: 0,
      check: false,
    },
    {
      name: "產業",
      id: 0,
      check: false,
    },
    {
      name: "藝術文化",
      id: 0,
      check: false,
    },
    {
      name: "社會運動",
      id: 0,
      check: false,
    },
    {
      name: "政治",
      id: 0,
      check: false,
    },
    {
      name: "中藥",
      id: 0,
      check: false,
    },
    {
      name: "布",
      id: 0,
      check: false,
    },
    {
      name: "茶",
      id: 0,
      check: false,
    },
    {
      name: "米",
      id: 0,
      check: false,
    },
    {
      name: "糕餅",
      id: 0,
      check: false,
    },
    {
      name: "刺繡佛具",
      id: 0,
      check: false,
    },
    {
      name: "文化藝術",
      id: 0,
      check: false,
    },
    {
      name: "家器具",
      id: 0,
      check: false,
    },
    {
      name: "餐飲",
      id: 0,
      check: false,
    },
    {
      name: "歷史建築",
      id: 0,
      check: false,
    },
    {
      name: "古蹟",
      id: 0,
      check: false,
    },
    {
      name: "消失的建築",
      id: 0,
      check: false,
    },
    {
      name: "南北貨",
      id: 0,
      check: false,
    },
    {
      name: "街屋",
      id: 0,
      check: false,
    },
    {
      name: "洋樓",
      id: 0,
      check: false,
    },
    {
      name: "廟宇家祠",
      id: 0,
      check: false,
    },
    {
      name: "博物館",
      id: 0,
      check: false,
    },
    {
      name: "市場",
      id: 0,
      check: false,
    },
    {
      name: "捷運站",
      id: 0,
      check: false,
    },
    {
      name: "公車站",
      id: 0,
      check: false,
    },
    {
      name: "ubike站",
      id: 0,
      check: false,
    },
    {
      name: "街邊小吃",
      id: 0,
      check: false,
    },
    {
      name: "餐廳",
      id: 0,
      check: false,
    },
    {
      name: "咖啡店",
      id: 0,
      check: false,
    },
    {
      name: "酒吧",
      id: 0,
      check: false,
    },
    {
      name: "商店",
      id: 0,
      check: false,
    },
    {
      name: "公園",
      id: 0,
      check: false,
    },
    {
      name: "資訊站",
      id: 0,
      check: false,
    },
    {
      name: "廁所",
      id: 0,
      check: false,
    },
    {
      name: "事件據點",
      id: 0,
      check: "false",
    },
  ]);
  const [phoneModal, setPhoneModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [theAlert, setTheAlert] = useState({ text: "", severity: "error" });
  var [access_token, setAccess_token] = useState("");
  const fetchView_log = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/view_log", {
      method: "POST",

      headers: {
        Authorization: `Bearer ${props.access_token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        page: "map_page",
      }),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };
  useEffect(() => {
    access_token = Cookies.get("access_token");
    if (access_token === undefined) {
      access_token = "";
    }
    setAccess_token(access_token);
    const fetchData = async () => {
      var thedata = await Promise.all([
        fetchTagsData(),
        fetchCategoriesData(),
        fetchThemesData(),
        fetchArticlesData(),
        // fetchEditData(),
        fetchMyPin(),
        fetchView_log(),
      ]);
      for (var i = 0; i < thedata[1].categories.length; i++) {
        for (var j = 0; j < categories.length; j++) {
          if (thedata[1].categories[i].name === categories[j].name) {
            categories[j].id = thedata[1].categories[i].id;
            categories[j].check = false;
            break;
          }
        }
      }
      //setCategories(thedata[1].categories);
      console.log(thedata[4].articles, access_token, props.id);
      if (access_token !== "")
        thedata[3].articles.map((item) => {
          item.note = thedata[4].articles.some((it) => {
            return it.id === item.id;
          });
        });

      point = [];
      for (let i = 0; i < thedata[3].articles.length; i++) {
        if (thedata[3].articles[i].latitude !== null) {
          point = [thedata[3].articles[i], ...point];
          //setPoint([thedata[3].articles[i],...point]);
        }
      }
      setPoint([...point]);
      console.log(point);
    };
    fetchData();
  }, []);
  //all fetch function
  const fetchArticlesData = () => {
    var search = categories
      .filter((item) => {
        return item.check === true;
      })
      .map((item) => {
        return item.id;
      })
      .join();
    console.log(categories);
    return fetch(
      "https://uisur.ad.ntust.edu.tw/backend/api/articles?" +
      "categories=" +
      categories
        .filter((item) => {
          return item.check === true;
        })
        .map((item) => {
          return item.id;
        })
        .join(),
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };
  const fetchThemesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/types")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { types: myJson };
      });
  };
  const fetchTagsData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/tags")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { tags: myJson };
        //setTagsData(temp);
      });
  };
  const fetchMyPin = () => {
    return fetch(
      "https://uisur.ad.ntust.edu.tw/backend/api/favorites/articles",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };
  const fetchCategoriesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/categories")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { categories: myJson };
        //setTagsData(temp);
      });
  };
  const fetchEditData = () => {
    return fetch(
      "https://uisur.ad.ntust.edu.tw/backend/api/articles/" +
      props.id.toString()
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { editData: myJson };
        //setTagsData(temp);
      });
  };
  const search_article = () => {
    const fetchData = async () => {
      var thedata = await Promise.all([
        //fetchTagsData(),
        //fetchCategoriesData(),
        fetchArticlesData(),
        fetchMyPin(),
      ]);

      console.log(thedata[1].articles, access_token, props.id);
      if (access_token !== "")
        thedata[0].articles.map((item) => {
          item.note = thedata[1].articles.some((it) => {
            return it.id === item.id;
          });
        });

      point = [];
      for (let i = 0; i < thedata[0].articles.length; i++) {
        if (thedata[0].articles[i].latitude !== null) {
          point = [thedata[0].articles[i], ...point];
          //setPoint([thedata[3].articles[i],...point]);
        }
      }
      setPoint([...point]);
      console.log(point);
    };
    fetchData();
  };
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  var i = 0;
  var j = 0;
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <FrontAppBar />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={theAlert.severity}
        >
          {theAlert.text}
        </MuiAlert>
      </Snackbar>
      {/* for phone */}
      <Modal
        className={classes.modal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={phoneModal}
        onClose={() => {
          setPhoneModal(false);
        }}
      >
        <Paper style={{ paddingLeft: "10%", background: "none" }}>
          <Grid item md={10} sm={10} xs={10} lg={10}>
            <Button
              onClick={() => {
                setPhoneModal(false);
              }}
              className={classes.closefilterbutton}
            >
              close filter
            </Button>
            <Button
              onClick={() => {
                categories.map((item) => {
                  item.check = false;
                });
                setCategories([...categories]);
                search_article();
              }}
              className={classes.showAllPlacebutton}
            >
              showing all places
            </Button>
            <Accordion
              className={classes.firstaccordion}
              backgroundColor=""
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography /*className={classes.heading}*/>時間軸</Typography>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/

                        checked={
                          categories.find((item) => {
                            return item.name === "民國時期";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        name="民國時期"
                        color="primary"
                      />
                    }
                    label="民國時期"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        //checked={state.checkedB}
                        //onChange={handleChange}
                        checked={
                          categories.find((item) => {
                            return item.name === "日治時期";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                        }}
                        name="日治時期"
                        color="primary"
                      />
                    }
                    label="日治時期"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="清朝時期"
                        checked={
                          categories.find((item) => {
                            return item.name === "清朝時期";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="清朝時期"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="不明年代"
                        checked={
                          categories.find((item) => {
                            return item.name === "不明年代";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="不明年代"
                  />
                </FormGroup>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography /*className={classes.heading}*/>
                  人物相關
                </Typography>
                <div style={{ flexGrow: "1" }} />
                <img
                  onClick={(e) => {
                    const temp_name = ["產業", "藝術文化", "社會運動", "政治"];
                    for (let i = 0; i < temp_name.length; i++) {
                      categories.find((item) => {
                        return item.name === temp_name[i];
                      }).check = !categories.find((item) => {
                        return item.name === temp_name[i];
                      }).check;
                    }
                    setCategories([...categories]);
                    search_article();
                    e.stopPropagation();
                  }}
                  src={require("../../assets/markerIcon/2.png")}
                  width="30px"
                />
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="產業"
                        checked={
                          categories.find((item) => {
                            return item.name === "產業";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="產業"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="藝術文化"
                        checked={
                          categories.find((item) => {
                            return item.name === "藝術文化";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="藝術文化"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="社會運動"
                        checked={
                          categories.find((item) => {
                            return item.name === "社會運動";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="社會運動"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="政治"
                        checked={
                          categories.find((item) => {
                            return item.name === "政治";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="政治"
                  />
                </FormGroup>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel10"}
              onChange={handleChange("panel10")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography /*className={classes.heading}*/>
                  生活產業
                </Typography>
                <div style={{ flexGrow: "1" }} />
                <img
                  onClick={(e) => {
                    const temp_name = [
                      "南北貨",
                      "中藥",
                      "布",
                      "茶",
                      "米",
                      "糕餅",
                      "刺繡佛具",
                      "文化藝術",
                      "家器具",
                      "餐飲",
                    ];

                    for (let i = 0; i < temp_name.length; i++) {
                      categories.find((item) => {
                        return item.name === temp_name[i];
                      }).check = !categories.find((item) => {
                        return item.name === temp_name[i];
                      }).check;
                    }
                    setCategories([...categories]);
                    search_article();
                    e.stopPropagation();
                  }}
                  src={require("../../assets/markerIcon/3.png")}
                  width="30px"
                />
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="南北貨"
                        checked={
                          categories.find((item) => {
                            return item.name === "南北貨";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="南北貨"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="中藥"
                        checked={
                          categories.find((item) => {
                            return item.name === "中藥";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="中藥"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="布"
                        checked={
                          categories.find((item) => {
                            return item.name === "布";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="布"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="茶"
                        checked={
                          categories.find((item) => {
                            return item.name === "茶";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="茶"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="米"
                        checked={
                          categories.find((item) => {
                            return item.name === "米";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="米"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="糕餅"
                        checked={
                          categories.find((item) => {
                            return item.name === "糕餅";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="糕餅"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="刺繡佛具"
                        checked={
                          categories.find((item) => {
                            return item.name === "刺繡佛具";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="刺繡佛具"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="文化藝術"
                        checked={
                          categories.find((item) => {
                            return item.name === "文化藝術";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="文化藝術"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="家器具"
                        checked={
                          categories.find((item) => {
                            return item.name === "家器具";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="家器具"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="餐飲"
                        checked={
                          categories.find((item) => {
                            return item.name === "餐飲";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="餐飲"
                  />
                </FormGroup>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography /*className={classes.heading}*/>
                  建築類型
                </Typography>
                <div style={{ flexGrow: "1" }} />
                <img
                  onClick={(e) => {
                    const temp_name = [
                      "歷史建築",
                      "古蹟",
                      "消失的建築",
                      "街屋",
                      "洋樓",
                      "廟宇家祠",
                      "博物館",
                      "市場",
                    ];
                    for (let i = 0; i < temp_name.length; i++) {
                      categories.find((item) => {
                        return item.name === temp_name[i];
                      }).check = !categories.find((item) => {
                        return item.name === temp_name[i];
                      }).check;
                    }
                    setCategories([...categories]);
                    search_article();
                    e.stopPropagation();
                  }}
                  src={require("../../assets/markerIcon/4.png")}
                  width="30px"
                />
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="歷史建築"
                        checked={
                          categories.find((item) => {
                            return item.name === "歷史建築";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="歷史建築"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="古蹟"
                        checked={
                          categories.find((item) => {
                            return item.name === "古蹟";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="古蹟"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="消失的建築"
                        checked={
                          categories.find((item) => {
                            return item.name === "消失的建築";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="消失的建築"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="街屋"
                        checked={
                          categories.find((item) => {
                            return item.name === "街屋";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="街屋"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="洋樓"
                        checked={
                          categories.find((item) => {
                            return item.name === "洋樓";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="洋樓"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="廟宇家祠"
                        checked={
                          categories.find((item) => {
                            return item.name === "廟宇家祠";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="廟宇家祠"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="博物館"
                        checked={
                          categories.find((item) => {
                            return item.name === "博物館";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="博物館"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="市場"
                        checked={
                          categories.find((item) => {
                            return item.name === "市場";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="市場"
                  />
                </FormGroup>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography /*className={classes.heading}*/>
                  交通資訊
                </Typography>
                <div style={{ flexGrow: "1" }} />
                <img
                  onClick={(e) => {
                    const temp_name = ["捷運站", "公車站", "ubike站"];
                    for (let i = 0; i < temp_name.length; i++) {
                      categories.find((item) => {
                        return item.name === temp_name[i];
                      }).check = !categories.find((item) => {
                        return item.name === temp_name[i];
                      }).check;
                    }
                    setCategories([...categories]);
                    search_article();
                    e.stopPropagation();
                  }}
                  src={require("../../assets/markerIcon/19.png")}
                  width="30px"
                />
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="捷運站"
                        checked={
                          categories.find((item) => {
                            return item.name === "捷運站";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="捷運站"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="公車站"
                        checked={
                          categories.find((item) => {
                            return item.name === "公車站";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="公車站"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="ubike站"
                        checked={
                          categories.find((item) => {
                            return item.name === "ubike站";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="ubike站"
                  />
                </FormGroup>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography /*className={classes.heading}*/>
                  在地美食
                </Typography>
                <div style={{ flexGrow: "1" }} />
                <img
                  onClick={(e) => {
                    const temp_name = ["街邊小吃", "餐廳", "咖啡店", "酒吧"];
                    for (let i = 0; i < temp_name.length; i++) {
                      categories.find((item) => {
                        return item.name === temp_name[i];
                      }).check = !categories.find((item) => {
                        return item.name === temp_name[i];
                      }).check;
                    }
                    setCategories([...categories]);
                    search_article();
                    e.stopPropagation();
                  }}
                  src={require("../../assets/markerIcon/26.png")}
                  width="30px"
                />
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="街邊小吃"
                        checked={
                          categories.find((item) => {
                            return item.name === "街邊小吃";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="街邊小吃"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="餐廳"
                        checked={
                          categories.find((item) => {
                            return item.name === "餐廳";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="餐廳"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="咖啡店"
                        checked={
                          categories.find((item) => {
                            return item.name === "咖啡店";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="咖啡店"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="酒吧"
                        checked={
                          categories.find((item) => {
                            return item.name === "酒吧";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="酒吧"
                  />
                </FormGroup>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography /*className={classes.heading}*/>
                  推薦景點
                </Typography>
                <div style={{ flexGrow: "1" }} />
                <img
                  onClick={(e) => {
                    const temp_name = ["商店", "公園", "事件據點"];
                    for (let i = 0; i < temp_name.length; i++) {
                      categories.find((item) => {
                        return item.name === temp_name[i];
                      }).check = !categories.find((item) => {
                        return item.name === temp_name[i];
                      }).check;
                    }
                    setCategories([...categories]);
                    search_article();
                    e.stopPropagation();
                  }}
                  src={require("../../assets/markerIcon/112.png")}
                  width="30px"
                />
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="商店"
                        checked={
                          categories.find((item) => {
                            return item.name === "商店";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="商店"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="公園"
                        checked={
                          categories.find((item) => {
                            return item.name === "公園";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="公園"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="事件據點"
                        checked={
                          categories.find((item) => {
                            return item.name === "事件據點";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="事件據點"
                  />
                </FormGroup>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography /*className={classes.heading}*/>
                  服務設施
                </Typography>
                <div style={{ flexGrow: "1" }} />
                <img
                  onClick={(e) => {
                    const temp_name = ["資訊站", "廁所"];
                    for (let i = 0; i < temp_name.length; i++) {
                      categories.find((item) => {
                        return item.name === temp_name[i];
                      }).check = !categories.find((item) => {
                        return item.name === temp_name[i];
                      }).check;
                    }
                    setCategories([...categories]);
                    search_article();
                    e.stopPropagation();
                  }}
                  src={require("../../assets/markerIcon/113.png")}
                  width="30px"
                />
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="資訊站"
                        checked={
                          categories.find((item) => {
                            return item.name === "資訊站";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="資訊站"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        /*checked={state.checkedA} onChange={handleChange}*/ name="廁所"
                        checked={
                          categories.find((item) => {
                            return item.name === "廁所";
                          }).check
                        }
                        onChange={(e) => {
                          categories.find((item) => {
                            return item.name === e.target.name;
                          }).check = !categories.find((item) => {
                            return item.name === e.target.name;
                          }).check;
                          setCategories([...categories]);
                          search_article();
                        }}
                        color="primary"
                      />
                    }
                    label="廁所"
                  />
                </FormGroup>
              </AccordionDetails>
            </Accordion>
            {/* <Typography
              style={{
                fontWeight: "900",
                paddingBottom: "8px",
                paddingTop: "10px",
              }}
            >
              有私房景點要分享嗎？
            </Typography> */}

            <Button
              style={{ background: "black", marginTop: "10px" }}
              onClick={() => {
                window.location = "https://forms.gle/SKkfwVSZuMCq4J1V8";
              }}
              className={classes.showAllPlacebutton}
            >
              新地點推薦
            </Button>
          </Grid>
        </Paper>
      </Modal>
      <div className={classes.thediv}>
        <Grid container spacing={1} className={classes.root}>
          <Hidden smDown>
            <Grid item xs={false} sm={false} md={2} lg={2}>
              <Button
                onClick={() => {
                  categories.map((item) => {
                    item.check = false;
                  });
                  setCategories([...categories]);
                  search_article();
                }}
                className={classes.showAllPlacebutton}
              >
                showing all places
              </Button>
              <Accordion
                className={classes.firstaccordion}
                backgroundColor=""
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography /*className={classes.heading}*/>
                    時間軸
                  </Typography>
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/

                          checked={
                            categories.find((item) => {
                              return item.name === "民國時期";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          name="民國時期"
                          color="primary"
                        />
                      }
                      label="民國時期"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          //checked={state.checkedB}
                          //onChange={handleChange}
                          checked={
                            categories.find((item) => {
                              return item.name === "日治時期";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                          }}
                          name="日治時期"
                          color="primary"
                        />
                      }
                      label="日治時期"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="清朝時期"
                          checked={
                            categories.find((item) => {
                              return item.name === "清朝時期";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="清朝時期"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="不明年代"
                          checked={
                            categories.find((item) => {
                              return item.name === "不明年代";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="不明年代"
                    />
                  </FormGroup>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography /*className={classes.heading}*/>
                    人物相關
                  </Typography>
                  <div style={{ flexGrow: "1" }} />
                  <img
                    onClick={(e) => {
                      const temp_name = [
                        "產業",
                        "藝術文化",
                        "社會運動",
                        "政治",
                      ];
                      for (let i = 0; i < temp_name.length; i++) {
                        categories.find((item) => {
                          return item.name === temp_name[i];
                        }).check = !categories.find((item) => {
                          return item.name === temp_name[i];
                        }).check;
                      }
                      setCategories([...categories]);
                      search_article();
                      e.stopPropagation();
                    }}
                    src={require("../../assets/markerIcon/2.png")}
                    width="30px"
                  />
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="產業"
                          checked={
                            categories.find((item) => {
                              return item.name === "產業";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="產業"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="藝術文化"
                          checked={
                            categories.find((item) => {
                              return item.name === "藝術文化";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="藝術文化"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="社會運動"
                          checked={
                            categories.find((item) => {
                              return item.name === "社會運動";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="社會運動"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="政治"
                          checked={
                            categories.find((item) => {
                              return item.name === "政治";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="政治"
                    />
                  </FormGroup>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel10"}
                onChange={handleChange("panel10")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography /*className={classes.heading}*/>
                    生活產業
                  </Typography>
                  <div style={{ flexGrow: "1" }} />
                  <img
                    onClick={(e) => {
                      const temp_name = [
                        "南北貨",
                        "中藥",
                        "布",
                        "茶",
                        "米",
                        "糕餅",
                        "刺繡佛具",
                        "文化藝術",
                        "家器具",
                        "餐飲",
                      ];

                      for (let i = 0; i < temp_name.length; i++) {
                        categories.find((item) => {
                          return item.name === temp_name[i];
                        }).check = !categories.find((item) => {
                          return item.name === temp_name[i];
                        }).check;
                      }
                      setCategories([...categories]);
                      search_article();
                      e.stopPropagation();
                    }}
                    src={require("../../assets/markerIcon/3.png")}
                    width="30px"
                  />
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="南北貨"
                          checked={
                            categories.find((item) => {
                              return item.name === "南北貨";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="南北貨"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="中藥"
                          checked={
                            categories.find((item) => {
                              return item.name === "中藥";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="中藥"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="布"
                          checked={
                            categories.find((item) => {
                              return item.name === "布";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="布"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="茶"
                          checked={
                            categories.find((item) => {
                              return item.name === "茶";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="茶"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="米"
                          checked={
                            categories.find((item) => {
                              return item.name === "米";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="米"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="糕餅"
                          checked={
                            categories.find((item) => {
                              return item.name === "糕餅";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="糕餅"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="刺繡佛具"
                          checked={
                            categories.find((item) => {
                              return item.name === "刺繡佛具";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="刺繡佛具"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="文化藝術"
                          checked={
                            categories.find((item) => {
                              return item.name === "文化藝術";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="文化藝術"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="家器具"
                          checked={
                            categories.find((item) => {
                              return item.name === "家器具";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="家器具"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="餐飲"
                          checked={
                            categories.find((item) => {
                              return item.name === "餐飲";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="餐飲"
                    />
                  </FormGroup>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography /*className={classes.heading}*/>
                    建築類型
                  </Typography>
                  <div style={{ flexGrow: "1" }} />
                  <img
                    onClick={(e) => {
                      const temp_name = [
                        "歷史建築",
                        "古蹟",
                        "消失的建築",
                        "街屋",
                        "洋樓",
                        "廟宇家祠",
                        "博物館",
                        "市場",
                      ];
                      for (let i = 0; i < temp_name.length; i++) {
                        categories.find((item) => {
                          return item.name === temp_name[i];
                        }).check = !categories.find((item) => {
                          return item.name === temp_name[i];
                        }).check;
                      }
                      setCategories([...categories]);
                      search_article();
                      e.stopPropagation();
                    }}
                    src={require("../../assets/markerIcon/4.png")}
                    width="30px"
                  />
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="歷史建築"
                          checked={
                            categories.find((item) => {
                              return item.name === "歷史建築";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="歷史建築"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="古蹟"
                          checked={
                            categories.find((item) => {
                              return item.name === "古蹟";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="古蹟"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="消失的建築"
                          checked={
                            categories.find((item) => {
                              return item.name === "消失的建築";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="消失的建築"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="街屋"
                          checked={
                            categories.find((item) => {
                              return item.name === "街屋";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="街屋"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="洋樓"
                          checked={
                            categories.find((item) => {
                              return item.name === "洋樓";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="洋樓"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="廟宇家祠"
                          checked={
                            categories.find((item) => {
                              return item.name === "廟宇家祠";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="廟宇家祠"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="博物館"
                          checked={
                            categories.find((item) => {
                              return item.name === "博物館";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="博物館"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="市場"
                          checked={
                            categories.find((item) => {
                              return item.name === "市場";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="市場"
                    />
                  </FormGroup>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography /*className={classes.heading}*/>
                    交通資訊
                  </Typography>
                  <div style={{ flexGrow: "1" }} />
                  <img
                    onClick={(e) => {
                      const temp_name = ["捷運站", "公車站", "ubike站"];
                      for (let i = 0; i < temp_name.length; i++) {
                        categories.find((item) => {
                          return item.name === temp_name[i];
                        }).check = !categories.find((item) => {
                          return item.name === temp_name[i];
                        }).check;
                      }
                      setCategories([...categories]);
                      search_article();
                      e.stopPropagation();
                    }}
                    src={require("../../assets/markerIcon/19.png")}
                    width="30px"
                  />
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="捷運站"
                          checked={
                            categories.find((item) => {
                              return item.name === "捷運站";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="捷運站"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="公車站"
                          checked={
                            categories.find((item) => {
                              return item.name === "公車站";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="公車站"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="ubike站"
                          checked={
                            categories.find((item) => {
                              return item.name === "ubike站";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="ubike站"
                    />
                  </FormGroup>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography /*className={classes.heading}*/>
                    在地美食
                  </Typography>
                  <div style={{ flexGrow: "1" }} />
                  <img
                    onClick={(e) => {
                      const temp_name = ["街邊小吃", "餐廳", "咖啡店", "酒吧"];
                      for (let i = 0; i < temp_name.length; i++) {
                        categories.find((item) => {
                          return item.name === temp_name[i];
                        }).check = !categories.find((item) => {
                          return item.name === temp_name[i];
                        }).check;
                      }
                      setCategories([...categories]);
                      search_article();
                      e.stopPropagation();
                    }}
                    src={require("../../assets/markerIcon/26.png")}
                    width="30px"
                  />
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="街邊小吃"
                          checked={
                            categories.find((item) => {
                              return item.name === "街邊小吃";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="街邊小吃"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="餐廳"
                          checked={
                            categories.find((item) => {
                              return item.name === "餐廳";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="餐廳"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="咖啡店"
                          checked={
                            categories.find((item) => {
                              return item.name === "咖啡店";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="咖啡店"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="酒吧"
                          checked={
                            categories.find((item) => {
                              return item.name === "酒吧";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="酒吧"
                    />
                  </FormGroup>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel6"}
                onChange={handleChange("panel6")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography /*className={classes.heading}*/>
                    推薦景點
                  </Typography>
                  <div style={{ flexGrow: "1" }} />
                  <img
                    onClick={(e) => {
                      const temp_name = ["商店", "公園", "事件據點"];
                      for (let i = 0; i < temp_name.length; i++) {
                        categories.find((item) => {
                          return item.name === temp_name[i];
                        }).check = !categories.find((item) => {
                          return item.name === temp_name[i];
                        }).check;
                      }
                      setCategories([...categories]);
                      search_article();
                      e.stopPropagation();
                    }}
                    src={require("../../assets/markerIcon/112.png")}
                    width="30px"
                  />
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="商店"
                          checked={
                            categories.find((item) => {
                              return item.name === "商店";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="商店"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="公園"
                          checked={
                            categories.find((item) => {
                              return item.name === "公園";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="公園"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="事件據點"
                          checked={
                            categories.find((item) => {
                              return item.name === "事件據點";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="事件據點"
                    />
                  </FormGroup>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel7"}
                onChange={handleChange("panel7")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography /*className={classes.heading}*/>
                    服務設施
                  </Typography>
                  <div style={{ flexGrow: "1" }} />
                  <img
                    onClick={(e) => {
                      const temp_name = ["資訊站", "廁所"];
                      for (let i = 0; i < temp_name.length; i++) {
                        categories.find((item) => {
                          return item.name === temp_name[i];
                        }).check = !categories.find((item) => {
                          return item.name === temp_name[i];
                        }).check;
                      }
                      setCategories([...categories]);
                      search_article();
                      e.stopPropagation();
                    }}
                    src={require("../../assets/markerIcon/113.png")}
                    width="30px"
                  />
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="資訊站"
                          checked={
                            categories.find((item) => {
                              return item.name === "資訊站";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="資訊站"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          /*checked={state.checkedA} onChange={handleChange}*/ name="廁所"
                          checked={
                            categories.find((item) => {
                              return item.name === "廁所";
                            }).check
                          }
                          onChange={(e) => {
                            categories.find((item) => {
                              return item.name === e.target.name;
                            }).check = !categories.find((item) => {
                              return item.name === e.target.name;
                            }).check;
                            setCategories([...categories]);
                            search_article();
                          }}
                          color="primary"
                        />
                      }
                      label="廁所"
                    />
                  </FormGroup>
                </AccordionDetails>
              </Accordion>
              <Typography
                style={{
                  fontWeight: "900",
                  paddingBottom: "8px",
                  paddingTop: "10px",
                }}
              >
                有私房景點要分享嗎？
              </Typography>

              <Button
                style={{ background: "black" }}
                onClick={() => {
                  window.location = "https://forms.gle/SKkfwVSZuMCq4J1V8";
                }}
                className={classes.showAllPlacebutton}
              >
                新地點推薦
              </Button>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <TheMap
                point={point}
                setModal={(bool) => {
                  setPhoneModal(bool);
                }}
              />
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
              style={{ paddingLeft: 28, paddingRight: 28 }}
            >
              <TheMap
                point={point}
                setModal={(bool) => {
                  setPhoneModal(bool);
                }}
              />
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <GridList cellHeight={225} className={classes.gridList} cols={1}>
                {point.map((item) => (
                  <>
                    <SightCard
                      conponent="div"
                      point={item}
                      handlePointClick={() => {
                        if (access_token === "") {
                          setTheAlert({
                            text: "登入以啟用收藏功能",
                            severity: "info",
                          });
                          setOpen(true);
                        } else if (!item.note) {
                          //var status_Number;
                          const respond = fetch(
                            "https://uisur.ad.ntust.edu.tw/backend/api/favorites/articles",
                            {
                              method: "POST",
                              headers: {
                                Authorization: `Bearer ${access_token}`,
                                "Content-Type": "application/json",
                              },
                              body: JSON.stringify({
                                article_id: item.id,
                              }),
                            }
                          )
                            .then((res) => {
                              console.log(res.ok);
                              if (res.ok) {
                                setTheAlert({
                                  text: "收藏成功",
                                  severity: "success",
                                });
                                setOpen(true);
                                item.note = true;
                                setPoint([...point]);
                                //fetchData();
                                //setTagEditModal(false);
                              } else {
                                setTheAlert({
                                  text: "收藏失敗",
                                  severity: "error",
                                });
                                setOpen(true);
                              }
                              return res.json();
                            })
                            .then((result) => {
                              //console.log(result);
                            });
                        } else if (item.note) {
                          {
                            //var status_Number;
                            const respond = fetch(
                              "https://uisur.ad.ntust.edu.tw/backend/api/favorites/articles/" +
                              item.id.toString(),
                              {
                                method: "DELETE",
                                headers: {
                                  Authorization: `Bearer ${access_token}`,
                                  "Content-Type": "application/json",
                                },
                              }
                            )
                              .then((res) => {
                                console.log(res.ok);
                                if (res.ok) {
                                  setTheAlert({
                                    text: "刪除收藏成功",
                                    severity: "success",
                                  });
                                  setOpen(true);
                                  item.note = false;
                                  setPoint([...point]);
                                  //fetchData();
                                  //setTagEditModal(false);
                                } else {
                                  setTheAlert({
                                    text: "刪除收藏失敗",
                                    severity: "error",
                                  });
                                  setOpen(true);
                                }
                                return res.json();
                              })
                              .then((result) => {
                                //console.log(result);
                              });
                          }
                        }
                      }}
                      key={i.toString()}
                    />
                    <div id={"data" + (i++).toString()} />
                  </>
                ))}
              </GridList>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <GridList className={classes.phoneGridList} cols={1}>
                {point.map((item) => (
                  <>
                    <PhoneSightCard
                      conponent="div"
                      point={item}
                      handlePointClick={() => {
                        if (access_token === "") {
                          setTheAlert({
                            text: "登入以啟用收藏功能",
                            severity: "info",
                          });
                          setOpen(true);
                        } else if (!item.note) {
                          //var status_Number;
                          const respond = fetch(
                            "https://uisur.ad.ntust.edu.tw/backend/api/favorites/articles",
                            {
                              method: "POST",
                              headers: {
                                Authorization: `Bearer ${access_token}`,
                                "Content-Type": "application/json",
                              },
                              body: JSON.stringify({
                                article_id: item.id,
                              }),
                            }
                          )
                            .then((res) => {
                              console.log(res.ok);
                              if (res.ok) {
                                setTheAlert({
                                  text: "收藏成功",
                                  severity: "success",
                                });
                                setOpen(true);
                                item.note = true;
                                setPoint([...point]);
                                //fetchData();
                                //setTagEditModal(false);
                              } else {
                                setTheAlert({
                                  text: "收藏失敗",
                                  severity: "error",
                                });
                                setOpen(true);
                              }
                              return res.json();
                            })
                            .then((result) => {
                              //console.log(result);
                            });
                        } else if (item.note) {
                          {
                            //var status_Number;
                            const respond = fetch(
                              "https://uisur.ad.ntust.edu.tw/backend/api/favorites/articles/" +
                              item.id.toString(),
                              {
                                method: "DELETE",
                                headers: {
                                  Authorization: `Bearer ${access_token}`,
                                  "Content-Type": "application/json",
                                },
                              }
                            )
                              .then((res) => {
                                console.log(res.ok);
                                if (res.ok) {
                                  setTheAlert({
                                    text: "刪除收藏成功",
                                    severity: "success",
                                  });
                                  setOpen(true);
                                  item.note = false;
                                  setPoint([...point]);
                                  //fetchData();
                                  //setTagEditModal(false);
                                } else {
                                  setTheAlert({
                                    text: "刪除收藏失敗",
                                    severity: "error",
                                  });
                                  setOpen(true);
                                }
                                return res.json();
                              })
                              .then((result) => {
                                //console.log(result);
                              });
                          }
                        }
                      }}
                      key={(j++).toString()}
                    />
                    <div id={"data" + j.toString()} />
                  </>
                ))}
              </GridList>
            </Grid>
          </Hidden>
        </Grid>
      </div>
    </>
  );
};

export default MapPage;
