import React from "react";
import { BrowserRouter, Route, Link, HashRouter } from "react-router-dom";
import Cookies, { set } from "js-cookie";
import {
  createMuiTheme,
  withStyles,
  responsiveFontSizes,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import FrontAppBar from "../FrontAppBar/FrontAppBar.js";
import { Card, CardMedia, Divider, Hidden } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import RoomIcon from "@material-ui/icons/Room";
import Map from "./MapPdfComponent/Map.js";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 0, 0),
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  title: {
    fontWeight: "950",
    fontSize: "1.2rem",
    "@media (min-width:600px)": {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.8rem",
    },
  },
  newLink: {
    fontWeight: "bold",
    textDecoration: "none",
    color: "#000000",
  },
  media: {
    height: 140,
  },
  seeall: {
    textDecoration: "none",
    color: "#7f7f7f",
  },
  themeImg: {
    width: "100%",
    display: "block",
    aspectRatio: "1/1",
  },

  themeImghover: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    opacity: "0",

    "&:hover": {
      backgroundColor: "#ff7348a8",
      transition: ".5s ease",
      opacity: "1",
      //borderColor: '#0062cc',
    },
  },
  themeImghovertext: {
    color: "black",
    fontSize: "30px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
}));

// let theme = createMuiTheme();
// theme = responsiveFontSizes(theme);
//MapPage
const MapPdf = (props) => {
  var [refrash, SetRefrash] = useState(false);

  const [word, setWord] = useState("made by haha");
  const [size, setSize] = useState(400);
  const [bgColor, setBgColor] = useState("ffffff");
  const [qrCode, setQrCode] = useState("");

  // Changing the URL only when the user
  // changes the input
  useEffect(() => {
    setQrCode(
      `http://api.qrserver.com/v1/create-qr-code/?data=${word.replace(
        "#",
        "%23"
      )}&size=${size}x${size}&bgcolor=${bgColor}`
    );
    console.log(word);
  }, [word, size, bgColor]);

  const [data, setData] = React.useState({
    id: 2,
    name: "name",
    summary: "content here",
    places: [
      {
        id: 4,
        name: "azzza1sssawzza",
        thumbnail: "thumbnails/7YucFolItbn72SevdIQLZHf1h7wy6jwoioOl4YoL.jpeg",
        latitude: 12.123111,
        longitude: 25.255,
      },
      {
        id: 8,
        name: "TEST23",
        thumbnail: null,
        latitude: 12.123111,
        longitude: 25.255,
      },
      {
        id: 9,
        name: "TEST231",
        thumbnail: null,
        latitude: 12.123111,
        longitude: 25.255,
      },
    ],
    views: 0,
    creator_id: "42652e3b-b746-47c4-a3b7-a9962c0c6eac",
    is_public: 1,
    created_at: "2021-02-05T16:10:54.000000Z",
    updated_at: "2021-02-05T16:10:54.000000Z",
    creator: {
      uuid: "42652e3b-b746-47c4-a3b7-a9962c0c6eac",
      account: "admin",
      type: "user",
      created_at: "2021-02-05T16:10:54.000000Z",
      updated_at: "2021-02-05T16:10:54.000000Z",
    },
    tags: [
      {
        id: 1,
        name: "a",
      },
    ],
  });
  const Day_convert = (d) => {
    const a = new Date(d);
    const month_names_short = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return (
      month_names_short[a.getMonth()] +
      " " +
      a.getDate() +
      ", " +
      a.getFullYear()
    );
  };
  const now_day_convert = () => {
    var todayDate = new Date().toISOString().slice(0, 10);
    return todayDate.replace("-", ".").replace("-", ".");
  };
  var [point, setPoint] = useState([
    {
      id: 46,
      note: false,
      type_id: 4,
      name: "dsadsd",
      summary: "dsds",
      title: "dsd",
      thumbnail: null,
      is_visible: 1,
      is_drafted: 1,
      is_allowed: 1,
      views: 72,
      created_at: "2021-02-06T15:45:24.000000Z",
      updated_at: "2021-03-02T14:17:43.000000Z",
      creator_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
      latitude: null,
      longitude: null,
      creator: {
        uuid: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
        account: "admin",
        type: "admin",
        created_at: "2021-02-05T09:16:47.000000Z",
        updated_at: "2021-02-05T09:16:47.000000Z",
      },
    },
    {
      id: 46,
      note: false,
      type_id: 4,
      name: "dsadsd",
      summary: "dsds",
      title: "dsd",
      thumbnail: null,
      is_visible: 1,
      is_drafted: 1,
      is_allowed: 1,
      views: 72,
      created_at: "2021-02-06T15:45:24.000000Z",
      updated_at: "2021-03-02T14:17:43.000000Z",
      creator_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
      latitude: null,
      longitude: null,
      creator: {
        uuid: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
        account: "admin",
        type: "admin",
        created_at: "2021-02-05T09:16:47.000000Z",
        updated_at: "2021-02-05T09:16:47.000000Z",
      },
    },
    {
      id: 46,
      note: false,
      type_id: 4,
      name: "dsadsd",
      summary: "dsds",
      title: "dsd",
      thumbnail: null,
      is_visible: 1,
      is_drafted: 1,
      is_allowed: 1,
      views: 72,
      created_at: "2021-02-06T15:45:24.000000Z",
      updated_at: "2021-03-02T14:17:43.000000Z",
      creator_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
      latitude: null,
      longitude: null,
      creator: {
        uuid: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
        account: "admin",
        type: "admin",
        created_at: "2021-02-05T09:16:47.000000Z",
        updated_at: "2021-02-05T09:16:47.000000Z",
      },
    },
    {
      id: 46,
      note: false,
      type_id: 4,
      name: "dsadsd",
      summary: "dsds",
      title: "dsd",
      thumbnail: null,
      is_visible: 1,
      is_drafted: 1,
      is_allowed: 1,
      views: 72,
      created_at: "2021-02-06T15:45:24.000000Z",
      updated_at: "2021-03-02T14:17:43.000000Z",
      creator_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
      latitude: null,
      longitude: null,
      creator: {
        uuid: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
        account: "admin",
        type: "admin",
        created_at: "2021-02-05T09:16:47.000000Z",
        updated_at: "2021-02-05T09:16:47.000000Z",
      },
    },
    {
      id: 46,
      note: false,
      type_id: 4,
      name: "dsadsd",
      summary: "dsds",
      title: "dsd",
      thumbnail: null,
      is_visible: 1,
      is_drafted: 1,
      is_allowed: 1,
      views: 72,
      created_at: "2021-02-06T15:45:24.000000Z",
      updated_at: "2021-03-02T14:17:43.000000Z",
      creator_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
      latitude: null,
      longitude: null,
      creator: {
        uuid: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
        account: "admin",
        type: "admin",
        created_at: "2021-02-05T09:16:47.000000Z",
        updated_at: "2021-02-05T09:16:47.000000Z",
      },
    },
    {
      id: 46,
      note: false,
      type_id: 4,
      name: "dsadsd",
      summary: "dsds",
      title: "dsd",
      thumbnail: null,
      is_visible: 1,
      is_drafted: 1,
      is_allowed: 1,
      views: 72,
      created_at: "2021-02-06T15:45:24.000000Z",
      updated_at: "2021-03-02T14:17:43.000000Z",
      creator_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
      latitude: null,
      longitude: null,
      creator: {
        uuid: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
        account: "admin",
        type: "admin",
        created_at: "2021-02-05T09:16:47.000000Z",
        updated_at: "2021-02-05T09:16:47.000000Z",
      },
    },
    {
      id: 46,
      note: false,
      type_id: 4,
      name: "dsadsd",
      summary: "dsds",
      title: "dsd",
      thumbnail: null,
      is_visible: 1,
      is_drafted: 1,
      is_allowed: 1,
      views: 72,
      created_at: "2021-02-06T15:45:24.000000Z",
      updated_at: "2021-03-02T14:17:43.000000Z",
      creator_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
      latitude: null,
      longitude: null,
      creator: {
        uuid: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
        account: "admin",
        type: "admin",
        created_at: "2021-02-05T09:16:47.000000Z",
        updated_at: "2021-02-05T09:16:47.000000Z",
      },
    },
    {
      id: 46,
      note: false,
      type_id: 4,
      name: "dsadsd",
      summary: "dsds",
      title: "dsd",
      thumbnail: null,
      is_visible: 1,
      is_drafted: 1,
      is_allowed: 1,
      views: 72,
      created_at: "2021-02-06T15:45:24.000000Z",
      updated_at: "2021-03-02T14:17:43.000000Z",
      creator_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
      latitude: null,
      longitude: null,
      creator: {
        uuid: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
        account: "admin",
        type: "admin",
        created_at: "2021-02-05T09:16:47.000000Z",
        updated_at: "2021-02-05T09:16:47.000000Z",
      },
    },
    {
      id: 46,
      note: false,
      type_id: 4,
      name: "dsadsd",
      summary: "dsds",
      title: "dsd",
      thumbnail: null,
      is_visible: 1,
      is_drafted: 1,
      is_allowed: 1,
      views: 72,
      created_at: "2021-02-06T15:45:24.000000Z",
      updated_at: "2021-03-02T14:17:43.000000Z",
      creator_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
      latitude: null,
      longitude: null,
      creator: {
        uuid: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
        account: "admin",
        type: "admin",
        created_at: "2021-02-05T09:16:47.000000Z",
        updated_at: "2021-02-05T09:16:47.000000Z",
      },
    },
    {
      id: 46,
      note: false,
      type_id: 4,
      name: "dsadsd",
      summary: "dsds",
      title: "dsd",
      thumbnail: null,
      is_visible: 1,
      is_drafted: 1,
      is_allowed: 1,
      views: 72,
      created_at: "2021-02-06T15:45:24.000000Z",
      updated_at: "2021-03-02T14:17:43.000000Z",
      creator_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
      latitude: null,
      longitude: null,
      creator: {
        uuid: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
        account: "admin",
        type: "admin",
        created_at: "2021-02-05T09:16:47.000000Z",
        updated_at: "2021-02-05T09:16:47.000000Z",
      },
    },
    {
      id: 46,
      note: false,
      type_id: 4,
      name: "dsadsd",
      summary: "dsds",
      title: "dsd",
      thumbnail: null,
      is_visible: 1,
      is_drafted: 1,
      is_allowed: 1,
      views: 72,
      created_at: "2021-02-06T15:45:24.000000Z",
      updated_at: "2021-03-02T14:17:43.000000Z",
      creator_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
      latitude: null,
      longitude: null,
      creator: {
        uuid: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
        account: "admin",
        type: "admin",
        created_at: "2021-02-05T09:16:47.000000Z",
        updated_at: "2021-02-05T09:16:47.000000Z",
      },
    },
    {
      id: 46,
      note: false,
      type_id: 4,
      name: "dsadsd",
      summary: "dsds",
      title: "dsd",
      thumbnail: null,
      is_visible: 1,
      is_drafted: 1,
      is_allowed: 1,
      views: 72,
      created_at: "2021-02-06T15:45:24.000000Z",
      updated_at: "2021-03-02T14:17:43.000000Z",
      creator_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
      latitude: null,
      longitude: null,
      creator: {
        uuid: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
        account: "admin",
        type: "admin",
        created_at: "2021-02-05T09:16:47.000000Z",
        updated_at: "2021-02-05T09:16:47.000000Z",
      },
    },
    {
      id: 46,
      note: false,
      type_id: 4,
      name: "dsadsd",
      summary: "dsds",
      title: "dsd",
      thumbnail: null,
      is_visible: 1,
      is_drafted: 1,
      is_allowed: 1,
      views: 72,
      created_at: "2021-02-06T15:45:24.000000Z",
      updated_at: "2021-03-02T14:17:43.000000Z",
      creator_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
      latitude: null,
      longitude: null,
      creator: {
        uuid: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
        account: "admin",
        type: "admin",
        created_at: "2021-02-05T09:16:47.000000Z",
        updated_at: "2021-02-05T09:16:47.000000Z",
      },
    },
    {
      id: 46,
      note: false,
      type_id: 4,
      name: "dsadsd",
      summary: "dsds",
      title: "dsd",
      thumbnail: null,
      is_visible: 1,
      is_drafted: 1,
      is_allowed: 1,
      views: 72,
      created_at: "2021-02-06T15:45:24.000000Z",
      updated_at: "2021-03-02T14:17:43.000000Z",
      creator_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
      latitude: null,
      longitude: null,
      creator: {
        uuid: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
        account: "admin",
        type: "admin",
        created_at: "2021-02-05T09:16:47.000000Z",
        updated_at: "2021-02-05T09:16:47.000000Z",
      },
    },
    {
      id: 46,
      note: false,
      type_id: 4,
      name: "dsadsd",
      summary: "dsds",
      title: "dsd",
      thumbnail: null,
      is_visible: 1,
      is_drafted: 1,
      is_allowed: 1,
      views: 72,
      created_at: "2021-02-06T15:45:24.000000Z",
      updated_at: "2021-03-02T14:17:43.000000Z",
      creator_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
      latitude: null,
      longitude: null,
      creator: {
        uuid: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
        account: "admin",
        type: "admin",
        created_at: "2021-02-05T09:16:47.000000Z",
        updated_at: "2021-02-05T09:16:47.000000Z",
      },
    },
    {
      id: 46,
      note: false,
      type_id: 4,
      name: "dsadsd",
      summary: "dsds",
      title: "dsd",
      thumbnail: null,
      is_visible: 1,
      is_drafted: 1,
      is_allowed: 1,
      views: 72,
      created_at: "2021-02-06T15:45:24.000000Z",
      updated_at: "2021-03-02T14:17:43.000000Z",
      creator_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
      latitude: null,
      longitude: null,
      creator: {
        uuid: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
        account: "admin",
        type: "admin",
        created_at: "2021-02-05T09:16:47.000000Z",
        updated_at: "2021-02-05T09:16:47.000000Z",
      },
    },
    {
      id: 46,
      note: false,
      type_id: 4,
      name: "dsadsd",
      summary: "dsds",
      title: "dsd",
      thumbnail: null,
      is_visible: 1,
      is_drafted: 1,
      is_allowed: 1,
      views: 72,
      created_at: "2021-02-06T15:45:24.000000Z",
      updated_at: "2021-03-02T14:17:43.000000Z",
      creator_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
      latitude: null,
      longitude: null,
      creator: {
        uuid: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
        account: "admin",
        type: "admin",
        created_at: "2021-02-05T09:16:47.000000Z",
        updated_at: "2021-02-05T09:16:47.000000Z",
      },
    },
    {
      id: 46,
      note: false,
      type_id: 4,
      name: "dsadsd",
      summary: "dsds",
      title: "dsd",
      thumbnail: null,
      is_visible: 1,
      is_drafted: 1,
      is_allowed: 1,
      views: 72,
      created_at: "2021-02-06T15:45:24.000000Z",
      updated_at: "2021-03-02T14:17:43.000000Z",
      creator_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
      latitude: null,
      longitude: null,
      creator: {
        uuid: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
        account: "admin",
        type: "admin",
        created_at: "2021-02-05T09:16:47.000000Z",
        updated_at: "2021-02-05T09:16:47.000000Z",
      },
    },
  ]);

  var [access_token, setAccess_token] = useState("");
  useEffect(() => {
    if (refrash) {
      window.location.reload();
    } else {
      SetRefrash(true);
    }
    access_token = Cookies.get("access_token");
    if (access_token === undefined) {
      access_token = "";
    }
    setAccess_token(access_token);
    const fetchData = async () => {
      var thedata = await Promise.all([fetchViewData()]);

      //console.log(thedata[4].articles, access_token, props.id);
      // if (access_token !== "")
      //   thedata[3].articles.map((item) => {
      //     item.note = thedata[4].articles.some((it) => {
      //       return it.id === item.id;
      //     });
      //   });

      setData(thedata[0].viewData);
      setPoint(thedata[0].viewData.places);
      setWord("https://uisur.ad.ntust.edu.tw/#/Mapbox/" + props.id.toString());
    };
    fetchData();
  }, [props.id]);
  const fetchViewData = () => {
    return fetch(
      "https://uisur.ad.ntust.edu.tw/backend/api/maps/" + props.id.toString(),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { viewData: myJson };
        //setTagsData(temp);
      });
  };
  return (
    <>
      {point.length > 30 ? (
        <>
          <div
            style={{
              position: "relative",
              width: "29.7cm",
              height: "20.68cm",
              padding: "0.5cm 0.5cm 0.5cm 0.5cm",
            }}
          >
            <Grid
              alignItems="flex-start"
              container
              xl={12}
              md={12}
              lg={12}
              sm={12}
              xs={12}
            >
              <Grid item xl={1} md={1} lg={1} sm={1} xs={1}>
                <img
                  style={{
                    display: "inline-block",
                    width: "3cm",
                    height: "3cm",
                  }}
                  src={qrCode}
                  alt=""
                />
              </Grid>
              <Grid
                style={{ paddingLeft: "1cm" }}
                item
                xl={10}
                md={10}
                lg={10}
                sm={10}
                xs={10}
              >
                <Typography style={{ fontSize: "26px" }}>
                  {data.name}
                </Typography>
                <Typography>
                  {point.length.toString() + " places"}
                  <RoomIcon />
                </Typography>
                <Typography display="inline">{"published by "}</Typography>
                <Typography style={{ fontWeight: "900" }} display="inline">
                  {data.creator.name != null
                    ? data.creator.name
                    : data.creator.account}
                </Typography>
                <div />
                <Typography display="inline">{"On "}</Typography>

                <Typography style={{ fontWeight: "900" }} display="inline">
                  {Day_convert(data.created_at)}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              alignItems="flex-start"
              container
              xl={12}
              md={12}
              lg={12}
              sm={12}
              xs={12}
            >
              <Grid container item xl={3} md={3} lg={3} sm={3} xs={3}>
                {point.slice(0, 15).map((item, index) => (
                  <Grid
                    item
                    style={{ padding: "0.25cm 0.25cm 0.25cm 0.25cm" }}
                    xl={12}
                    md={12}
                    lg={12}
                    sm={12}
                    xs={12}
                  >
                    <div
                      style={{
                        lineHeight: "0.5cm",
                        width: "0.5cm",
                        height: "0.5cm",
                        display: " inline-block",
                        textAlign: "center",
                        color: "#fff",
                        background: "#000",
                        borderRadius: "50%",
                      }}
                    >
                      {(index + 1).toString()}
                    </div>
                    <Typography display="inline" style={{ fontWeight: "900" }}>
                      {" " + item.name}
                    </Typography>{" "}
                  </Grid>
                ))}
              </Grid>
              <Grid container item xl={3} md={3} lg={3} sm={3} xs={3}>
                {point.length > 15 &&
                  point.slice(16, 30).map((item, index) => (
                    <Grid
                      item
                      style={{ padding: "0.25cm 0.25cm 0.25cm 0.25cm" }}
                      xl={12}
                      md={12}
                      lg={12}
                      sm={12}
                      xs={12}
                    >
                      <div
                        style={{
                          lineHeight: "0.5cm",
                          width: "0.5cm",
                          height: "0.5cm",
                          display: " inline-block",
                          textAlign: "center",
                          color: "#fff",
                          background: "#000",
                          borderRadius: "50%",
                        }}
                      >
                        {(index + 16).toString()}
                      </div>
                      <Typography
                        display="inline"
                        style={{ fontWeight: "900" }}
                      >
                        {" " + item.name}
                      </Typography>{" "}
                    </Grid>
                  ))}
              </Grid>
              <Grid container item xl={3} md={3} lg={3} sm={3} xs={3}>
                {point.length > 30 &&
                  point.slice(31, 45).map((item, index) => (
                    <Grid
                      item
                      style={{ padding: "0.25cm 0.25cm 0.25cm 0.25cm" }}
                      xl={12}
                      md={12}
                      lg={12}
                      sm={12}
                      xs={12}
                    >
                      <div
                        style={{
                          lineHeight: "0.5cm",
                          width: "0.5cm",
                          height: "0.5cm",
                          display: " inline-block",
                          textAlign: "center",
                          color: "#fff",
                          background: "#000",
                          borderRadius: "50%",
                        }}
                      >
                        {(index + 16).toString()}
                      </div>
                      <Typography
                        display="inline"
                        style={{ fontWeight: "900" }}
                      >
                        {" " + item.name}
                      </Typography>{" "}
                    </Grid>
                  ))}
              </Grid>
              <Grid container item xl={3} md={3} lg={3} sm={3} xs={3}>
                {point.length > 45 &&
                  point.slice(46, 60).map((item, index) => (
                    <Grid
                      item
                      style={{ padding: "0.25cm 0.25cm 0.25cm 0.25cm" }}
                      xl={12}
                      md={12}
                      lg={12}
                      sm={12}
                      xs={12}
                    >
                      <div
                        style={{
                          lineHeight: "0.5cm",
                          width: "0.5cm",
                          height: "0.5cm",
                          display: " inline-block",
                          textAlign: "center",
                          color: "#fff",
                          background: "#000",
                          borderRadius: "50%",
                        }}
                      >
                        {(index + 16).toString()}
                      </div>
                      <Typography
                        display="inline"
                        style={{ fontWeight: "900" }}
                      >
                        {" " + item.name}
                      </Typography>{" "}
                    </Grid>
                  ))}
              </Grid>
            </Grid>
            <Typography
              style={{
                position: "absolute",
                bottom: "0.3cm",
                right: "1cm",
                color: "#221815",
                fontSize: "10px",
                paddingTop: "0.3cm",
              }}
            >
              {"created by Dadaocheng Information System. Date: " +
                now_day_convert() +
                " | " +
                "page 1/2"}
            </Typography>
          </div>
          <div
            style={{
              width: "29.7cm",
              height: "20.67cm",
              padding: "0.5cm 0.5cm 0.5cm 0.5cm",
              position: "relative",
            }}
          >
            <Grid
              style={{ paddingBottom: "0.3cm" }}
              alignItems="flex-start"
              container
              xl={12}
              md={12}
              lg={12}
              sm={12}
              xs={12}
            >
              <Grid item xl={1} md={1} lg={1} sm={1} xs={1}>
                <img
                  style={{
                    display: "inline-block",
                    width: "3cm",
                    height: "3cm",
                  }}
                  src={qrCode}
                  alt=""
                />
              </Grid>
              <Grid
                style={{ paddingLeft: "1cm" }}
                item
                xl={10}
                md={10}
                lg={10}
                sm={10}
                xs={10}
              >
                <Typography style={{ fontSize: "26px" }}>
                  {data.name}
                </Typography>
                <Typography>
                  {point.length.toString() + " places"}
                  <RoomIcon />
                </Typography>
                <Typography display="inline">{"published by "}</Typography>
                <Typography style={{ fontWeight: "900" }} display="inline">
                  {data.creator.name != null
                    ? data.creator.name
                    : data.creator.account}
                </Typography>
                <div />
                <Typography display="inline">{"On "}</Typography>

                <Typography style={{ fontWeight: "900" }} display="inline">
                  {Day_convert(data.created_at)}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              alignItems="flex-start"
              container
              xl={12}
              md={12}
              lg={12}
              sm={12}
              xs={12}
            >
              <Grid container item xl={6} md={6} lg={6} sm={6} xs={6}>
                <Map point={point}></Map>
              </Grid>
              <Grid
                container
                style={{ width: "17cm", height: "17cm", paddingLeft: "1cm" }}
                item
                xl={6}
                md={6}
                lg={6}
                sm={6}
                xs={6}
              >
                <img src={require("./MapPdfComponent/note_img.png")} />
              </Grid>
            </Grid>
            <Typography
              style={{
                position: "absolute",
                bottom: "0.3cm",
                right: "1cm",
                color: "#221815",
                fontSize: "10px",
                paddingTop: "0.3cm",
              }}
            >
              {"created by Dadaocheng Information System. Date: " +
                now_day_convert() +
                " | " +
                "page 2/2"}
            </Typography>
          </div>
        </>
      ) : (
        <div
          style={{
            position: "relative",
            width: "29.7cm",
            height: "20.68cm",
            padding: "0.5cm 0.5cm 0.5cm 0.5cm",
          }}
        >
          <Grid
            alignItems="flex-start"
            container
            xl={12}
            md={12}
            lg={12}
            sm={12}
            xs={12}
          >
            <Grid item xl={1} md={1} lg={1} sm={1} xs={1}>
              <img
                style={{ display: "inline-block", width: "3cm", height: "3cm" }}
                src={qrCode}
                alt=""
              />
            </Grid>
            <Grid
              style={{ paddingLeft: "1cm" }}
              item
              xl={10}
              md={10}
              lg={10}
              sm={10}
              xs={10}
            >
              <Typography style={{ fontSize: "26px" }}>{data.name}</Typography>
              <Typography>
                {point.length.toString() + " places"}
                <RoomIcon />
              </Typography>
              <Typography display="inline">{"published by "}</Typography>
              <Typography style={{ fontWeight: "900" }} display="inline">
                {data.creator.name != null
                  ? data.creator.name
                  : data.creator.account}
              </Typography>
              <div />
              <Typography display="inline">{"On "}</Typography>

              <Typography style={{ fontWeight: "900" }} display="inline">
                {Day_convert(data.created_at)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            alignItems="flex-start"
            container
            xl={12}
            md={12}
            lg={12}
            sm={12}
            xs={12}
          >
            <Grid container item xl={3} md={3} lg={3} sm={3} xs={3}>
              {point.slice(0, 15).map((item, index) => (
                <Grid
                  item
                  style={{ padding: "0.25cm 0.25cm 0.25cm 0.25cm" }}
                  xl={12}
                  md={12}
                  lg={12}
                  sm={12}
                  xs={12}
                >
                  <div
                    style={{
                      lineHeight: "0.5cm",
                      width: "0.5cm",
                      height: "0.5cm",
                      display: " inline-block",
                      textAlign: "center",
                      color: "#fff",
                      background: "#000",
                      borderRadius: "50%",
                    }}
                  >
                    {(index + 1).toString()}
                  </div>
                  <Typography display="inline" style={{ fontWeight: "900" }}>
                    {" " + item.name}
                  </Typography>{" "}
                </Grid>
              ))}
            </Grid>
            <Grid container item xl={3} md={3} lg={3} sm={3} xs={3}>
              {point.length > 15 &&
                point.slice(16, 30).map((item, index) => (
                  <Grid
                    item
                    style={{ padding: "0.25cm 0.25cm 0.25cm 0.25cm" }}
                    xl={12}
                    md={12}
                    lg={12}
                    sm={12}
                    xs={12}
                  >
                    <div
                      style={{
                        lineHeight: "0.5cm",
                        width: "0.5cm",
                        height: "0.5cm",
                        display: " inline-block",
                        textAlign: "center",
                        color: "#fff",
                        background: "#000",
                        borderRadius: "50%",
                      }}
                    >
                      {(index + 16).toString()}
                    </div>
                    <Typography display="inline" style={{ fontWeight: "900" }}>
                      {" " + item.name}
                    </Typography>{" "}
                  </Grid>
                ))}
            </Grid>
            <Grid container item xl={6} md={6} lg={6} sm={6} xs={6}>
              <Map point={point}></Map>
            </Grid>
          </Grid>
          <Typography
            style={{
              position: "absolute",
              bottom: "0.3cm",
              right: "1cm",
              color: "#221815",
              fontSize: "10px",
              paddingTop: "0.3cm",
            }}
          >
            {"created by Dadaocheng Information System. Date: " +
              now_day_convert()}
          </Typography>
        </div>
      )}
    </>
  );
};

export default MapPdf;
