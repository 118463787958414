import React from "react";
import { BrowserRouter, Route, Link, HashRouter } from "react-router-dom";

import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { DropzoneArea } from "material-ui-dropzone";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import LeftAppBar from "../LeftAppBar/LeftAppBar.js";
import ManageAppBar from "../ManageAppBar/ManageAppBar.js";
import { Card, CardMedia, Divider, Hidden } from "@material-ui/core";
import HtmlEditor from "./HtmlEditor/HtmlEditor.js";
import Paper from "@material-ui/core/Paper";
import { set } from "js-cookie";
import { grey } from "@material-ui/core/colors";
import { object } from "underscore";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 0, 0),
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  threeTitle: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    wordBreak: "keep-all",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  title: {
    marginLeft: theme.spacing(2),

    marginRight: theme.spacing(3),
    wordBreak: "keep-all",
  },
  textEditorGrid: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(3),
    border: "1px black solid",
  },
  newLink: {
    fontWeight: "bold",
    textDecoration: "none",
    color: "#000000",
  },
  media: {
    height: 140,
  },
  buttomMargin: {
    marginBottom: theme.spacing(1),
  },
  seeall: {
    textDecoration: "none",
    color: "#7f7f7f",
  },
  previewButton: {
    color: "#C69C6D",
    borderColor: "#C69C6D",
    marginRight: theme.spacing(1),
    //margin: theme.spacing(0, 2),
    "&:hover": {
      backgroundColor: "#ffffff",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
  marginTheTop: {
    marginTop: theme.spacing(2),
  },
  saveButton: {
    background: "#C69C6D",
    color: "#ffffff",
    borderColor: "#C69C6D",
    marginRight: theme.spacing(1),
    //margin: theme.spacing(0, 2),
    "&:hover": {
      backgroundColor: "#C69C6D",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
  chooseButton: {
    background: "#4D4D4D",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    color: "#ffffff",
    borderColor: "#4D4D4D",
    "&:hover": {
      backgroundColor: "#4D4D4D",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: "#005cbf",
    },
  },
  publishButton: {
    background: "#4D4D4D",
    marginRight: theme.spacing(2),

    color: "#ffffff",
    borderColor: "#4D4D4D",
    "&:hover": {
      backgroundColor: "#4D4D4D",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: "#005cbf",
    },
  },
  disabled: {
    background: grey,
    color: "#333333",
  },
  LongText: {
    whiteSpace: "nowrap",
    marginLeft: theme.spacing(2),
  },
  rightflex: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  publishpopper: {
    zIndex: theme.zIndex.modal + 1,
  },
}));

//MapPage
const DataPage = (props) => {
  //All Data
  var [data, setData] = useState({
    map_filter_type: 1,
    name: "", //文章名稱
    summary: "", // 摘要
    content: "", //內容
    type: "", //型態
    title: "", // 自訂網址
    is_visible: false, //boolean 是否顯示於地圖
    is_drafted: true, // boolean 是否為草稿
    available_at: "", // 什麼時候公開（不填就是直接公開）
    url: "", // 網站
    relations: "", //跟之前一樣填有關的文章的id
    start_year: "",
    end_year: "", //年代
    longitude: "",
    latitude: "", // 經緯度
    weight: "", // 權重
    thumbnail: [], // 縮圖
    categories: "", // 類別id
    tags: [], // tags name
    recommends: [], //推薦頁的id
    image: [], //其他圖片
  });
  const [htmlCode, setHtmlcode] = useState("");
  var [tags, setTags] = useState([]);
  var [categories, setCategories] = useState([
    { id: 2, name: "23", is_visible: 2, parent_id: 1 },
  ]);
  var [recommendPage, setRecommendPage] = useState([]);
  //varsetDerivativeRelationship
  var [relationship, setRelationship] = useState([]);
  var [inputTagText, setInputTagText] = useState("");
  var [types, setTypes] = useState([
    {
      id: 1,
      name: "history",
      thubnail: null,
      is_themes: 1,
      operator_id: "",
      created_at: null,
      updated_at: null,
    },
  ]);
  var [inputRule, setInputRule] = useState({
    id: 1,
    type_id: 1,
    need_name: 1,
    need_era: 1,
    need_summary: 1,
    need_url: 0,
    need_relation: 1,
    need_tag: 1,
    need_recommend: 0,
    need_visible: 0,
    need_content: 1,
  });
  var [articles, setArticles] = useState([
    {
      id: 43,
      name: "dsadd",
      summary: "dfdf",
      title: null,
      thumbnail: "thumbnails/WBWJL7jqrKZtIBsVxRFUmoCDgmeHpjNFDiYawmat.jpeg",
      latitude: null,
      longitude: null,
    },
  ]);
  const type_array = [2, 3, 4, 5, 6, 7, 19, 26, 112, 113];
  //modal var
  const [categoryModal, setCategoryModal] = useState(false);

  const [urlModal, setUrlModal] = useState(false);

  const [thumbnailModal, setThumbnailModal] = useState(false);

  const [otherImgModal, setOtherImgModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [temp, setTemp] = useState("");

  const handlePopClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const pop_open = Boolean(anchorEl);
  const id = pop_open ? "transitions-popper" : undefined;
  /* var [TagChip, setTagChip] = useState(
    <Chip
      variant="outlined"
      onClick={{}}
      label="wait"
      color="secondary"
      size="small"
    />
  );*/
  var [CategoryChip, setCategoryChip] = useState(
    <Chip
      variant="outlined"
      onClick={{}}
      label="wait"
      color="secondary"
      size="small"
    />
  );
  const handleDataChange = (event) => {
    const target = event.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (target.type === "radio" && name != "type") {
      if (value === "true") value = true;
      else value = false;
    }

    data = { ...data, [name]: value };

    setData({ ...data });

    console.log(data, htmlCode);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //run once after first render for check login
  useEffect(() => {
    const fetchData = async () => {
      const thedata = await Promise.all([
        fetchTagsData(),
        fetchCategoriesData(),
        fetchThemesData(),
        fetchArticlesData(),
      ]);
      console.log(thedata[0].tags);
      tags = thedata[0].tags;
      categories = thedata[1].categories;
      types = thedata[2].types;
      for (let i = 0; i < tags.length; i++) tags[i].checked = false;
      setTags(tags);
      for (let i = 0; i < categories.length; i++) categories[i].checked = false;
      setCategories(categories);
      for (let i = 0; i < types.length; i++)
        types[i].rules.name = types[i].name;

      data.type = types[0].id.toString();
      setTypes(types);
      setData(data);
      setInputRule(types[0].rules);
      console.log(props.access_token);
      setArticles(thedata[3].articles);
      //setTagChip(TagChip);
    };
    fetchData();
  }, []);
  const fetchTagCat = () => {
    fetch("https://uisur.ad.ntust.edu.tw/backend/api/tags")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        console.log(myJson);
        var temp_save = tags;
        var temp_new = myJson;

        for (var i = 0; i < temp_new.length; i++) {
          temp_new[i].checked = false;
          for (var j = 0; j < temp_save.length; j++) {
            if (
              temp_save[j].checked &&
              temp_new[i].name === temp_save[j].name
            ) {
              temp_new[i].checked = true;
            }
          }
        }
        setTags(temp_new);
      });

    fetch("https://uisur.ad.ntust.edu.tw/backend/api/categories")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        console.log(myJson);
        var temp_save = categories;
        var temp_new = myJson;

        for (var i = 0; i < temp_new.length; i++) {
          temp_new[i].checked = false;
          for (var j = 0; j < temp_save.length; j++) {
            if (
              temp_save[j].checked &&
              temp_new[i].name === temp_save[j].name
            ) {
              temp_new[i].checked = true;
            }
          }
        }
        setCategories(temp_new);
      });
    //alert("done");
  };
  //all fetch function
  const fetchArticlesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/articles")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };
  const fetchThemesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/types")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { types: myJson };
      });
  };
  const fetchTagsData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/tags")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { tags: myJson };
        //setTagsData(temp);
      });
  };
  const fetchCategoriesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/categories")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { categories: myJson };
        //setTagsData(temp);
      });
  };

  //submit form
  const handleSubmit = () => {
    {
      return new Promise(function (resolve, reject) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + props.access_token);
        myHeaders.append("Accept", "application/json");
        var uploadData = new FormData();
        //dat.append('file', data.image[0]);
        //dat.append('user', 'hubot');
        uploadData.append("type", data.type);
        uploadData.append("name", data.name);
        uploadData.append("summary", data.summary);
        uploadData.append("content", htmlCode);
        uploadData.append("categories", data.categories);
        if (data.tags.length === 0) {
          uploadData.append("tags[]", "");
        }
        data.tags.forEach((e) => {
          uploadData.append("tags[]", e);
        });
        uploadData.append("start_year", data.start_year);
        uploadData.append("end_year", data.end_year);
        uploadData.append("recommends", data.recommends);
        uploadData.append("longitude", data.longitude);
        uploadData.append("latitude", data.latitude);
        uploadData.append("relations", data.relations);
        uploadData.append("title", data.title);
        uploadData.append("weight", data.weight);
        uploadData.append("url", data.url);
        uploadData.append("map_filter_type", data.map_filter_type);

        uploadData.append(
          "is_visible",
          JSON.stringify(data.is_visible === true ? 1 : 0)
        );
        uploadData.append(
          "is_drafted",
          JSON.stringify(data.is_drafted === true ? 1 : 0)
        );

        //uploadData.append("is_visible", JSON.stringify(1));
        //uploadData.append("is_drafted", JSON.stringify(0));

        uploadData.append("available_at", data.available_at);
        //uploadData.append("thubnail",data.thumbnail);
        if (data.thumbnail.length !== 0)
          uploadData.append("thumbnail", data.thumbnail[0]);
        if (data.image.length !== 0)
          for (var i = 0; i < data.image.length; i++)
            uploadData.append("image[]", data.image[i]);

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: uploadData,
          redirect: "follow",
        };
        var fetchstatus = false;
        fetch(
          "https://uisur.ad.ntust.edu.tw/backend/api/articles",
          requestOptions
        )
          .then((response) => {
            if (response.ok) {
              alert("上傳成功");
              fetchstatus = true;
              resolve("");
            } else {
              fetchstatus = false;
              //alert("上傳失敗");
            }
            return response.text();
          })
          .then((result) => {
            console.log(result);
            var objJsonArray = JSON.parse(result);
            var tempstring = "";
            var i = 0;
            tempstring += objJsonArray.message + "\n";
            for (var j in objJsonArray.errors) {
              console.log(j);
              i += 1;
              tempstring =
                tempstring + i.toString() + "." + objJsonArray.errors[j] + "\n";
            }
            if (fetchstatus === false) alert(tempstring);
          })
          .catch((error) => {
            console.log("error", error);
          });
      });
    }
    // var status_Number;
    // console.log(JSON.stringify({
    //   type:data.type,
    //   name: data.name,
    //   summary: data.summary,
    //   content: htmlCode,
    //   categories: data.categories,
    //   tags: data.tags,
    //   start_year: data.start_year,
    //   end_year: data.end_year,
    //   recommend:data.recommend,
    //   longitude: data.longitude,
    //   latitude: data.latitude,
    //   relations:data.relations,
    //   title:data.title,
    //   weight: data.weight,
    //   url:data.url,
    //   is_visible:data.is_visible===true?1:0,
    //   is_drafted:data.is_drafted===true?1:0,
    //   available_at:data.available_at,
    //   thubnail:data.thumbnail,
    //   image:data.image,
    // }));

    //  fetch("https://uisur.ad.ntust.edu.tw/backend/api/articles", {
    //   method: "POST",
    //   headers: {
    //     Authorization: `Bearer ${props.access_token}`,
    //     "Content-Type": "multipart/form-data",
    //   },
    //   //'contentType': false, //required
    // //'processData': false, // required

    //   body: uploadData,
    // })
    //   .then(function (response) {
    //     status_Number = response.status;
    //     console.log(response);
    //     if (response.ok) {

    //       alert("新建資料成功");
    //     } else {
    //       alert("新建資料失敗");
    //     }
    //     return response.json();
    //   })
    //   .then(function (myJson) {

    //   });
  };
  const handleHtml = () => {};

  const classes = useStyles();

  return (
    <>
      <div style={{}}>
        <ManageAppBar />
        <Grid container>
          <Grid item md={1} lg={1} sm={1}>
            <LeftAppBar />
          </Grid>
          <Grid item md={11} lg={11} sm={11}>
            <Grid container>
              <Grid item container alignItems="center" lg={12} md={12} sm={12}>
                <Typography className={classes.title}>型態</Typography>
                <FormControl component="fieldset" row>
                  <RadioGroup
                    aria-label="position"
                    name="type"
                    defaultValue={data.type.toString()}
                    onChange={(e) => {
                      handleDataChange(e);
                      for (var i = 0; i < types.length; i++) {
                        if (types[i].id.toString() === e.target.value) {
                          setInputRule(types[i].rules);
                          break;
                        }
                      }
                    }}
                    row
                  >
                    {types
                      .filter((item) => {
                        return (
                          -1 !=
                          type_array.findIndex((i) => {
                            return i === item.id;
                          })
                        );
                      })
                      .map((type) => (
                        <FormControlLabel
                          value={type.id.toString()}
                          checked={type.id.toString() === data.type.toString()}
                          control={<Radio color="primary" />}
                          label={type.name}
                        />
                      ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              wrap="nowrap"
              lg={12}
              md={12}
              sm={12}
              className={classes.buttomMargin}
            >
              <Typography className={classes.title}>名稱</Typography>
              <TextField
                id="name"
                size="small"
                name="name"
                variant="outlined"
                disabled={inputRule.need_name ? false : true}
                value={data.name}
                onChange={handleDataChange}
              />

              <Typography className={classes.title}>代表圖片</Typography>
              <Button
                variant="contained"
                color="default"
                className={classes.button}
                startIcon={<CloudUploadIcon />}
                onClick={() => {
                  setThumbnailModal(true);
                }}
              >
                檔案上傳
              </Button>

              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={thumbnailModal}
                onClose={() => {
                  setThumbnailModal(false);
                }}
              >
                <DropzoneArea
                  acceptedFiles={["image/*"]}
                  initialFiles={data.thumbnail}
                  filesLimit={1}
                  maxFileSize={3000000 * 2}
                  dropzoneText="Put the image or click here "
                  onChange={(files) => {
                    data.thumbnail = files;
                    setData({ ...data });
                    console.log("Files:", files, data);
                  }}
                />
              </Modal>
              <Typography className={classes.title}>其他圖片</Typography>
              <Button
                variant="contained"
                color="default"
                className={classes.button}
                startIcon={<CloudUploadIcon />}
                onClick={() => {
                  setOtherImgModal(true);
                }}
              >
                檔案上傳
              </Button>
              <FormControl className={classes.formControl}>
                <InputLabel id="map_dot_label">地點類型</InputLabel>
                <Select
                  labelId="map_dot"
                  id="map_dot"
                  name="map_filter_type"
                  value={data.map_filter_type}
                  onChange={handleDataChange}
                >
                  <MenuItem value={1}>人物</MenuItem>
                  <MenuItem value={2}>產業</MenuItem>
                  <MenuItem value={3}>建築</MenuItem>
                  <MenuItem value={4}>美食</MenuItem>
                  <MenuItem value={5}>特色</MenuItem>
                  <MenuItem value={6}>交通</MenuItem>
                </Select>
              </FormControl>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={otherImgModal}
                onClose={() => {
                  setOtherImgModal(false);
                }}
              >
                <DropzoneArea
                  acceptedFiles={["image/*"]}
                  initialFiles={data.image}
                  filesLimit={5}
                  maxFileSize={3000000 * 2}
                  dropzoneText="Put the image or click here "
                  onChange={(files) => {
                    data.image = files;
                    setData({ ...data });
                    console.log("Files:", files, data);
                  }}
                />
              </Modal>
              <div className={classes.rightflex} />
              <Button
                variant="outlined"
                size="small"
                className={classes.previewButton}
                onClick={(e) => {
                  data.is_drafted = true;
                  setData({ ...data });
                  handleSubmit().then(() => {
                    var temp = "";

                    fetch("https://uisur.ad.ntust.edu.tw/backend/api/articles")
                      .then(function (response) {
                        return response.json();
                      })
                      .then(function (myJson) {
                        console.log(myJson);
                        //setTagsData(temp);
                        for (let i = 0; i < myJson.length; i++) {
                          if (data.name === myJson[i].name) {
                            temp = myJson[i].id;
                            window.open("#/Article/" + data.name);
                            document.location.href =
                              "#/ManageInterface/EditDataPage/" + temp;

                            break;
                          }
                          console.log(
                            myJson,
                            data.name,
                            data.name === myJson[i].name
                          );
                        }
                      });
                    console.log("temp", temp);
                  });

                  //props.history.push("/new/url");
                }}
              >
                預覽
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.saveButton}
                onClick={(e) => {
                  data.is_drafted = true;
                  setData({ ...data });
                  handleSubmit();
                }}
              >
                上傳為私人
              </Button>

              <Button
                variant="outlined"
                size="small"
                className={classes.publishButton}
                onClick={handlePopClick}
              >
                上傳為公開
              </Button>
              <Popper
                id={id}
                open={pop_open}
                anchorEl={anchorEl}
                transition
                className={classes.publishpopper}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <div className={classes.paper}>
                      <Typography>Set publish time </Typography>
                      <Divider />
                      <FormControl component="fieldset" row>
                        <RadioGroup
                          aria-label="position"
                          name="position"
                          defaultValue="Immediately"
                        >
                          <FormControlLabel
                            value="false"
                            checked={!data.Schedule}
                            control={<Radio color="primary" />}
                            label="Immediately"
                            name="Schedule"
                            onChange={(e) => {
                              data.available_at = "";
                              //setData({ ...data });
                              handleDataChange(e);
                            }}
                          />
                          <FormControlLabel
                            value="true"
                            checked={data.Schedule}
                            control={<Radio color="primary" />}
                            label="Schedule"
                            onChange={handleDataChange}
                            name="Schedule"
                          />
                        </RadioGroup>
                        <TextField
                          id="datetime-local"
                          label="Publish Time"
                          disabled={!data.Schedule ? true : false}
                          type="datetime-local"
                          defaultValue="2021-02-01T10:30"
                          name="available_at"
                          onChange={handleDataChange}
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                      <Divider />
                      <Grid container justify="center">
                        <Button
                          className={classes.saveButton}
                          onClick={(e) => {
                            data.is_drafted = false;
                            setData({ ...data });
                            handleSubmit();
                          }}
                          size="small"
                        >
                          publish datapage
                        </Button>
                      </Grid>
                    </div>
                  </Fade>
                )}
              </Popper>
            </Grid>
            <Divider />
            <Grid item lg={12} md={12} className={classes.marginTheTop}>
              <Grid container>
                <Grid item container lg={5} md={5} sm={5}>
                  <Grid
                    item
                    container
                    alignItems="center"
                    wrap="nowrap"
                    lg={12}
                    md={12}
                    sm={12}
                    className={classes.buttomMargin}
                  >
                    <Typography className={classes.title}>年代</Typography>
                    <TextField
                      id="start_year"
                      type="number"
                      disabled={inputRule.need_era ? false : true}
                      name="start_year"
                      size="small"
                      variant="outlined"
                      classes={{ disabled: classes.disabled }}
                      onChange={handleDataChange}
                      value={data.start_year}
                    />
                    <Typography className={classes.title}>~</Typography>
                    <TextField
                      id="end_year"
                      size="small"
                      name="end_year"
                      type="number"
                      onChange={handleDataChange}
                      variant="outlined"
                      value={data.end_year}
                      disabled={inputRule.need_era ? false : true}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    lg={12}
                    md={12}
                    sm={12}
                    className={classes.buttomMargin}
                    wrap="nowrap"
                  >
                    <Typography className={classes.title}>摘要</Typography>
                    <TextField
                      multiline
                      fullWidth
                      rows={2}
                      name="summary"
                      id="summary"
                      size="small"
                      variant="outlined"
                      onChange={handleDataChange}
                      value={data.summary}
                      disabled={inputRule.need_summary ? false : true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="100字簡要說明景點可以寫地址、營業時間等等資訊"
                    />
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    container
                    alignItems="center"
                    wrap="nowrap"
                    className={classes.buttomMargin}
                  >
                    <Typography className={classes.title}>網站</Typography>
                    <TextField
                      id="url"
                      type="url"
                      size="small"
                      variant="outlined"
                      name="url"
                      disabled={inputRule.need_url ? false : true}
                      value={data.url}
                      onChange={handleDataChange}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    container
                    alignItems="center"
                    wrap="nowrap"
                    className={classes.buttomMargin}
                  >
                    <Typography className={classes.threeTitle}>
                      關係性
                    </Typography>
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      options={articles}
                      getOptionLabel={(option) => option.name}
                      //defaultValue={[]}
                      fullWidth
                      name="relationship"
                      disabled={inputRule.need_relation ? false : true}
                      value={relationship}
                      onChange={(e, newValue) => {
                        console.log(newValue);
                        setRelationship([...newValue]);
                        var tempstring = "";
                        for (var i = 0; i < newValue.length; i++) {
                          tempstring += newValue[i].id.toString() + ",";
                        }
                        if (tempstring != "") {
                          tempstring = tempstring.substring(
                            0,
                            tempstring.length - 1
                          );
                        }
                        data.relations = tempstring;
                        console.log(tempstring);
                        setData({ ...data });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="關係性"
                          placeholder="關係性"
                        />
                      )}
                    />

                    {/* <TextField
                      id="outlined-basic"
                      size="small"
                      variant="outlined"
                      placeholder="衍生關係性"
                      disabled={inputRule.need_relation ? false : true}
                      name="DerivativeRelationship"
                      value={data.DerivativeRelationship}
                      onChange={handleDataChange}
                    />
                    <TextField
                      id="outlined-basic"
                      size="small"
                      variant="outlined"
                      disabled={inputRule.need_relation ? false : true}
                      name="Interrelationship"
                      placeholder="相互關係性"
                      value={data.Interrelationship}
                      onChange={handleDataChange}
                    /> */}
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    container
                    alignItems="center"
                    // wrap="nowrap"
                    className={classes.buttomMargin}
                  >
                    <Typography className={classes.title}>標籤</Typography>
                    <TextField
                      id="outlined-basic"
                      size="small"
                      variant="outlined"
                      placeholder="#hashtags"
                      name="tag"
                      value={inputTagText}
                      onChange={(e) => {
                        setInputTagText(e.target.value);
                      }}
                    />

                    <Button
                      className={classes.chooseButton}
                      onClick={() => {
                        data.tags.push(inputTagText);
                        setData({ ...data });
                        setInputTagText("");
                      }}
                      disabled={inputRule.need_tag ? false : true}
                    >
                      新增
                    </Button>
                    {data.tags.map((item) => (
                      <Chip
                        label={item}
                        onDelete={() => {
                          data.tags = data.tags.filter((x) => {
                            return x !== item;
                          });
                          setData({ ...data });
                        }}
                      ></Chip>
                    ))}
                    <Typography className={classes.LongText}>
                      搜尋的分類
                    </Typography>
                    <Button
                      className={classes.chooseButton}
                      onClick={() => {
                        fetchTagCat();
                        setCategoryModal(true);
                      }}
                    >
                      選擇{" "}
                    </Button>
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      className={classes.modal}
                      open={categoryModal}
                      onClose={() => {
                        setCategoryModal(false);
                      }}
                    >
                      <div className={classes.paper}>
                        <Typography variant="h4"> 請選擇分類</Typography>
                        <Divider style={{ marginBottom: "15px" }} />
                        <Typography style={{ marginTop: "15px" }} variant="h5">
                          時間軸
                        </Typography>
                        <Divider style={{ marginBottom: "15px" }} />
                        {categories
                          .filter((item) => {
                            return item.parent_id === 52;
                          })
                          .map((category) => (
                            <Chip
                              variant={
                                category.checked ? "default" : "outlined"
                              }
                              onClick={(e) => {
                                category.checked = !category.checked;
                                setCategories([...categories]);
                                var catdata = "";
                                for (var i = 0; i < categories.length; i++) {
                                  if (categories[i].checked) {
                                    catdata +=
                                      categories[i].id.toString() + ",";
                                  }
                                }
                                if (catdata != "")
                                  catdata = catdata.substring(
                                    0,
                                    catdata.length - 1
                                  );
                                data.categories = catdata;
                              }}
                              label={category.name}
                              style={{ color: "#ed7d31" }}
                              color="secondary"
                              size="small"
                            />
                          ))}
                        <Typography style={{ marginTop: "15px" }} variant="h5">
                          {" "}
                          人物相關
                        </Typography>

                        <Divider style={{ marginBottom: "15px" }} />
                        {categories
                          .filter((item) => {
                            return item.parent_id === 53;
                          })
                          .map((category) => (
                            <Chip
                              variant={
                                category.checked ? "default" : "outlined"
                              }
                              onClick={(e) => {
                                category.checked = !category.checked;
                                setCategories([...categories]);
                                var catdata = "";
                                for (var i = 0; i < categories.length; i++) {
                                  if (categories[i].checked) {
                                    catdata +=
                                      categories[i].id.toString() + ",";
                                  }
                                }
                                if (catdata != "")
                                  catdata = catdata.substring(
                                    0,
                                    catdata.length - 1
                                  );
                                data.categories = catdata;
                              }}
                              label={category.name}
                              style={{ color: "#0070c0" }}
                              color="secondary"
                              size="small"
                            />
                          ))}
                        <Typography style={{ marginTop: "15px" }} variant="h5">
                          生活產業
                        </Typography>
                        <Divider style={{ marginBottom: "15px" }} />
                        {categories
                          .filter((item) => {
                            return item.parent_id === 54;
                          })
                          .map((category) => (
                            <Chip
                              variant={
                                category.checked ? "default" : "outlined"
                              }
                              onClick={(e) => {
                                category.checked = !category.checked;
                                setCategories([...categories]);
                                var catdata = "";
                                for (var i = 0; i < categories.length; i++) {
                                  if (categories[i].checked) {
                                    catdata +=
                                      categories[i].id.toString() + ",";
                                  }
                                }
                                if (catdata != "")
                                  catdata = catdata.substring(
                                    0,
                                    catdata.length - 1
                                  );
                                data.categories = catdata;
                              }}
                              label={category.name}
                              style={{ color: "#ab5ad1" }}
                              color="secondary"
                              size="small"
                            />
                          ))}
                        <Typography style={{ marginTop: "15px" }} variant="h5">
                          建築類型
                        </Typography>
                        <Divider style={{ marginBottom: "15px" }} />
                        {categories
                          .filter((item) => {
                            return item.parent_id === 71;
                          })
                          .map((category) => (
                            <Chip
                              variant={
                                category.checked ? "default" : "outlined"
                              }
                              onClick={(e) => {
                                category.checked = !category.checked;
                                setCategories([...categories]);
                                var catdata = "";
                                for (var i = 0; i < categories.length; i++) {
                                  if (categories[i].checked) {
                                    catdata +=
                                      categories[i].id.toString() + ",";
                                  }
                                }
                                if (catdata != "")
                                  catdata = catdata.substring(
                                    0,
                                    catdata.length - 1
                                  );
                                data.categories = catdata;
                              }}
                              label={category.name}
                              style={{ color: "#c00000" }}
                              color="secondary"
                              size="small"
                            />
                          ))}
                        <Typography style={{ marginTop: "15px" }} variant="h5">
                          {" "}
                          交通資訊
                        </Typography>

                        <Divider style={{ marginBottom: "15px" }} />
                        {categories
                          .filter((item) => {
                            return item.parent_id === 73;
                          })
                          .map((category) => (
                            <Chip
                              variant={
                                category.checked ? "default" : "outlined"
                              }
                              onClick={(e) => {
                                category.checked = !category.checked;
                                setCategories([...categories]);
                                var catdata = "";
                                for (var i = 0; i < categories.length; i++) {
                                  if (categories[i].checked) {
                                    catdata +=
                                      categories[i].id.toString() + ",";
                                  }
                                }
                                if (catdata != "")
                                  catdata = catdata.substring(
                                    0,
                                    catdata.length - 1
                                  );
                                data.categories = catdata;
                              }}
                              label={category.name}
                              style={{ color: "#0070c0" }}
                              color="secondary"
                              size="small"
                            />
                          ))}
                        <Typography style={{ marginTop: "15px" }} variant="h5">
                          {" "}
                          在地美食
                        </Typography>

                        <Divider style={{ marginBottom: "15px" }} />
                        {categories
                          .filter((item) => {
                            return item.parent_id === 77;
                          })
                          .map((category) => (
                            <Chip
                              variant={
                                category.checked ? "default" : "outlined"
                              }
                              onClick={(e) => {
                                category.checked = !category.checked;
                                setCategories([...categories]);
                                var catdata = "";
                                for (var i = 0; i < categories.length; i++) {
                                  if (categories[i].checked) {
                                    catdata +=
                                      categories[i].id.toString() + ",";
                                  }
                                }
                                if (catdata != "")
                                  catdata = catdata.substring(
                                    0,
                                    catdata.length - 1
                                  );
                                data.categories = catdata;
                              }}
                              label={category.name}
                              style={{ color: "#0070c0" }}
                              color="secondary"
                              size="small"
                            />
                          ))}
                        <Typography style={{ marginTop: "15px" }} variant="h5">
                          {" "}
                          推薦景點
                        </Typography>

                        <Divider style={{ marginBottom: "15px" }} />
                        {categories
                          .filter((item) => {
                            return item.parent_id === 82;
                          })
                          .map((category) => (
                            <Chip
                              variant={
                                category.checked ? "default" : "outlined"
                              }
                              onClick={(e) => {
                                category.checked = !category.checked;
                                setCategories([...categories]);
                                var catdata = "";
                                for (var i = 0; i < categories.length; i++) {
                                  if (categories[i].checked) {
                                    catdata +=
                                      categories[i].id.toString() + ",";
                                  }
                                }
                                if (catdata != "")
                                  catdata = catdata.substring(
                                    0,
                                    catdata.length - 1
                                  );
                                data.categories = catdata;
                              }}
                              label={category.name}
                              style={{ color: "#0070c0" }}
                              color="secondary"
                              size="small"
                            />
                          ))}
                        <Typography style={{ marginTop: "15px" }} variant="h5">
                          {" "}
                          服務設施
                        </Typography>

                        <Divider style={{ marginBottom: "15px" }} />
                        {categories
                          .filter((item) => {
                            return item.parent_id === 85;
                          })
                          .map((category) => (
                            <Chip
                              variant={
                                category.checked ? "default" : "outlined"
                              }
                              onClick={(e) => {
                                category.checked = !category.checked;
                                setCategories([...categories]);
                                var catdata = "";
                                for (var i = 0; i < categories.length; i++) {
                                  if (categories[i].checked) {
                                    catdata +=
                                      categories[i].id.toString() + ",";
                                  }
                                }
                                if (catdata != "")
                                  catdata = catdata.substring(
                                    0,
                                    catdata.length - 1
                                  );
                                data.categories = catdata;
                              }}
                              label={category.name}
                              style={{ color: "#0070c0" }}
                              color="secondary"
                              size="small"
                            />
                          ))}
                        <Typography style={{ marginTop: "15px" }} variant="h5">
                          無父類別
                        </Typography>
                        <Divider style={{ marginBottom: "15px" }} />
                        {categories
                          .filter((item) => {
                            return item.parent_id === null;
                          })
                          .map((category) => (
                            <Chip
                              variant={
                                category.checked ? "default" : "outlined"
                              }
                              onClick={(e) => {
                                category.checked = !category.checked;
                                setCategories([...categories]);
                                var catdata = "";
                                for (var i = 0; i < categories.length; i++) {
                                  if (categories[i].checked) {
                                    catdata +=
                                      categories[i].id.toString() + ",";
                                  }
                                }
                                if (catdata != "")
                                  catdata = catdata.substring(
                                    0,
                                    catdata.length - 1
                                  );
                                data.categories = catdata;
                              }}
                              label={category.name}
                              color="secondary"
                              style={{ color: "#000000" }}
                              size="small"
                            />
                          ))}
                      </div>
                    </Modal>
                  </Grid>
                </Grid>

                <Grid item container lg={5} md={5} sm={5}>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    container
                    className={classes.buttomMargin}
                    alignItems="center"
                    wrap="nowrap"
                  >
                    <Typography className={classes.threeTitle}>
                      推薦頁
                    </Typography>
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      options={articles}
                      getOptionLabel={(option) => option.name}
                      //defaultValue={[]}
                      fullWidth
                      name="recommends"
                      disabled={inputRule.need_recommend ? false : true}
                      value={recommendPage}
                      onChange={(e, newValue) => {
                        console.log(newValue);
                        setRecommendPage([...newValue]);
                        var tempstring = "";
                        for (var i = 0; i < newValue.length; i++) {
                          tempstring += newValue[i].id.toString() + ",";
                        }
                        if (tempstring != "") {
                          tempstring = tempstring.substring(
                            0,
                            tempstring.length - 1
                          );
                        }
                        data.recommends = tempstring;
                        console.log(tempstring);
                        setData({ ...data });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="推薦頁"
                          placeholder="請輸入推薦頁"
                        />
                      )}
                    />
                    {/* <TextField
                      id="recommend"
                      disabled={data.type === "History" ? true : false}
                      size="small"
                      variant="outlined"
                      disabled={inputRule.need_recommend?false:true}
                      onChange={handleDataChange}
                      name="recommend"
                      value={data.recommend}
                    /> */}
                  </Grid>

                  {/* <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    container
                    className={classes.buttomMargin}
                    alignItems="center"
                    wrap="nowrap"
                  >
                    <Typography className={classes.threeTitle}>
                      於地圖
                    </Typography>
                    <FormControl component="fieldset" row>
                      <RadioGroup
                        aria-label="position"
                        name="position"
                        defaultValue="People"
                        row
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio color="primary" />}
                          label="顯示"
                          disabled={inputRule.need_visible ? false : true}
                          name="is_drafted"
                          onChange={handleDataChange}
                          checked={data.is_drafted}
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio color="primary" />}
                          label="不顯示"
                          name="is_drafted"
                          disabled={inputRule.need_visible ? false : true}
                          checked={!data.is_drafted}
                          onChange={handleDataChange}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid> */}
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    container
                    alignItems="center"
                    wrap="nowrap"
                    className={classes.buttomMargin}
                  >
                    <Typography className={classes.title}>位置</Typography>
                    <TextField
                      id="latitude"
                      size="small"
                      variant="outlined"
                      placeholder="緯度"
                      name="latitude"
                      type="number"
                      value={data.latitude}
                      onChange={handleDataChange}
                    />
                    <TextField
                      id="longitude"
                      size="small"
                      type="number"
                      variant="outlined"
                      placeholder="經度"
                      name="longitude"
                      value={data.longitude}
                      onChange={handleDataChange}
                    />
                    <Button
                      variant="contained"
                      color="default"
                      className={classes.button}
                      onClick={() => {
                        setUrlModal(true);
                      }}
                    >
                      GOOGLE網址
                    </Button>
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      className={classes.modal}
                      open={urlModal}
                      onClose={() => {
                        setUrlModal(false);
                      }}
                    >
                      <Paper className={classes.paper}>
                        <Typography fullWidth>請輸入網址</Typography>
                        <Divider />
                        <TextField
                          name="googleurl"
                          value={temp}
                          onChange={(e) => {
                            setTemp(e.target.value);
                          }}
                          placeholder="網址"
                        ></TextField>
                        <Divider />
                        <Button
                          variant="contained"
                          color="default"
                          className={classes.button}
                          onClick={() => {
                            var thistemp = data;
                            try {
                              console.log(temp.split("!3d")[1].split("!4d"));

                              thistemp.longitude = temp
                                .split("!3d")
                                [temp.split("!3d").length - 1].split("!4d")[1]
                                .split("?")[0];
                              thistemp.latitude = temp
                                .split("!3d")
                                [temp.split("!3d").length - 1].split("!4d")[0];
                              setData(thistemp);
                            } catch {
                              thistemp.longitude = 0;
                              thistemp.latitude = 0;
                              setData(thistemp);
                            }
                            setTemp("");
                            setUrlModal(false);
                          }}
                        >
                          確認
                        </Button>{" "}
                        <Button
                          variant="contained"
                          color="default"
                          className={classes.button}
                          onClick={() => {
                            setUrlModal(false);
                          }}
                        >
                          取消
                        </Button>
                      </Paper>
                    </Modal>
                  </Grid>

                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    container
                    alignItems="center"
                    className={classes.buttomMargin}
                    wrap="nowrap"
                  >
                    <Typography className={classes.title}>權重</Typography>
                    <TextField
                      id="weight"
                      size="small"
                      type="number"
                      name="weight"
                      variant="outlined"
                      value={data.weight}
                      onChange={handleDataChange}
                    />
                    {/* <Typography className={classes.LongText}>
                      搜尋的分類
                    </Typography>
                    <Button
                      className={classes.chooseButton}
                      onClick={() => {
                        setCategoryModal(true);
                      }}
                    >
                      選擇{" "}
                    </Button>
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      className={classes.modal}
                      open={categoryModal}
                      onClose={() => {
                        setCategoryModal(false);
                      }}
                    >
                      <div className={classes.paper}>
                        <h2 id="transition-modal-title">請選擇分類</h2>
                        <Divider />
                        {categories.map((category) => (
                          <Chip
                            variant={category.checked ? "default" : "outlined"}
                            onClick={(e) => {
                              category.checked = !category.checked;
                              setCategories([...categories]);
                              var catdata = "";
                              for (var i = 0; i < categories.length; i++) {
                                if (categories[i].checked) {
                                  catdata += categories[i].id.toString() + ",";
                                }
                              }
                              if (catdata != "")
                                catdata = catdata.substring(
                                  0,
                                  catdata.length - 1
                                );
                              data.categories = catdata;
                            }}
                            label={category.name}
                            color="secondary"
                            size="small"
                          />
                        ))}
                      </div>
                    </Modal> */}
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    container
                    alignItems="center"
                    className={classes.buttomMargin}
                    wrap="nowrap"
                  >
                    <Typography className={classes.LongText}>
                      本頁網址/
                    </Typography>
                    <Typography>{inputRule.name + "/"} </Typography>
                    <TextField
                      id="title"
                      size="small"
                      fullWidth
                      variant="outlined"
                      placeholder=""
                      name="title"
                      value={data.title}
                      onChange={handleDataChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              lg={11}
              md={11}
              sm={11}
              className={classes.textEditorGrid}
            >
              <HtmlEditor SetHtmlData={setHtmlcode} />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default DataPage;
