import React from "react";
import { BrowserRouter, Route, Link, HashRouter } from "react-router-dom";

import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import Box from "@material-ui/core/Box";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import LeftAppBar from "../LeftAppBar/LeftAppBar.js";
import ManageAppBar from "../ManageAppBar/ManageAppBar.js";
import { Card, CardMedia, Divider, Hidden } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 0, 0),
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  fulldiv: {
    height: "100%",
    width: "100%",
  },
  loginButton: {
    marginTop: theme.spacing(2),
  },
  threeTitle: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    wordBreak: "keep-all",
  },
  title: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(3),
    wordBreak: "keep-all",
  },
  thepaper: {
    marginTop: theme.spacing(3),
  },
  newLink: {
    fontWeight: "bold",
    textDecoration: "none",
    color: "#000000",
  },
  registerText: {
    marginBottom: theme.spacing(3),
  },
  media: {
    height: 140,
  },
  buttomMargin: {
    marginBottom: theme.spacing(1),
  },
  seeall: {
    textDecoration: "none",
    color: "#7f7f7f",
  },
  welcomeText: {
    marginTop: theme.spacing(3),
  },
}));

const ManageLogin = (props) => {
  /*var geoData = require("../../data.json");
  fetch("https://uisur.ad.ntust.edu.tw/backend/api/sights")
    .then(function (response) {
      return response.json();
    })
    .then(function (myJson) {
      console.log(myJson);
    });*/
  const [accountName, setAccountName] = useState(undefined);
  const [password, setPassword] = useState(undefined);

  const classes = useStyles();
  const API_Login = async () => {
    if (accountName && password) {
      var status_Number = 0;
      await fetch("https://uisur.ad.ntust.edu.tw/backend/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          account: accountName,
          password: password,
        }),
      })
        .then(function (response) {
          //console.log(response);
          status_Number = response.status;
          return response.json();
        })
        .then(function (myJson) {
          if (status_Number == 200) {
            console.log(myJson);
            //console.log(myJson.access_token);
            props.handleLogin(true, accountName, myJson.access_token);
            window.location.reload();
          } else {
            alert("登入帳號失敗");
          }
        });
    }
  };
  return (
    <>
      <div style={{}}>
        <ManageAppBar />
        <Grid container>
          <Grid item md={3} lg={3} sm={3}>
            <div className={classes.fulldiv} />
          </Grid>

          <Grid item md={6} lg={6} sm={6}>
            <Paper className={classes.thepaper} elevation={12}>
              <Grid
                item
                container
                alignItems="center"
                justify="center"
                lg={12}
                md={12}
                sm={12}
              >
                <Typography variant="h4" className={classes.welcomeText}>
                  歡迎登入大稻埕管理系統
                </Typography>
              </Grid>
              <Grid
                item
                container
                alignItems="flex-end"
                justify="center"
                lg={12}
                md={12}
                sm={12}
              >
                <Typography variant="h6">帳號:</Typography>
                <TextField
                  id="accountName"
                  label="Account"
                  value={accountName}
                  onChange={(e) => {
                    setAccountName(e.target.value);
                  }}
                />
              </Grid>
              <Grid
                item
                container
                alignItems="flex-end"
                justify="center"
                lg={12}
                md={12}
                sm={12}
              >
                <Typography variant="h6">密碼</Typography>
                <TextField
                  id="standard-password-input"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                />
              </Grid>
              <Grid
                item
                container
                alignItems="flex-end"
                justify="center"
                lg={12}
                md={12}
                sm={12}
              >
                <Button
                  outline
                  className={classes.loginButton}
                  onClick={API_Login}
                >
                  登入
                </Button>
              </Grid>
              <Grid
                item
                container
                alignItems="flex-end"
                justify="flex-end"
                lg={12}
                md={12}
                sm={12}
              >
                {/* <Typography
                  className={classes.registerText}
                  component={Link}
                  to="/ManageInterface/ManageRegister"
                >
                  還沒有帳號嗎，註冊一個吧!
                </Typography> */}
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={3} lg={3} sm={3}></Grid>
        </Grid>
      </div>
    </>
  );
};

export default ManageLogin;
