import "./App.css";
import React, { useState, useEffect } from "react";

import {
  HashRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";
import Cookies from "js-cookie";

import NewDataPage from "./NewDataPage";
import _ from "underscore";
import MyMenu from "./frontendPage/MyMenu/MyMenu";
import LoginPage from "./frontendPage/LoginPage/LoginPage";
import RegisterPage from "./frontendPage/RegisterPage/RegisterPage";
import MapPage from "./frontendPage/Map/MapPage";
import HomePage from "./frontendPage/HomePage/HomePage.js";
import InsightsPage from "./managePage/InsightsPage/InsightsPage.js";
import DataPage from "./managePage/DataPage/DataPage.js";
import ManageRegister from "./managePage/ManageRegister/ManageRegister.js";
import ManageLogin from "./managePage/ManageLogin/ManageLogin.js";
import ViewDataPage from "./managePage/DataPage/ViewDataPage";
import EditDataPage from "./managePage/DataPage/EditDataPage";
import MenuPage from "./managePage/MenuPage/MenuPage";
import MediaPage from "./managePage/MediaPage/MediaPage";
import UserPage from "./managePage/UserPage/UserPage";
import Article from "./frontendPage/Article/Article";
import Themes from "./frontendPage/Themes/Themes";
import CreateMap from "./frontendPage/CreateMap/CreateMap";
import MapboxPage from "./managePage/MapboxPage/MapboxPage";
import Mapbox from "./frontendPage/Mapbox/Mapbox";
import MapPond from "./frontendPage/MapPond/MapPond";
import SearchPage from "./frontendPage/SearchPage/SearchPage";
import Viewoverall from "./managePage/InsightsPage/Viewoverall";
import ManagePage from "./managePage/ManagePage/ManagePage";
import ContestPage from "./managePage/ContestPage/ContestPage";
import SubmissionsPage from "./managePage/SubmissionsPage/SubmissionsPage";
import EventPage from "./managePage/EventPage/EventPage";
import MapPdf from "./frontendPage/MapPdf/MapPdf.js";
import User from "./frontendPage/User/User.js";
const App = (props) => {
  let [isLogin, setIslogin] = useState(undefined);
  let [isLoading, setIsLoading] = useState(true);
  let [access_token, setAccess_token] = useState("");
  let [accountType, setAccountType] = useState("");
  let [accountName, setAccountName] = useState("");

  const handleLogin = (loginstatus, accountname, access_Token) => {
    isLogin = loginstatus;
    accountName = accountname;
    access_token = access_Token;
    if (isLogin) {
      Cookies.set("access_token", access_token, {
        expires: 3600 / 24 / 60 / 60,
      });
      Cookies.set("accountName", accountName, { expires: 3600 / 24 / 60 / 60 });
    }
    setIslogin(loginstatus);
    setAccountName(accountName);
    setAccess_token(access_token);
  };

  //run once after first render for check login
  useEffect(async () => {
    document.title = "大稻埕平台";
    access_token = Cookies.get("access_token");

    if (access_token === undefined) {
      isLogin = false;
    } else {
      isLogin = true;
      setInterval(() => {
        if (Cookies.get("access_token") === undefined) {
          setIslogin(false);
        }
      }, 3000);
      await fetch("https://uisur.ad.ntust.edu.tw/backend/api/me", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (myJson) {
          console.log(myJson);
          setAccountType(myJson.type);
          setAccountName(myJson.account);
          Cookies.set("accountName", myJson.account, {
            expires: 3600 / 24 / 60 / 60,
          });
        });

      accountName = Cookies.get("accountName");
    }

    setIslogin(isLogin);
    setAccess_token(access_token);
    setAccountName(accountName);
    setIsLoading(false);

    console.log(isLogin, access_token);
  }, []);

  return isLoading ? (
    <div />
  ) : (
    <Router>
      <Switch>
        {/*pdf*/}

        <Route
          exact
          path="/MapPdf/:id"
          render={(props) => <MapPdf id={props.match.params.id} />}
        />

        {/*fix facebook*/}

        <Route exact path="/_=_" render={() => <Redirect to="/Home" />} />
        {/*Register Page(frontend)*/}

        <Route
          exact
          path="/Register"
          render={() =>
            isLogin === false ? (
              <RegisterPage handleLogin={handleLogin} />
            ) : (
              <Redirect to="/Home" />
            )
          }
        />
        {/*Home Page(frontend)*/}
        <Route
          exact
          path="/MyMenu"
          render={() =>
            isLogin === false ? (
              <Redirect to="/Login" />
            ) : (
              <User searchmode={false} uuid={"0"} />
            )
          }
        />
        <Route
          exact
          path="/User/:id"
          render={(props) => (
            <User searchmode={true} uuid={props.match.params.id} />
          )}
        />
        {/*SearchPage (frontend)*/}
        <Route
          exact
          path="/SearchPage/:id"
          render={(props) => (
            <SearchPage hasvalue={1} id={props.match.params.id} />
          )}
        ></Route>
        <Route
          exact
          path="/SearchPage"
          render={(props) => <SearchPage hasvalue={0} />}
        ></Route>

        {/*MapPond (frontend)*/}
        <Route
          exact
          path="/MapPond"
          render={() => <MapPond access_token={access_token} />}
        />
        <Route
          exact
          path="/CreateMap"
          render={() =>
            isLogin === false ? (
              <Redirect to="/Login" />
            ) : (
              <CreateMap access_token={access_token} editMode={false} />
            )
          }
        />
        <Route
          exact
          path="/Mapbox/:id"
          render={(props) => (
            <Mapbox access_token={access_token} id={props.match.params.id} />
          )}
        />
        <Route
          exact
          path="/EditMap/:id"
          render={(props) =>
            isLogin === false ? (
              <Redirect to="/Login" />
            ) : (
              <CreateMap
                access_token={access_token}
                editMode={true}
                id={props.match.params.id}
              />
            )
          }
        />

        <Route
          exact
          path="/Home"
          render={() => <HomePage access_token={access_token} />}
        />
        {/*Map Page(frontend)*/}
        <Route exact path="/MapPage" render={() => <MapPage></MapPage>} />

        {/*Login Page(frontend)*/}
        <Route
          exact
          path="/Login"
          render={() =>
            isLogin === false ? (
              <LoginPage handleLogin={handleLogin} />
            ) : (
              <Redirect to="/Home" />
            )
          }
        />
        <Route
          exact
          path="/Article/:id+"
          render={(props) => (
            <Article access_token={access_token} id={props.match.params.id} />
          )}
        />
        <Route
          exact
          path="/Themes/:id"
          render={(props) => (
            <Themes access_token={access_token} id={props.match.params.id} />
          )}
        />
        <Route
          exact
          path="/About"
          render={(props) => <Themes access_token={access_token} id={33} />}
        />

        {/*index Page(first visit this page)*/}
        <Route exact path="/" render={() => <HomePage />} />
        {/*Insights Page(first visit ManageInterface page)*/}

        <Route
          exact
          path="/ManageInterface"
          render={() =>
            isLogin &&
            (accountType === "admin") | (accountType === "manager") ? (
              <Redirect to="/ManageInterface/InsightsPage" />
            ) : (
              <Redirect to="/ManageInterface/ManageLogin" />
            )
          }
        />

        {/*Insights Page(ManageInterface)*/}
        <Route
          exact
          path="/ManageInterface/InsightsPage"
          render={() =>
            isLogin &&
            (accountType === "admin") | (accountType === "manager") ? (
              <InsightsPage />
            ) : (
              <Redirect to="/ManageInterface/ManageLogin" />
            )
          }
        />
        {/*Submissions Page(ManageInterface)*/}
        <Route
          exact
          path="/ManageInterface/SubmissionsPage"
          render={() =>
            isLogin &&
            (accountType === "admin") | (accountType === "manager") ? (
              <SubmissionsPage access_token={access_token} />
            ) : (
              <Redirect to="/ManageInterface/ManageLogin" />
            )
          }
        />
        {/*Data Page(ManageInterface)*/}
        <Route
          exact
          path="/ManageInterface/DatasPage"
          render={() =>
            isLogin &&
            (accountType === "admin") | (accountType === "manager") ? (
              <DataPage access_token={access_token} />
            ) : (
              <Redirect to="/ManageInterface/ManageLogin" />
            )
          }
        />

        {/*Manage Page(ManageInterface)*/}
        <Route
          exact
          path="/ManageInterface/ManagePage"
          render={() =>
            isLogin &&
            (accountType === "admin") | (accountType === "manager") ? (
              <ManagePage access_token={access_token} />
            ) : (
              <Redirect to="/ManageInterface/ManageLogin" />
            )
          }
        />
        {/*Contest Page(ManageInterface)*/}
        <Route
          exact
          path="/ManageInterface/ContestPage"
          render={() =>
            isLogin &&
            (accountType === "admin") | (accountType === "manager") ? (
              <ContestPage access_token={access_token} />
            ) : (
              <Redirect to="/ManageInterface/ManageLogin" />
            )
          }
        />
        {/*Event Page(ManageInterface)*/}
        <Route
          exact
          path="/ManageInterface/EventPage/:id"
          render={(props) =>
            isLogin &&
            (accountType === "admin") | (accountType === "manager") ? (
              <EventPage
                id={props.match.params.id}
                access_token={access_token}
              />
            ) : (
              <Redirect to="/ManageInterface/ManageLogin" />
            )
          }
        />

        <Route
          exact
          path="/ManageInterface/EditDataPage/:id+"
          render={(props) =>
            isLogin &&
            (accountType === "admin") | (accountType === "manager") ? (
              <EditDataPage
                access_token={access_token}
                id={props.match.params.id}
              />
            ) : (
              <Redirect to="/ManageInterface/ManageLogin" />
            )
          }
        />
        <Route
          exact
          path="/ManageInterface/ViewDataPage"
          render={() =>
            isLogin &&
            (accountType === "admin") | (accountType === "manager") ? (
              <ViewDataPage access_token={access_token} />
            ) : (
              <Redirect to="/ManageInterface/ManageLogin" />
            )
          }
        />
        <Route
          exact
          path="/ManageInterface/MediaPage"
          render={() =>
            isLogin &&
            (accountType === "admin") | (accountType === "manager") ? (
              <MediaPage access_token={access_token} />
            ) : (
              <Redirect to="/ManageInterface/ManageLogin" />
            )
          }
        />
        <Route
          exact
          path="/ManageInterface/MenuPage"
          render={() =>
            isLogin &&
            (accountType === "admin") | (accountType === "manager") ? (
              <MenuPage access_token={access_token} />
            ) : (
              <Redirect to="/ManageInterface/ManageLogin" />
            )
          }
        />
        <Route
          exact
          path="/ManageInterface/UserPage"
          render={() =>
            isLogin &&
            (accountType === "admin") | (accountType === "manager") ? (
              <UserPage access_token={access_token} />
            ) : (
              <Redirect to="/ManageInterface/ManageLogin" />
            )
          }
        />
        <Route
          exact
          path="/ManageInterface/MapboxPage"
          render={() =>
            isLogin &&
            (accountType === "admin") | (accountType === "manager") ? (
              <MapboxPage access_token={access_token} />
            ) : (
              <Redirect to="/ManageInterface/ManageLogin" />
            )
          }
        />
        <Route
          exact
          path="/Viewoverall/:id"
          render={(props) =>
            isLogin &&
            (accountType === "admin") | (accountType === "manager") ? (
              <Viewoverall
                access_token={access_token}
                id={props.match.params.id}
              />
            ) : (
              <Redirect to="/ManageInterface/ManageLogin" />
            )
          }
        />
        <Route
          exact
          path="/ManageInterface/ManageLogin"
          render={() =>
            isLogin &&
            (accountType === "admin") | (accountType === "manager") ? (
              <Redirect to="/ManageInterface/InsightsPage" />
            ) : (
              <ManageLogin handleLogin={handleLogin} />
            )
          }
        />
        <Route
          exact
          path="/ManageInterface/ManageRegister"
          render={() =>
            isLogin &&
            (accountType === "admin") | (accountType === "manager") ? (
              <Redirect to="/ManageInterface/InsightsPage" />
            ) : (
              <ManageRegister handleLogin={handleLogin} />
            )
          }
        />
      </Switch>
    </Router>
  );
};

export default App;
