import React from "react";
import { BrowserRouter, Route, Link, HashRouter } from "react-router-dom";
import ViewTab from "./SubmissionsPageComponent/ViewTab.js";
import CreateTheme from "./SubmissionsPageComponent/CreateTheme.js";

import Cookies from "js-cookie";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import GridList from "@material-ui/core/GridList";
import Chip from "@material-ui/core/Chip";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import LeftAppBar from "../LeftAppBar/LeftAppBar.js";
import ManageAppBar from "../ManageAppBar/ManageAppBar.js";
import { Card, CardMedia, Divider, Hidden, TextField } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 0, 0),
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  modal: {



    overflow: 'scroll',

    //display:'block'
  },
  fab1: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fab2: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(16),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  fab3: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(28),
  },
  title: {
    fontWeight: "bold",
    fontSize: "1.2rem",
    "@media (min-width:600px)": {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.4rem",
    },
  },
  newLink: {
    fontWeight: "bold",
    textDecoration: "none",
    color: "#000000",
  },
  media: {
    height: 140,
  },
  PopperIndex: {
    zIndex: theme.zIndex.modal + 1,
  },

  seeall: {
    textDecoration: "none",
    color: "#7f7f7f",
  },
}));

const SubmissionsPage = (props) => {
  var geoData = require("../../data.json");
  var [tags, setTags] = useState([{ id: 2, name: "23", checked: false }]);
  var [categories, setCategories] = useState([
    { id: 2, name: "23", is_visible: 2, parent_id: 1 },
  ]);
  const [modalopen, setModalopen] = useState(false);
  const [categoryModal, setCategoryModal] = useState(false);
  const [tagModal, setTagModal] = useState(false);
  const [tagName, setTagName] = useState("");
  const [catName, setCatName] = useState("");
  const [catParentName, setCatParentName] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState("top");
  const [popText, setPopText] = useState("");
  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
    // setPlacement(newPlacement);
  };

  const fetchData = async () => {
    const thedata = await Promise.all([fetchTagsData(), fetchCategoriesData()]);
    console.log(thedata[0].tags);
    tags = thedata[0].tags;
    categories = thedata[1].categories;

    setTags(tags);

    setCategories(categories);

    //setTagChip(TagChip);
  };
  //run once after first render for check login
  useEffect(() => {
    fetchData();
  }, []);

  const fetchTagsData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/tags")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { tags: myJson };
        //setTagsData(temp);
      });
  };
  const fetchCategoriesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/categories")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { categories: myJson };
        //setTagsData(temp);
      });
  };

  const classes = useStyles();

  return (
    <>
      <div style={{}}>
        <ManageAppBar />
        <Grid container>
          <Grid item md={1} lg={1} sm={1}>
            <LeftAppBar />
          </Grid>
          <Grid item container md={11} sm={11} lg={11}>
            <ViewTab></ViewTab>


            <Modal
              aria-labelledby="transition-modal-title"

              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={modalopen}
              onClose={() => {
                setModalopen(false);
              }}
            >
              <CreateTheme setModalopen={() => { setModalopen(false); }}></CreateTheme>
            </Modal>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default SubmissionsPage;
