import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Paper from "@material-ui/core/Paper";
import {
  HashRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";
import Cookies from "js-cookie";
import InputBase from "@material-ui/core/InputBase";
import AppBar from "@material-ui/core/AppBar";
import SearchIcon from "@material-ui/icons/Search";
import Register from "../../RegisterPage";
import Login from "../../LoginPage";
import NewDataPage from "../../NewDataPage";
import _ from "underscore";
import MapPage from "../Map/MapPage";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Botton from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles, fade } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import DrawerAppBar from "./Drawer/DrawerAppBar";
import LoginDrawerBar from "./Drawer/LoginDrawerBar";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import { Modal, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { BorderBottom, Height } from "@material-ui/icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: "10px",
  },
  appbar: {
    /*[theme.breakpoints.up("sm")]: */
    /*width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,*/
    paddingTop: "10px",
    paddingBottom: "10px",
    width: "100%",
    background: "#ffffff",

    top: 0,
    //left: "2.5%",

    //borderColor: 'text.primary',
    //borders:"#ffffff 5px solid",
    boxShadow: "rgb(0 0 0 / 40%) 0 0 10px 0",

    /*[theme.breakpoints.down("sm")]: {
      background: "ffffffff",
      width: "100%",
    },*/
  },
  appbarbox: {
    //margin:theme.spacing(10,10,10,10),
  },
  appbarbotton: {
    textTransform: "none",
    whiteSpace: "nowrap",
    fontSize: "18px",
    fontWeight: "bold",
  },
  appbarfirstbutton: {
    textTransform: "none",
    fontSize: "18px",
    whiteSpace: "nowrap",
    fontWeight: "bold",
  },
  loginbutton: {
    //margin: theme.spacing(1),
    backgroundColor: "#ffffff",
    color: "#000000",
    borderRadius: "0% 0% 0% 0%",
    fontSize: "16px",
    paddingBottom: "2px",
    textTransform: "none",
    fontWeight: "800",
    borderLeft: "2px solid #000000",
    borderBottom: "2px solid #000000",
    borderTop: "2px solid #000000",
    maxHeight: "30px",
    minHeight: "30px",
    "&:hover": {
      backgroundColor: "#ffffff",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
  },
  searchbutton: {
    [theme.breakpoints.down("md")]: {
      // flexGrow: 1,
    },
  },
  joinbutton: {
    color: "#ffffff",
    fontSize: "16px",
    paddingBottom: "2px",
    backgroundColor: "#000000",
    boxShadow: "none",
    textTransform: "none",
    fontWeight: "800",
    borderRight: "2px solid #000000",
    borderBottom: "2px solid #000000",
    borderTop: "2px solid #000000",
    borderRadius: "0% 0% 0% 0%",
    maxHeight: "30px",

    minHeight: "30px",

    "&:hover": {
      backgroundColor: "#000000",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    //backgroundColor: '#000000',
    color: "#000000",
  },
  title: {},
  subtitle: {},
  buttongroup: {},
  buttongroupMargin: {
    [theme.breakpoints.down("sm")]: {
      flexGrow: 0,
    },
  },
  toolbar: {
    padding: "0 2.5%  0 2.5%",
  },
  seacrhfield: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 140,
    height: 35,
    marginLeft: "15px",
    borderRadius: "20px 20px 20px 20px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  //to fix appbar
  offset: theme.mixins.toolbar,
}));
const FrontAppBar = (props) => {
  const [themeAnchorEl, setThemeAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setThemeAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setThemeAnchorEl(null);
  };
  const matches = useMediaQuery("(min-width:1565px)");
  //const [isLogin, setIslogin] = useState(false);
  const [activeItem, setActiveItem] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [searchModal, setSearchModal] = useState(false);
  //const [username, setUsername] = useState("");
  const [drawerState, setDrawerState] = useState(false);
  const [loginDrawerState, setLoginDrawerState] = useState(false);
  const [article, setArticle] = useState([]);
  let [isLogin, setIslogin] = useState(undefined);

  let [access_token, setAccess_token] = useState("");
  let [accountName, setAccountName] = useState("");
  const [type, setType] = useState([
    {
      id: 1,
      name: "history",
      thubnail: null,
      is_themes: 1,
      operator_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
      created_at: null,
      updated_at: null,
      rules: {
        id: 1,
        type_id: 1,
        need_name: 1,
        need_era: 1,
        need_summary: 1,
        need_url: 0,
        need_relation: 1,
        need_tag: 1,
        need_recommend: 0,
        need_visible: 0,
        need_content: 1,
      },
    },
  ]);
  const classes = useStyles();
  //run once after first render for check login
  useEffect(() => {
    document.title = `大稻埕資訊平台`;
    const fetchData = async () => {
      const thedata = await Promise.all([
        //fetchTagsData(),
        //fetchCategoriesData(),
        fetchThemesData(),
        //fetchArticlesData(),
      ]);
      //console.log(thedata[0].tags);
      // tags = thedata[0].tags;
      // categories = thedata[1].categories;
      // types = thedata[2].types;
      //setArticle(thedata[3].articles);
      setType(thedata[0].types);
      //setTagChip(TagChip);
    };

    fetchData();
  }, []);
  useEffect(() => {
    //document.title = "大稻埕平台";
    access_token = Cookies.get("access_token");
    if (access_token === undefined) {
      isLogin = false;
    } else {
      isLogin = true;

      accountName = Cookies.get("accountName");
    }

    //setIsLoading(false);
    setIslogin(isLogin);
    setAccess_token(access_token);
    setAccountName(accountName);
    //console.log(isLogin, access_token);
  }, []);
  //all fetch function
  const fetchArticlesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/articles")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };
  const fetchThemesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/types")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { types: myJson };
      });
  };
  const fetchTagsData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/tags")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { tags: myJson };
        //setTagsData(temp);
      });
  };
  const fetchCategoriesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/categories")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { categories: myJson };
        //setTagsData(temp);
      });
  };

  const handleLogin = (flag, username, access_token) => { };

  const handleLogout = () => { };

  return (
    <div className={classes.root}>
      <DrawerAppBar
        type={type}
        drawerState={drawerState}
        setDrawerState={setDrawerState}
      />
      <AppBar position="fixed" className={classes.appbar}>
        <Toolbar
          className={classes.toolbar}
        //style={{ borderBottom: "1px solid black" }}
        >
          {/* <Hidden mdUp> */}
          {!matches && (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                setDrawerState(true);
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          {/* </Hidden> */}
          <Link
            to="/home"
            style={{
              width: "250px",
              paddingTop: "8px",
              textDecoration: "none",
            }}
          >

            <img
              border="0"
              src={require("./assets/大稻埕logo-27.png")}
              width="100%"
            />
          </Link>

          {matches && (
            <Paper square className={classes.seacrhfield}>
              <InputBase
                className={classes.input}
                placeholder="search..."
                inputProps={{ "aria-label": "search" }}
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
              <Link to={"/SearchPage/" + "value=" + searchValue}>
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Link>
            </Paper>
          )}
          <div style={{ flexGrow: "1" }} />
          {/* <Hidden smDown> */}
          {matches && (
            <>
              <Button
                className={classes.appbarfirstbutton}
                component={Link}
                to="/Home"
              >
                首頁 Home
              </Button>
              <Button
                className={classes.appbarbotton}
                component={Link}
                to="/MapPage"
              >
                地圖探索 Map
              </Button>
              <Button
                className={classes.appbarbotton}
                component={Link}
                to="/MapPond"
              >
                地圖分享池 MapPond
              </Button>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className={classes.appbarbotton}
              >
                主題探索 Themes▾
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={themeAnchorEl}
                keepMounted
                open={Boolean(themeAnchorEl)}
                onClose={handleClose}
              >
                {type
                  .filter((item) => {
                    return item.is_themes;
                  })
                  .map((item) => (
                    <MenuItem
                      onClick={handleClose}
                      component={Link}
                      to={"/themes/" + item.id}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
              </Menu>
              <Button
                className={classes.appbarbotton}
                component={Link}
                to="/searchPage/themes=5"
              >
                稻埕故事 Stories
              </Button>
              <Button
                className={classes.appbarbotton}
                component={Link}
                style={{ marginRight: "10px" }}
                to="/About"
              >
                關於 About
              </Button>
            </>
          )}
          {/* </Hidden> */}
          <div className={classes.buttongroupMargin} />
          {!matches && isLogin &&

            <IconButton aria-label="opendrawer"
              component={isLogin ? Button : Link}
              to="/Register"
              onClick={(e) => {
                if (isLogin) {
                  setLoginDrawerState(true);
                }
              }}
            >
              {/* <ChevronLeftIcon></ChevronLeftIcon > */}
              <EmojiPeopleIcon style={{ fontSize: '24px' }}></EmojiPeopleIcon >
            </IconButton>
          }
          {matches &&
            <ButtonGroup
              className={classes.buttongroup}
              size="small"
              aria-label="small outlined button group"
            >
              <Button
                component={isLogin ? Button : Link}
                to="/Login"
                onClick={(e) => {
                  if (isLogin) {
                    const Logout = () => {
                      var status_Number = 0;
                      fetch(
                        "https://uisur.ad.ntust.edu.tw/backend/api/auth/logout",
                        {
                          method: "POST",
                          headers: {
                            Authorization: `Bearer ${access_token}`,
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({}),
                        }
                      )
                        .then(function (response) {
                          //console.log(response);
                          if (response.ok) {
                            //alert("成功登出");
                          }
                          //isLogin = false;
                          //accountName = "";
                          // access_token = "";
                          const deleteAllCookies = () => {
                            const cookies = document.cookie.split(";");

                            for (let i = 0; i < cookies.length; i++) {
                              const cookie = cookies[i];
                              const eqPos = cookie.indexOf("=");
                              const name =
                                eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                              document.cookie =
                                name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                            }
                          };
                          deleteAllCookies();
                          Cookies.remove("access_token");
                          Cookies.remove("access_token", {
                            sameSite: "Lax",
                            secure: true,
                            path: "",
                            domain: ".uisur.ad.ntust.edu.tw",
                          });
                          Cookies.remove("accountName");
                          window.location.reload();
                          return response.json();
                        })
                        .then(function (myJson) { });
                    };
                    Logout();
                  }
                }}
                className={classes.loginbutton}
              >
                {!isLogin ? "Login" : "Logout"}
              </Button>
              <Button
                component={isLogin ? Button : Link}
                to="/Register"
                onClick={(e) => {
                  if (isLogin) {
                    setLoginDrawerState(true);
                  }
                }}
                className={classes.joinbutton}
              >
                {!isLogin ? " Join" : accountName}
              </Button>
            </ButtonGroup>
          }
        </Toolbar>
        <div
          style={{
            // borderBottom: "1px solid black",
            boxShadow: "rgb(0 0 0 / 40%) 0 0 10px 0",
            width: "95%",
            marginLeft: "2.5%",
          }}
        />
      </AppBar>
      <LoginDrawerBar
        type={type}
        drawerState={loginDrawerState}
        setDrawerState={setLoginDrawerState}
        username={accountName}
      />
      <div
        className={classes.offset}
      // style={{
      //   borderBottom: "1px solid black",
      //   width: "95%",
      //   marginLeft: "2.5%",
      // }}
      />
    </div>
  );
};

export default FrontAppBar;
