import React, { Component, useEffect, useState } from "react";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import Markerer from "react-leaflet-enhanced-marker";

import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import { Hidden, List } from "@material-ui/core";
export const pointerIcon1 = new L.Icon({
  iconUrl: require("../../../assets/marker-icon.png"),
  iconRetinaUrl: require("../../../assets/marker-icon.png"),
  iconAnchor: [12.5, 55],
  popupAnchor: [12.5, -55],
  iconSize: [25, 55],
  //shadowUrl: require('./assets/marker-shadow.png'),
  //shadowSize: [68, 95],
  //shadowAnchor: [20, 92],
});
export const pointerIcon2 = new L.Icon({
  iconUrl: require("../../../assets/marker-icon-red.png"),
  iconRetinaUrl: require("../../../assets/marker-icon-red.png"),
  iconAnchor: [12.5, 55],
  popupAnchor: [12.5, -55],
  iconSize: [25, 55],
  //shadowUrl: require('./assets/marker-shadow.png'),
  //shadowSize: [68, 95],
  //shadowAnchor: [20, 92],
});
const useStyles = makeStyles((theme) => ({
  map: {
    width: "100%",
    height: "250px",
    [theme.breakpoints.down("sm")]: {
      height: "250px",
    },
  },
}));

const ControlMaker = (props) => {
  var removeflag = false;
  console.log(props.point);
  var point = props.point;
  const initMarker = (ref) => {
    if (ref) {
      ref.leafletElement.openPopup();
    }
  };
  return (
    <Marker
      position={[
        props.point.latitude !== null ? props.point.latitude : 0,
        props.point.longitude !== null ? props.point.longitude : 0,
      ]}
      icon={pointerIcon1}
      ref={initMarker}
      onMouseOver={(e) => {
        e.target.openPopup();
      }}
      onMouseOut={(e) => {
        e.target.closePopup();
      }}
    // onClick={(e) => {
    //   e.target.openPopup();
    //   document
    //     .getElementById("data" + props.listin.toString())
    //     .scrollIntoView({
    //       behavior: "smooth",
    //       block: "nearest",
    //       inline: "start",
    //     });
    //console.log(e.target.key);
    //   if (!removeflag) {
    //     document
    //       .getElementById("data" + props.listin.toString())
    //       .scrollIntoView({
    //         behavior: 'smooth', block: 'nearest', inline: 'start'
    //       });
    //     removeflag = !removeflag;
    //     e.target.setIcon(pointerIcon2);
    //   } else {
    //     removeflag = !removeflag;
    //     e.target.setIcon(pointerIcon1);
    //   }
    //   e.target.openPopup();
    //}}
    >
      <Hidden smDown>
        <Popup autoPan={false}>
          <h2> {(1 + props.listin).toString() + ". " + point.name} </h2>
        </Popup>
      </Hidden>
      <Hidden mdUp>
        <Popup autoPan={false}>
          <h4> {(1 + props.listin).toString() + ". " + point.name} </h4>
        </Popup>
      </Hidden>
    </Marker>
  );
};
class ReactComponent extends Component {
  render() {
    const markerStyle = {
      backgroundColor: "blue",
      color: "white",
      display: "flex",
      justifyContent: "center",
      width: "20px",
      height: "20px",

      borderRadius: "20px",
      alignItems: "center",
    };
    return <div style={markerStyle}>1</div>;
  }
}

const TheMap = (props) => {
  const classes = useStyles();
  const Thestate = {
    lat: 37.7749,
    lng: 122.4194,
    zoom: 13,
  };

  useEffect(() => {
    const fetchData = async () => {
      var thedata = await Promise.all([
        fetchTagsData(),
        fetchCategoriesData(),
        fetchThemesData(),
        fetchArticlesData(),
        // fetchEditData(),
      ]);

      for (let i = 0; i < thedata[3].articles.length; i++) {
        if (thedata[3].articles[i].latitude !== null) {
          //  point = [thedata[3].articles[i], ...point];
          //setPoint([thedata[3].articles[i],...point]);
        }
      }
    };
    //fetchData();
  }, []);
  //all fetch function
  const fetchArticlesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/articles")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };
  const fetchThemesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/types")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { types: myJson };
      });
  };
  const fetchTagsData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/tags")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { tags: myJson };
        //setTagsData(temp);
      });
  };
  const fetchCategoriesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/categories")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { categories: myJson };
        //setTagsData(temp);
      });
  };
  const fetchEditData = () => {
    return fetch(
      "https://uisur.ad.ntust.edu.tw/backend/api/articles/" +
      props.id.toString() +
      "?edit=true"
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { editData: myJson };
        //setTagsData(temp);
      });
  };

  var markerList = props.points.map((item, index) => (
    <ControlMaker
      point={item}
      listin={index}
      key={index.toString()}
    ></ControlMaker>
  ));

  return (
    <Map
      center={[25.0731023, 121.5154135]}
      zoom={14.2}
      className={classes.map}
      on={(e) => {
        //console.log("dsdsd");
      }}
    >
      {markerList}

      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        id="mapbox/streets-v11" //light-v9
      //attribution='Map data: © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: © <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
      />
    </Map>
  );
};

export default TheMap;
