import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import RoomIcon from '@material-ui/icons/Room';
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import Cookies from "js-cookie";
import Avatar from "@material-ui/core/Avatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import Collapse from "@material-ui/core/Collapse";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import RestoreIcon from "@material-ui/icons/Restore";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PinDropIcon from "@material-ui/icons/PinDrop";
import NoteIcon from "@material-ui/icons/Note";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import DirectionsIcon from "@material-ui/icons/Directions";
import Map from './Map';
import { BrowserRouter, Route, Link, HashRouter } from "react-router-dom";
import { Box, Grid, Tab, Tabs, Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
    height: "30px",
  },
  list: {
    width: 250,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  fullList: {
    width: "auto",
  },

  DrawerIndex: {
    zIndex: theme.zIndex.modal - 100,
  },
  ava: {
    fontSize: '10px', position: 'absolute', bottom: '5px', right: '43px',
    background: 'white'

    ,
    color: "black",
    border: '1px black solid',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px', position: 'absolute', bottom: '5px', right: '20px',
    }
  }
}));

export default function MyMap(props) {
  const classes = useStyles();
  const { drawerState, setDrawerState } = props;
  const [open, setOpen] = React.useState(false);
  const [tabValue, setTabValue] = React.useState("Note");
  let [originData, setOriginData] = React.useState([]);
  const [mymap, setMymap] = React.useState([
    {
      id: 20,
      type_id: 11,
      name: "AAA",
      places: [],
      summary: "b",
      title: "titleHERE",
      url: null,
      relations_uuid: "2283f55b-36e7-4247-95d1-e6f6cf6e21e3",
      content: "7",
      weight: 0,
      thumbnail: "thumbnails/EeCrgGDGr9SIBVi1q8B9lm9iwqbtKcBPKS63bPId.png",
      views: 0,
      is_visible: 0,
      is_drafted: 0,
      is_allowed: 0,
      creator_id: "42652e3b-b746-47c4-a3b7-a9962c0c6eac",
      checker_id: null,
      available_at: null,
      created_at: "2021-03-01T07:05:10.000000Z",
      updated_at: "2021-03-01T07:05:10.000000Z",
      start_year: 1911,
      end_year: 1912,
    },
  ]);
  const [myFavoriteMap, setMyFavoriteMap] = React.useState([
    {
      id: 20,
      type_id: 11,
      name: "AAA",
      places: [],
      summary: "b",
      title: "titleHERE",
      url: null,
      relations_uuid: "2283f55b-36e7-4247-95d1-e6f6cf6e21e3",
      content: "7",
      weight: 0,
      thumbnail: "thumbnails/EeCrgGDGr9SIBVi1q8B9lm9iwqbtKcBPKS63bPId.png",
      views: 0,
      is_visible: 0,
      is_drafted: 0,
      is_allowed: 0,
      creator_id: "42652e3b-b746-47c4-a3b7-a9962c0c6eac",
      checker_id: null,
      available_at: null,
      created_at: "2021-03-01T07:05:10.000000Z",
      updated_at: "2021-03-01T07:05:10.000000Z",
      start_year: 1911,
      end_year: 1912,
    },
  ]);
  let [access_token, setAccess_token] = React.useState("");
  let [isLogin, setIslogin] = React.useState(false);
  const DateToString = (inputDate) => {
    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    var theDate = new Date(inputDate)
    return months[theDate.getMonth()].substr(0, 3) + ' ' + theDate.getDate() + ' , ' + theDate.getFullYear()
  };
  React.useEffect(() => {
    access_token = Cookies.get("access_token");
    if (access_token === undefined) {
      isLogin = false;
    } else {
      isLogin = true;

      // accountName = Cookies.get("accountName");
    }

    const fetchData = async () => {
      var thedata = await Promise.all([fetchMyMap(), fetchMyFavoriteMap()]);
      setMymap(thedata[0].articles);
      console.log(thedata);
      originData = [...thedata[0].articles.reverse()];
      setOriginData(thedata[0].articles);
      setMyFavoriteMap(thedata[1].articles);
    };
    if (isLogin) fetchData();
    setIslogin(isLogin);
    setAccess_token(access_token);

    console.log(isLogin, access_token);
  }, []);

  const fetchMyFavoriteMap = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/favorites/maps", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };
  const fetchMyMap = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/me/maps", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };

  return (
    <>
      <Paper component="form" className={classes.root}>
        <InputBase
          className={classes.input}
          placeholder="Search Map"
          fullWidth
          onChange={(e) => {
            setMymap(
              originData.filter((item) => {
                console.log(item.name.search(e.target.value));
                return item.name.search(e.target.value) != -1;
              })
            );
          }}
          inputProps={{ "aria-label": "search google maps" }}
        />
        {/* <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton> */}
      </Paper>

      <Grid container xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography variant='h5' style={{ marginTop: 20 }}>
            我創作的:
          </Typography>
        </Grid>
        {mymap.map((item) => (
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Link to={"/Mapbox/" + item.id.toString()}>
              <Paper
                style={{ height: '250px', width: "95%", marginTop: '8px', marginRight: "16px" }}
              >
                <Map points={item.places}></Map>
              </Paper>
            </Link>
            <Paper elevation={0} style={{ position: 'relative' }}>
              <Typography style={{ display: 'inline-block' }}>{item.name + "  /  " + item.places.length.toString() + " places"} </Typography><RoomIcon fontSize='10px'></RoomIcon>
              <Typography>{"published on " + DateToString(item.created_at)} </Typography>

              <Link to={'/EditMap/' + item.id.toString()}>
                <Avatar className={classes.ava}>Edit</Avatar>
              </Link>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginTop: 20 }}>
        <Typography variant='h5'>
          我收藏的:
        </Typography>
      </Grid>
      {myFavoriteMap.map((item) => (
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Link to={"/Mapbox/" + item.id.toString()}>
            <Paper
              style={{ height: '250px', width: "95%", marginTop: '8px', marginRight: "16px" }}
            >
              <Map points={item.places}></Map>
            </Paper>
          </Link>
          <Paper elevation={0} style={{ position: 'relative' }}>
            <Typography style={{ display: 'inline-block' }}>{item.name + "  /  " + item.places.length.toString() + " places"} </Typography><RoomIcon fontSize='10px'></RoomIcon>
            <Typography>{"published on " + DateToString(item.created_at)} </Typography>


          </Paper>
        </Grid>
      ))}
    </>
  );
}
