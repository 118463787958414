import * as React from "react";
import {
  DataGrid,
  ColDef,
  ValueGetterParams,
  CellParams,
  GridApi,
} from "@material-ui/data-grid";
import Cookies from "js-cookie";
import { Button, Modal } from "@material-ui/core";
import {
  HashRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";
import CreateTheme from "./CreateTheme";
import CreateTimeLine from "./CreateTimeLine";
import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 0, 0),
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  modal: {
    overflow: "scroll",

    //display:'block'
  },
  fab1: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fab2: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(16),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  fab3: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(28),
  },
  title: {
    fontWeight: "bold",
    fontSize: "1.2rem",
    "@media (min-width:600px)": {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.4rem",
    },
  },
  newLink: {
    fontWeight: "bold",
    textDecoration: "none",
    color: "#000000",
  },
  media: {
    height: 140,
  },
  PopperIndex: {
    zIndex: theme.zIndex.modal + 1,
  },

  seeall: {
    textDecoration: "none",
    color: "#7f7f7f",
  },
}));
export default function DataTable(props) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [editid, setEditid] = React.useState(0);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Title", width: 130 },
    { field: "uploadedby", headerName: "Uploaded By", width: 170 },
    {
      field: "created_at",
      headerName: "LanchedDate",
      //type: "number",
      width: 170,
    },
    {
      field: "updated_at",
      headerName: "LastEdited",
      //type: "number",
      width: 150,
    },
    {
      field: "views",
      headerName: "Views",
      //type: "number",
      width: 90,
    },
    // {
    //   field: "fullName",
    //   headerName: "Full name",
    //   description: "This column has a value getter and is not sortable.",
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.getValue("firstName") || ""} ${
    //       params.getValue("lastName") || ""
    //     }`,
    // },
    {
      field: "edit",
      headerName: "編輯",
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const thisRow = {};

        const api = params.api;
        const fields = api
          .getAllColumns()
          .map((c) => c.field)
          .filter((c) => c !== "__check__" && !!c);

        fields.forEach((f) => {
          thisRow[f] = params.getValue(f);
        });
        return (
          <Button
            component={Link}
            to={"/ManageInterface/EditDataPage/" + thisRow.name}
          >
            編輯此頁面
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "刪除",
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const thisRow = {};

        const api = params.api;
        const fields = api
          .getAllColumns()
          .map((c) => c.field)
          .filter((c) => c !== "__check__" && !!c);

        fields.forEach((f) => {
          thisRow[f] = params.getValue(f);
        });
        return (
          <Button
            onClick={() => {
              console.log(thisRow)
              const access_token = Cookies.get("access_token");
              var check = window.confirm("確認要刪除嗎?");
              if (!check) {
                return;
              }
              //var status_Number;
              // const respond =  fetch(
              //   "https://uisur.ad.ntust.edu.tw/backend/api/articles/" + thisRow.id,
              //   {
              //     method: "DELETE",
              //     headers: {
              //       Authorization: `Bearer ${access_token}`,
              //       "Content-Type": "application/json",
              //     },
              //     body: JSON.stringify({}),
              //   }
              // )
              //   .then((res) => {
              //     console.log(res.ok);
              //     if (res.ok) {
              //       alert("刪除成功");
              //       const fetchData = async () => {
              //         const thedata = await Promise.all([fetchSightData()]);
              //         console.log(thedata[0].tags);

              //         setRows(thedata[0].tags);
              //       };
              //       fetchData();
              //     } else {
              //       alert("刪除失敗");
              //     }
              //     return res.json();
              //   })
              //   .then((result) => {
              //     //console.log(result);
              //   });

              var myHeaders = new Headers();
              myHeaders.append("Authorization", "Bearer " + access_token);

              var uploadData = new FormData();

              //uploadData.append("is_visible", JSON.stringify(1));
              //uploadData.append("is_drafted", JSON.stringify(0));

              var requestOptions = {
                method: "DELETE",
                headers: myHeaders,
                body: uploadData,
                redirect: "follow",
              };

              fetch(
                "https://uisur.ad.ntust.edu.tw/backend/api/articles/" + thisRow.name,
                requestOptions
              )
                .then((response) => {
                  if (response.ok) {
                    alert("刪除成功");
                    const fetchData = async () => {
                      const thedata = await Promise.all([fetchSightData()]);
                      console.log(thedata[0].tags);
                      window.location.reload();
                      setRows(thedata[0].tags);
                    };
                    fetchData();
                  } else {
                    alert("刪除失敗");
                  }
                  return response.text();
                })
                .then((result) => {
                  console.log(result);
                })
                .catch((error) => console.log("error", error));
            }}
          >
            刪除
          </Button>
        );
      },
    },
    {
      field: "public",
      headerName: "公開狀態",
      sortable: false,
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const thisRow = {};

        const api = params.api;
        const fields = api
          .getAllColumns()
          .map((c) => c.field)
          .filter((c) => c !== "__check__" && !!c);

        fields.forEach((f) => {
          thisRow[f] = params.getValue(f);
        });
        return (
          <Button
            onClick={() => {
              const access_token = Cookies.get("access_token");
              var check = window.confirm("確認要變更嗎?");
              if (!check) {
                return;
              }
              var now_status = rows.filter(item => { return item.name === thisRow.name })[0].is_drafted;
              console.log(now_status);
              var change_status;
              if (now_status === 1) {
                change_status = 0;
              } else {
                change_status = 1;
              }

              var myHeaders = new Headers();
              myHeaders.append("Authorization", "Bearer " + access_token);

              var uploadData = new FormData();

              //uploadData.append("is_visible", JSON.stringify(1));
              uploadData.append("is_drafted", JSON.stringify(change_status));

              var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: uploadData,
                redirect: "follow",
              };

              fetch(
                "https://uisur.ad.ntust.edu.tw/backend/api/articles/" + thisRow.name,
                requestOptions
              )
                .then((response) => {
                  if (response.ok) {
                    alert("變更成功");
                    const fetchData = async () => {
                      const thedata = await Promise.all([fetchSightData()]);
                      console.log(thedata[0].tags);
                      window.location.reload();
                      setRows(thedata[0].tags);
                    };
                    fetchData();
                  } else {
                    alert("變更失敗");
                  }
                  return response.text();
                })
                .then((result) => {
                  console.log(result);
                })
                .catch((error) => console.log("error", error));
            }}
          >

            {(rows.filter(item => { return item.name === thisRow.name })[0].is_drafted === 1) ? (
              "不公開"
            ) : (
              "公開"
            )}
          </Button>
        );
      },
    },
    {
      field: "is_allowed",
      headerName: "審核狀態",
      sortable: false,
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const thisRow = {};

        const api = params.api;
        const fields = api
          .getAllColumns()
          .map((c) => c.field)
          .filter((c) => c !== "__check__" && !!c);

        fields.forEach((f) => {
          thisRow[f] = params.getValue(f);
        });
        return (
          <Button
            onClick={() => {
              const access_token = Cookies.get("access_token");
              var check = window.confirm("確認要變更嗎?");
              if (!check) {
                return;
              }
              var now_status = rows.filter(item => { return item.name === thisRow.name })[0].is_allowed;
              console.log(now_status);
              var change_status;
              if (now_status === 1) {
                change_status = 0;
              } else {
                change_status = 1;
              }

              var myHeaders = new Headers();
              myHeaders.append("Authorization", "Bearer " + access_token);

              var uploadData = new FormData();

              //uploadData.append("is_visible", JSON.stringify(1));
              uploadData.append("is_allowed", JSON.stringify(change_status));

              var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: uploadData,
                redirect: "follow",
              };

              fetch(
                "https://uisur.ad.ntust.edu.tw/backend/api/articles/" + thisRow.name,
                requestOptions
              )
                .then((response) => {
                  if (response.ok) {
                    alert("變更成功");
                    const fetchData = async () => {
                      const thedata = await Promise.all([fetchSightData()]);
                      console.log(thedata[0].tags);
                      window.location.reload();
                      setRows(thedata[0].tags);
                    };
                    fetchData();
                  } else {
                    alert("變更失敗");
                  }
                  return response.text();
                })
                .then((result) => {
                  console.log(result);
                })
                .catch((error) => console.log("error", error));
            }}
          >

            {(thisRow.is_allowed === 0) ? (
              "尚未審核"
            ) : (
              "省核通過"
            )}
          </Button>
        );
      },
    },
    {
      field: "view_web",
      headerName: "瀏覽頁面",
      sortable: false,
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const thisRow = {};

        const api = params.api;
        const fields = api
          .getAllColumns()
          .map((c) => c.field)
          .filter((c) => c !== "__check__" && !!c);

        fields.forEach((f) => {
          thisRow[f] = params.getValue(f);
        });
        return (
          <Link style={{ textDecoration: "none", color: "black" }} to={"/Article/" + thisRow.name}>
            <Button >
              瀏覽
            </Button>
          </Link>
        );
      },
    },
  ];
  const [rows, setRows] = React.useState([
    {
      id: 1,
      lastName: "Snow",
      firstName: "Jon",
      age: 35,
      editor: "dsada",
    },
    { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
    { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
    { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
    { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
    { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
    { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
    { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
    { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
  ]);
  React.useEffect(() => {
    const fetchData = async () => {
      var thedata = await Promise.all([fetchSightData()]);
      console.log(thedata[0].tags);
      thedata[0].tags = thedata[0].tags.map((tag) => {
        tag.uploadedby = tag.creator.account;
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        const cd = new Date(tag.created_at);
        const ud = new Date(tag.updated_at);
        tag.created_at =
          months[cd.getMonth()] + " " + cd.getDate() + " , " + cd.getFullYear();
        tag.updated_at =
          months[ud.getMonth()] + " " + ud.getDate() + " , " + ud.getFullYear();
        return tag;
      });
      if (props.typeID === "2") {
        thedata[0].tags = thedata[0].tags.filter(
          (tag) => tag.is_allowed === 1
        );
      }
      if (props.typeID === "1") {
        thedata[0].tags = thedata[0].tags.filter(
          (tag) => tag.is_allowed === 0
        );
      }
      if (props.typeID === "0") {

      }
      console.log(props.typeID);
      setRows(thedata[0].tags);
    };
    fetchData();
  }, []);
  const fetchSightData = () => {
    const access_token = Cookies.get("access_token");
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/articles", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        console.log(myJson);
        return { tags: myJson };
        //setTagsData(temp);
      });
  };
  return (
    <div style={{ height: 650, width: "100%" }}>
      <DataGrid rows={rows} columns={columns} rowsPerPageOptions={[10, 20, 50]} pageSize={10} pagination />
      <Modal
        className={classes.modal}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <CreateTheme
          editMode={true}
          id={editid}
          setModalopen={() => {
            setOpen(false);
          }}
        />
      </Modal>
    </div>
  );
}
