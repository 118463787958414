import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Cookies from "js-cookie";
import _ from "underscore";
import "./LoginPage.css";
class Register extends React.Component {
  state = {};
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    var that = this;
    //event.preventDefault();
    if (this.state && this.state.username && this.state.password) {
      var status_Number = 0;
      fetch("http://uisur.ad.ntust.edu.tw:8000/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          account: this.state.username,
          password: this.state.password,
        }),
      })
        .then(function (response) {
          //console.log(response);
          status_Number = response.status;
          return response.json();
        })
        .then(function (myJson) {
          if (status_Number != 401) {
            //console.log(myJson);
            //console.log(myJson.access_token);
            that.props.handleLogin(
              true,
              that.state.username,
              myJson.access_token
            );
          } else {
            alert("登入失敗 請檢查帳號與密碼是否正確");
          }
        });
    }
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    var that = this;
    return (
      <div>
        <div className="top"></div>

        <form onSubmit={this.handleSubmit} className="loginFrom">
          <React.Fragment>
            <Grid item xs={12} sm={6}>
              <TextField
                id="username"
                label="username"
                value={this.state.username}
                margin="normal"
                onChange={this.handleChange("username")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="password"
                label="password"
                value={this.state.password}
                type="password"
                margin="normal"
                onChange={this.handleChange("password")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleSubmit}
              >
                登入
              </Button>
            </Grid>
          </React.Fragment>
        </form>
      </div>
    );
  }
}

export default Register;
