import React, { Component, useEffect, useState } from "react";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

import {
    createMuiTheme,
    withStyles,
    makeStyles,
    ThemeProvider,
} from "@material-ui/core/styles";
import { Hidden, List } from "@material-ui/core";






//const { Map: LeafletMap, TileLayer, Marker, Popup } = ReactLeaflet
export const pointerIcon1 = new L.Icon({
    iconUrl: require("../../../assets/marker-icon.png"),
    iconRetinaUrl: require("../../../assets/marker-icon.png"),
    iconAnchor: [12.5, 55],
    popupAnchor: [12.5, -55],
    iconSize: [25, 55],
    //shadowUrl: require('./assets/marker-shadow.png'),
    //shadowSize: [68, 95],
    //shadowAnchor: [20, 92],
});
export const pointerIcon2 = new L.Icon({
    iconUrl: require("../../../assets/marker-icon-red.png"),
    iconRetinaUrl: require("../../../assets/marker-icon-red.png"),
    iconAnchor: [12.5, 55],
    popupAnchor: [12.5, -55],
    iconSize: [25, 55],
    //shadowUrl: require('./assets/marker-shadow.png'),
    //shadowSize: [68, 95],
    //shadowAnchor: [20, 92],
});

const useStyles = makeStyles((theme) => ({
    map: {
        width: "100%",
        height: "17cm",

    },
}));

const ControlMaker = (props) => {
    var removeflag = false;
    var point = props.point;
    var [size, setSize] = useState([30, 30])
    const marker_name = '../asset/' + "1-100-" + (props.listin.toString().length == 1 ? ("0" + props.listin.toString()) : props.listin.toString()) + '.png';
    //const marker_name = '../asset/1-100-03.png'

    const markerIcon = [
        (
            new L.Icon({
                iconUrl: require('../asset/' + "1-100-" + (props.listin.toString().length == 1 ? ("0" + props.listin.toString()) : props.listin.toString()) + '.png'),
                iconRetinaUrl: require('../asset/' + "1-100-" + (props.listin.toString().length == 1 ? ("0" + props.listin.toString()) : props.listin.toString()) + '.png'),
                iconAnchor: [15, 15],
                popupAnchor: [0, -15],
                iconSize: [30, 30],
            })),
        (
            new L.Icon({
                iconUrl: require('../asset/' + "1-100-" + (props.listin.toString().length == 1 ? ("0" + props.listin.toString()) : props.listin.toString()) + '.png'),
                iconRetinaUrl: require('../asset/' + "1-100-" + (props.listin.toString().length == 1 ? ("0" + props.listin.toString()) : props.listin.toString()) + '.png'),
                iconAnchor: [22.5, 22.5],
                popupAnchor: [0, -22.5],
                iconSize: [45, 45],
            })),
        (
            new L.Icon({
                iconUrl: require('../asset/' + "1-100-" + (props.listin.toString().length == 1 ? ("0" + props.listin.toString()) : props.listin.toString()) + '.png'),
                iconRetinaUrl: require('../asset/' + "1-100-" + (props.listin.toString().length == 1 ? ("0" + props.listin.toString()) : props.listin.toString()) + '.png'),
                iconAnchor: [15, 15],
                popupAnchor: [0, -15],
                iconSize: [30, 30],
            })
        )];
    return (
        <Marker
            position={[
                point.latitude === null ? 0 : point.latitude,
                point.longitude === null ? 0 : point.longitude,

            ]}
            icon={point.note === true ? markerIcon[2] : markerIcon[0]}
            onMouseOver={(e) => {
                e.target.openPopup();
            }}
            onMouseOut={(e) => {
                e.target.closePopup();
            }}
            onClick={(e) => {
                //setSize([45, 45]);
                e.target.openPopup();
                console.log(e.target);
                document
                    .getElementById("data" + props.listin.toString())
                    .scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "start",
                    });
                //console.log(e.target.key);
                //   if (!removeflag) {
                //     document
                //       .getElementById("data" + props.listin.toString())
                //       .scrollIntoView({
                //         behavior: 'smooth', block: 'nearest', inline: 'start'
                //       });
                //     removeflag = !removeflag;
                //     e.target.setIcon(pointerIcon2);
                //   } else {
                //     removeflag = !removeflag;
                //     e.target.setIcon(pointerIcon1);
                //   }
                //   e.target.openPopup();
            }}
        >
            <Hidden smDown>
                <Popup>
                    <h2> {point.name} </h2>
                </Popup>
            </Hidden>
            <Hidden mdUp>
                <Popup>
                    <h4> {point.name} </h4>
                </Popup>
            </Hidden>
        </Marker>
    );
};
const TheMap = (props) => {
    const classes = useStyles();
    const Thestate = {
        lat: 37.7749,
        lng: -122.4194,
        zoom: 13,
    };
    const point = props.point;
    //all fetch function
    const fetchArticlesData = () => {
        return fetch("https://uisur.ad.ntust.edu.tw/backend/api/articles")
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                return { articles: myJson };
                //setTagsData(temp);
            });
    };
    const fetchThemesData = () => {
        return fetch("https://uisur.ad.ntust.edu.tw/backend/api/types")
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                return { types: myJson };
            });
    };
    const fetchTagsData = () => {
        return fetch("https://uisur.ad.ntust.edu.tw/backend/api/tags")
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                return { tags: myJson };
                //setTagsData(temp);
            });
    };
    const fetchCategoriesData = () => {
        return fetch("https://uisur.ad.ntust.edu.tw/backend/api/categories")
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                return { categories: myJson };
                //setTagsData(temp);
            });
    };
    const fetchEditData = () => {
        return fetch(
            "https://uisur.ad.ntust.edu.tw/backend/api/articles/" + props.id.toString()
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                return { editData: myJson };
                //setTagsData(temp);
            });
    };

    var i = 0;

    return (
        <Map
            center={[25.0596137, 121.5126518]}
            zoom={16.3}
            className={classes.map}
            on={(e) => {
                //console.log("dsdsd");
            }}
        >
            {point.map((item, index) => (
                <ControlMaker point={item} listin={index + 1} key={(index).toString()}></ControlMaker>
            ))}

            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                id="mapbox/streets-v11" //light-v9
                attribution='Map data: © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: © <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
            />
        </Map>
    );
};

export default TheMap;
