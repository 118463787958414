import React, { Suspense, SuspenseList, useReducer } from "react";
import { BrowserRouter, Route, Link, HashRouter } from "react-router-dom";

import {
  createMuiTheme,
  withStyles,
  makeStyles,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import GridList from "@material-ui/core/GridList";
import SettingsIcon from "@material-ui/icons/Settings";
import Chip from "@material-ui/core/Chip";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import FormGroup from "@material-ui/core/FormGroup";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import FrontAppBar from "../FrontAppBar/FrontAppBar.js";
import { Divider, Hidden, Modal, Paper, TextField } from "@material-ui/core";
import { set } from "js-cookie";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import queryString from "query-string";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 0, 0),
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  root1: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 10),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  marginTheTop: {
    marginTop: "20px",
  },
  cardbutton: {
    marginLeft: "auto",
  },
  firstaccordion: {
    margin: theme.spacing(1, 0, 0, 0),
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  dividercolor: {
    backgroundColor: "#424242",

    width: "600 px",
    height: "600 px",
  },
  shareButton: {
    margin: theme.spacing(0, 0, 2, 2),
    color: "#ffffff",
    backgroundColor: "#424242",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#000000",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
  noteButton: {
    margin: theme.spacing(0, 0, 2, 0),
    color: "#ffffff",
    backgroundColor: "#424242",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#000000",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
  gridFirst: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  phoneGridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    boxShadow: 1,
    //height: "20vh",
    width: "100vw",
    //padding: theme.spacing(3)
  },
  gridList: {
    //width: 500,
    boxShadow: 1,
    height: "80vh",
  },
  thepaper: {
    width: "400px",
    height: "420",
    padding: theme.spacing(0, 5, 0),
  },
  imgFormat: {
    margin: theme.spacing(1, 1, 0, 0),
    width: "100%",
  },
  chipitem: {
    color: "#ffffff",
    backgroundColor: "#424242",
    margin: theme.spacing(1, 1, 0, 0),
  },
  registerButton: {
    color: "#1a73e8",
  },
  loginbutton: {
    //margin: theme.spacing(1),
    backgroundColor: "#1a73e8",
    color: "#ffffff",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1a73e8",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
  },
  showAllPlacebutton: {
    width: "100%",
    color: "#ffffff",
    backgroundColor: "#424242",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#000000",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
let textTheme = createMuiTheme();
textTheme = responsiveFontSizes(textTheme);

//MapPage
const SearchPage = (props) => {
  const [article, setArticle] = useState([
    {
      is_themes: 0,
      content: [{ content: "<p></p>", startYear: "", endYear: "", title: "" }],
      name: "dsadas66666",
      thumbnail: "",
      summary: "",
    },
  ]);
  const [map, setMap] = useState([
    {
      id: 0,
      summary: "",
      places: [],
      name: "loading",
      tags: [{ id: 0, name: "loading" }],
    },
  ]);
  const [open, setOpen] = useState(false);
  var [cat, setCat] = useState([
    {
      id: 0,
      name: "loading",
      parent_id: null,
    },
  ]);
  var [tag, setTag] = useState([
    {
      id: 1,
      name: "loading",
      is_visible: 1,
    },
  ]);
  const [theme, setTheme] = useState([
    {
      id: 5,
      name: "stories",
    },
  ]);
  const [topfive, setTopfive] = useState([
    { id: 1, keyword: "loading", times: 5 },
  ]);
  const type_array = [2, 3, 4, 5, 6, 7, 19, 26, 112, 113];
  var [access_token, setAccess_token] = useState("");
  var [searchString, setSearchString] = useState("");
  var [refrash, SetRefrash] = useState(false);
  const [theAlert, setTheAlert] = useState({ text: "", severity: "error" });
  const classes = useStyles();
  const [MapSelect, setMapSelect] = useState(0);
  const [ArticleSelect, setArticleSelect] = useState(0);
  const [settingModal, setSettingModal] = useState(false);
  const [justTrigger, setjustTrigger] = useState(0);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const handleOnClick = () => {
    //SetRefrash(true);
  };
  useEffect(() => {
    submitSearch();
  }, [justTrigger]);

  useEffect(() => {
    if (refrash) {
      window.location.reload();
    } else {
      SetRefrash(true);
    }

    const fetchData = async () => {
      var thedata = await Promise.all([
        fetchTagsData(),
        fetchCategoriesData(),
        fetchThemesData(),
        fetchArticlesData(),

        fetchMap(), //fetchEditData(),
        fetchTopfive(),
      ]);
      console.log(thedata);
      setTopfive([...thedata[5].types]);

      thedata[1].categories = thedata[1].categories
        .filter((x) => {
          return x.parent_id != null;
        })
        .map((item) => {
          item.check = false;
          return item;
        });

      thedata[0].tags = thedata[0].tags.map((item) => {
        item.check = false;
        return item;
      });

      thedata[2].types = thedata[2].types.map((item) => {
        item.check = false;
        return item;
      });

      //thedata[2].types.content = JSON.parse(thedata[2].types.content);
      console.log(thedata[2].types);
      setArticle([...thedata[3].articles]);
      setMap(thedata[4].tags);

      console.log(thedata[2].types);
      if (props.hasvalue === 1) {
        console.log(queryString.parse(props.id));
        let para = new URLSearchParams(props.id);
        para = Object.fromEntries(para);
        console.log(para);
        // lot of check the search value
        if (para.hasOwnProperty("value")) {
          setSearchString(para["value"]);
        }
        if (para.hasOwnProperty("themes")) {
          para["themes"].split(",").forEach((item) => {
            thedata[2].types.find(
              (th_item) => th_item["id"].toString() === item
            ).check = true;
          });
        }
        if (para.hasOwnProperty("tags")) {
          para["tags"].split(",").forEach((item) => {
            thedata[0].tags.find(
              (th_item) => th_item["name"].toString() === item
            ).check = true;
          });
        }
        if (para.hasOwnProperty("cats")) {
          para["cats"].split(",").forEach((item) => {
            thedata[1].categories.find(
              (th_item) => th_item["id"].toString() === item
            ).check = true;
          });
        }
        //
      }
      setTheme(thedata[2].types);
      setTag(thedata[0].tags);
      setCat(thedata[1].categories);
      setjustTrigger(1);
    };
    fetchData();
  }, [props.id]);

  const submitSearch = (inputTag = "") => {
    console.log(ArticleSelect);
    var articlefetch = "https://uisur.ad.ntust.edu.tw/backend/api/articles";
    var mapfetch = "https://uisur.ad.ntust.edu.tw/backend/api/maps";
    var searchCats = cat
      .filter((item) => {
        return item.check === true;
      })
      .map((item) => {
        return item.id;
      })
      .join();

    var searchTag = tag
      .filter((item) => {
        return item.check === true;
      })
      .map((item) => {
        return item.name;
      })
      .join();

    var searchTheme = theme
      .filter((item) => {
        return item.check === true;
      })
      .map((item) => {
        return item.id;
      })
      .join();

    console.log(searchTag === "");
    //var articlefetch = "https://uisur.ad.ntust.edu.tw/backend/api/articles" + (searchString === '' ? "" : ("string=" + searchString))
    if (ArticleSelect === MapSelect) {
      fetch(
        "https://uisur.ad.ntust.edu.tw/backend/api/articles?" +
          "string=" +
          searchString +
          searchTag
            .split(",")
            .map((e) => {
              if (e === "") {
                return "";
              }
              return "&tags[]=" + e;
            })
            .join() +
          "&themes=" +
          searchTheme +
          "&categories=" +
          searchCats,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (myJson) {
          setArticle([...myJson]);
        })
        .catch((e) => {
          console.log(e, "error");
        });
      fetch(
        "https://uisur.ad.ntust.edu.tw/backend/api/maps?" +
          "&tags=" +
          searchTag +
          "&theme=" +
          searchTheme,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (myJson) {
          setMap([...myJson]);
          //setTagsData(temp);
        });
    } else if (ArticleSelect === 0) {
      setMap([]);
      fetch(
        "https://uisur.ad.ntust.edu.tw/backend/api/articles?" +
          "string=" +
          searchString +
          searchTag
            .split(",")
            .map((e) => {
              if (e === "") {
                return "";
              }
              return "&tags[]=" + e;
            })
            .join() +
          "&theme=" +
          searchTheme +
          "&categories=" +
          searchCats,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (myJson) {
          setArticle([...myJson]);
          //setTagsData(temp);
        });
    } else {
      setArticle([]);
      fetch(
        "https://uisur.ad.ntust.edu.tw/backend/api/maps?" +
          "&tags=" +
          searchTag +
          "&theme=" +
          searchTheme,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (myJson) {
          setMap([...myJson]);
          //setTagsData(temp);
        });
    }
  };

  //all fetch function
  const fetchArticlesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/articles")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };
  const fetchTopfive = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/hot-keywords")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { types: myJson };
      });
  };
  const fetchThemesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/types")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { types: myJson };
      });
  };
  const fetchTagsData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/tags")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { tags: myJson };
        //setTagsData(temp);
      });
  };
  const fetchMap = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/maps")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { tags: myJson };
        //setTagsData(temp);
      });
  };
  const fetchCategoriesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/categories")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { categories: myJson };
        //setTagsData(temp);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ThemeProvider theme={textTheme}>
        <FrontAppBar />
        <div>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={theAlert.severity}>
              {theAlert.text}
            </Alert>
          </Snackbar>
          <Modal
            className={classes.modal}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            onClose={() => {
              setSettingModal(false);
            }}
            open={settingModal}
          >
            <Paper className={classes.paper}>
              <h2 id="transition-modal-title">請選擇主題</h2>
              <Divider />
              {theme
                .filter((item) => {
                  return (
                    -1 !=
                    type_array.findIndex((i) => {
                      return i === item.id;
                    })
                  );
                })
                .map((item) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onClick={(e) => {
                          if (item.check === false) {
                            item.check = true;
                            setTheme([...theme]);
                          } else {
                            item.check = false;
                            setTheme([...theme]);
                          }
                        }}
                        checked={item.check === true}
                      />
                    }
                    label={item.name}
                  />
                ))}
              <Divider />
              {/* <h2 id="transition-modal-title">請選擇標籤</h2>
              <Divider />
              {tag.map((item) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      onClick={(e) => {
                        if (item.check === false) {
                          item.check = true;
                          setTag([...tag]);
                        } else {
                          item.check = false;
                          setTag([...tag]);
                        }
                      }}
                      //name={item.name}
                      checked={item.check === true}
                    />
                  }
                  label={item.name}
                />
              ))} */}
              <Divider />
              {/* <h2 id="transition-modal-title">請選擇分類</h2>
              <Divider />
              {cat.map((item) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      onClick={(e) => {
                        if (item.check === false) {
                          item.check = true;
                          setCat([...cat]);
                        } else {
                          item.check = false;
                          setCat([...cat]);
                        }
                      }}
                     
                      checked={item.check === true}
                    />
                  }
                  label={item.name}
                />
              ))}
              <Divider /> */}
            </Paper>
          </Modal>
          <Grid
            container
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.root}
          >
            <Grid container item xl={2} lg={2} md={2} sm={1} xs={1}></Grid>
            <Grid container item xl={8} lg={8} md={8} sm={10} xs={10}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Paper
                  square={false}
                  variant="outlined"
                  elevation={5}
                  className={classes.root1}
                >
                  <IconButton
                    onClick={() => {
                      setSettingModal(true);
                    }}
                    className={classes.iconButton}
                    aria-label="menu"
                  >
                    <SettingsIcon />
                  </IconButton>
                  <Divider
                    className={classes.divider}
                    orientation="vertical"
                  ></Divider>
                  <InputBase
                    className={classes.input}
                    onChange={(e) => {
                      setSearchString(e.target.value);
                    }}
                    value={searchString}
                    placeholder="Search "
                    inputProps={{ "aria-label": "search " }}
                  />
                  <IconButton
                    onClick={submitSearch}
                    className={classes.iconButton}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                {topfive.map((item) => (
                  <Chip
                    onClick={() => {
                      searchString = item.keyword;
                      setSearchString(item.keyword);
                    }}
                    style={{ marginTop: 8, marginLeft: 2, marginRight: 2 }}
                    label={item.keyword}
                  ></Chip>
                ))}
              </Grid>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      onClick={(e) => {
                        if (ArticleSelect === 0) {
                          setArticleSelect(1);
                        } else {
                          setArticleSelect(0);
                        }
                      }}
                      name="checkedB"
                    />
                  }
                  label="Map"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="checkedC"
                      onClick={(e) => {
                        if (MapSelect === 0) {
                          setMapSelect(1);
                        } else {
                          setMapSelect(0);
                        }
                      }}
                    />
                  }
                  label="Article"
                />
              </FormGroup>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}></Grid>

              {article.length != 0 &&
                article.map((item) => (
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                    style={{ padding: 20 }}
                    spacing={2}
                  >
                    {/* <img src={"https://uisur.ad.ntust.edu.tw/backend/storage/" + item.thumbnail} height={300} width={"100%"} /> */}
                    <Link to={"/Article/" + item.name}>
                      <LazyLoadImage
                        alt={"image.alt"}
                        effect="opacity"
                        placeholder={
                          <Paper
                            style={{ background: "grey", color: "grey" }}
                          ></Paper>
                        }
                        height={300}
                        width={"100%"}
                        src={
                          "https://uisur.ad.ntust.edu.tw/backend/storage/" +
                          item.thumbnail
                        }
                      />
                    </Link>
                    {/* <LazyImage src={"https://uisur.ad.ntust.edu.tw/backend/storage/" + item.thumbnail}></LazyImage> */}
                    <Typography variant="subtitle2" style={{ color: "grey" }}>
                      article
                    </Typography>
                    <Link
                      to={"/Article/" + item.name}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <Typography variant="h5" style={{ background: "000000" }}>
                        {item.name}
                      </Typography>
                    </Link>
                  </Grid>
                ))}

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}></Grid>
              {/* {article.map(item => ())} */}

              {map.length != 0 &&
                map.map((item) => (
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Paper
                      style={{ background: "#f7f6f5", paddingTop: 16 }}
                      elevation={0}
                    >
                      <Link
                        onMouseOut={(e) => {
                          e.target.style.textDecoration = "none";
                        }}
                        onMouseOver={(e) => {
                          e.target.style.textDecoration = "underline";
                        }}
                        style={{ textDecoration: "none", color: "black" }}
                        to={"/Mapbox/" + item.id}
                      >
                        <Typography variant="h5">
                          {"[推薦地圖]" + item.name}
                        </Typography>
                      </Link>
                      <Typography variant="body1">{item.summary}</Typography>
                    </Paper>
                  </Grid>
                ))}
            </Grid>
            <Grid container item xl={2} lg={2} md={2} sm={1} xs={1}></Grid>
          </Grid>
        </div>
      </ThemeProvider>
    </>
  );
};

export default SearchPage;
