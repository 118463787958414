import React, { Component, useEffect, useState } from "react";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import Markerer from "react-leaflet-enhanced-marker";

import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import { Hidden, List } from "@material-ui/core";
export const pointerIcon1 = new L.Icon({
  iconUrl: require("../../../assets/marker-icon.png"),
  iconRetinaUrl: require("../../../assets/marker-icon.png"),
  iconAnchor: [12.5, 55],
  popupAnchor: [12.5, -55],
  iconSize: [25, 55],
  //shadowUrl: require('./assets/marker-shadow.png'),
  //shadowSize: [68, 95],
  //shadowAnchor: [20, 92],
});
export const pointerIcon2 = new L.Icon({
  iconUrl: require("../../../assets/marker-icon-red.png"),
  iconRetinaUrl: require("../../../assets/marker-icon-red.png"),
  iconAnchor: [12.5, 55],
  popupAnchor: [12.5, -55],
  iconSize: [25, 55],
  //shadowUrl: require('./assets/marker-shadow.png'),
  //shadowSize: [68, 95],
  //shadowAnchor: [20, 92],
});
const useStyles = makeStyles((theme) => ({
  map: {
    width: "100%",
    height: "1000px",
  },
}));

const ControlMaker = (props) => {
  var removeflag = false;
  console.log(props.point);
  var point = props.point;

  const get_img_url = () => {
    const number_text = ["1", "10", "100", "1000"];
    const color_text = ["BL", "P", "R", "OR", "Y", "G"];
    var number_len;
    if (props.times.toString().length > 4) {
      number_len = 4 - 1;
    } else {
      number_len = props.times.toString().length - 1;
    }
    if (point["map_filter_type"] === null) {
      return "BL" + "-" + number_text[number_len] + ".png";
    }
    console.log(point, "this point");
    return (
      color_text[point["map_filter_type"] - 1] +
      "-" +
      number_text[number_len] +
      ".png"
    );
  };

  const markerIcon = [
    new L.Icon({
      iconUrl: require("../../../assets/map_filter_dot/" + get_img_url()),
      iconRetinaUrl: require("../../../assets/map_filter_dot/" + get_img_url()),
      iconAnchor: [15, 15],
      popupAnchor: [0, -15],
      iconSize: [30, 30],
    }),
  ];

  return (
    <Marker
      position={[
        props.point.latitude !== null ? props.point.latitude : 0,
        props.point.longitude !== null ? props.point.longitude : 0,
      ]}
      icon={point["map_filter_type"] === null ? pointerIcon1 : markerIcon[0]}
      onMouseOver={(e) => {
        e.target.openPopup();
      }}
      onMouseOut={(e) => {
        e.target.closePopup();
      }}
    // onClick={(e) => {
    //   e.target.openPopup();
    //   document
    //     .getElementById("data" + props.listin.toString())
    //     .scrollIntoView({
    //       behavior: "smooth",
    //       block: "nearest",
    //       inline: "start",
    //     });
    //console.log(e.target.key);
    //   if (!removeflag) {
    //     document
    //       .getElementById("data" + props.listin.toString())
    //       .scrollIntoView({
    //         behavior: 'smooth', block: 'nearest', inline: 'start'
    //       });
    //     removeflag = !removeflag;
    //     e.target.setIcon(pointerIcon2);
    //   } else {
    //     removeflag = !removeflag;
    //     e.target.setIcon(pointerIcon1);
    //   }
    //   e.target.openPopup();
    //}}
    >
      <Hidden smDown>
        <Popup>
          <h2> {point.name} </h2>
        </Popup>
      </Hidden>
      <Hidden mdUp>
        <Popup>
          <h4> {point.name} </h4>
        </Popup>
      </Hidden>
    </Marker>
  );
};
class ReactComponent extends Component {
  render() {
    const markerStyle = {
      backgroundColor: "blue",
      color: "white",
      display: "flex",
      justifyContent: "center",
      width: "20px",
      height: "20px",

      borderRadius: "20px",
      alignItems: "center",
    };
    return <div style={markerStyle}>1</div>;
  }
}

const TheMap = (props) => {
  const classes = useStyles();
  const Thestate = {
    lat: 37.7749,
    lng: 122.4194,
    zoom: 13,
  };

  var markerList = props.points.map((item, index) => (
    <ControlMaker point={item["article"]} times={item["times"]}></ControlMaker>
  ));

  return (
    <Map
      center={[25.0731023, 121.5154135]}
      zoom={14.2}
      className={classes.map}
      on={(e) => { }}
    >
      {markerList}

      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        id="mapbox/streets-v11"
      />
    </Map>
  );
};

export default TheMap;
