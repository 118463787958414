import React from "react";
import { BrowserRouter, Route, Link, HashRouter } from "react-router-dom";

import {
    createMuiTheme,
    withStyles,
    makeStyles,
    ThemeProvider,
} from "@material-ui/core/styles";
import Cookies from "js-cookie";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import GridList from "@material-ui/core/GridList";
import Chip from "@material-ui/core/Chip";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Typography from "@material-ui/core/Typography";

import { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import LeftAppBar from "../LeftAppBar/LeftAppBar.js";
import ManageAppBar from "../ManageAppBar/ManageAppBar.js";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(0, 0, 0, 0),
        height: "100%",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    fab1: {
        position: "absolute",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    fab2: {
        position: "absolute",
        bottom: theme.spacing(2),
        right: theme.spacing(16),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    fab3: {
        position: "absolute",
        bottom: theme.spacing(2),
        right: theme.spacing(28),
    },
    title: {
        fontWeight: "bold",
        fontSize: "1.2rem",
        "@media (min-width:600px)": {
            fontSize: "1.5rem",
        },
        [theme.breakpoints.up("md")]: {
            fontSize: "2.4rem",
        },
    },
    newLink: {
        fontWeight: "bold",
        textDecoration: "none",
        color: "#000000",
    },
    media: {
        height: 140,
    },
    PopperIndex: {
        zIndex: theme.zIndex.modal + 1,
    },

    seeall: {
        textDecoration: "none",
        color: "#7f7f7f",
    },
}));

const Viewoverall = (props) => {

    const start_date = props.id.split("&")[0];
    const end_date = props.id.split("&")[1];


    const [overall, setOverall] = useState({
        "keywords": [
            {
                "keyword": "23",
                "times": 1
            },
        ],
        "pages": [
            {
                "loggable_type": "App\\Models\\Type",
                "loggable_id": 21,
                "times": 60,
                "loggable": {
                    "id": 21,
                    "name": "home"
                }
            },
        ],
        "favorite_articles": [
            {
                "article_id": 7,
                "times": 1,
                "article": {
                    "id": 7,
                    "name": "azzza1"
                }
            }
        ],
        "favorite_maps": [
            {
                "article_id": 7,
                "times": 1,
                "article": {
                    "id": 7,
                    "name": "azzza1"
                }
            }
        ]
    });

    let [access_token, setAccess_token] = useState("");
    const [data_selete, setData_selete] = useState("搜尋關鍵字");
    //run once after first render for check login
    useEffect(() => {
        access_token = Cookies.get("access_token");
        if (access_token === undefined) {
            access_token = "";
        }
        setAccess_token(access_token);
        const fetchData = async () => {
            var thedata = await Promise.all([
                fetchRanks(),

            ]);
            for (var i = 0; i < thedata[0].editData["pages"].length; i++) {
                if (thedata[0].editData["pages"][i]["loggable"] == null) {
                    thedata[0].editData["pages"][i]["loggable"] = { "name": "" }
                }
            }
            setOverall(thedata[0].editData)

        };

        fetchData();
    }, []);

    const fetchRanks = () => {

        return fetch(
            "https://uisur.ad.ntust.edu.tw/backend/api/statistics/ranks?" + "start_date=" + start_date + "&" + "end_date=" + end_date
            , {

                method: "GET",
                headers: {
                    Authorization: `Bearer ${access_token}`,
                    "Content-Type": "application/json",
                    accept: "application/json",
                },
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                return { editData: myJson };
                //setTagsData(temp);
            });
    };
    const stringData = () => {
        return start_date.getFullYear() + '/' + (start_date.getMonth() + 1) + "/" + start_date.getDate() + " - " + end_date.getFullYear() + '-' + (end_date.getMonth() + 1) + "-" + end_date.getDate();
    }
    const classes = useStyles();

    return (
        <>
            <div >

                <ManageAppBar />
                <Grid container>
                    <Grid item md={1} lg={1} sm={1}>
                        <LeftAppBar />
                    </Grid>
                    <Grid item style={{ paddingLeft: "20px", paddingTop: "20px", paddingRight: "20px" }} container alignContent="flex-start" md={11} sm={11} lg={11}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h4" style={{ fontWeight: "bold" }}>數據排行榜</Typography>


                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Typography style={{ paddingLeft: "5px", paddingBottom: "20px" }} variant="caption">{"時間區段" + start_date + " ~ " + end_date}</Typography>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Chip
                                variant="outlined"
                                onClick={() => { setData_selete("搜尋關鍵字") }}
                                label="搜尋關鍵字"
                                style={data_selete === '搜尋關鍵字' ? { marginRight: "16px", marginLeft: "16px", color: "white", marginRight: "8px", background: "black" } : { marginRight: "16px", marginLeft: "16px", color: "black", background: "white", border: "black 1px solid" }}
                                clickable
                            />
                            <Chip
                                variant="outlined"
                                onClick={() => { setData_selete("頁面瀏覽數") }}
                                label="頁面瀏覽數"
                                style={data_selete === '頁面瀏覽數' ? { marginRight: "16px", marginLeft: "16px", color: "white", marginRight: "8px", background: "black" } : { marginRight: "16px", marginLeft: "16px", color: "black", background: "white", border: "black 1px solid" }}
                                clickable
                            />
                            <Chip
                                variant="outlined"
                                onClick={() => { setData_selete("頁面蒐藏數") }}
                                label="頁面蒐藏數"
                                style={data_selete === '頁面蒐藏數' ? { marginRight: "16px", marginLeft: "16px", color: "white", marginRight: "8px", background: "black" } : { marginRight: "16px", marginLeft: "16px", color: "black", background: "white", border: "black 1px solid" }}
                                clickable
                            />
                            <Chip
                                variant="outlined"
                                onClick={() => { setData_selete("地圖蒐藏數") }}
                                label="地圖蒐藏數"
                                style={data_selete === '地圖蒐藏數' ? { marginRight: "16px", marginLeft: "16px", color: "white", marginRight: "8px", background: "black" } : { marginRight: "16px", marginLeft: "16px", color: "black", background: "white", border: "black 1px solid" }}
                                clickable
                            />

                        </Grid>
                        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>

                            {data_selete === "搜尋關鍵字" && overall["keywords"].map(item => (<Grid item xl={3} lg={3} md={3} sm={3} xs={3} style={{ paddingTop: '8px', paddingBottom: "8px", paddingLeft: "8px", paddingRight: "8px", marginBottom: '6px' }}>
                                <Paper elevation={0} style={{ backgroundColor: '#f4f4f4', paddingTop: '8px', paddingBottom: "8px", paddingLeft: "8px", paddingRight: "8px", display: "flex", marginBottom: '6px' }}>
                                    <Typography display="inline">{item.keyword}</Typography>
                                    <div style={{ display: "inline", flex: '1' }} />
                                    <Typography display="inline">{item.times + " 次 "}</Typography>
                                </Paper>
                            </Grid>))

                            }
                            {data_selete === "頁面瀏覽數" && overall["pages"].map(item => (<Grid item xl={3} lg={3} md={3} sm={3} xs={3} style={{ paddingTop: '8px', paddingBottom: "8px", paddingLeft: "8px", paddingRight: "8px", marginBottom: '6px' }}>
                                <Paper elevation={0} style={{ backgroundColor: '#f4f4f4', paddingTop: '8px', paddingBottom: "8px", paddingLeft: "8px", paddingRight: "8px", display: "flex", marginBottom: '6px' }}>
                                    <Typography display="inline">{item["loggable"].name}</Typography>
                                    <div style={{ display: "inline", flex: '1' }} />
                                    <Typography display="inline">{item.times + " 次 "}</Typography>
                                </Paper>
                            </Grid>))}
                            {data_selete === "頁面蒐藏數" && overall["favorite_articles"].map(item => (<Grid item xl={3} lg={3} md={3} sm={3} xs={3} style={{ paddingTop: '8px', paddingBottom: "8px", paddingLeft: "8px", paddingRight: "8px", marginBottom: '6px' }}>
                                <Paper elevation={0} style={{ backgroundColor: '#f4f4f4', paddingTop: '8px', paddingBottom: "8px", paddingLeft: "8px", paddingRight: "8px", display: "flex", marginBottom: '6px' }}>
                                    <Typography display="inline">{item["article"].name}</Typography>
                                    <div style={{ display: "inline", flex: '1' }} />
                                    <Typography display="inline">{item.times + " 次 "}</Typography>
                                </Paper>
                            </Grid>))}
                            {data_selete === "地圖蒐藏數" && overall["favorite_maps"].map(item => (<Grid item xl={3} lg={3} md={3} sm={3} xs={3} style={{ paddingTop: '8px', paddingBottom: "8px", paddingLeft: "8px", paddingRight: "8px", marginBottom: '6px' }}>
                                <Paper elevation={0} style={{ backgroundColor: '#f4f4f4', paddingTop: '8px', paddingBottom: "8px", paddingLeft: "8px", paddingRight: "8px", display: "flex", marginBottom: '6px' }}>
                                    <Typography display="inline">{item["article"].name}</Typography>
                                    <div style={{ display: "inline", flex: '1' }} />
                                    <Typography display="inline">{item.times + " 次 "}</Typography>
                                </Paper>
                            </Grid>))}



                        </Grid>

                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default Viewoverall;
