import React from "react";
import { BrowserRouter, Route, Link, HashRouter } from "react-router-dom";

import {
  createMuiTheme,
  withStyles,
  makeStyles,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import GridList from "@material-ui/core/GridList";
import Chip from "@material-ui/core/Chip";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import FrontAppBar from "../FrontAppBar/FrontAppBar.js";
import { Divider, Hidden, Paper, Slider, TextField } from "@material-ui/core";
import { set } from "js-cookie";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import { Input } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 0, 0),
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  marginTheTop: {
    marginTop: "20px",
  },
  cardbutton: {
    marginLeft: "auto",
  },
  firstaccordion: {
    margin: theme.spacing(1, 0, 0, 0),
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  dividercolor: {
    backgroundColor: "#424242",

    width: "600 px",
    height: "600 px",
  },
  shareButton: {
    margin: theme.spacing(0, 0, 2, 2),
    color: "#ffffff",
    backgroundColor: "#424242",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#000000",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
  noteButton: {
    margin: theme.spacing(0, 0, 2, 0),
    color: "#ffffff",
    backgroundColor: "#424242",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#000000",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
  gridFirst: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  phoneGridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    boxShadow: 1,
    //height: "20vh",
    width: "100vw",
    //padding: theme.spacing(3)
  },
  gridList: {
    //width: 500,
    boxShadow: 1,
    height: "80vh",
  },
  thepaper: {
    width: "400px",
    height: "420",
    padding: theme.spacing(0, 5, 0),
  },
  imgFormat: {
    margin: theme.spacing(1, 1, 0, 0),
    width: "100%",
  },
  chipitem: {
    color: "#ffffff",
    backgroundColor: "#424242",
    margin: theme.spacing(1, 1, 0, 0),
  },
  registerButton: {
    color: "#1a73e8",
  },
  loginbutton: {
    //margin: theme.spacing(1),
    backgroundColor: "#1a73e8",
    color: "#ffffff",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1a73e8",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
  },
  showAllPlacebutton: {
    width: "100%",
    color: "#ffffff",
    backgroundColor: "#424242",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#000000",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
}));
const marks = [
  {
    value: 0,
    label: "\n0",
  },
  {
    value: 18,
    label: "\n18",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 33,
    label: "33",
  },
  {
    value: 41,
    label: "41",
  },
  {
    value: 49,
    label: "49",
  },
  {
    value: 57,
    label: "57",
  },
  {
    value: 65,
    label: "65",
  },
  {
    value: 79,
    label: "79",
  },
  {
    value: 100,
    label: "100",
  },
];
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
let textTheme = createMuiTheme();
textTheme = responsiveFontSizes(textTheme);
//MapPage
const RegisterPage = (props) => {
  const [data, setData] = useState({
    is_themes: 0,
    content: [{ content: "<p></p>", startYear: "", endYear: "", title: "" }],
    name: "dsadas66666",
    thumbnail: "",
  });
  const [open, setOpen] = useState(false);
  var [name, setName] = useState("");
  var [account, setAccount] = useState("");
  var [password, setPassword] = useState("");
  var [confirmpassword, setConfirmPassword] = useState("");
  var [userarea, setUserarea] = useState("dadaocheng");
  var [usergender, setUsergender] = useState("male");
  var [userage, setUserage] = useState(20);
  var [access_token, setAccess_token] = useState("");
  var [email, setEmail] = useState("");
  var [refrash, SetRefrash] = useState(false);
  const [theAlert, setTheAlert] = useState({ text: "", severity: "error" });
  const classes = useStyles();
  const handleOnClick = () => {
    //SetRefrash(true);
  };
  const handleSliderChange = (event, newValue) => {
    setUserage(newValue);
  };

  const handleInputChange = (event) => {
    setUserage(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlur = () => {
    if (userage < 0) {
      setUserage(0);
    } else if (userage > 100) {
      setUserage(100);
    }
  };

  function valuetext(label) {
    return `${label}`;
  }

  function valueLabelFormat(value) {
    const a = marks.findIndex((mark) => mark.value === value);
    return a;
  }

  useEffect(() => {
    if (refrash) {
      window.location.reload();
    } else {
      SetRefrash(true);
    }

    const fetchData = async () => {
      var thedata = await Promise.all([
        fetchTagsData(),
        fetchCategoriesData(),
        fetchThemesData(),
        fetchArticlesData(),
        //fetchEditData(),
      ]);
      thedata[2].types.content = JSON.parse(thedata[2].types.content);
      console.log(thedata[2].types);
      setData(thedata[2].types);
    };
    //fetchData();
  }, [props.id]);
  //all fetch function
  const fetchArticlesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/articles")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };
  const fetchThemesData = () => {
    return fetch(
      "https://uisur.ad.ntust.edu.tw/backend/api/types/" + props.id.toString()
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { types: myJson };
      });
  };
  const fetchTagsData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/tags")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { tags: myJson };
        //setTagsData(temp);
      });
  };
  const fetchCategoriesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/categories")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { categories: myJson };
        //setTagsData(temp);
      });
  };
  const fetchEditData = () => {
    return fetch(
      "https://uisur.ad.ntust.edu.tw/backend/api/articles/" +
        props.id.toString()
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { editData: myJson };
        //setTagsData(temp);
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const fetchLogin = () => {
    return new Promise(function (resolve, reject) {
      fetch("https://uisur.ad.ntust.edu.tw/backend/api/auth/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          name: name,
          account: account,
          password: password,
          confirm_password: confirmpassword,
          gender: usergender,
          location: userarea,
          email: email,
          age:
            userage < 18
              ? "under_eighteen"
              : userage < 26
              ? "eighteen_to_twentyfive"
              : userage < 34
              ? "twentysix_to_thirdtythree"
              : userage < 42
              ? "thirdtyfour_to_fortyone"
              : userage < 50
              ? "fortytwo_to_fortynine"
              : userage < 58
              ? "fifty_to_fiftyseven"
              : userage < 66
              ? "fiftyeight_to_sixtyfive"
              : userage < 80
              ? "sixtysix_to_seventynine"
              : "above_eighty",
        }),
      })
        .then(function (response) {
          if (response.ok) {
            console.log(response);
            setTheAlert({ text: "註冊成功", severity: "success" });
          } else {
            setTheAlert({
              text: "註冊失敗，請檢查帳號及密碼",
              severity: "error",
            });
          }
          setOpen(true);
          return response.json();
        })
        .then(function (myJson) {
          console.log(myJson);
          if (myJson.access_token !== undefined) {
            access_token = myJson.access_token;
            setAccess_token(myJson.access_token);
          }
        })
        .then(() => {
          resolve("");
        });
    });
  };
  return (
    <>
      <ThemeProvider theme={textTheme}>
        <FrontAppBar />
        <div>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={theAlert.severity}>
              {theAlert.text}
            </Alert>
          </Snackbar>
          <Grid container className={classes.root}>
            <Grid xl={1} lg={1} md={1} sm={1} xs={1} item></Grid>

            <Grid item container xl={10} lg={10} md={10} sm={10} xs={10}>
              <Grid
                container
                justify="center"
                alignItems="center"
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <Paper variant="outlined" className={classes.thepaper}>
                  <Grid container justify="center" alignItems="center">
                    <Typography variant="h4" style={{ marginTop: "40px" }}>
                      註冊
                    </Typography>
                  </Grid>
                  <Grid container justify="center" alignItems="center">
                    <Typography style={{ marginTop: "10px" }} variant="body2">
                      註冊您的大稻埕導覽帳戶
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    className={classes.marginTheTop}
                    justify="center"
                    alignItems="center"
                  >
                    <TextField
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      name="name"
                      label="使用者名稱"
                    ></TextField>
                  </Grid>
                  <Grid
                    container
                    className={classes.marginTheTop}
                    justify="center"
                    alignItems="center"
                  >
                    <TextField
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        setAccount(e.target.value);
                      }}
                      name="account"
                      label="登入帳號"
                    ></TextField>
                  </Grid>
                  <Grid
                    className={classes.marginTheTop}
                    container
                    justify="center"
                    alignItems="center"
                  >
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="password"
                      type="password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      label="登入密碼"
                    ></TextField>
                  </Grid>
                  <Grid
                    className={classes.marginTheTop}
                    container
                    justify="center"
                    alignItems="center"
                  >
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="confirmpassword"
                      type="password"
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                      label="密碼確認"
                    ></TextField>
                  </Grid>
                  <Grid
                    className={classes.marginTheTop}
                    container
                    justify="center"
                    alignItems="center"
                  >
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      label="電子郵件"
                    ></TextField>
                  </Grid>
                  <Grid
                    className={classes.marginTheTop}
                    container
                    justify="center"
                    alignItems="center"
                  >
                    <Typography variant="h5">性別</Typography>
                  </Grid>
                  <Grid
                    className={classes.marginTheTop}
                    container
                    justify="center"
                    alignItems="center"
                  >
                    <Chip
                      color="primary"
                      variant={usergender === "male" ? "default" : "outlined"}
                      style={{
                        paddingLeft: 3,
                        paddingRight: 3,
                        marginLeft: 3,
                        marginRight: 3,
                      }}
                      label={"男生"}
                      onClick={() => {
                        setUsergender("male");
                      }}
                    ></Chip>
                    <Chip
                      color="primary"
                      variant={usergender === "female" ? "default" : "outlined"}
                      label={"女生"}
                      style={{
                        paddingLeft: 3,
                        paddingRight: 3,
                        marginLeft: 3,
                        marginRight: 3,
                      }}
                      onClick={() => {
                        setUsergender("female");
                      }}
                    ></Chip>
                  </Grid>
                  <Grid
                    className={classes.marginTheTop}
                    container
                    justify="center"
                    alignItems="center"
                  >
                    <Typography variant="h5">您的所在地</Typography>
                  </Grid>
                  <Grid
                    className={classes.marginTheTop}
                    container
                    justify="center"
                    alignItems="center"
                  >
                    <Chip
                      color="primary"
                      variant={
                        userarea === "dadaocheng" ? "default" : "outlined"
                      }
                      style={{
                        paddingLeft: 3,
                        paddingRight: 3,
                        marginLeft: 3,
                        marginRight: 3,
                      }}
                      label={"大稻埕"}
                      onClick={() => {
                        setUserarea("dadaocheng");
                      }}
                    ></Chip>
                    <Chip
                      color="primary"
                      variant={
                        userarea === "other_area" ? "default" : "outlined"
                      }
                      label={"其他地區(台灣)"}
                      style={{
                        paddingLeft: 3,
                        paddingRight: 3,
                        marginLeft: 3,
                        marginRight: 3,
                      }}
                      onClick={() => {
                        setUserarea("other_area");
                      }}
                    ></Chip>
                    <Chip
                      color="primary"
                      variant={
                        userarea === "other_country" ? "default" : "outlined"
                      }
                      label={"其他國家"}
                      style={{
                        paddingLeft: 3,
                        paddingRight: 3,
                        marginLeft: 3,
                        marginRight: 3,
                      }}
                      onClick={() => {
                        setUserarea("other_country");
                      }}
                    ></Chip>
                  </Grid>
                  <Grid
                    className={classes.marginTheTop}
                    container
                    justify="center"
                    alignItems="center"
                  >
                    <Typography variant="h5">年齡</Typography>
                  </Grid>
                  <Grid
                    className={classes.marginTheTop}
                    container
                    justify="center"
                    alignItems="center"
                  >
                    <Slider
                      value={typeof userage === "number" ? userage : 0}
                      //valueLabelFormat={valueLabelFormat}
                      getAriaValueText={valuetext}
                      onChange={handleSliderChange}
                      aria-labelledby="discrete-slider-restrict"
                      step={1}
                      valueLabelDisplay="on"
                      marks={marks}
                    />
                  </Grid>
                  <Grid
                    style={{
                      flex: "1",
                      marginTop: " 0 px",
                    }}
                    container
                    justify="flex-end"
                    alignItems="flex-end"
                  >
                    {" "}
                    <Input
                      className={classes.input}
                      value={userage}
                      margin="dense"
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      inputProps={{
                        step: 10,
                        min: 0,
                        max: 100,
                        type: "number",
                        "aria-labelledby": "input-slider",
                      }}
                    />
                  </Grid>

                  <Grid
                    style={{
                      flex: "1",
                      marginTop: " 40px",
                      marginBottom: "40px",
                    }}
                    container
                  >
                    <Button
                      component={Link}
                      to={"/login"}
                      className={classes.registerButton}
                    >
                      已有帳號?
                    </Button>

                    <div style={{ flexGrow: "1" }} />
                    <Button
                      className={classes.loginbutton}
                      onClick={(e) => {
                        fetchLogin().then(() => {
                          console.log(access_token);
                          if (access_token !== "") {
                            props.handleLogin(true, account, access_token);
                          }
                        });
                      }}
                    >
                      註冊
                    </Button>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}></Grid>
          </Grid>
        </div>
      </ThemeProvider>
    </>
  );
};

export default RegisterPage;
