import React from "react";
import { BrowserRouter, Route, Link, HashRouter } from "react-router-dom";
import ViewTab from "./DataPageComponent/ViewTab.js";
import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Cookies from "js-cookie";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import GridList from "@material-ui/core/GridList";
import Chip from "@material-ui/core/Chip";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import LeftAppBar from "../LeftAppBar/LeftAppBar.js";
import ManageAppBar from "../ManageAppBar/ManageAppBar.js";
import { Card, CardMedia, Divider, Hidden, TextField } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 0, 0),
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fab1: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fab2: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(16),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  fab3: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(28),
  },
  title: {
    fontWeight: "bold",
    fontSize: "1.2rem",
    "@media (min-width:600px)": {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.4rem",
    },
  },
  newLink: {
    fontWeight: "bold",
    textDecoration: "none",
    color: "#000000",
  },
  media: {
    height: 140,
  },
  PopperIndex: {
    zIndex: theme.zIndex.modal + 1,
  },

  seeall: {
    textDecoration: "none",
    color: "#7f7f7f",
  },
}));

const ViewDataPage = (props) => {
  var [tags, setTags] = useState([{ id: 2, name: "23", checked: false }]);
  var [categories, setCategories] = useState([
    { id: 2, name: "23", is_visible: 2, parent_id: 1 },
  ]);
  var [catEditModal, setCatEditModal] = useState(false);
  var [tagEditModal, setTagEditModal] = useState(false);
  var [editData, setEditData] = useState("");
  var [editTarget, setEditTarget] = useState("");
  const [categoryModal, setCategoryModal] = useState(false);
  const [tagModal, setTagModal] = useState(false);
  const [tagName, setTagName] = useState("");
  const [catName, setCatName] = useState("");
  const [catParentName, setCatParentName] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState("top");
  const [popText, setPopText] = useState("");
  const handleClick = (newPlacement) => (event) => {
    // setAnchorEl(event.currentTarget);
    // setOpen((prev) => !prev);
    // setPlacement(newPlacement);
  };

  const fetchData = async () => {
    const thedata = await Promise.all([fetchTagsData(), fetchCategoriesData()]);
    console.log(thedata[0].tags);
    tags = thedata[0].tags;
    categories = thedata[1].categories;

    setTags(tags);

    setCategories(categories);

    //setTagChip(TagChip);
  };
  //run once after first render for check login
  useEffect(() => {
    fetchData();
  }, []);

  const fetchTagsData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/tags")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { tags: myJson };
        //setTagsData(temp);
      });
  };
  const fetchCategoriesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/categories")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { categories: myJson };
        //setTagsData(temp);
      });
  };

  const classes = useStyles();

  return (
    <>
      <div style={{}}>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={tagEditModal}
          onClose={() => {
            setTagEditModal(false);
          }}
        >
          <div className={classes.paper}>
            <h2 id="transition-modal-title">要修改'{editTarget.name}'為</h2>
            <Divider />
            <TextField
              id="name"
              size="small"
              name="editData"
              variant="outlined"
              value={editData}
              onChange={(e) => {
                setEditData(e.target.value);
              }}
            ></TextField>

            <Button
              onClick={() => {
                const access_token = Cookies.get("access_token");
                //var status_Number;
                const respond = fetch(
                  "https://uisur.ad.ntust.edu.tw/backend/api/tags/" + editTarget.id,
                  {
                    method: "PATCH",
                    headers: {
                      Authorization: `Bearer ${access_token}`,
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      name: editData,
                    }),
                  }
                )
                  .then((res) => {
                    console.log(res.ok);
                    if (res.ok) {
                      alert("修改成功");
                      fetchData();
                      setTagEditModal(false);
                    } else {
                      alert("修改失敗");
                    }
                    return res.json();
                  })
                  .then((result) => {
                    //console.log(result);
                  });
              }}
            >
              修改
            </Button>
            <Button
              onClick={() => {
                setTagEditModal(false);
              }}
            >
              取消
            </Button>
          </div>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={catEditModal}
          onClose={() => {
            setCatEditModal(false);
          }}
        >
          <div className={classes.paper}>
            <h2 id="transition-modal-title">要修改'{editTarget.name}'為</h2>
            <Divider />
            {categories.map((category) => (
              <Chip
                label={category.name}
                variant={category.id === catParentName ? "default" : "outlined"}
                onClick={() => {
                  if (category.id === catParentName) setCatParentName(-999);
                  else setCatParentName(category.id);
                }}
                color="secondary"
                size="small"
                onPointerEnter={() => {
                  var addText = "";
                  if (category.parent_id)
                    addText = " parent = " + category.parent_id.toString();
                  addText = "id = " + category.id.toString() + addText;
                  setPopText(addText);
                  handleClick("top");
                  console.log(!category.parent_id, popText);
                }}
                onPointerLeave={handleClick("top")}
              />
            ))}
            <Divider />
            <TextField
              id="name"
              size="small"
              name="editData"
              variant="outlined"
              value={editData}
              onChange={(e) => {
                setEditData(e.target.value);
              }}
            ></TextField>
            <Button
              onClick={() => {
                const access_token = Cookies.get("access_token");
                //var status_Number;
                const respond = fetch(
                  "https://uisur.ad.ntust.edu.tw/backend/api/categories/" +
                  editTarget.id,
                  {
                    method: "PATCH",
                    headers: {
                      Authorization: `Bearer ${access_token}`,
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      name: editData,
                      parent:
                        catParentName === -999 ? undefined : catParentName,
                    }),
                  }
                )
                  .then((res) => {
                    console.log(res.ok);
                    if (res.ok) {
                      alert("修改成功");
                      fetchData();
                      setTagEditModal(false);
                    } else {
                      alert("修改失敗");
                    }
                    return res.json();
                  })
                  .then((result) => {
                    //console.log(result);
                  });
              }}
            >
              修改
            </Button>
            <Button
              onClick={() => {
                setCatEditModal(false);
              }}
            >
              取消
            </Button>
          </div>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={categoryModal}
          onClose={() => {
            setCategoryModal(false);
          }}
        >
          <div className={classes.paper}>
            <h2 id="transition-modal-title">請增加分類,如有父類別請選擇</h2>
            <h4 id="transition-modal-title">右鍵可修改</h4>
            <Divider />
            {categories.map((category) => (
              <Chip
                label={category.name}
                onContextMenu={(event) => {
                  event.preventDefault();
                  setEditTarget(category);
                  setEditData(category.name);
                  setCatParentName(
                    category.parent_id === null ? -999 : category.parent_id
                  );
                  setCatEditModal(true);
                }}
                variant={category.id === catParentName ? "default" : "outlined"}
                onDelete={() => {
                  const access_token = Cookies.get("access_token");
                  //var status_Number;
                  const respond = fetch(
                    "https://uisur.ad.ntust.edu.tw/backend/api/categories/" +
                    category.id,
                    {
                      method: "DELETE",
                      headers: {
                        Authorization: `Bearer ${access_token}`,
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({}),
                    }
                  )
                    .then((res) => {
                      console.log(res.ok);
                      if (res.ok) {
                        alert("刪除成功");

                        fetchData();
                      } else {
                        alert("刪除失敗");
                      }
                      return res.json();
                    })
                    .then((result) => {
                      //console.log(result);
                    });
                }}
                onClick={() => {
                  if (category.id === catParentName) setCatParentName(-999);
                  else setCatParentName(category.id);
                }}
                color="secondary"
                size="small"
                onPointerEnter={() => {
                  var addText = "";
                  if (category.parent_id)
                    addText = " parent = " + category.parent_id.toString();
                  addText = "id = " + category.id.toString() + addText;
                  setPopText(addText);
                  handleClick("top");
                  console.log(!category.parent_id, popText);
                }}
                onPointerLeave={handleClick("top")}
              />
            ))}
            <Divider />
            <Grid
              container
              item
              alignItems="center"
              wrap="nowrap"
              lg={12}
              md={12}
              sm={12}
            >
              <Typography>分類名稱</Typography>
              <TextField
                id="outlined-basic"
                size="small"
                name="catName"
                onChange={(e) => {
                  setCatName(e.target.value);
                }}
                variant="outlined"
              //value={data.Year2}
              />
              {/* <Typography>父分類</Typography>
              <TextField
                id="outlined-basic"
                size="small"
                name="catParentName"
                onChange={(e) => {
                  setCatParentName(e.target.value);
                }}
                variant="outlined"
                //value={data.Year2}
              /> */}

              <Button
                onClick={() => {
                  const access_token = Cookies.get("access_token");
                  //var status_Number;
                  const respond = fetch(
                    "https://uisur.ad.ntust.edu.tw/backend/api/categories",
                    {
                      method: "POST",
                      headers: {
                        Authorization: `Bearer ${access_token}`,
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        name: catName,
                        parent:
                          catParentName === -999 ? undefined : catParentName,
                      }),
                    }
                  )
                    .then((res) => {
                      console.log(res.ok);
                      if (res.ok) {
                        alert("新增成功");

                        fetchData();
                      } else {
                        alert("新增失敗");
                      }
                      return res.json();
                    })
                    .then((result) => {
                      //console.log(result);
                    });
                }}
              >
                添加
              </Button>
            </Grid>
          </div>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={tagModal}
          onClose={() => {
            setTagModal(false);
          }}
        >
          <div className={classes.paper}>
            <h2 id="transition-modal-title">請增加分類</h2>
            <h4 id="transition-modal-title">右鍵可修改</h4>
            <Divider />
            {tags.map((tag) => (
              <Chip
                label={tag.name}
                onContextMenu={(event) => {
                  event.preventDefault();
                  setEditTarget(tag);
                  setEditData(tag.name);
                  setTagEditModal(true);
                }}
                onDelete={() => {
                  const access_token = Cookies.get("access_token");
                  //var status_Number;
                  const respond = fetch(
                    "https://uisur.ad.ntust.edu.tw/backend/api/tags/" + tag.id,
                    {
                      method: "DELETE",
                      headers: {
                        Authorization: `Bearer ${access_token}`,
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({}),
                    }
                  )
                    .then((res) => {
                      console.log(res.ok);
                      if (res.ok) {
                        alert("刪除成功");
                        fetchData();
                      } else {
                        alert("刪除失敗");
                      }
                      return res.json();
                    })
                    .then((result) => {
                      //console.log(result);
                    });
                }}
                color="secondary"
                size="small"
                onPointerEnter={() => {
                  setPopText("id = " + tag.id.toString());
                  handleClick("top");
                }}
                onPointerLeave={handleClick("top")}
              />
            ))}
            <Divider />
            <Grid
              container
              item
              alignItems="center"
              justify="flex-end"
              wrap="nowrap"
              lg={12}
              md={12}
              sm={12}
            >
              <Typography>標籤名稱</Typography>
              <TextField
                id="outlined-basic"
                size="small"
                name="tagName"
                onChange={(e) => {
                  setTagName(e.target.value);
                }}
                variant="outlined"
              //value={data.Year2}
              />
              <Button
                onClick={() => {
                  const access_token = Cookies.get("access_token");
                  //var status_Number;
                  const respond = fetch(
                    "https://uisur.ad.ntust.edu.tw/backend/api/tags",
                    {
                      method: "POST",
                      headers: {
                        Authorization: `Bearer ${access_token}`,
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        name: tagName,
                      }),
                    }
                  )
                    .then((res) => {
                      console.log(res.ok);
                      if (res.ok) {
                        alert("新增成功");

                        fetchData();
                      } else {
                        alert("新增失敗");
                      }
                      return res.json();
                    })
                    .then((result) => {
                      //console.log(result);
                    });
                }}
              >
                添加
              </Button>
            </Grid>
          </div>
        </Modal>
        <Popper
          className={classes.PopperIndex}
          open={open}
          anchorEl={anchorEl}
          placement={placement}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <Typography className={classes.typography}>
                  {popText}
                </Typography>
              </Paper>
            </Fade>
          )}
        </Popper>
        <ManageAppBar />
        <Grid container>
          <Grid item md={1} lg={1} sm={1}>
            <LeftAppBar />
          </Grid>
          <Grid item container md={11} sm={11} lg={11}>
            <ViewTab></ViewTab>
            <Link to="/ManageInterface/DatasPage">
              <Fab
                aria-label="Add"
                variant="extended"
                className={classes.fab1}
                color="primary"
              >
                <AddIcon />
                Sight
              </Fab>
            </Link>
            <Fab
              variant="extended"
              className={classes.fab2}
              onClick={() => {
                setTagModal(true);
              }}
              color="primary"
            >
              <AddIcon />
              Tag
            </Fab>
            <Fab
              variant="extended"
              className={classes.fab3}
              onClick={() => {
                setCategoryModal(true);
              }}
              color="primary"
            >
              <AddIcon />
              Category
            </Fab>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ViewDataPage;
