import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import DataTable from "./DataTable";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ViewTab() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  var [types, setTypes] = React.useState([
    {
      id: 1,
      name: "history",
      thubnail: null,
      is_themes: 1,
      operator_id: "",
      created_at: null,
      updated_at: null,
    },
  ]);
  React.useEffect(() => {
    const fetchData = async () => {
      const thedata = await Promise.all([
        fetchContestData()
      ]);
      //console.log(thedata[0].tags);
      //tags = thedata[0].tags;
      //categories = thedata[1].categories;

      setTypes(types);

      //setTagChip(TagChip);
    };
    fetchData();
  }, []);

  const fetchContestData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/contests")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { types: myJson };
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Contests" {...a11yProps(0)} />
          {/* <Tab label="Drafts" {...a11yProps(1)} />
          <Tab label="Trash" {...a11yProps(2)} /> */}

        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <DataTable typeID={""} />
      </TabPanel>

    </div>
  );
}
