import React from "react";
import { BrowserRouter, Route, Link, HashRouter } from "react-router-dom";

import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Fade from "@material-ui/core/Fade";
import Cookies from "js-cookie";
import Modal from "@material-ui/core/Modal";
import Fab from "@material-ui/core/Fab";
import Backdrop from "@material-ui/core/Backdrop";
import { DropzoneArea } from "material-ui-dropzone";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import ManageAppBar from "../../ManageAppBar/ManageAppBar";
import LeftAppBar from "../../LeftAppBar/LeftAppBar";
import { Card, CardMedia, Divider, Hidden } from "@material-ui/core";

import Paper from "@material-ui/core/Paper";
import { set } from "js-cookie";
import ClearIcon from "@material-ui/icons/Clear";
import { grey } from "@material-ui/core/colors";
import { values } from "underscore";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 0, 0),
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  threeTitle: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    wordBreak: "keep-all",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    position: "relative",
    padding: "50px"
  },
  title: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(3),
    wordBreak: "keep-all",
  },
  textEditorGrid: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(3),
    border: "1px black solid",
  },
  newLink: {
    fontWeight: "bold",
    textDecoration: "none",
    color: "#000000",
  },
  media: {
    height: 140,
  },
  buttomMargin: {
    marginBottom: theme.spacing(1),
  },
  seeall: {
    textDecoration: "none",
    color: "#7f7f7f",
  },
  previewButton: {
    color: "#C69C6D",
    borderColor: "#C69C6D",
    marginRight: theme.spacing(1),
    //margin: theme.spacing(0, 2),
    "&:hover": {
      backgroundColor: "#ffffff",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
  saveButton: {
    background: "#C69C6D",
    color: "#ffffff",
    borderColor: "#C69C6D",
    marginRight: theme.spacing(1),
    //margin: theme.spacing(0, 2),
    "&:hover": {
      backgroundColor: "#C69C6D",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
  chooseButton: {
    background: "#4D4D4D",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    color: "#ffffff",
    borderColor: "#4D4D4D",
    "&:hover": {
      backgroundColor: "#4D4D4D",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: "#005cbf",
    },
  },
  fab1: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  publishButton: {
    background: "#4D4D4D",
    marginRight: theme.spacing(2),

    color: "#ffffff",
    borderColor: "#4D4D4D",
    "&:hover": {
      backgroundColor: "#4D4D4D",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: "#005cbf",
    },
  },
  disabled: {
    background: grey,
    color: "#333333",
  },
  LongText: {
    whiteSpace: "nowrap",
  },
  rightflex: {
    flexGrow: 1,
  },
  publishpopper: {
    zIndex: theme.zIndex.modal + 1,
  },
  TimeLine: {
    marginBottom: theme.spacing(2),
  },
}));

//MapPage
const CreateContest = (props) => {
  //All Data
  var [data, setData] = useState({

    name: "",
    start_at: "2022-01-01T00:01",
    is_visible: 0,
    contest_id: '0',


    description: '',
    end_at: '2022-01-02T00:01:00',

  });

  var [types, setTypes] = useState([]);



  const handleDataChange = (event) => {
    const target = event.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    if (value === true && target.type === "checkbox") {
      value = 1;
    } else if (value === false && target.type === "checkbox") {
      value = 0;
    }
    const name = target.name;
    if (target.type === "radio" && name != "type") {
      if (value === "true") value = true;
      else value = false;
    }

    data = { ...data, [name]: value };
    console.log(data);
    setData({ ...data });

  };

  //run once after first render for check login
  useEffect(() => {
    const fetchData = async (mode) => {
      const thedata = await Promise.all([fetchEditData()]);
      if (mode) {
        var d_index = thedata[0].editData.findIndex((item) => { return item.id === props.id })


        setData(thedata[0].editData[d_index])
      } else {
        setData({
          ...data, contest_id: props.contest_id
        })
      }

    };

    fetchData(props.editMode);



  }, []);
  //all fetch function



  const fetchEditData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/contests/events")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { editData: myJson };
        //setTagsData(temp);
      });
  };

  //submit form
  const handleSubmit = () => {
    {
      var access_token = Cookies.get("access_token");
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + access_token);
      myHeaders.append("Accept", "application/json");
      var uploadData = new FormData();
      var uploadData = new URLSearchParams();
      //dat.append('file', data.image[0]);
      //dat.append('user', 'hubot');
      uploadData.append("name", data.name);

      uploadData.append("description", data.description);
      uploadData.append("contest_id", data.contest_id);


      var requestOptions = {
        method: props.editMode === true ? 'PATCH' : "POST",
        headers: myHeaders,
        body: uploadData,
        redirect: "follow",
      };
      var fetchstatus = false;
      fetch(
        "https://uisur.ad.ntust.edu.tw/backend/api/contests/events" +
        (props.editMode === true ? "/" + props.id : ""),
        requestOptions
      )
        .then((response) => {
          if (response.ok) {
            alert(props.editMode === true ? "修改成功" : "上傳成功");
            fetchstatus = true;
            //resolve("");
          } else {
            alert(props.editMode === true ? "修改失敗" : "上傳失敗");
            fetchstatus = false;
          }
          return response.text();
        })
        .then((result) => {
          console.log(result);
          var objJsonArray = JSON.parse(result);
          var tempstring = "";
          var i = 0;
          tempstring += objJsonArray.message + "\n";
          for (var j in objJsonArray.errors) {
            console.log(j);
            i += 1;
            tempstring =
              tempstring + i.toString() + "." + objJsonArray.errors[j] + "\n";
          }
          if (fetchstatus === false) alert(tempstring);
        })
        .catch((error) => console.log("error", error));
    }

    // var status_Number;
    // console.log(JSON.stringify({
    //   type:data.type,
    //   name: data.name,
    //   summary: data.summary,
    //   content: htmlCode,
    //   categories: data.categories,
    //   tags: data.tags,
    //   start_year: data.start_year,
    //   end_year: data.end_year,
    //   recommend:data.recommend,
    //   longitude: data.longitude,
    //   latitude: data.latitude,
    //   relations:data.relations,
    //   title:data.title,
    //   weight: data.weight,
    //   url:data.url,
    //   is_visible:data.is_visible===true?1:0,
    //   is_drafted:data.is_drafted===true?1:0,
    //   available_at:data.available_at,
    //   thubnail:data.thumbnail,
    //   image:data.image,
    // }));

    //  fetch("https://uisur.ad.ntust.edu.tw/backend/api/articles", {
    //   method: "POST",
    //   headers: {
    //     Authorization: `Bearer ${props.access_token}`,
    //     "Content-Type": "multipart/form-data",
    //   },
    //   //'contentType': false, //required
    // //'processData': false, // required

    //   body: uploadData,
    // })
    //   .then(function (response) {
    //     status_Number = response.status;
    //     console.log(response);
    //     if (response.ok) {

    //       alert("新建資料成功");
    //     } else {
    //       alert("新建資料失敗");
    //     }
    //     return response.json();
    //   })
    //   .then(function (myJson) {

    //   });
  };
  const handleHtml = (props) => { };

  const classes = useStyles();

  return (
    <div style={{}}>
      {/* <ManageAppBar /> */}

      <Grid container>
        {/* <Grid item md={1} lg={1} sm={1}>
        <LeftAppBar />
      </Grid> */}
        <Grid
          item
          container
          md={12}
          sm={12}
          lg={12}
          alignContent="center"
          alignItems="center"
          justify="center"
        >
          <Paper className={classes.paper}>
            <Fab
              aria-label="Add"
              variant="extended"
              className={classes.fab1}
              color="primary"
              onClick={() => {
                props.setModalopen(false);
              }}
            >
              <ClearIcon />
            </Fab>
            <Typography variant="h5">活動名稱</Typography>
            <TextField
              id="title"
              size="small"
              name="name"
              value={data.name}
              onChange={handleDataChange}
              variant="outlined"
            />
            <Divider />
            <Typography variant="h5">活動描述</Typography>
            <TextField
              id="title"
              size="small"
              name="description"
              value={data.description}
              onChange={handleDataChange}
              variant="outlined"
            />
            <Divider />



            <Divider />
            <Grid container justify="center">
              <Button
                className={classes.saveButton}
                onClick={handleSubmit}
                justify="center"
              >
                submit
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateContest;
