import React from "react";
import { BrowserRouter, Route, Link, HashRouter } from "react-router-dom";
import Cookies, { converter } from "js-cookie";
import { DropzoneArea } from "material-ui-dropzone";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import {
  createMuiTheme,
  withStyles,
  responsiveFontSizes,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import FrontAppBar from "../FrontAppBar/FrontAppBar.js";
import { Card, CardMedia, Divider, Hidden, Modal } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MyNote from "./UserConponent/MyNote";
import AddCon from "./UserConponent/AddCon";
import MyPin from "./UserConponent/MyPin";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import MyMap from "./UserConponent/MyMap";
import TextField from "@material-ui/core/TextField";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 0, 0),
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  title: {
    fontWeight: "950",
    fontSize: "1.2rem",
    "@media (min-width:600px)": {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.8rem",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    padding: "35px",
  },
  themeImghover: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    opacity: "0",

    "&:hover": {
      backgroundColor: "#ff7348a8",
      transition: ".5s ease",
      opacity: "1",
      //borderColor: '#0062cc',
    },
  },
  themeImghovertext: {
    color: "black",
    fontSize: "30px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
}));

// let theme = createMuiTheme();
// theme = responsiveFontSizes(theme);
//MapPage
const User = (props) => {
  const [article, setArticle] = useState([{ type_id: 4 }]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const matches = useMediaQuery("(min-width:1565px)");
  // const user_info
  const [openadd, setOpenadd] = useState(false);
  const [value, setValue] = React.useState(2);
  const [select, setSelect] = React.useState("路徑地圖");
  const [modifyAvatarModal, setModifyAvatarModal] = useState(false);
  const [modifyIntroductionModal, setModifyIntroductionModal] = useState(false);
  const [modifyNameModal, setModifyNameModal] = useState(false);
  const [modifyPasswordModal, setModifyPasswordModal] = useState(false);
  const [modifyEmailModal, setModifyEmailModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [theAlert, setTheAlert] = useState({ text: "", severity: "error" });
  const [myEmail, setMyEmail] = useState("");
  let [deleteId, setDeleteId] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function copyCurrentUrl(text) {
    var dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    setTheAlert({ text: "複製成功", severity: "success" });
    setOpenAlert(true);
  }
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const [usersData, setUsersData] = React.useState({
    name: "",
    maps: [],
    articles: [],
  });
  let [needUpdata, setNeedUpdate] = useState(0);
  const [writeIntro, setWriteIntro] = React.useState(false);
  const [submitIntro, setSubmitIntro] = React.useState("");
  const [submitAvatar, setSubmitAvatar] = React.useState([]);
  const [submitPassword, setSubmitPassword] = React.useState("");
  const [submitName, setSubmitName] = React.useState("");
  const [submitNewPassword, setSubmitNewPassword] = React.useState("");
  const [submitEmail, setSubmitEmail] = useState("");
  const [submitNewComfirmPassword, setSubmitNewComfirmPassword] =
    React.useState("");
  const [deleteCheckString, setDeleteCheckString] =
    React.useState("確定要刪除XXX地圖?");
  const [deleteModal, setDeleteModal] = React.useState(false);
  const classes = useStyles();
  //run once after first render for check login
  var [access_token, setAccess_token] = useState("");

  const fetchNewUserData = async () => {
    const medata = await Promise.all([fetchMeData()]);
    setMyEmail(medata[0].articles.email === null ? "無電子郵件" : medata[0].articles.email);
    const thedata = await Promise.all([fetchUserData(medata[0].articles.uuid)]);
    console.log(thedata);
    setUsersData(thedata[0].articles);
  };
  const submitModifyData = (data) => {
    const access_token = Cookies.get("access_token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + access_token);
    myHeaders.append("Accept", "application/json");

    var uploadData = new FormData();
    uploadData.append("_method", "PATCH");
    for (var key in data) {
      uploadData.append(key, data[key]);
    }

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: uploadData,
      redirect: "follow",
    };

    fetch("https://uisur.ad.ntust.edu.tw/backend/api/me", requestOptions)
      .then((response) => {
        if (response.ok) {
          alert("變更成功");
          fetchNewUserData();
        } else {
          alert("變更失敗");
        }
        return response.text();
      })
      .then((result) => {
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    access_token = Cookies.get("access_token");
    if (access_token === undefined) {
      access_token = "";
    }
    setAccess_token(access_token);
    const fetchData = async () => {
      const medata = await Promise.all([fetchMeData()]);
      const thedata = await Promise.all([
        fetchUserData(medata[0].articles.uuid),
      ]);
      setMyEmail(medata[0].articles.email === null ? "無電子郵件" : medata[0].articles.email)
      console.log(thedata);
      setUsersData(thedata[0].articles);
    };

    const fetchSearchData = async () => {
      const thedata = await Promise.all([fetchUserData(props.uuid)]);
      setUsersData(thedata[0].articles);
    };

    if (props.searchmode === false) {
      fetchData();
    } else {
      fetchSearchData();
    }
  }, [needUpdata, props.uuid]);
  const covert_to_date_text = (date) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const ud = new Date(date);
    return (
      months[ud.getMonth()] + " " + ud.getDate() + " , " + ud.getFullYear()
    );
  };
  //all fetch function
  const fetchArticlesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/articles")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };
  const fetchMeData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/me", {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };
  const fetchUserData = (uuid) => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/users/" + uuid, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };

  return (
    <>
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleAlertClose}
          severity={theAlert.severity}
        >
          {theAlert.text}
        </MuiAlert>
      </Snackbar>
      <Modal
        className={classes.modal}
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
        aria-labelledby="simple-modal-title-delete"
        aria-describedby="simple-modal-description-delete"
      >
        <Paper className={classes.paper}>
          <Typography variant="h5">刪除確認</Typography>
          <Box style={{ height: "12px" }}></Box>
          <Typography>{deleteCheckString}</Typography>
          <Box style={{ height: "20px" }}></Box>
          <Box style={{ display: "flex" }}>
            <Box style={{ flexGrow: "1" }}></Box>
            <Button
              onClick={() => {
                const access_token = Cookies.get("access_token");

                var myHeaders = new Headers();
                myHeaders.append("Authorization", "Bearer " + access_token);

                var uploadData = new FormData();

                var requestOptions = {
                  method: "DELETE",
                  headers: myHeaders,
                  body: uploadData,
                  redirect: "follow",
                };

                fetch(
                  "https://uisur.ad.ntust.edu.tw/backend/api/maps/" + deleteId,
                  requestOptions
                )
                  .then((response) => {
                    if (response.ok) {
                      setTheAlert({ text: "刪除成功", severity: "success" });

                      setOpenAlert(false);

                      setNeedUpdate(needUpdata + 1);
                    } else {
                      setTheAlert({ text: "刪除失敗", severity: "error" });
                      setOpenAlert(false);
                    }
                    return response.text();
                  })
                  .then((result) => {
                    console.log(result);
                  })
                  .catch((error) => console.log("error", error));
              }}
            >
              確認
            </Button>
            <Button
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              取消
            </Button>
          </Box>
        </Paper>
      </Modal>
      <Modal
        className={classes.modal}
        open={modifyEmailModal}
        onClose={() => {
          setModifyEmailModal(false);
        }}
        aria-labelledby="simple-modal-title-email"
        aria-describedby="simple-modal-description-email"
      >
        <Paper className={classes.paper}>
          <Typography variant="h5">更改電子郵件</Typography>
          <Typography variant="h6">現在電子郵件為 : {myEmail}</Typography>
          <TextField
            id="standard1"
            label="修改電子郵件"
            value={submitEmail}
            onChange={(e) => {
              setSubmitEmail(e.target.value);
            }}
            style={{ marginBottom: "40px" }}
            variant="filled"
          />
          <Typography variant="h5">請輸入密碼</Typography>
          <TextField
            id="standard2"
            label="輸入密碼"
            type="password"
            value={submitPassword}
            onChange={(e) => {
              setSubmitPassword(e.target.value);
            }}
            style={{ marginBottom: "40px" }}
            variant="filled"
          />

          <div style={{ display: "flex" }}>
            <Button
              style={{ background: "#e8e8e8" }}
              onClick={() => {
                submitModifyData({
                  old_password: submitPassword,
                  email: submitEmail,
                });
              }}
            >
              上傳修改
            </Button>
            <div style={{ flexGrow: "1" }}></div>
            <Button
              style={{ background: "#e8e8e8" }}
              onClick={() => {
                setModifyNameModal(false);
              }}
            >
              {" "}
              取消修改
            </Button>
          </div>
        </Paper>
      </Modal>
      <Modal
        className={classes.modal}
        open={modifyNameModal}
        onClose={() => {
          setModifyNameModal(false);
        }}
        aria-labelledby="simple-modal-title-name"
        aria-describedby="simple-modal-description-name"
      >
        <Paper className={classes.paper}>
          <Typography variant="h5">更改名稱</Typography>
          <TextField
            id="standard1"
            label="修改名稱"
            value={submitName}
            onChange={(e) => {
              setSubmitName(e.target.value);
            }}
            style={{ marginBottom: "40px" }}
            variant="filled"
          />
          <Typography variant="h5">請輸入密碼</Typography>
          <TextField
            id="standard2"
            label="輸入密碼"
            type="password"
            value={submitPassword}
            onChange={(e) => {
              setSubmitPassword(e.target.value);
            }}
            style={{ marginBottom: "40px" }}
            variant="filled"
          />

          <div style={{ display: "flex" }}>
            <Button
              style={{ background: "#e8e8e8" }}
              onClick={() => {
                submitModifyData({
                  old_password: submitPassword,
                  name: submitName,
                });
              }}
            >
              上傳修改
            </Button>
            <div style={{ flexGrow: "1" }}></div>
            <Button
              style={{ background: "#e8e8e8" }}
              onClick={() => {
                setModifyNameModal(false);
              }}
            >
              {" "}
              取消修改
            </Button>
          </div>
        </Paper>
      </Modal>
      <Modal
        className={classes.modal}
        open={modifyIntroductionModal}
        onClose={() => {
          setModifyIntroductionModal(false);
        }}
        aria-labelledby="simple-modal-title-Introduct"
        aria-describedby="simple-modal-description-Introduct"
      >
        <Paper className={classes.paper}>
          <Typography variant="h5">更改自我介紹</Typography>
          <TextField
            id="standard3"
            label="修改自我介紹"
            rows={4}
            value={submitIntro}
            onChange={(e) => {
              setSubmitIntro(e.target.value);
            }}
            style={{ marginBottom: "40px" }}
            multiline
            variant="filled"
          />
          <Typography variant="h5">請輸入密碼</Typography>
          <TextField
            id="standard4"
            label="輸入密碼"
            type="password"
            value={submitPassword}
            onChange={(e) => {
              setSubmitPassword(e.target.value);
            }}
            style={{ marginBottom: "40px" }}
            variant="filled"
          />
          <div style={{ display: "flex" }}>
            <Button
              style={{ background: "#e8e8e8" }}
              onClick={() => {
                submitModifyData({
                  old_password: submitPassword,
                  introduction: submitIntro,
                });
              }}
            >
              上傳修改
            </Button>
            <div style={{ flexGrow: "1" }}></div>
            <Button
              style={{ background: "#e8e8e8" }}
              onClick={() => {
                setModifyIntroductionModal(false);
              }}
            >
              {" "}
              取消修改
            </Button>
          </div>
        </Paper>
      </Modal>
      <Modal
        className={classes.modal}
        open={modifyAvatarModal}
        onClose={() => {
          setModifyAvatarModal(false);
        }}
        aria-labelledby="simple-modal-title-avatar"
        aria-describedby="simple-modal-description-avatar"
      >
        <Paper className={classes.paper}>
          <Typography variant="h5">更改大頭貼</Typography>
          <DropzoneArea
            acceptedFiles={["image/*"]}
            maxFileSize={3000000 * 2}
            filesLimit={1}
            dropzoneText={"Drag and drop an image here or click"}
            onChange={(files) => {
              setSubmitAvatar(files);
              console.log(files);
            }}
          />
          {/* <input
                        accept="image/*"
                        style={{ display: 'none' }}

                        id="contained-button-file"
                        onChange={(e) => { setSubmitAvatar(e.target.files[0]) }}
                        type="file"

                    />
                    <label htmlFor="contained-button-file">
                        <Button variant="contained" color="primary" component="span">
                            Upload
                        </Button>
                    </label> */}
          <Typography variant="h5">請輸入密碼</Typography>
          <TextField
            id="standard6"
            label="輸入密碼"
            type="password"
            value={submitPassword}
            onChange={(e) => {
              setSubmitPassword(e.target.value);
            }}
            style={{ marginBottom: "40px" }}
            variant="filled"
          />
          <div style={{ display: "flex" }}>
            <Button
              style={{ background: "#e8e8e8" }}
              onClick={() => {
                if (submitAvatar.length != 0)
                  submitModifyData({
                    old_password: submitPassword,
                    avatar: submitAvatar[0],
                  });
              }}
            >
              上傳修改
            </Button>
            <div style={{ flexGrow: "1" }}></div>
            <Button
              style={{ background: "#e8e8e8" }}
              onClick={() => {
                setModifyAvatarModal(false);
              }}
            >
              {" "}
              取消修改
            </Button>
          </div>
        </Paper>
      </Modal>
      <Modal
        className={classes.modal}
        open={modifyPasswordModal}
        onClose={() => {
          setModifyPasswordModal(false);
        }}
        aria-labelledby="simple-modal-title-password"
        aria-describedby="simple-modal-description-password"
      >
        <Paper className={classes.paper}>
          <Typography variant="h5">請輸入新密碼</Typography>
          <TextField
            id="standard7"
            label="新密碼"
            value={submitNewPassword}
            onChange={(e) => {
              setSubmitNewPassword(e.target.value);
            }}
            style={{ marginBottom: "40px" }}
            type="password"
            variant="filled"
          />
          <Typography variant="h5">再次輸入新密碼</Typography>
          <TextField
            id="standard9"
            label="再次輸入新密碼"
            value={submitNewComfirmPassword}
            onChange={(e) => {
              setSubmitNewComfirmPassword(e.target.value);
            }}
            style={{ marginBottom: "40px" }}
            type="password"
            variant="filled"
          />
          <Typography variant="h5">請輸入舊密碼</Typography>
          <TextField
            id="standard8"
            label="舊密碼"
            type="password"
            value={submitPassword}
            onChange={(e) => {
              setSubmitPassword(e.target.value);
            }}
            style={{ marginBottom: "40px" }}
            variant="filled"
          />
          <div style={{ display: "flex" }}>
            <Button
              style={{ background: "#e8e8e8" }}
              onClick={() => {
                submitModifyData({
                  old_password: submitPassword,
                  new_password: submitNewPassword,
                  new_comfirm_password: submitNewComfirmPassword,
                });
              }}
            >
              上傳修改
            </Button>
            <div style={{ flexGrow: "1" }}></div>
            <Button
              style={{ background: "#e8e8e8" }}
              onClick={() => {
                setModifyPasswordModal(false);
              }}
            >
              {" "}
              取消修改
            </Button>
          </div>
        </Paper>
      </Modal>

      <Hidden smDown>
        <div
          style={
            matches ? { padding: " 30px  8.3%" } : { padding: " 8px  0px" }
          }
        >
          <FrontAppBar />
          <Grid
            lg={12}
            md={12}
            xs={12}
            xl={12}
            sm={12}
            container
            alignItems="center"
          >
            <Grid
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              item
              style={{
                paddingLeft: "30px",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                position: "relative",
              }}
            >
              {usersData.type === "user" ? (
                <Avatar
                  src={
                    "https://uisur.ad.ntust.edu.tw/backend/storage/" +
                    usersData.avatar
                  }
                  style={{
                    border: "2px solid black",
                    aspectRatio: "1",
                    height: "150px",
                    width: "150px",
                  }}
                />
              ) : usersData.type === "business" ? (
                <Avatar
                  src={
                    "https://uisur.ad.ntust.edu.tw/backend/storage/" +
                    usersData.avatar
                  }
                  style={{
                    border: "2px solid green",
                    aspectRatio: "1",
                    height: "150px",
                    width: "150px",
                  }}
                />
              ) : (
                <Avatar
                  src={
                    "https://uisur.ad.ntust.edu.tw/backend/storage/" +
                    usersData.avatar
                  }
                  style={{
                    border: "2px solid #ff7348",
                    aspectRatio: "1",
                    height: "150px",
                    width: "150px",
                  }}
                />
              )}

              <div style={{ paddingLeft: "20px" }}>
                {usersData.type === "user" ? (
                  <Typography
                    variant="h5"
                    style={{ color: "black", fontWeight: "900" }}
                  >
                    {usersData.name}
                  </Typography>
                ) : usersData.type === "business" ? (
                  <Typography
                    variant="h5"
                    style={{ color: "green", fontWeight: "900" }}
                  >
                    {usersData.name}
                  </Typography>
                ) : (
                  <Typography
                    variant="h5"
                    style={{ color: "#ff7348", fontWeight: "900" }}
                  >
                    {usersData.name}
                  </Typography>
                )}
              </div>
              <div style={{ paddingLeft: "50px" }}>
                {props.searchmode === false ? (
                  <Button
                    onClick={handleMenuClick}
                    style={{
                      minWidth: "140px",
                      fontSize: "16px",
                      borderRadius: "32px",
                      border: "1px solid black",
                      fontWeight: "900",
                    }}
                  >
                    編輯個人資訊
                  </Button>
                ) : (
                  <Button
                    style={{
                      fontSize: "16px",
                      borderRadius: "32px",
                      border: "1px solid black",
                      fontWeight: "900",
                    }}
                  >
                    追蹤
                  </Button>
                )}
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      setModifyNameModal(true);
                      setSubmitName(usersData.name);
                    }}
                  >
                    修改名稱
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      setModifyIntroductionModal(true);
                      setSubmitIntro(usersData.introduction);
                    }}
                  >
                    修改自我介紹
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      setModifyPasswordModal(true);
                      setSubmitNewPassword("");
                    }}
                  >
                    修改密碼
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      setModifyEmailModal(true);
                      setSubmitNewPassword("");
                    }}
                  >
                    修改電子郵件
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      setModifyAvatarModal(true);
                      setSubmitAvatar([]);
                    }}
                  >
                    修改大頭貼
                  </MenuItem>
                </Menu>
              </div>
            </Grid>
            <Grid
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              alignContent="center"
              alignItems="center"
              item
              style={{ position: "relative", display: "flex" }}
            >
              <div style={{ flexGrow: "1" }}>
                <Typography
                  variant="h5"
                  style={{
                    textAlign: "center",
                    fontSize: "32px",
                    fontWeight: "900",
                  }}
                >
                  {usersData.articles.length}
                </Typography>
                <Typography style={{ textAlign: "center" }}>篇故事</Typography>
              </div>
              <div style={{ flexGrow: "1" }}>
                <Typography
                  variant="h5"
                  style={{
                    textAlign: "center",
                    fontSize: "32px",
                    fontWeight: "900",
                  }}
                >
                  {usersData.maps.length}
                </Typography>
                <Typography style={{ textAlign: "center" }}>
                  路徑地圖
                </Typography>
              </div>
              {/* <div style={{ flexGrow: "1" }}>
                <Typography
                  variant="h5"
                  style={{
                    textAlign: "center",
                    fontSize: "32px",
                    fontWeight: "900",
                  }}
                >
                  0
                </Typography>
                <Typography style={{ textAlign: "center" }}>追蹤者</Typography>
              </div>
              <div style={{ flexGrow: "1" }}>
                <Typography
                  variant="h5"
                  style={{
                    textAlign: "center",
                    fontSize: "32px",
                    fontWeight: "900",
                  }}
                >
                  0
                </Typography>
                <Typography style={{ textAlign: "center" }}>追蹤中</Typography>
              </div> */}
              {props.searchmode === false && (
                <div style={{ flexGrow: "1", position: "relative" }}>
                  <IconButton
                    aria-label="add"
                    onClick={() => {
                      setOpenadd(!openadd);
                    }}
                  >
                    <AddCircleIcon
                      style={{
                        fontSize: "64",
                        color: "black",
                        position: "relative",
                      }}
                    ></AddCircleIcon>
                  </IconButton>
                  {openadd === true && (
                    <Paper
                      style={{
                        transition: "width 2s;",
                        position: "absolute",
                        transform: "translate(-15%,80%)",
                        bottom: 0,
                        left: 0,
                        border: "1px solid black",
                        width: "160px",
                      }}
                    >
                      <div>
                        <Link
                          style={{ textDecoration: "none" }}
                          to="/CreateMap"
                        >
                          <Button style={{ width: "100%", fontSize: "20px" }}>
                            <img
                              src={require("./Asset/domap.png")}
                              style={{ width: "35px", height: "27px" }}
                            ></img>
                            {"　路徑地圖"}
                          </Button>
                        </Link>
                      </div>
                    </Paper>
                  )}
                </div>
              )}
            </Grid>
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
              style={{
                paddingTop: "30px",
                paddingRight: "50%",
                paddingLeft: "3%",
              }}
            >
              <div style={{ position: "relative" }}>
                {writeIntro === false ? (
                  <Typography
                    style={{ color: "#919191", whiteSpace: "pre-line" }}
                  >
                    {usersData.introduction}
                  </Typography>
                ) : (
                  <TextField
                    id="standard-textarea"
                    label="修改自我介紹"
                    rows={4}
                    value={submitIntro}
                    onChange={(e) => {
                      setSubmitIntro(e.target.value);
                    }}
                    style={{ marginBottom: "40px" }}
                    multiline
                    variant="filled"
                  />
                )}
                {writeIntro === true && (
                  <Button
                    style={{
                      transform: "translate(0%,0%)",
                      position: "absolute",
                      bottom: "-2px",
                      left: "0",
                      border: "1px solid black",
                      marginRight: "10px",
                    }}
                    onClick={() => { }}
                  >
                    確認修改
                  </Button>
                )}
                {writeIntro === true && (
                  <Button
                    style={{
                      transform: "translate(110%,0%)",
                      position: "absolute",
                      bottom: "-2px",
                      left: "-2px",
                      border: "1px solid black",
                    }}
                    onClick={() => {
                      setWriteIntro(false);
                    }}
                  >
                    取消修改
                  </Button>
                )}
              </div>
            </Grid>
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ paddingTop: "20px" }}
              item
            >
              <Paper square>
                <Tabs
                  scrollButtons="auto"
                  value={value}
                  indicatorColor="#f44336"
                  textColor="#f44336"
                  variant="scrollable"
                  onChange={handleChange}
                  aria-label="disabled tabs example"
                  style={{
                    borderColor: "black",
                    alignContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  {props.searchmode === false && (
                    <Tab
                      style={{ color: "#919191" }}
                      label=" 我的貢獻 |"
                      disabled
                    />
                  )}

                  {/* <Tab
                    label="故事"
                    onClick={() => {
                      setSelect("故事");
                    }}
                    style={
                      select === "故事"
                        ? {
                            borderBottom: "3px solid black",
                            fontSize: "20px",
                            fontWeight: "900",
                          }
                        : { fontWeight: "900" }
                    }
                  /> */}
                  <Tab
                    label="路徑地圖"
                    onClick={() => {
                      setSelect("路徑地圖");
                    }}
                    style={
                      select === "路徑地圖"
                        ? {
                          borderBottom: "3px solid black",
                          fontSize: "20px",
                          fontWeight: "900",
                        }
                        : { fontWeight: "900" }
                    }
                  />

                  {props.searchmode === false && (
                    <Tab
                      style={{ color: "#919191" }}
                      label="  我的收藏 |"
                      disabled
                    />
                  )}
                  {props.searchmode === false && (
                    <Tab
                      label="NOTES"
                      onClick={() => {
                        setSelect("NOTES");
                      }}
                      style={
                        select === "NOTES"
                          ? {
                            borderBottom: "3px solid black",
                            fontSize: "20px",
                            fontWeight: "900",
                          }
                          : { fontWeight: "900" }
                      }
                    />
                  )}
                  {props.searchmode === false && (
                    <Tab
                      label="PINS"
                      onClick={() => {
                        setSelect("PINS");
                      }}
                      style={
                        select === "PINS"
                          ? {
                            borderBottom: "3px solid black",
                            fontSize: "20px",
                            fontWeight: "900",
                          }
                          : { fontWeight: "900" }
                      }
                    />
                  )}
                  {props.searchmode === false && (
                    <Tab
                      label="MAPS"
                      onClick={() => {
                        setSelect("MAPS");
                      }}
                      style={
                        select === "MAPS"
                          ? {
                            borderBottom: "3px solid black",
                            fontSize: "20px",
                            fontWeight: "900",
                          }
                          : { fontWeight: "900" }
                      }
                    />
                  )}
                </Tabs>
              </Paper>
            </Grid>

            {select === "故事" && (
              <Grid xl={12} lg={12} md={12} sm={12} xs={12} item container>
                {usersData.articles.map((item) => (
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      borderBottom: "1px solid black ",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                    item
                    container
                  >
                    <Grid xl={7} lg={7} md={12} sm={12} xs={12} item>
                      <Typography variant="caption">
                        {covert_to_date_text(item.created_at)}
                      </Typography>
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={"/article/" + item.name}
                      >
                        <Typography variant="h5">{item.name}</Typography>
                      </Link>
                      <Typography variant="body1">{item.summary}</Typography>
                    </Grid>
                    <Grid
                      xl={5}
                      lg={5}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ display: "flex", alignItems: "center" }}
                      item
                    >
                      <div style={{ flexGrow: "1" }}></div>
                      <div
                        style={{
                          paddingRight: "20px",
                          borderRight: "1px solid black ",
                        }}
                      >
                        <Link
                          style={{ textDecoration: "none" }}
                          to={"/article/" + item.name}
                        >
                          <img
                            src={
                              "https://uisur.ad.ntust.edu.tw/backend/storage/" +
                              item.thumbnail
                            }
                            style={{
                              aspectRatio: "1/1",
                              width: "150px",
                              height: "150px",
                            }}
                          ></img>
                        </Link>
                      </div>

                      {props.searchmode === false && (
                        <>
                          <div
                            style={{
                              position: "relative",
                              textAlign: "center",
                              paddingLeft: "20px",
                              height: "100%",
                              paddingRight: "20px",
                              borderRight: "1px solid black ",
                            }}
                          >
                            <div
                              style={{
                                transform: "translate(-50%, -50%)",
                                position: "relative",
                                top: "50%",
                                left: "50%",
                              }}
                            >
                              <Typography variant="h5">觀看次數</Typography>
                              <Typography>{item.views}</Typography>
                            </div>
                          </div>
                          <div
                            style={{
                              position: "relative",
                              textAlign: "center",
                              paddingLeft: "20px",
                              height: "100%",
                              paddingRight: "20px",
                              borderRight: "1px solid black ",
                            }}
                          >
                            <div
                              style={{
                                transform: "translate(-50%, -50%)",
                                position: "relative",
                                top: "50%",
                                left: "50%",
                              }}
                            >
                              <Typography variant="h5">文章狀態</Typography>
                              <Typography>
                                {item.is_allowed === 1 ? "公開中" : "審查中"}
                              </Typography>
                            </div>
                          </div>
                        </>
                      )}
                      <div
                        style={{ paddingRight: "20px", paddingLeft: "20px" }}
                      >
                        <Button
                          style={{
                            display: "block",
                            marginBottom: "20px",
                            color: "black",
                            background: "white",
                          }}
                        >
                          {props.searchmode === true ? "收藏" : "編輯"}
                        </Button>
                        <Button
                          style={{
                            display: "block",
                            color: "white",
                            background: "black",
                          }}
                        >
                          {props.searchmode === true ? "分享" : "刪除"}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}

            {select === "路徑地圖" && (
              <Grid xl={12} lg={12} md={12} sm={12} xs={12} item container>
                {usersData.maps.map((item) => (
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      borderBottom: "1px solid black ",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                    item
                    container
                  >
                    <Grid xl={7} lg={7} md={12} sm={12} xs={12} item>
                      <Typography variant="caption">
                        {covert_to_date_text(item.created_at)}
                      </Typography>
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={"/Mapbox/" + item.id}
                      >
                        <Typography variant="h5">{item.name}</Typography>
                      </Link>
                      <Typography variant="body1">{item.summary}</Typography>
                    </Grid>
                    <Grid
                      xl={5}
                      lg={5}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ display: "flex", alignItems: "center" }}
                      item
                    >
                      {/* <div style={{ paddingRight: '20px', borderRight: '1px solid black ' }}><img src={
                                        "https://uisur.ad.ntust.edu.tw/backend/storage/" +
                                        item.thumbnail
                                    } style={{ aspectRatio: '1/1', width: '150px', height: '150px' }}></img>
                                    </div> */}
                      <div style={{ flexGrow: "1" }}></div>
                      {props.searchmode === false && (
                        <>
                          <div
                            style={{
                              position: "relative",
                              textAlign: "center",
                              paddingLeft: "20px",
                              height: "100%",
                              paddingRight: "20px",
                              borderRight: "1px solid black ",
                            }}
                          >
                            <div
                              style={{
                                transform: "translate(-50%, -50%)",
                                position: "relative",
                                top: "50%",
                                left: "50%",
                              }}
                            >
                              <Typography variant="h5">觀看次數</Typography>
                              <Typography>{item.views}</Typography>
                            </div>
                          </div>
                          <div
                            style={{
                              position: "relative",
                              textAlign: "center",
                              paddingLeft: "20px",
                              height: "100%",
                              paddingRight: "20px",
                              borderRight: "1px solid black ",
                            }}
                          >
                            <div
                              style={{
                                transform: "translate(-50%, -50%)",
                                position: "relative",
                                top: "50%",
                                left: "50%",
                              }}
                            >
                              <Typography variant="h5">地圖狀態</Typography>
                              <Typography>
                                {item.is_public === 0 ? "不公開" : "公開"}
                              </Typography>
                            </div>
                          </div>
                        </>
                      )}
                      <div
                        style={{ paddingRight: "20px", paddingLeft: "20px" }}
                      >
                        {/* <Button
                          style={{
                            display: "block",
                            marginBottom: "20px",
                            color: "black",
                            background: "white",
                          }}
                        >
                          {props.searchmode === true ? "收藏" : "編輯"}
                        </Button> */}
                        {props.searchmode === false && (
                          <Link
                            to={"/EditMap/" + item.id}
                            style={{ textDecoration: "none" }}
                          >
                            <Button
                              style={{
                                display: "block",
                                marginBottom: "20px",
                                color: "black",
                                background: "white",
                              }}
                            >
                              {"編輯"}
                            </Button>
                          </Link>
                        )}

                        <Button
                          style={{
                            display: "block",
                            color: "white",
                            background: "black",
                          }}
                          onClick={() => {
                            if (props.searchmode === true) {
                              copyCurrentUrl(
                                "https://uisur.ad.ntust.edu.tw/#/Mapbox/" +
                                item.id.toString()
                              );
                            } else {
                              setDeleteCheckString(
                                "確定要刪除 " + item.name + " 地圖嗎？"
                              );
                              setDeleteId(item.id);
                              setDeleteModal(true);
                            }
                          }}
                        >
                          {props.searchmode === true ? "分享" : "刪除"}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}
            {select === "MAPS" && <MyMap />}

            {select === "NOTES" && <MyNote />}

            {select === "PINS" && <MyPin />}
          </Grid>
        </div>
      </Hidden>
      {/* for phone */}
      <Hidden mdUp>
        <div
          style={
            matches ? { padding: " 30px  40px" } : { padding: " 8px  0px" }
          }
        >
          <FrontAppBar />
          <Grid
            lg={12}
            md={12}
            xs={12}
            xl={12}
            sm={12}
            container
            alignItems="center"
          >
            <Grid
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              item
              style={{
                paddingTop: "20px",
                paddingLeft: "10px",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                position: "relative",
              }}
            >
              {usersData.type === "user" ? (
                <Avatar
                  src={
                    "https://uisur.ad.ntust.edu.tw/backend/storage/" +
                    usersData.avatar
                  }
                  style={{
                    border: "2px solid black",
                    aspectRatio: "1",
                    height: "75px",
                    width: "75px",
                  }}
                />
              ) : usersData.type === "business" ? (
                <Avatar
                  src={
                    "https://uisur.ad.ntust.edu.tw/backend/storage/" +
                    usersData.avatar
                  }
                  style={{
                    border: "2px solid green",
                    aspectRatio: "1",
                    height: "75px",
                    width: "75px",
                  }}
                />
              ) : (
                <Avatar
                  src={
                    "https://uisur.ad.ntust.edu.tw/backend/storage/" +
                    usersData.avatar
                  }
                  style={{
                    border: "2px solid #ff7348",
                    aspectRatio: "1",
                    height: "75px",
                    width: "75px",
                  }}
                />
              )}

              <div style={{ flexGrow: "1" }}>
                <Typography
                  variant="h5"
                  style={{
                    textAlign: "center",
                    fontSize: "32px",
                    fontWeight: "900",
                  }}
                >
                  {usersData.articles.length}
                </Typography>
                <Typography style={{ textAlign: "center" }}>篇故事</Typography>
              </div>
              <div style={{ flexGrow: "1" }}>
                <Typography
                  variant="h5"
                  style={{
                    textAlign: "center",
                    fontSize: "32px",
                    fontWeight: "900",
                  }}
                >
                  {usersData.maps.length}
                </Typography>
                <Typography style={{ textAlign: "center" }}>
                  路徑地圖
                </Typography>
              </div>
              {/* <div style={{ flexGrow: "1" }}>
                <Typography
                  variant="h5"
                  style={{
                    textAlign: "center",
                    fontSize: "32px",
                    fontWeight: "900",
                  }}
                >
                  0
                </Typography>
                <Typography style={{ textAlign: "center" }}>追蹤者</Typography>
              </div>
              <div style={{ flexGrow: "1" }}>
                <Typography
                  variant="h5"
                  style={{
                    textAlign: "center",
                    fontSize: "32px",
                    fontWeight: "900",
                  }}
                >
                  0
                </Typography>
                <Typography style={{ textAlign: "center" }}>追蹤中</Typography>
              </div> */}
            </Grid>
            <Grid
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              alignContent="center"
              alignItems="center"
              item
              style={{
                paddingTop: "15px",
                position: "relative",
                display: "flex",
              }}
            >
              <div style={{ paddingLeft: "20px" }}>
                {usersData.type === "user" ? (
                  <Typography
                    variant="h5"
                    style={{ color: "black", fontWeight: "900" }}
                  >
                    {usersData.name}
                  </Typography>
                ) : usersData.type === "business" ? (
                  <Typography
                    variant="h5"
                    style={{ color: "green", fontWeight: "900" }}
                  >
                    {usersData.name}
                  </Typography>
                ) : (
                  <Typography
                    variant="h5"
                    style={{ color: "#ff7348", fontWeight: "900" }}
                  >
                    {usersData.name}
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
              style={{
                paddingTop: "20px",
                paddingRight: "20px",
                paddingLeft: "20px",
              }}
            >
              <div style={{ position: "relative" }}>
                {writeIntro === false ? (
                  <Typography
                    style={{ color: "#919191", whiteSpace: "pre-line" }}
                  >
                    {usersData.introduction}
                  </Typography>
                ) : (
                  <TextField
                    id="standard-textarea"
                    label="修改自我介紹"
                    rows={4}
                    value={submitIntro}
                    onChange={(e) => {
                      setSubmitIntro(e.target.value);
                    }}
                    multiline
                    variant="filled"
                    style={{ marginBottom: "40px" }}
                  />
                )}
                {writeIntro === true && (
                  <Button
                    style={{
                      zIndex: "10",
                      transform: "translate(0%,0%)",
                      position: "absolute",
                      bottom: "-2px",
                      left: "0",
                      border: "1px solid black",
                      marginRight: "10px",
                    }}
                    onClick={() => {
                      var password = prompt("需要密碼進行修改資料");

                      const access_token = Cookies.get("access_token");
                      var myHeaders = new Headers();
                      myHeaders.append(
                        "Authorization",
                        "Bearer " + access_token
                      );
                      myHeaders.append("Accept", "application/json");
                      myHeaders.append(
                        "Content-Type",
                        "application/x-www-form-urlencoded;charset=utf-8"
                      );
                      var uploadData = new URLSearchParams();

                      //uploadData.append("is_visible", JSON.stringify(1));
                      uploadData.append("introduction", submitIntro);
                      uploadData.append("old_password", password);
                      var requestOptions = {
                        method: "PATCH",
                        headers: myHeaders,
                        body: uploadData,
                        redirect: "follow",
                      };

                      fetch(
                        "https://uisur.ad.ntust.edu.tw/backend/api/me",
                        requestOptions
                      )
                        .then((response) => {
                          if (response.ok) {
                            alert("變更成功");
                            window.location.reload();
                          } else {
                            alert("變更失敗");
                          }
                          return response.text();
                        })
                        .then((result) => {
                          console.log(result);
                        })
                        .catch((error) => console.log("error", error));
                    }}
                  >
                    確認修改
                  </Button>
                )}
                {writeIntro === true && (
                  <Button
                    style={{
                      zIndex: "10",
                      transform: "translate(110%,0%)",
                      position: "absolute",
                      bottom: "-2px",
                      left: "-2px",
                      border: "1px solid black",
                    }}
                    onClick={() => {
                      setWriteIntro(false);
                    }}
                  >
                    取消修改
                  </Button>
                )}
              </div>
            </Grid>
            <Grid
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              alignContent="center"
              alignItems="center"
              item
              style={{ position: "relative", display: "flex" }}
            >
              <div
                style={
                  props.searchmode === false
                    ? { paddingLeft: "20px", flexGrow: "1" }
                    : { paddingRight: "10%", paddingLeft: "10%", flexGrow: "1" }
                }
              >
                {props.searchmode === false ? (
                  <Button
                    fullWidth
                    onClick={handleMenuClick}
                    style={{
                      flexGrow: "1",
                      borderRadius: "16px",
                      border: "1px solid black",
                      fontWeight: "900",
                    }}
                  >
                    編輯個人資訊
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    style={{
                      borderRadius: "10% / 90%",
                      border: "1px solid black",
                      fontWeight: "900",
                      flexGrow: "1",
                    }}
                  >
                    追蹤
                  </Button>
                )}

                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      setModifyNameModal(true);
                      setSubmitName(usersData.name);
                    }}
                  >
                    修改名稱
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      setModifyIntroductionModal(true);
                      setSubmitIntro(usersData.introduction);
                    }}
                  >
                    修改自我介紹
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      setModifyPasswordModal(true);
                      setSubmitNewPassword("");
                    }}
                  >
                    修改密碼
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      setModifyEmailModal(true);
                      setSubmitNewPassword("");
                    }}
                  >
                    修改電子郵件
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      setModifyAvatarModal(true);
                      setSubmitAvatar([]);
                    }}
                  >
                    修改大頭貼
                  </MenuItem>
                </Menu>
              </div>
              {props.searchmode === false && (
                <div style={{ position: "relative" }}>
                  <IconButton
                    aria-label="add"
                    onClick={() => {
                      setOpenadd(!openadd);
                    }}
                  >
                    <AddCircleIcon
                      style={{
                        fontSize: "64",
                        color: "black",
                        position: "relative",
                      }}
                    ></AddCircleIcon>
                  </IconButton>
                  {openadd === true && (
                    <Paper
                      style={{
                        zIndex: "10",
                        background: "white",
                        color: "white",
                        transition: "width 2s;",
                        position: "absolute",
                        transform: "translate(-30%,80%)",
                        bottom: 0,
                        left: 0,
                        border: "1px solid black",
                        width: "120px",
                      }}
                    >
                      <Link style={{ textDecoration: "none" }} to="/CreateMap">
                        <div>
                          <Button style={{ width: "100%" }}>
                            <img
                              src={require("./Asset/domap.png")}
                              style={{ width: "28px", height: "20px" }}
                            ></img>
                            {"　路徑地圖"}
                          </Button>
                        </div>
                      </Link>
                      {/* <div>
                                            <Button style={{ width: '100%' }}>
                                                <img src={require('./Asset/dostory.png')} style={{ width: '28px', height: '20px' }}></img>
                                                {"　　故事　"}
                                            </Button>
                                        </div> */}
                    </Paper>
                  )}
                </div>
              )}
            </Grid>

            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ paddingTop: "20px" }}
              item
            >
              <Paper square>
                <Tabs
                  scrollButtons="auto"
                  value={value}
                  indicatorColor="#f44336"
                  textColor="#f44336"
                  variant="scrollable"
                  onChange={handleChange}
                  aria-label="disabled tabs example"
                  style={{
                    borderColor: "black",
                    alignContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  {props.searchmode === false && (
                    <Tab
                      style={{ color: "#919191" }}
                      label=" 我的貢獻 |"
                      disabled
                    />
                  )}

                  {/* <Tab
                    label="故事"
                    onClick={() => {
                      setSelect("故事");
                    }}
                    style={
                      select === "故事"
                        ? {
                            borderBottom: "3px solid black",
                            fontSize: "20px",
                            fontWeight: "900",
                          }
                        : { fontWeight: "900" }
                    }
                  /> */}
                  <Tab
                    label="路徑地圖"
                    onClick={() => {
                      setSelect("路徑地圖");
                    }}
                    style={
                      select === "路徑地圖"
                        ? {
                          borderBottom: "3px solid black",
                          fontSize: "20px",
                          fontWeight: "900",
                        }
                        : { fontWeight: "900" }
                    }
                  />

                  {props.searchmode === false && (
                    <Tab
                      style={{ color: "#919191" }}
                      label="  我的收藏 |"
                      disabled
                    />
                  )}
                  {props.searchmode === false && (
                    <Tab
                      label="NOTES"
                      onClick={() => {
                        setSelect("NOTES");
                      }}
                      style={
                        select === "NOTES"
                          ? {
                            borderBottom: "3px solid black",
                            fontSize: "20px",
                            fontWeight: "900",
                          }
                          : { fontWeight: "900" }
                      }
                    />
                  )}
                  {props.searchmode === false && (
                    <Tab
                      label="PINS"
                      onClick={() => {
                        setSelect("PINS");
                      }}
                      style={
                        select === "PINS"
                          ? {
                            borderBottom: "3px solid black",
                            fontSize: "20px",
                            fontWeight: "900",
                          }
                          : { fontWeight: "900" }
                      }
                    />
                  )}
                  {props.searchmode === false && (
                    <Tab
                      label="MAPS"
                      onClick={() => {
                        setSelect("MAPS");
                      }}
                      style={
                        select === "MAPS"
                          ? {
                            borderBottom: "3px solid black",
                            fontSize: "20px",
                            fontWeight: "900",
                          }
                          : { fontWeight: "900" }
                      }
                    />
                  )}
                </Tabs>
              </Paper>
            </Grid>

            {select === "故事" && (
              <Grid xl={12} lg={12} md={12} sm={12} xs={12} item container>
                {usersData.articles.map((item) => (
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      borderBottom: "1px solid black ",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                    item
                    container
                  >
                    <Grid xl={7} lg={7} md={12} sm={12} xs={12} item>
                      <Typography variant="caption">
                        {covert_to_date_text(item.created_at)}
                      </Typography>
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={"/article/" + item.name}
                      >
                        <Typography variant="h5">{item.name}</Typography>
                      </Link>
                      <Typography variant="body1">{item.summary}</Typography>
                    </Grid>
                    <Grid
                      xl={5}
                      lg={5}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ display: "flex", alignItems: "center" }}
                      container
                      item
                    >
                      {/* <div style={{ paddingRight: '20px', borderRight: '1px solid black ' }}><img src={
                                            "https://uisur.ad.ntust.edu.tw/backend/storage/" +
                                            item.thumbnail
                                        } style={{ aspectRatio: '1/1', width: '150px', height: '150px' }}></img>
                                        </div> */}
                      <Grid
                        xl={5}
                        lg={5}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{ display: "flex", alignItems: "center" }}
                        item
                      >
                        <div style={{ padding: "5px" }}>
                          {" "}
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to={"/article/" + item.name}
                          >
                            <img
                              src={
                                "https://uisur.ad.ntust.edu.tw/backend/storage/" +
                                item.thumbnail
                              }
                              style={{
                                aspectRatio: "1/1",
                                width: "100%",
                                height: "auto",
                              }}
                            ></img>
                          </Link>
                        </div>
                      </Grid>
                      <Grid
                        xl={5}
                        lg={5}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{ display: "flex", alignItems: "center" }}
                        container
                        item
                        alignItems="center"
                      >
                        {props.searchmode === false && (
                          <>
                            <div
                              style={{
                                flexGrow: "1",
                                position: "relative",
                                textAlign: "center",
                                paddingLeft: "20px",
                                height: "100%",
                                paddingRight: "20px",
                                borderRight: "1px solid black ",
                              }}
                            >
                              <div
                                style={{
                                  transform: "translate(-50%, 0%)",
                                  position: "relative",
                                  top: "50%",
                                  left: "50%",
                                }}
                              >
                                <Typography
                                  variant="h5"
                                  style={{ fontSize: "16px" }}
                                >
                                  觀看次數
                                </Typography>
                                <Typography style={{ fontWeight: "900" }}>
                                  {item.views}
                                </Typography>
                              </div>
                            </div>
                            <div
                              style={{
                                flexGrow: "1",
                                position: "relative",
                                textAlign: "center",
                                paddingLeft: "20px",
                                height: "100%",
                                paddingRight: "20px",
                                borderRight: "1px solid black ",
                              }}
                            >
                              <div
                                style={{
                                  transform: "translate(-50%, 0%)",
                                  position: "relative",
                                  top: "50%",
                                  left: "50%",
                                }}
                              >
                                <Typography
                                  variant="h5"
                                  style={{ fontSize: "16px" }}
                                >
                                  文章狀態
                                </Typography>
                                <Typography style={{ fontWeight: "900" }}>
                                  {item.is_allowed === 1 ? "公開中" : "審查中"}
                                </Typography>
                              </div>
                            </div>
                          </>
                        )}
                        <div
                          style={{
                            flexGrow: "1",
                            position: "relative",
                            textAlign: "center",
                            paddingLeft: "20px",
                            height: "100%",
                          }}
                        >
                          <div
                            style={
                              props.searchmode === true
                                ? { display: "flex", paddingRight: "20px" }
                                : {
                                  width: "100%",
                                  transform: "translate(0%, 0%)",
                                  position: "relative",
                                  top: "0%",
                                  left: "0%",
                                }
                            }
                          >
                            {props.searchmode === true && (
                              <div style={{ flexGrow: "1" }}></div>
                            )}
                            <Button
                              style={
                                props.searchmode === true
                                  ? {
                                    marginRight: "10px",
                                    color: "black",
                                    background: "white",
                                  }
                                  : {
                                    transform: "translate(-50%,0%)",
                                    position: "relative",
                                    left: "50%",
                                    display: "block",
                                    marginBottom: "20px",
                                    color: "black",
                                    background: "white",
                                  }
                              }
                            >
                              {props.searchmode === true ? "收藏" : "編輯"}
                            </Button>

                            <Button
                              style={
                                props.searchmode === true
                                  ? { color: "white", background: "black" }
                                  : {
                                    transform: "translate(-50%,0%)",
                                    position: "relative",
                                    left: "50%",
                                    display: "block",
                                    color: "white",
                                    background: "black",
                                  }
                              }
                            >
                              {props.searchmode === true ? "分享" : "刪除"}
                            </Button>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}

            {select === "路徑地圖" && (
              <Grid xl={12} lg={12} md={12} sm={12} xs={12} item container>
                {usersData.maps.map((item) => (
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      borderBottom: "1px solid black ",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                    item
                    container
                  >
                    <Grid xl={7} lg={7} md={12} sm={12} xs={12} item>
                      <Typography variant="caption">
                        {covert_to_date_text(item.created_at)}
                      </Typography>
                      <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={"/Mapbox/" + item.id}
                      >
                        <Typography variant="h5">{item.name}</Typography>
                      </Link>
                      <Typography variant="body1">{item.summary}</Typography>
                    </Grid>
                    <Grid
                      xl={5}
                      lg={5}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ display: "flex", alignItems: "center" }}
                      container
                      item
                      alignItems="center"
                    >
                      {props.searchmode === false && (
                        <>
                          <div
                            style={{
                              flexGrow: "1",
                              position: "relative",
                              textAlign: "center",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                              borderRight: "1px solid black ",
                            }}
                          >
                            <div
                              style={{
                                transform: "translate(-50%, 0%)",
                                position: "relative",
                                top: "50%",
                                left: "50%",
                              }}
                            >
                              <Typography
                                variant="h5"
                                style={{ fontSize: "16px" }}
                              >
                                觀看次數
                              </Typography>
                              <Typography style={{ fontWeight: "900" }}>
                                {item.views}
                              </Typography>
                            </div>
                          </div>
                          <div
                            style={{
                              flexGrow: "1",
                              position: "relative",
                              textAlign: "center",
                              paddingLeft: "20px",

                              paddingRight: "20px",
                              borderRight: "1px solid black ",
                            }}
                          >
                            <div
                              style={{
                                transform: "translate(-50%, 0%)",
                                position: "relative",
                                top: "50%",
                                left: "50%",
                              }}
                            >
                              <Typography
                                variant="h5"
                                style={{ fontSize: "16px" }}
                              >
                                地圖狀態
                              </Typography>
                              <Typography style={{ fontWeight: "900" }}>
                                {item.is_publie === 1 ? "公開" : "不公開"}
                              </Typography>
                            </div>
                          </div>
                        </>
                      )}
                      <div
                        style={{
                          flexGrow: "1",
                          position: "relative",
                          textAlign: "center",
                          paddingLeft: "20px",
                        }}
                      >
                        <div
                          style={
                            props.searchmode === true
                              ? { display: "flex", paddingRight: "20px" }
                              : {
                                width: "100%",
                                transform: "translate(0%, 0%)",
                                position: "relative",
                                top: "0%",
                                left: "0%",
                              }
                          }
                        >
                          {props.searchmode === true && (
                            <div style={{ flexGrow: "1" }}></div>
                          )}
                          {/* <Button
                            style={
                              props.searchmode === true
                                ? {
                                    marginRight: "10px",
                                    color: "black",
                                    background: "white",
                                  }
                                : {
                                    transform: "translate(-50%,0%)",
                                    position: "relative",
                                    left: "50%",
                                    display: "block",
                                    marginBottom: "20px",
                                    color: "black",
                                    background: "white",
                                  }
                            }
                          >
                            {props.searchmode === true ? "收藏" : "編輯"}
                          </Button> */}
                          {props.searchmode === false && (
                            <Link
                              to={"/EditMap/" + item.id}
                              style={{ textDecoration: "none" }}
                            >
                              <Button
                                style={
                                  props.searchmode === true
                                    ? {
                                      marginRight: "10px",
                                      color: "black",
                                      background: "white",
                                    }
                                    : {
                                      transform: "translate(-50%,0%)",
                                      position: "relative",
                                      left: "50%",
                                      display: "block",
                                      marginBottom: "20px",
                                      color: "black",
                                      background: "white",
                                    }
                                }
                              >
                                {"編輯"}
                              </Button>
                            </Link>
                          )}
                          <Button
                            onClick={() => {
                              if (props.searchmode === true) {
                                copyCurrentUrl(
                                  "https://uisur.ad.ntust.edu.tw/#/Mapbox/" +
                                  item.id.toString()
                                );
                              } else {
                                setDeleteCheckString(
                                  "確定要刪除 " + item.name + " 地圖嗎？"
                                );
                                setDeleteId(item.id);
                                setDeleteModal(true);
                              }
                            }}
                            style={
                              props.searchmode === true
                                ? { color: "white", background: "black" }
                                : {
                                  transform: "translate(-50%,0%)",
                                  position: "relative",
                                  left: "50%",
                                  display: "block",
                                  color: "white",
                                  background: "black",
                                }
                            }
                          >
                            {props.searchmode === true ? "分享" : "刪除"}
                          </Button>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}
            {select === "MAPS" && <MyMap />}

            {select === "NOTES" && <MyNote />}

            {select === "PINS" && <MyPin />}
          </Grid>
        </div>
      </Hidden>
    </>
  );
};

export default User;
