import "braft-editor/dist/index.css";
import React, { useState, useEffect } from "react";
import BraftEditor from "braft-editor";
import { Paper } from "@material-ui/core";

const HtmlEditor = (props) => {
  const [editorState, setEditorState] = useState(
    BraftEditor.createEditorState(props.content)
  );
  const [outputHtml, setOutputHtml] = useState("");
  /*
  componentDidMount () {
    this.isLivinig = true
    
    setTimeout(this.setEditorContentAsync, 3000)
  }

  componentWillUnmount () {
    this.isLivinig = false
  }

  handleChange = (editorState) => {
    this.setState({
      editorState: editorState,
      outputHTML: editorState.toHTML()
    })
  }*/
  useEffect(() => {
    
    setEditorState(BraftEditor.createEditorState(props.content));
    console.log(props.content);
  }, [props.content]);

  return (
    <Paper>
      <div className="editor-wrapper">
        <BraftEditor
          value={editorState}
          onChange={(editorState) => {
            setEditorState({ editorState });
            //props.htmlHandle(editorState.toHTML());
            //setOutputHtml(editorState.toHTML());
            //props.SetHtmlData(editorState.toHTML());
            //console.log(editorState, "/n", outputHtml, editorState.toHTML());
          }}
          onBlur={(editorState) => {
            //setEditorState({ editorState });
            props.htmlHandle(editorState.toHTML());
            //setOutputHtml(editorState.toHTML());
            //props.SetHtmlData(editorState.toHTML());
            //console.log(editorState, "/n", outputHtml, editorState.toHTML());
          }}
        />
      </div>
      
    </Paper>
  );
};
export default HtmlEditor;
