import React from "react";
import { BrowserRouter, Route, Link, HashRouter } from "react-router-dom";

import {
  createMuiTheme,
  withStyles,
  makeStyles,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import GridList from "@material-ui/core/GridList";
import Chip from "@material-ui/core/Chip";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import FrontAppBar from "../FrontAppBar/FrontAppBar.js";
import { Divider, Hidden, Paper } from "@material-ui/core";
import { set } from "js-cookie";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 0, 0),
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  recommendImg: {
    display: 'block', width: '100%', height: '200px', objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      height: '150px'
    },
  },
  imageList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: 'white',
  },
  titleBar: {
    background:
      'black',
  },
  cardbutton: {
    marginLeft: "auto",
  },
  firstaccordion: {
    margin: theme.spacing(1, 0, 0, 0),
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  dividercolor: {
    backgroundColor: "#424242",

    width: "600 px",
    height: "600 px",
  },
  shareButton: {
    margin: theme.spacing(0, 0, 2, 2),
    color: "#ffffff",
    backgroundColor: "#424242",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#000000",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
  noteButton: {
    margin: theme.spacing(0, 0, 2, 0),
    color: "#ffffff",
    backgroundColor: "#424242",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#000000",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
  gridFirst: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  phoneGridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    boxShadow: 1,
    //height: "20vh",
    width: "100vw",
    //padding: theme.spacing(3)
  },
  gridList: {
    //width: 500,
    boxShadow: 1,
    height: "80vh",
  },
  imgFormat: {
    margin: theme.spacing(1, 1, 0, 0),
    width: "100%",
  },
  chipitem: {
    color: "#ffffff",
    backgroundColor: "#424242",
    margin: theme.spacing(1, 1, 0, 0),
  },
  showAllPlacebutton: {
    width: "100%",
    color: "#ffffff",
    backgroundColor: "#424242",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#000000",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
}));

let textTheme = createMuiTheme();
textTheme = responsiveFontSizes(textTheme);
//MapPage
const Article = (props) => {
  const [data, setData] = useState({
    is_themes: 0,
    content: [{ content: "<p></p>", startYear: "", endYear: "", title: "" }],
    name: "",
    thumbnail: "",
    has_timeline: 0,
    recommends: [],
  });
  var [recommend, setRecommend] = useState([]);
  var [relations, setRelations] = useState([]);
  var [article, setArticle] = useState([]);
  const [open, setOpen] = useState(false);
  const [theAlert, setTheAlert] = useState({ text: "", severity: "error" });
  var [refrash, SetRefrash] = useState(false);
  const classes = useStyles();
  function copyCurrentUrl() {
    var dummy = document.createElement("input"),
      text = window.location.href;

    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    setTheAlert({ text: "複製成功", severity: "success" });
    setOpen(true);
  }
  const handleOnClick = () => {
    //SetRefrash(true);
  };
  useEffect(() => {
    if (refrash) {
      window.location.reload();
    } else {
      SetRefrash(true);
    }
    setRecommend([]);
    setRelations([]);
    setArticle([]);
    const fetchData = async () => {
      var thedata = await Promise.all([
        fetchTagsData(),
        fetchCategoriesData(),
        fetchThemesData(),
        fetchArticlesData(),
        //fetchEditData(),
      ]);
      thedata[2].types.content = JSON.parse(thedata[2].types.content);
      //the Article superurl process
      for (var j = 0; j < thedata[2].types.content.length; j++) {
        thedata[2].types.content[j].content =
          thedata[2].types.content[j].content.split("[[");
        for (var i = 1; i < thedata[2].types.content[j].content.length; i++) {
          thedata[2].types.content[j].content[i] =
            thedata[2].types.content[j].content[i].split("]]");
          thedata[2].types.content[j].content[i][0] =
            '<a href="/#/Article/' +
            thedata[2].types.content[j].content[i][0] +
            '" target="" style="color: #ff7348;">' +
            thedata[2].types.content[j].content[i][0] +
            "</a>";
          thedata[2].types.content[j].content[i] =
            thedata[2].types.content[j].content[i].join("");
          //console.log(i,thedata[2].types.content[i]);
        }
        thedata[2].types.content[j].content =
          thedata[2].types.content[j].content.join("");
      }

      console.log(thedata[2].types);
      setData(thedata[2].types);
    };
    fetchData();
  }, [props.id]);
  //all fetch function
  const fetchArticlesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/articles")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };
  const fetchThemesData = () => {
    return fetch(
      "https://uisur.ad.ntust.edu.tw/backend/api/types/" + props.id.toString()
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { types: myJson };
      });
  };
  const fetchTagsData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/tags")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { tags: myJson };
        //setTagsData(temp);
      });
  };
  const fetchCategoriesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/categories")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { categories: myJson };
        //setTagsData(temp);
      });
  };
  const fetchEditData = () => {
    return fetch(
      "https://uisur.ad.ntust.edu.tw/backend/api/articles/" +
      props.id.toString()
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { editData: myJson };
        //setTagsData(temp);
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ThemeProvider a={props.id.toString()} theme={textTheme}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleClose}
            severity={theAlert.severity}
          >
            {theAlert.text}
          </MuiAlert>
        </Snackbar>
        <FrontAppBar />
        <div>
          <Grid container className={classes.root}>
            {/* <Grid xl={1} lg={1} md={1} sm={1} xs={1} item></Grid> */}

            <Grid item style={{ paddingTop: '10px' }} container xl={10} lg={10} md={10} sm={10} xs={10}>
              {/* <Button size="small" className={classes.noteButton}>
                note
              </Button> */}
              <Button
                onClick={copyCurrentUrl}
                size="small"
                className={classes.shareButton}
              >
                share
              </Button>
              <Grid
                item
                container
                justify="flex-start"
                alignItems="flex-start"
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                {data.represenative_image != null && data.represenative_image != '' &&
                  <Hidden smDown><img width={'100%'} height={'400px'} style={{ objectFit: 'cover' }} src={"https://uisur.ad.ntust.edu.tw/backend/storage/" + data.represenative_image}></img></Hidden>}
                {data.represenative_image != null && data.represenative_image != '' &&
                  <Hidden mdUp><img width={'100%'} height={'200px'} style={{ objectFit: 'cover' }} src={"https://uisur.ad.ntust.edu.tw/backend/storage/" + data.represenative_image}></img></Hidden>}
              </Grid>
              <Grid
                item
                container
                justify="flex-start"
                alignItems="flex-start"
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ paddingTop: '10px' }}
              >
                {data.has_timeline === 1 ? (
                  <Typography variant="h3" style={{ fontWeight: 900 }}>
                    {data.name}
                  </Typography>
                ) : (
                  <Typography
                    style={{ marginBottom: "20px", fontWeight: 550 }}
                    variant="h3"
                  >
                    {data.name}
                  </Typography>
                )}
              </Grid>
              {data.has_timeline === 1 ? (
                <Grid
                  container
                  justify="flex-start"
                  alignItems="flex-start"
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <ButtonGroup
                    fullWidth
                    color="primary"
                    aria-label="outlined primary button group"
                  >
                    <Button fullWidth className={classes.timebutton1}>
                      清朝時期
                    </Button>
                    <Button fullWidth className={classes.timebutton2}>
                      日治時期
                    </Button>
                    <Button fullWidth className={classes.timebutton3}>
                      民國時期
                    </Button>
                  </ButtonGroup>
                  {data.content !== null && (
                    <Timeline align="left">
                      {data.content.map((item) => (
                        <TimelineItem>
                          <TimelineOppositeContent
                            style={{ flex: 0 }}
                          ></TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Paper elevation={0}>
                              <Typography variant="h4">
                                {item.startYear +
                                  (item.endYear !== "" ? "~" : "") +
                                  item.endYear +
                                  " " +
                                  (item.title !== undefined ? item.title : "")}
                              </Typography>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.content,
                                }}
                              />
                            </Paper>
                          </TimelineContent>
                        </TimelineItem>
                      ))}
                    </Timeline>
                  )}
                </Grid>
              ) : (
                <Grid
                  container
                  item
                  justify="flex-start"
                  alignItems="flex-start"
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  {
                    data.content !== null &&
                    data.content.map((item) => (
                      <Grid
                        item
                        justify="flex-start"
                        alignItems="flex-start"
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <Paper style={{ background: '#f8f6f5' }} elevation={0}>
                          <Typography variant="h4" style={{ fontWeight: '900' }}>
                            {item.startYear +
                              (item.endYear !== "" ? "~" : "") +
                              item.endYear +
                              " " +
                              (item.title !== undefined ? item.title : "")}
                          </Typography>
                          <div
                            dangerouslySetInnerHTML={{ __html: item.content.replaceAll('background-color:#ffffff', 'background-color:#f8f6f5').replaceAll("<img", '<img style=\"max-width: 100%; height: auto;\"') }}
                          />
                        </Paper>
                      </Grid>
                    ))}
                </Grid>
              )}

              {data.recommends.length != 0 &&
                <Grid
                  style={{ paddingTop: '20px' }}
                  container
                  item
                  justify="flex-start"
                  alignItems="flex-start"
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}

                >

                </Grid>
              }
              {data.recommends.length != 0 &&
                <Grid
                  container
                  item
                  justify="flex-start"
                  alignItems="flex-start"
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}

                >


                  {data.recommends.map((item) => (
                    <Grid xl={4}
                      lg={4}
                      md={4}
                      sm={6}
                      xs={6} item style={{ padding: '5px', position: 'relative' }}>


                      <Link to={"/themes/" + item.id} ><img src={"https://uisur.ad.ntust.edu.tw/backend/storage/" + item.thumbnail} className={classes.recommendImg} /></Link>
                      <div style={{ alignItems: 'center', display: 'flex', background: 'black', position: 'relative' }}>
                        <Typography style={{ paddingLeft: "5px", flexGrow: '1', fontSize: '20px', color: 'white' }}>
                          {item.name}

                        </Typography>  <Link to={"/themes/" + item.id} > <IconButton style={{}}><ArrowForwardIcon style={{ color: 'white' }}></ArrowForwardIcon></IconButton> </Link> </div>


                    </Grid>
                  ))}



                </Grid>
              }
            </Grid>

          </Grid>
        </div>
      </ThemeProvider >
    </>
  );
};

export default Article;
