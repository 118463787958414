import React from "react";
import { BrowserRouter, Route, Link, HashRouter } from "react-router-dom";

import {
  createMuiTheme,
  withStyles,
  makeStyles,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import FormGroup from "@material-ui/core/FormGroup";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import FrontAppBar from "../FrontAppBar/FrontAppBar.js";
import { Avatar, Card, CardMedia, Divider, Hidden } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import MyNote from "./MyMenuConponent/MyNote";
import AddCon from "./MyMenuConponent/AddCon";
import MyPin from "./MyMenuConponent/MyPin";
import MyMap from "./MyMenuConponent/MyMap";
import Changepassword from "./MyMenuConponent/Changepassword.js";
import Modal from '@material-ui/core/Modal';
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 0, 0),
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  title: {
    fontWeight: "bold",
    fontSize: "1.2rem",
    "@media (min-width:600px)": {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.4rem",
    },
  },
  newLink: {
    fontWeight: "bold",
    textDecoration: "none",
    color: "#000000",
  },
  media: {
    height: 140,
  },
  seeall: {
    textDecoration: "none",
    color: "#7f7f7f",
  },
  chipSelect: {
    background: "black",
    color: "white",
    "&:hover": {
      backgroundColor: "#000000",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
  },
}));

let textTheme = createMuiTheme();
textTheme = responsiveFontSizes(textTheme);
//MapPage
const MyMenu = (props) => {
  var geoData = require("../../data.json");
  const [article, setArticle] = useState();
  const [chipSelect, setChipSelect] = useState("mynote");

  const [type, setType] = useState([
    {
      id: 1,
      name: "history",
      thubnail: null,
      is_themes: 1,
      operator_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
      created_at: null,
      updated_at: null,
      rules: {
        id: 1,
        type_id: 1,
        need_name: 1,
        need_era: 1,
        need_summary: 1,
        need_url: 0,
        need_relation: 1,
        need_tag: 1,
        need_recommend: 0,
        need_visible: 0,
        need_content: 1,
      },
    },
  ]);

  const classes = useStyles();
  //run once after first render for check login
  useEffect(() => {
    const fetchData = async () => {
      const thedata = await Promise.all([
        fetchTagsData(),
        fetchCategoriesData(),
        fetchThemesData(),
        fetchArticlesData(),
      ]);
      console.log(thedata[0].tags);
      // tags = thedata[0].tags;
      // categories = thedata[1].categories;
      // types = thedata[2].types;
      setArticle(thedata[3].articles);
      setType(thedata[2].types);
      //setTagChip(TagChip);
    };
    //fetchData();
  }, []);
  //all fetch function
  const fetchArticlesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/articles")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };
  const fetchThemesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/types")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { types: myJson };
      });
  };
  const fetchTagsData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/tags")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { tags: myJson };
        //setTagsData(temp);
      });
  };
  const fetchCategoriesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/categories")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { categories: myJson };
        //setTagsData(temp);
      });
  };

  return (
    <>
      <ThemeProvider theme={textTheme}>
        <div style={{ padding: " 8px  40px" }}>
          <FrontAppBar />
          <Grid container justify="flex-start">
            <Grid
              container
              direction="column"
              lg={2}
              md={2}
              xs={4}
              xl={2}
              sm={3}
              justify="flex-start"
              alignItems="center"
            >
              <Grid style={{ marginTop: "8px" }}>
                <Avatar></Avatar>
              </Grid>
              <Grid>
                <div
                  style={{
                    background: "black",
                    color: "black",
                    width: "2px",
                    height: "30px",
                  }}
                />
              </Grid>
              <Grid>
                {chipSelect === "mynote" ? (
                  <Chip
                    className={classes.chipSelect}
                    label="my note"
                    variant="outlined"
                  />
                ) : (
                  <Chip
                    //className={classes.chipSelect}
                    label="my note"
                    variant="outlined"
                    onClick={(e) => {
                      setChipSelect("mynote");
                    }}
                  />
                )}
              </Grid>
              <Grid>
                <div
                  style={{
                    background: "black",
                    color: "black",
                    width: "2px",
                    height: "30px",
                  }}
                />
              </Grid>
              <Grid>
                {chipSelect === "mypin" ? (
                  <Chip
                    className={classes.chipSelect}
                    label="my pin"
                    variant="outlined"
                  />
                ) : (
                  <Chip
                    //className={classes.chipSelect}
                    label="my pin"
                    variant="outlined"
                    onClick={(e) => {
                      setChipSelect("mypin");
                    }}
                  />
                )}
              </Grid>
              <Grid>
                <div
                  style={{
                    background: "black",
                    color: "black",
                    width: "2px",
                    height: "30px",
                  }}
                />
              </Grid>
              <Grid>
                {chipSelect === "mymap" ? (
                  <Chip
                    className={classes.chipSelect}
                    label="my map"
                    variant="outlined"
                  />
                ) : (
                  <Chip
                    //className={classes.chipSelect}
                    label="my map "
                    variant="outlined"
                    onClick={(e) => {
                      setChipSelect("mymap");
                    }}
                  />
                )}
              </Grid>
              <Grid>
                <div
                  style={{
                    background: "black",
                    color: "black",
                    width: "2px",
                    height: "30px",
                  }}
                />
              </Grid>

              <Grid>
                {chipSelect === "mycontribution" ? (
                  <Chip
                    className={classes.chipSelect}
                    label="contribution"
                    variant="outlined"
                  />
                ) : (
                  <Chip
                    //className={classes.chipSelect}
                    label="contribution"
                    variant="outlined"
                    onClick={(e) => {
                      setChipSelect("mycontribution");
                    }}
                  />
                )}
              </Grid>
              <Grid>
                <div
                  style={{
                    background: "black",
                    color: "black",
                    width: "2px",
                    height: "30px",
                  }}
                />
              </Grid>

              <Grid>
                {chipSelect === "changepassword" ? (
                  <Chip
                    className={classes.chipSelect}
                    label="change password"
                    variant="outlined"
                  />
                ) : (
                  <Chip
                    //className={classes.chipSelect}
                    label="change password"
                    variant="outlined"
                    onClick={(e) => {
                      setChipSelect("changepassword");
                    }}
                  />
                )}

              </Grid>


              <Grid>
                <div
                  style={{
                    background: "black",
                    color: "black",
                    width: "2px",
                    height: "30px",
                  }}
                />
              </Grid>
              <Grid>
                {chipSelect === "+" ? (
                  <Chip
                    className={classes.chipSelect}
                    label="+"
                    variant="outlined"
                  />
                ) : (
                  <Chip
                    //className={classes.chipSelect}
                    label="+"
                    variant="outlined"
                    onClick={(e) => {
                      setChipSelect("+");
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              lg={10}
              md={10}
              xs={8}
              xl={10}
              sm={9}
            >
              {chipSelect === "+" && <AddCon />}
              {chipSelect === "mypin" && <MyPin />}
              {chipSelect === "mynote" && <MyNote />}
              {chipSelect === "mymap" && <MyMap />}
              {chipSelect === "changepassword" && <Changepassword />}
            </Grid>
          </Grid>
        </div>
      </ThemeProvider>
    </>
  );
};

export default MyMenu;
