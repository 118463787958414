import * as React from "react";
import {
  DataGrid,
  ColDef,
  ValueGetterParams,
  CellParams,
  GridApi,
} from "@material-ui/data-grid";
import Cookies from "js-cookie";
import { Button } from "@material-ui/core";
import {
  HashRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";

export default function User(props) {
  var [access_token, setAccess_token] = React.useState("");

  const columns = (props.type === "user") ? ([
    { field: "uuid", headerName: "id", width: 0, hide: true },
    { field: "account", headerName: "user name", width: 130 },
    { field: "email", headerName: "email", width: 250 },
    {
      field: "created_at",
      headerName: "join date",
      //type: "number",
      width: 170,
    },
    {
      field: "updated_at",
      headerName: "last login",
      //type: "number",
      width: 150,
    }, {
      field: "userdate_type",
      headerName: "升級成 Business",
      sortable: false,
      width: 200,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const thisRow = {};

        const api = params.api;
        const fields = api
          .getAllColumns()
          .map((c) => c.field)
          .filter((c) => c !== "__check__" && !!c);

        fields.forEach((f) => {
          thisRow[f] = params.getValue(f);
        });
        return (
          <Button
            onClick={() => {
              const access_token = Cookies.get("access_token");
              var check = window.confirm("確認要更改為Business嗎?");
              if (!check) {
                return;
              }



              var myHeaders = new Headers();
              myHeaders.append("Authorization", "Bearer " + access_token);
              myHeaders.append("Accept", "application/json")
              myHeaders.append("Content-Type", "application/x-www-form-urlencoded;charset=utf-8")
              var uploadData = new URLSearchParams();

              //uploadData.append("is_visible", JSON.stringify(1));
              uploadData.append("type", "business");

              var requestOptions = {
                method: "PATCH",
                headers: myHeaders,
                body: uploadData,
                redirect: "follow",
              };

              fetch(
                "https://uisur.ad.ntust.edu.tw/backend/api/users/" + thisRow.uuid,
                requestOptions
              )
                .then((response) => {
                  if (response.ok) {
                    alert("變更成功");
                    const fetchData = async () => {
                      const thedata = await Promise.all([fetchSightData()]);
                      console.log(thedata[0].tags);
                      window.location.reload();
                      setRows(thedata[0].tags);
                    };
                    fetchData();
                  } else {
                    alert("變更失敗");
                  }
                  return response.text();
                })
                .then((result) => {
                  console.log(result);
                })
                .catch((error) => console.log("error", error));
            }}
          >

            更改
          </Button>
        );
      },
    },
    {
      field: "update_type",
      headerName: "升級成 Manager",
      sortable: false,
      width: 200,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const thisRow = {};

        const api = params.api;
        const fields = api
          .getAllColumns()
          .map((c) => c.field)
          .filter((c) => c !== "__check__" && !!c);

        fields.forEach((f) => {
          thisRow[f] = params.getValue(f);
        });
        return (
          <Button
            onClick={() => {
              const access_token = Cookies.get("access_token");
              var check = window.confirm("確認要更改為manager嗎?");
              if (!check) {
                return;
              }



              var myHeaders = new Headers();
              myHeaders.append("Authorization", "Bearer " + access_token);
              myHeaders.append("Accept", "application/json")
              myHeaders.append("Content-Type", "application/x-www-form-urlencoded;charset=utf-8")
              var uploadData = new URLSearchParams();

              //uploadData.append("is_visible", JSON.stringify(1));
              uploadData.append("type", "manager");

              var requestOptions = {
                method: "PATCH",
                headers: myHeaders,
                body: uploadData,
                redirect: "follow",
              };

              fetch(
                "https://uisur.ad.ntust.edu.tw/backend/api/users/" + thisRow.uuid,
                requestOptions
              )
                .then((response) => {
                  if (response.ok) {
                    alert("變更成功");
                    const fetchData = async () => {
                      const thedata = await Promise.all([fetchSightData()]);
                      console.log(thedata[0].tags);
                      window.location.reload();
                      setRows(thedata[0].tags);
                    };
                    fetchData();
                  } else {
                    alert("變更失敗");
                  }
                  return response.text();
                })
                .then((result) => {
                  console.log(result);
                })
                .catch((error) => console.log("error", error));
            }}
          >

            更改
          </Button>
        );
      },
    }
  ]) : (props.type === "manager") ? ([
    { field: "uuid", headerName: "id", width: 0, hide: true },
    { field: "account", headerName: "user name", width: 130 },
    { field: "email", headerName: "email", width: 250 },
    {
      field: "created_at",
      headerName: "join date",
      //type: "number",
      width: 170,
    },
    {
      field: "updated_at",
      headerName: "last login",
      //type: "number",
      width: 150,
    },
    {
      field: "update_type",
      headerName: "升級成 Admin",
      sortable: false,
      width: 200,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const thisRow = {};

        const api = params.api;
        const fields = api
          .getAllColumns()
          .map((c) => c.field)
          .filter((c) => c !== "__check__" && !!c);

        fields.forEach((f) => {
          thisRow[f] = params.getValue(f);
        });

        return (
          <Button
            onClick={() => {
              const access_token = Cookies.get("access_token");
              var check = window.confirm("確認要更改為Admin嗎?");
              if (!check) {
                return;
              }
              var myHeaders = new Headers();
              myHeaders.append("Authorization", "Bearer " + access_token);
              myHeaders.append("Accept", "application/json")
              myHeaders.append("Content-Type", "application/x-www-form-urlencoded;charset=utf-8")
              var uploadData = new URLSearchParams();

              //uploadData.append("is_visible", JSON.stringify(1));
              uploadData.append("type", "admin");

              var requestOptions = {
                method: "PATCH",
                headers: myHeaders,
                body: uploadData,
                redirect: "follow",
              };

              fetch(
                "https://uisur.ad.ntust.edu.tw/backend/api/users/" + thisRow.uuid,
                requestOptions
              )
                .then((response) => {
                  if (response.ok) {
                    alert("變更成功");
                    const fetchData = async () => {
                      const thedata = await Promise.all([fetchSightData()]);
                      console.log(thedata[0].tags);
                      window.location.reload();
                      setRows(thedata[0].tags);
                    };
                    fetchData();
                  } else {
                    alert("變更失敗");
                  }
                  return response.text();
                })
                .then((result) => {
                  console.log(result);
                })
                .catch((error) => console.log("error", error));
            }}
          >

            更改
          </Button>
        );
      },
    },
    {
      field: "dedate_type",
      headerName: "降級成 User",
      sortable: false,
      width: 200,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const thisRow = {};

        const api = params.api;
        const fields = api
          .getAllColumns()
          .map((c) => c.field)
          .filter((c) => c !== "__check__" && !!c);

        fields.forEach((f) => {
          thisRow[f] = params.getValue(f);
        });
        return (
          <Button
            onClick={() => {
              const access_token = Cookies.get("access_token");
              var check = window.confirm("確認要更改為User嗎?");
              if (!check) {
                return;
              }



              var myHeaders = new Headers();
              myHeaders.append("Authorization", "Bearer " + access_token);
              myHeaders.append("Accept", "application/json")
              myHeaders.append("Content-Type", "application/x-www-form-urlencoded;charset=utf-8")
              var uploadData = new URLSearchParams();

              //uploadData.append("is_visible", JSON.stringify(1));
              uploadData.append("type", "user");

              var requestOptions = {
                method: "PATCH",
                headers: myHeaders,
                body: uploadData,
                redirect: "follow",
              };

              fetch(
                "https://uisur.ad.ntust.edu.tw/backend/api/users/" + thisRow.uuid,
                requestOptions
              )
                .then((response) => {
                  if (response.ok) {
                    alert("變更成功");
                    const fetchData = async () => {
                      const thedata = await Promise.all([fetchSightData()]);
                      console.log(thedata[0].tags);
                      window.location.reload();
                      setRows(thedata[0].tags);
                    };
                    fetchData();
                  } else {
                    alert("變更失敗");
                  }
                  return response.text();
                })
                .then((result) => {
                  console.log(result);
                })
                .catch((error) => console.log("error", error));
            }}
          >

            更改
          </Button>
        );
      },
    }
  ]) : (props.type === 'admin') ? (
    [
      { field: "uuid", headerName: "id", width: 0, hide: true },
      { field: "account", headerName: "user name", width: 130 },
      { field: "email", headerName: "email", width: 250 },
      {
        field: "created_at",
        headerName: "join date",
        //type: "number",
        width: 170,
      },
      {
        field: "updated_at",
        headerName: "last login",
        //type: "number",
        width: 150,
      },
      {
        field: "dedate_type",
        headerName: "降級成 Manager",
        sortable: false,
        width: 200,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          const thisRow = {};

          const api = params.api;
          const fields = api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== "__check__" && !!c);

          fields.forEach((f) => {
            thisRow[f] = params.getValue(f);
          });
          return (
            <Button
              onClick={() => {
                const access_token = Cookies.get("access_token");
                var check = window.confirm("確認要更改為manager嗎?");
                if (!check) {
                  return;
                }



                var myHeaders = new Headers();
                myHeaders.append("Authorization", "Bearer " + access_token);
                myHeaders.append("Accept", "application/json")
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded;charset=utf-8")
                var uploadData = new URLSearchParams();

                //uploadData.append("is_visible", JSON.stringify(1));
                uploadData.append("type", "manager");

                var requestOptions = {
                  method: "PATCH",
                  headers: myHeaders,
                  body: uploadData,
                  redirect: "follow",
                };

                fetch(
                  "https://uisur.ad.ntust.edu.tw/backend/api/users/" + thisRow.uuid,
                  requestOptions
                )
                  .then((response) => {
                    if (response.ok) {
                      alert("變更成功");
                      const fetchData = async () => {
                        const thedata = await Promise.all([fetchSightData()]);
                        console.log(thedata[0].tags);
                        window.location.reload();
                        setRows(thedata[0].tags);
                      };
                      fetchData();
                    } else {
                      alert("變更失敗");
                    }
                    return response.text();
                  })
                  .then((result) => {
                    console.log(result);
                  })
                  .catch((error) => console.log("error", error));
              }}
            >

              更改
            </Button>
          );
        },
      }
    ]) : ([
      { field: "uuid", headerName: "id", width: 0, hide: true },
      { field: "account", headerName: "user name", width: 130 },
      { field: "email", headerName: "email", width: 250 },
      {
        field: "created_at",
        headerName: "join date",
        //type: "number",
        width: 170,
      },
      {
        field: "updated_at",
        headerName: "last login",
        //type: "number",
        width: 150,
      },
      {
        field: "dedate_type",
        headerName: "降級成 User",
        sortable: false,
        width: 200,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          const thisRow = {};

          const api = params.api;
          const fields = api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== "__check__" && !!c);

          fields.forEach((f) => {
            thisRow[f] = params.getValue(f);
          });
          return (
            <Button
              onClick={() => {
                const access_token = Cookies.get("access_token");
                var check = window.confirm("確認要更改為User嗎?");
                if (!check) {
                  return;
                }



                var myHeaders = new Headers();
                myHeaders.append("Authorization", "Bearer " + access_token);
                myHeaders.append("Accept", "application/json")
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded;charset=utf-8")
                var uploadData = new URLSearchParams();

                //uploadData.append("is_visible", JSON.stringify(1));
                uploadData.append("type", "user");

                var requestOptions = {
                  method: "PATCH",
                  headers: myHeaders,
                  body: uploadData,
                  redirect: "follow",
                };

                fetch(
                  "https://uisur.ad.ntust.edu.tw/backend/api/users/" + thisRow.uuid,
                  requestOptions
                )
                  .then((response) => {
                    if (response.ok) {
                      alert("變更成功");
                      const fetchData = async () => {
                        const thedata = await Promise.all([fetchSightData()]);
                        console.log(thedata[0].tags);
                        window.location.reload();
                        setRows(thedata[0].tags);
                      };
                      fetchData();
                    } else {
                      alert("變更失敗");
                    }
                    return response.text();
                  })
                  .then((result) => {
                    console.log(result);
                  })
                  .catch((error) => console.log("error", error));
              }}
            >

              更改
            </Button>
          );
        },
      }
    ]);
  var [rows, setRows] = React.useState([
    {
      id: 1,
      account: "ds",
      name: "history",
      thumbnail: null,
      is_themes: 1,
      operator_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
      created_at: null,
      updated_at: null,
      views: 0,
      rules: {
        id: 1,
        type_id: 1,
        need_name: 1,
        need_era: 1,
        need_summary: 1,
        need_url: 0,
        need_relation: 1,
        need_tag: 1,
        need_recommend: 0,
        need_visible: 0,
        need_content: 1,
      },
    },
  ]);
  React.useEffect(() => {
    const fetchData = async () => {
      var thedata = await Promise.all([fetchSightData()]);

      console.log(thedata[0].tags);
      thedata[0].tags = thedata[0].tags.map((tag) => {
        //tag.uploadedby = tag.creator.account;
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        const cd = new Date(tag.created_at);
        const ud = new Date(tag.updated_at);
        tag.id = tag.account;
        tag.created_at =
          months[cd.getMonth()] + " " + cd.getDate() + " , " + cd.getFullYear();
        tag.updated_at =
          months[ud.getMonth()] + " " + ud.getDate() + " , " + ud.getFullYear();
        return tag;
      });
      if (props.type === "user") {
        thedata[0].tags = thedata[0].tags.filter(
          (tag) => tag.type === "user"
        );
      }
      if (props.type === "admin") {
        thedata[0].tags = thedata[0].tags.filter(
          (tag) => tag.type === "admin"
        );
      }
      if (props.type === "manager") {
        thedata[0].tags = thedata[0].tags.filter(
          (tag) => tag.type === "manager"
        );
      }
      if (props.type === "business") {
        thedata[0].tags = thedata[0].tags.filter(
          (tag) => tag.type === "business"
        );
      }

      setRows(thedata[0].tags);
    };
    fetchData();
  }, []);
  const fetchSightData = () => {
    access_token = Cookies.get("access_token");
    setAccess_token(access_token);
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/users", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { tags: myJson };
        //setTagsData(temp);
      });
  };

  return (
    <div style={{ height: 650, width: "100%" }}>
      <DataGrid rows={rows} columns={columns} rowsPerPageOptions={[10, 20, 50]} pageSize={10} pagination />
    </div>
  );
}
