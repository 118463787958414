import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {
  HashRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";
import Cookies from "js-cookie";
import AppBar from "@material-ui/core/AppBar";
import SearchIcon from "@material-ui/icons/Search";
import _ from "underscore";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Botton from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { deepOrange, deepPurple } from "@material-ui/core/colors";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, fade } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    /*[theme.breakpoints.up("sm")]: */
    /*width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,*/
    width: "100%",
    background: "#000000",
    top: 0,
    //left: "2.5%",
    zIndex: theme.zIndex.drawer + 1,

    //borderColor: 'text.primary',
    //borders:"#ffffff 5px solid",
    boxShadow: "none",

    /*[theme.breakpoints.down("sm")]: {
      background: "ffffffff",
      width: "100%",
    },*/
  },
  appbarbox: {
    //margin:theme.spacing(10,10,10,10),
  },
  appbarbotton: {
    margin: theme.spacing(0, 0, 0, 1),
    flexGrow: 1,
    textTransform: "none",
  },
  appbarfirstbutton: {
    [theme.breakpoints.up("sm")]: {
      margin: "0 0 0 0",
    },
    [theme.breakpoints.up("md")]: {
      margin: "0 0 0 30%",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "0 0 0 50%",
    },
    textTransform: "none",
    flexGrow: 1,
  },

  searchbutton: {
    [theme.breakpoints.down("md")]: {
      // flexGrow: 1,
    },
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    marginLeft: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
  },
  subtitle: {
    marginLeft: theme.spacing(3),
    fontWeight: "400",
    flexGrow: 1,
  },
  buttongroupMargin: {
    [theme.breakpoints.down("sm")]: {
      flexGrow: 1,
    },
  },
  toolbar: {},
  //to fix appbar
  offset: theme.mixins.toolbar,
}));
const ManageAppBar = (props) => {
  const [themeAnchorEl, setThemeAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setThemeAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setThemeAnchorEl(null);
  };

  const isLogin = props.isLogin;
  //const [isLogin, setIslogin] = useState(false);
  const [activeItem, setActiveItem] = useState("");
  var [username, setUsername] = useState("");
  const [access_token, setAccess_token] = useState("");
  const [drawerState, setDrawerState] = useState(false);
  //const { username } = props;
  const classes = useStyles();
  useEffect(() => {
    document.title = `大稻埕管理系統`;
    username = Cookies.get("accountName");
    if (username === undefined) username = "";
    setUsername(username);
    // Run! Like go get some data from an API.
    // let name = "access_token";
    // let value = parseCookie()[name];
    // if (value) value = decodeURIComponent(value);
    // if (typeof value != "undefined") {
    //   setIslogin(true);
    //   setAccess_token(value);
    //   //console.log("查詢值為: " + value);
    // } else {
    //   setIslogin(false);
    //   setAccess_token("");
    // }
  }, []);

  const handleLogin = (flag, username, access_token) => {};

  const handleLogout = () => {};

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appbar}>
        <Toolbar
          className={classes.toolbar}
          //style={{ borderBottom: "1px solid black" }}
        >
          <Typography
            variant="h5"
            style={{ color: "white" }}
            align="center"
            className={classes.title}
          >
            Dadaocheng
          </Typography>
          <Typography
            variant="h6"
            style={{ color: "white" }}
            className={classes.subtitle}
          >
            Information System
          </Typography>
          <Typography variant="body" style={{ color: "white" }}>
            welcome,
          </Typography>
          <Avatar className={classes.orange}>{username.substring(0, 2)}</Avatar>
        </Toolbar>
      </AppBar>

      <div
        className={classes.offset}
        // style={{
        //   borderBottom: "1px solid black",
        //   width: "95%",
        //   marginLeft: "2.5%",
        // }}
      />
    </div>
  );
};

export default ManageAppBar;
