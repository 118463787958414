import React from "react";
import { BrowserRouter, Route, Link, HashRouter } from "react-router-dom";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import {
  createMuiTheme,
  withStyles,
  makeStyles,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import Cookies from "js-cookie";
import GridList from "@material-ui/core/GridList";
import Chip from "@material-ui/core/Chip";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import FrontAppBar from "../FrontAppBar/FrontAppBar.js";
import { Avatar, Divider, Hidden, Modal } from "@material-ui/core";
import { set } from "js-cookie";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 0, 0),
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  marginTheLeft: {
    marginLeft: theme.spacing(2),
  },
  marginTheRight: {
    marginRight: theme.spacing(2),
  },
  cardbutton: {
    marginLeft: "auto",
  },
  firstaccordion: {
    margin: theme.spacing(1, 0, 0, 0),
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  dividercolor: {
    backgroundColor: "#424242",

    width: "600 px",
    height: "600 px",
  },
  articleNameBlock: {
    [theme.breakpoints.up("lg")]: {
      background: "white",
      paddingBottom: "50px",
      paddingTop: "50px",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    [theme.breakpoints.down("md")]: {
      paddingBottom: "0px",
      paddingTop: "10px",
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  recommendImg: {
    display: "block",
    width: "100%",
    height: "200px",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      height: "150px",
    },
  },
  shareButton: {
    margin: theme.spacing(0, 0, 2, 2),
    backgroundColor: "#ffffff",
    color: "#000000",
    fontSize: "16px",
    textTransform: "none",
    border: "5px solid white",
    borderRadius: "0",
    fontWeight: "900",
    whiteSpace: "nowrap",
    paddingLeft: "5px",
    paddingRight: "5px",
    "&:hover": {
      backgroundColor: "#ff7348",
      border: "5px solid #ff7348",
      //borderColor: '#0062cc',
      color: "white",
      boxShadow: "none",
    },
  },
  notedButton: {
    margin: theme.spacing(0, 0, 2, 0),
    backgroundColor: "#ff7348",
    //color: "#000000",
    fontSize: "16px",
    textTransform: "none",
    border: "5px solid #ff7348",
    color: "white",
    fontWeight: "900",
    borderRadius: "0",
    whiteSpace: "nowrap",
    paddingLeft: "5px",
    paddingRight: "5px",
    "&:hover": {
      backgroundColor: "#ffffff",
      //borderColor: '#0062cc',
      border: "5px solid #ffffff",
      boxShadow: "none",
      color: "black",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
  noteButton: {
    margin: theme.spacing(0, 0, 2, 0),
    backgroundColor: "#ffffff",
    color: "#000000",
    fontSize: "16px",
    textTransform: "none",
    border: "5px solid white",
    borderRadius: "0",
    fontWeight: "900",
    paddingLeft: "5px",
    paddingRight: "5px",
    "&:hover": {
      backgroundColor: "#ff7348",
      //borderColor: '#0062cc',
      border: "5px solid #ff7348",
      color: "white",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
  gridFirst: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  articleContent: {
    [theme.breakpoints.up("lg")]: {
      paddingTop: "20px",
      paddingRight: "30px",
      paddingLeft: "20px",
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: "0px",
      paddingRight: "0px",
      paddingLeft: "0px",
    },
  },
  articleWriter: {
    display: "flex",
    paddingTop: "8px",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "20px",
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: "0px",
      paddingRight: "0px",
      paddingLeft: "0px",
    },
  },
  phoneGridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    boxShadow: 1,
    //height: "20vh",
    width: "100vw",
    //padding: theme.spacing(3)
  },
  gridList: {
    //width: 500,
    boxShadow: 1,
    height: "80vh",
  },
  modal: {
    display: "flex",
    paddingLeft: "10%",
    paddingRight: "10%",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  imgFormat: {
    aspectRatio: "1/1",
    width: "99.5%",
    objectFit: "cover",
    display: "block",
  },
  rightbar: {
    padding: "20px",
    marginTop: "20px",
    background: "#d0d0d04f",

    // [theme.breakpoints.up("md")]: {
    //   paddingLeft: "24px",
    // },
  },
  chipitem: {
    color: "#ffffff",
    backgroundColor: "#424242",
    margin: theme.spacing(1, 1, 0, 0),
    "&:hover": {
      backgroundColor: "#000000",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
  },

  showAllPlacebutton: {
    width: "100%",
    color: "#ffffff",
    backgroundColor: "#424242",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#000000",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
}));

let textTheme = createMuiTheme();
textTheme = responsiveFontSizes(textTheme);
//MapPage
const Article = (props) => {
  const [data, setData] = useState({
    id: 0,
    type_id: 0,
    name: "正在載入",
    start_year: null,
    end_year: null,
    summary: "",
    title: "",
    url: null,
    relations_uuid: "aa1b408f-3b37-49a4-b01d-809592444499",
    content: "",
    weight: 0,
    thumbnail: null,
    views: 90,
    is_visible: 0,
    is_drafted: 1,
    is_allowed: 1,
    creator_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
    checker_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
    available_at: null,
    created_at: "2021-03-01T10:09:16.000000Z",
    updated_at: "2021-03-02T03:24:37.000000Z",
    latitude: null,
    longitude: null,
    images: [],
    creator: {
      account: "",
      type: "",
      uuid: "",
    },
    categories: [
      {
        id: 1,
        name: "",
        parent_id: null,
      },
    ],
    tags: [
      {
        id: 9,
        name: "",
      },
    ],
    relations: [],
    recommends: [],
  });
  var [note, setNote] = useState(false);
  var [access_token, setAccess_token] = useState("");
  var [recommend, setRecommend] = useState([]);
  var [relations, setRelations] = useState([]);
  var [article, setArticle] = useState([]);
  var [refrash, SetRefrash] = useState(false);
  var [imgPath, setImgPath] = useState("");
  const [imgModal, setImgModal] = useState(false);

  const [open, setOpen] = useState(false);
  const [theAlert, setTheAlert] = useState({ text: "", severity: "error" });
  function copyCurrentUrl() {
    var dummy = document.createElement("input"),
      text = window.location.href;

    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    setTheAlert({ text: "複製成功", severity: "success" });
    setOpen(true);
  }
  const classes = useStyles();
  const handleOnClick = () => {
    //SetRefrash(true);
  };
  useEffect(() => {
    if (refrash) {
      window.location.reload();
    } else {
      SetRefrash(true);
    }
    setRecommend([]);
    setRelations([]);
    setArticle([]);
    access_token = Cookies.get("access_token");
    if (access_token === undefined) {
      access_token = "";
    }
    setAccess_token(access_token);
    const fetchData = async () => {
      var thedata = await Promise.all([
        fetchTagsData(),
        fetchCategoriesData(),
        fetchThemesData(),
        fetchArticlesData(),
        fetchEditData(),
        fetchMyPin(),
      ]);
      console.log(thedata[5].articles, access_token, props.id);

      if (access_token !== "")
        setNote(
          thedata[5].articles.some((item) => {
            return item.id.toString() === thedata[4].editData.id.toString();
          })
        );
      article = thedata[3].articles;
      setArticle(thedata[3].articles);
      for (var j = 0; j < thedata[4].editData.relations.length; j++) {
        for (var i = 0; i < thedata[3].articles.length; i++) {
          if (
            thedata[4].editData.relations[j] === thedata[3].articles[i].name
          ) {
            var temp = {
              name: thedata[3].articles[i].name,
              id: thedata[3].articles[i].id,
            };
            relations = [temp, ...relations];
          }
        }
      }

      setRelations([...relations]);
      console.log("the edit data is", props.id, thedata[4].editData);

      //the Article superurl process
      thedata[4].editData.content = thedata[4].editData.content.split("[[");
      for (var i = 1; i < thedata[4].editData.content.length; i++) {
        thedata[4].editData.content[i] =
          thedata[4].editData.content[i].split("]]");
        thedata[4].editData.content[i][0] = thedata[4].editData.content[
          i
        ][0].replace(/<(?:.|\n)*?>/gm, "");
        thedata[4].editData.content[i][0] =
          '<a href="/#/Article/' +
          thedata[4].editData.content[i][0] +
          '" target="" style="color: #ff7348;">' +
          thedata[4].editData.content[i][0] +
          "</a>";
        thedata[4].editData.content[i] =
          thedata[4].editData.content[i].join("");
        //console.log(i,thedata[4].editData.content[i]);
      }
      thedata[4].editData.content = thedata[4].editData.content.join("");

      //thedata[4].editData.content=thedata[4].editData.content.replace(new RegExp('\\[\\[', 'g'), '<a href=\"http://localhost/#/ManageInterface/DatasPage\" target=\"\">the url</a>');
      //thedata[4].editData.content=thedata[4].editData.content.replace(new RegExp('\\]\\]', 'g'), '</a>');
      //<a href=\"http://localhost/#/ManageInterface/DatasPage\" target=\"\">http://localhost/#/ManageInterface/DatasPage</a>
      console.log(thedata[4].editData.content);
      //thedata[4].editData.content.
      setData({ ...thedata[4].editData });

      console.log(article);
      setRecommend([...thedata[4].editData.recommends]);
    };
    fetchData();
    return function cleanup() {
      window.speechSynthesis.cancel();
    };
  }, [props.id]);
  const date_to_string = () => {
    const date = new Date(data.created_at);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return (
      months[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear()
    );
  };
  //all fetch function
  const fetchArticlesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/articles", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };
  const fetchMyPin = () => {
    return fetch(
      "https://uisur.ad.ntust.edu.tw/backend/api/favorites/articles",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };
  const fetchThemesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/types")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { types: myJson };
      });
  };
  const fetchTagsData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/tags")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { tags: myJson };
        //setTagsData(temp);
      });
  };
  const fetchCategoriesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/categories")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { categories: myJson };
        //setTagsData(temp);
      });
  };
  const fetchEditData = () => {
    const access_token = Cookies.get("access_token");
    return fetch(
      "https://uisur.ad.ntust.edu.tw/backend/api/articles/" +
        props.id.toString(),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { editData: myJson };
        //setTagsData(temp);
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ThemeProvider a={props.id.toString()} theme={textTheme}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleClose}
            severity={theAlert.severity}
          >
            {theAlert.text}
          </MuiAlert>
        </Snackbar>
        <FrontAppBar />
        <Modal
          className={classes.modal}
          open={imgModal}
          onClose={() => {
            setImgModal(false);
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={{ position: "relative" }}>
            {" "}
            <img
              style={{ aspectRatio: "1/1", width: "100%" }}
              src={"https://uisur.ad.ntust.edu.tw/backend/storage/" + imgPath}
            ></img>
            <Button
              onClick={() => {
                setImgModal(false);
              }}
              style={{
                background: "white",
                position: "absolute",
                top: "0",
                right: "0",
              }}
            >
              ✖
            </Button>
          </div>
        </Modal>
        <div>
          <Grid container spacing={0} className={classes.root}>
            <Grid xl={1} lg={1} md={1} sm={1} xs={1} item></Grid>
            <Grid
              item
              style={{ display: "flex", paddingTop: "10px" }}
              container
              xl={10}
              lg={10}
              md={10}
              sm={10}
              xs={10}
            >
              <Grid
                item
                style={{ display: "flex" }}
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <Button
                  onClick={() => {
                    if (access_token === "") {
                      setTheAlert({
                        text: "登入以啟用收藏功能",
                        severity: "info",
                      });
                      setOpen(true);
                    } else if (!note) {
                      //var status_Number;
                      const respond = fetch(
                        "https://uisur.ad.ntust.edu.tw/backend/api/favorites/articles",
                        {
                          method: "POST",
                          headers: {
                            Authorization: `Bearer ${access_token}`,
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({
                            article_id: data.id,
                          }),
                        }
                      )
                        .then((res) => {
                          console.log(res.ok);
                          if (res.ok) {
                            setTheAlert({
                              text: "收藏成功",
                              severity: "success",
                            });
                            setOpen(true);
                            setNote(true);
                            //fetchData();
                            //setTagEditModal(false);
                          } else {
                            setTheAlert({
                              text: "收藏失敗",
                              severity: "error",
                            });
                            setOpen(true);
                          }
                          return res.json();
                        })
                        .then((result) => {
                          //console.log(result);
                        });
                    } else if (note) {
                      {
                        //var status_Number;
                        const respond = fetch(
                          "https://uisur.ad.ntust.edu.tw/backend/api/favorites/articles/" +
                            data.id.toString(),
                          {
                            method: "DELETE",
                            headers: {
                              Authorization: `Bearer ${access_token}`,
                              "Content-Type": "application/json",
                            },
                          }
                        )
                          .then((res) => {
                            console.log(res.ok);
                            if (res.ok) {
                              setTheAlert({
                                text: "刪除收藏成功",
                                severity: "success",
                              });
                              setOpen(true);
                              setNote(false);
                              //fetchData();
                              //setTagEditModal(false);
                            } else {
                              setTheAlert({
                                text: "刪除收藏失敗",
                                severity: "error",
                              });
                              setOpen(true);
                            }
                            return res.json();
                          })
                          .then((result) => {
                            //console.log(result);
                          });
                      }
                    }
                  }}
                  size="small"
                  className={!note ? classes.noteButton : classes.notedButton}
                >
                  {!note ? "收藏" : "取消收藏"}
                </Button>
                <Button
                  size="small"
                  onClick={copyCurrentUrl}
                  className={classes.shareButton}
                >
                  分享
                </Button>
                <div style={{ flexGrow: "1" }} />
                <Button
                  size="small"
                  onClick={(e) => {
                    var htmlTagPattern = /<{1}[^>]{1,}>{1}/g; //HTML Tag Pattern
                    var article_content = data.content.replace(
                      htmlTagPattern,
                      ""
                    ); //移除HTML Tag
                    // e.target.style.backgroundColor = '#ff7348'
                    var msg = new SpeechSynthesisUtterance(
                      data.name + "," + data.summary + "," + article_content
                    );
                    msg.lang = "zh-TW";
                    window.speechSynthesis.cancel();
                    window.speechSynthesis.speak(msg);
                  }}
                  className={classes.shareButton}
                >
                  朗讀文章
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    if (
                      window.speechSynthesis.pending |
                      window.speechSynthesis.paused
                    ) {
                      window.speechSynthesis.resume();
                    } else if (window.speechSynthesis.speaking) {
                      window.speechSynthesis.pause();
                    }
                  }}
                  className={classes.shareButton}
                >
                  停止朗讀
                </Button>
              </Grid>
              <Grid container xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid
                  container
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.articleNameBlock}
                >
                  <div style={{ flexGrow: 1 }} />
                  <Grid
                    style={{ display: "flex" }}
                    item
                    xl={5}
                    lg={5}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    {/* <div style={{ flexGrow: "1" }} /> */}
                    <Hidden lgUp>
                      <img
                        //style={{ maxHeight: "400px" }}
                        src={
                          data.thumbnail
                            ? "https://uisur.ad.ntust.edu.tw/backend/storage/" +
                              data.thumbnail
                            : require("../HomePage/assets/exploreMap.png")
                        }
                        style={{ paddingBottom: "10px" }}
                        width="100%"
                      />
                    </Hidden>
                    <Hidden mdDown>
                      <img
                        style={{ maxHeight: "400px" }}
                        src={
                          data.thumbnail
                            ? "https://uisur.ad.ntust.edu.tw/backend/storage/" +
                              data.thumbnail
                            : require("../HomePage/assets/exploreMap.png")
                        }
                        // width="100%"
                      />
                    </Hidden>
                    {/* <div style={{ flexGrow: "1" }} /> */}
                  </Grid>
                  <Hidden lgUp>
                    <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
                      <Typography variant="h3" style={{ fontWeight: "950" }}>
                        {data.name}
                      </Typography>
                      <Typography
                        variant="h3"
                        style={{ lineHeight: "60px", fontWeight: "100" }}
                      >
                        {(data.start_year !== null ? data.start_year : "") +
                          (data.end_year !== null &&
                          data.end_year !== data.start_year
                            ? " ~ " + data.end_year
                            : "")}
                      </Typography>
                    </Grid>
                  </Hidden>

                  <Hidden mdDown>
                    <Grid
                      style={{ paddingLeft: "50px", paddingRight: "80px" }}
                      item
                      xl={5}
                      lg={5}
                      md={12}
                      sm={12}
                      xs={12}
                    >
                      <Typography variant="h3" style={{ fontWeight: "950" }}>
                        {data.name}
                      </Typography>
                      <Typography
                        variant="h3"
                        style={{ lineHeight: "60px", fontWeight: "100" }}
                      >
                        {(data.start_year !== null ? data.start_year : "") +
                          (data.end_year !== null &&
                          data.end_year !== data.start_year
                            ? " ~ " + data.end_year
                            : "")}
                      </Typography>
                      {data.summary != "" && (
                        <Typography
                          style={{
                            paddingTop: "10px",
                            lineHeight: "30px",
                            fontSize: "20px",
                            textJustify: "inter-ideograph",
                            textAlign: "justify",
                            whiteSpace: "pre-line",
                          }}
                          variant="body1"
                        >
                          {data.summary}
                        </Typography>
                      )}
                    </Grid>
                  </Hidden>
                  <div style={{ flexGrow: 1 }} />
                </Grid>
                <Grid
                  container
                  alignItems="flex-start"
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <Grid item container xl={8} lg={8} md={12} sm={12} xs={12}>
                    <Grid
                      style={{}}
                      className={classes.articleWriter}
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      alignItems="center"
                    >
                      <Link to={"/user/" + data.creator.uuid}>
                        {data.creator.type === "user" ? (
                          <Avatar
                            style={{
                              border: "2px solid black",
                              aspectRatio: "1",
                              height: "35px",
                              width: "35px",
                            }}
                          />
                        ) : data.creator.type === "business" ? (
                          <Avatar
                            style={{
                              border: "2px solid green",
                              aspectRatio: "1",
                              height: "35px",
                              width: "35px",
                            }}
                          />
                        ) : (
                          <Avatar
                            style={{
                              border: "2px solid #ff7348",
                              aspectRatio: "1",
                              height: "35px",
                              width: "35px",
                            }}
                          />
                        )}
                      </Link>
                      {data.creator.type === "user" ? (
                        <Typography
                          display="inline"
                          style={{
                            paddingLeft: "10px",
                            color: "black",
                            fontWeight: "900",
                          }}
                        >
                          {data.creator.name != null
                            ? data.creator.name
                            : data.creator.account}
                        </Typography>
                      ) : data.creator.type === "business" ? (
                        <Typography
                          display="inline"
                          style={{
                            paddingLeft: "10px",
                            color: "green",
                            fontWeight: "900",
                          }}
                        >
                          {data.creator.name != null
                            ? data.creator.name
                            : data.creator.account}
                        </Typography>
                      ) : (
                        <Typography
                          display="inline"
                          style={{
                            paddingLeft: "10px",
                            color: "#ff7348",
                            fontWeight: "900",
                          }}
                        >
                          {data.creator.name != null
                            ? data.creator.name
                            : data.creator.account}
                        </Typography>
                      )}
                      <Typography
                        display="inline"
                        style={{
                          color: "#686868",
                          paddingLeft: "10px",
                          fontWeight: "900",
                        }}
                      >
                        {" " + date_to_string()}
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.articleContent}
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.content
                            .replaceAll("#ffffff", "#f8f6f5")
                            .replaceAll(
                              "<img",
                              '<img style="max-width: 100%; height: auto;"'
                            ),
                        }}
                      />
                    </Grid>
                    {data.images.length !== 0 && (
                      <Grid
                        item
                        wrap="nowrap"
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <Typography
                          variant="h5"
                          style={{
                            fontFamily: ["Noto Sans TC", "sans-serif"].join(
                              ","
                            ),
                            fontWeight: "900",
                            marginTop: "20px",
                          }}
                        >
                          Photo Gallery
                        </Typography>
                        {/* <Divider /> */}
                        <Grid container style={{}}>
                          {data.images.length > 0 &&
                            data.images.map((item) => (
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={3}
                                style={{
                                  paddingLeft: "8px",
                                  paddingRight: "8px",
                                  paddingTop: "15px",
                                }}
                              >
                                <img
                                  className={classes.imgFormat}
                                  onClick={() => {
                                    setImgPath(item.image_path);
                                    setImgModal(true);
                                  }}
                                  src={
                                    "https://uisur.ad.ntust.edu.tw/backend/storage/" +
                                    item.image_path
                                  }
                                ></img>
                              </Grid>
                            ))}
                        </Grid>
                        <div className={classes.dividercolor}> </div>
                      </Grid>
                    )}
                    {recommend.length !== 0 && (
                      <Grid
                        item
                        wrap="nowrap"
                        style={{ paddingTop: "20px" }}
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        {/* <Typography variant="h5"
                          style={{
                            fontFamily: ["Noto Sans TC", "sans-serif"].join(
                              ","
                            ),
                            fontWeight: "900",
                            marginTop: "20px",
                          }}>推薦頁面</Typography> */}
                        <Divider
                          variant="middle"
                          style={{
                            marginLeft: "15%",
                            marginRight: "15%",
                            background: "rgb(0 0 0 / 95%)",
                          }}
                        />

                        <Grid
                          container
                          style={{ paddingLeft: "5px", paddingRight: "5px" }}
                        >
                          {recommend.map((item) => (
                            <Grid
                              xl={4}
                              lg={4}
                              md={4}
                              sm={6}
                              xs={6}
                              item
                              style={{ padding: "15px", position: "relative" }}
                            >
                              <Link to={"/Article/" + item.name}>
                                <img
                                  src={
                                    "https://uisur.ad.ntust.edu.tw/backend/storage/" +
                                    item.thumbnail
                                  }
                                  className={classes.recommendImg}
                                />
                              </Link>
                              <div
                                style={{
                                  alignItems: "center",
                                  display: "flex",
                                  background: "black",
                                  position: "relative",
                                }}
                              >
                                <Typography
                                  style={{
                                    paddingLeft: "5px",
                                    flexGrow: "1",
                                    fontSize: "20px",
                                    color: "white",
                                  }}
                                >
                                  {item.name}
                                </Typography>{" "}
                                <Link to={"/Article/" + item.name}>
                                  {" "}
                                  <IconButton style={{}}>
                                    <ArrowForwardIcon
                                      style={{ color: "white" }}
                                    ></ArrowForwardIcon>
                                  </IconButton>{" "}
                                </Link>{" "}
                              </div>
                            </Grid>
                          ))}
                          {/* {recommend.map((item) => (
                            <Grid item xl={3} lg={3} md={3} sm={3} xs={3} style={{
                              paddingLeft: "8px",
                              paddingRight: "8px",
                              paddingTop: '15px'
                            }}>
                              <Link to={"/article/" + item.name}>
                                <img
                                  className={classes.imgFormat}
                                  src={"https://uisur.ad.ntust.edu.tw/backend/storage/" + item.thumbnail}
                                />
                              </Link>
                            </Grid>
                          ))} */}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <Grid
                    item
                    container
                    className={classes.rightbar}
                    xl={4}
                    lg={4}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    {data.longitude != null && (
                      <Grid
                        item
                        wrap="nowrap"
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{}}
                      >
                        {/* <Typography variant="h5">Location</Typography>
                      <Divider variant="middle" /> */}
                        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.4135816773746!2d121.4788631153786!3d25.020034845004286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a92d63c3afd1%3A0x50bc5bb1591e3537!2z6ICB5Liy6KeSLeadv-api-S4reWxseW6lw!5e0!3m2!1szh-TW!2stw!4v1636974414688!5m2!1szh-TW!2stw" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe> */}
                        <a
                          target="_blank"
                          style={{ textDecoration: "none", color: "black" }}
                          href={
                            "http://maps.google.com/?q=" +
                            data.latitude +
                            "," +
                            data.longitude
                          }
                        >
                          {/* <img
                          src={require("../HomePage/assets/exploreMap.png")}
                          width="100%"
                        /> */}

                          <Button
                            variant="outline"
                            size="large"
                            style={{
                              background: "#ff7348",
                              minWidth: "128px",
                              color: "white",
                              lineHeight: "2",
                              fontWeight: "900",
                              borderRadius: "32px",
                              fontSize: "16px",
                            }}
                          >
                            導航前往！
                          </Button>
                        </a>
                      </Grid>
                    )}
                    {relations.length !== 0 && (
                      <Grid
                        item
                        wrap="nowrap"
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <Typography variant="h5">關係性</Typography>

                        <Divider />
                        {relations.map((item) => (
                          <Chip
                            label={item.name}
                            className={classes.chipitem}
                            component={Link}
                            to={"/article/" + item.id}
                            onClick={handleOnClick}
                            clickable
                          />
                        ))}
                      </Grid>
                    )}
                    {data.tags.length !== 0 && (
                      <Grid
                        item
                        wrap="nowrap"
                        //className={classes.marginTheRight}
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <Typography
                          variant="h5"
                          style={{
                            fontFamily: ["Noto Sans TC", "sans-serif"].join(
                              ","
                            ),
                            fontWeight: "900",
                            marginTop: "20px",
                          }}
                        >
                          Tags
                        </Typography>

                        {data.tags.map((item) => (
                          <Link
                            style={{
                              textDecoration: "none",
                            }}
                            to={"/SearchPage/tags=" + item.name}
                          >
                            <Chip
                              label={item.name}
                              className={classes.chipitem}
                            />
                          </Link>
                        ))}
                      </Grid>
                    )}
                    {data.categories.length !== 0 && (
                      <Grid
                        item
                        wrap="nowrap"
                        //className={classes.marginTheLeft}
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <Typography
                          variant="h5"
                          style={{
                            fontFamily: ["Noto Sans TC", "sans-serif"].join(
                              ","
                            ),
                            fontWeight: "900",
                            marginTop: "20px",
                          }}
                        >
                          Categories
                        </Typography>

                        {data.categories.map((item) => (
                          <Link
                            style={{ textDecoration: "none" }}
                            to={"/SearchPage/cats=" + item.id.toString()}
                          >
                            <Chip
                              label={item.name}
                              className={classes.chipitem}
                            />
                          </Link>
                        ))}
                      </Grid>
                    )}

                    {data.url !== null && (
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography
                          variant="h5"
                          style={{
                            fontFamily: ["Noto Sans TC", "sans-serif"].join(
                              ","
                            ),
                            fontWeight: "900",
                            marginTop: "20px",
                          }}
                        >
                          Websites
                        </Typography>

                        <Typography style={{ wordBreak: "break-all" }}>
                          {data.url}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}></Grid>
          </Grid>
        </div>
      </ThemeProvider>
    </>
  );
};

export default Article;
