import React from "react";
import { BrowserRouter, Route, Link, HashRouter } from "react-router-dom";

import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Fade from "@material-ui/core/Fade";
import Cookies from "js-cookie";
import Modal from "@material-ui/core/Modal";
import Fab from "@material-ui/core/Fab";
import Backdrop from "@material-ui/core/Backdrop";
import { DropzoneArea } from "material-ui-dropzone";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import ManageAppBar from "../../ManageAppBar/ManageAppBar";
import LeftAppBar from "../../LeftAppBar/LeftAppBar";
import { Card, CardMedia, Divider, Hidden } from "@material-ui/core";
import CreateTimeLine from "./CreateTimeLine";
import Paper from "@material-ui/core/Paper";
import { set } from "js-cookie";
import ClearIcon from "@material-ui/icons/Clear";
import { grey } from "@material-ui/core/colors";
import { values } from "underscore";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 0, 0),
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  threeTitle: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    wordBreak: "keep-all",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    position: "relative",
    padding: theme.spacing(2, 4, 3),
  },
  title: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(3),
    wordBreak: "keep-all",
  },
  textEditorGrid: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(3),
    border: "1px black solid",
  },
  newLink: {
    fontWeight: "bold",
    textDecoration: "none",
    color: "#000000",
  },
  media: {
    height: 140,
  },
  buttomMargin: {
    marginBottom: theme.spacing(1),
  },
  seeall: {
    textDecoration: "none",
    color: "#7f7f7f",
  },
  previewButton: {
    color: "#C69C6D",
    borderColor: "#C69C6D",
    marginRight: theme.spacing(1),
    //margin: theme.spacing(0, 2),
    "&:hover": {
      backgroundColor: "#ffffff",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
  saveButton: {
    background: "#C69C6D",
    color: "#ffffff",
    borderColor: "#C69C6D",
    marginRight: theme.spacing(1),
    //margin: theme.spacing(0, 2),
    "&:hover": {
      backgroundColor: "#C69C6D",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
  chooseButton: {
    background: "#4D4D4D",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    color: "#ffffff",
    borderColor: "#4D4D4D",
    "&:hover": {
      backgroundColor: "#4D4D4D",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: "#005cbf",
    },
  },
  fab1: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  publishButton: {
    background: "#4D4D4D",
    marginRight: theme.spacing(2),

    color: "#ffffff",
    borderColor: "#4D4D4D",
    "&:hover": {
      backgroundColor: "#4D4D4D",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: "#005cbf",
    },
  },
  disabled: {
    background: grey,
    color: "#333333",
  },
  LongText: {
    whiteSpace: "nowrap",
  },
  rightflex: {
    flexGrow: 1,
  },
  publishpopper: {
    zIndex: theme.zIndex.modal + 1,
  },
  TimeLine: {
    marginBottom: theme.spacing(2),
  },
}));

//MapPage
const CreateTheme = (props) => {
  //All Data
  var [data, setData] = useState({
    name: "", //文章名稱
    need_name: 0,
    need_era: 0,
    need_summary: 0,
    need_url: 0,
    need_relation: 0,
    need_tag: 0,
    need_recommend: 0,
    need_visible: 0,
    need_content: 0,
    is_themes: 0,
    has_timeline: 0,
    name: "",
    thumbnail: [],
    represenative_image: [],
    recommends: [],
  });
  const [nowRepresenative_image, setNowRepresenative_image] = useState("");
  const [nowThumbnail, setNowThumbnail] = useState("")
  const [deleteRepresenativeIamge, setdeleteRepresenativeIamge] = useState(0);
  const [deleteThumbnail, setdeleteThumbnail] = useState(0);
  var [timeLineData, setTimeLineData] = useState([
    { content: "<p></p>", startYear: "", endYear: "", title: "" },
  ]);
  var [types, setTypes] = useState([]);
  const [htmlCode, setHtmlcode] = useState("<p></p>");
  var [tags, setTags] = useState([{ id: 2, name: "23", checked: false }]);
  var [categories, setCategories] = useState([
    { id: 2, name: "23", is_visible: 2, parent_id: 1 },
  ]);
  var [recommendPage, setRecommendPage] = useState([]);
  //varsetDerivativeRelationship
  var [relationship, setRelationship] = useState([]);
  // var [types, setTypes] = useState([
  //   {
  //     id: 1,
  //     name: "history",
  //     thubnail: null,
  //     represenative_image: null,
  //     is_themes: 1,
  //     operator_id: "",
  //     created_at: null,
  //     updated_at: null,
  //   },
  // ]);
  var [inputRule, setInputRule] = useState({
    id: 1,
    type_id: 1,
    need_name: 1,
    need_era: 1,
    need_summary: 1,
    need_url: 0,
    need_relation: 1,
    need_tag: 1,
    need_recommend: 0,
    need_visible: 0,
    need_content: 1,
  });

  //modal var
  const [thumbnailModal, setThumbnailModal] = useState(false);
  const [represenative_imageModal, setRepresenative_imageModal] = useState(false);
  var [recommendPage, setRecommendPage] = useState([]);
  const handleDataChange = (event) => {
    const target = event.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    if (value === true && target.type === "checkbox") {
      value = 1;
    } else if (value === false && target.type === "checkbox") {
      value = 0;
    }
    const name = target.name;
    if (target.type === "radio" && name != "type") {
      if (value === "true") value = true;
      else value = false;
    }

    data = { ...data, [name]: value };

    setData({ ...data });

    console.log(data, htmlCode);
  };

  //run once after first render for check login
  useEffect(() => {
    const fetchData = async () => {
      const thedata = await Promise.all([fetchEditData(), fetchThemesData()]);
      var temp = thedata[0].editData;
      delete temp.rules.id;
      console.log("ds", temp, JSON.parse(temp.content));
      if (thedata[0].editData.thumbnail != null) {
        setNowThumbnail(thedata[0].editData.thumbnail);
      }
      if (thedata[0].editData.represenative_image != null) {
        setNowRepresenative_image(thedata[0].editData.represenative_image);
      }
      types = thedata[1].types
      data = thedata[0].editData
      //setRelationship
      for (let i = 0; i < thedata[0].editData.recommends.length; i++) {
        for (let j = 0; j < thedata[1].types.length; j++) {
          if (thedata[1].types[j].id === thedata[0].editData.recommends[i].id) {
            data.recommends[i] = thedata[1].types[j].id;
            recommendPage = [thedata[1].types[j], ...recommendPage];
          }
        }
      }

      setRecommendPage(recommendPage);
      setTypes(types)
      setData({
        ...thedata[0].editData,
        thumbnail: [],
        represenative_image: [],
        ...temp.rules,
      });
      if (temp.content !== null) setTimeLineData([...JSON.parse(temp.content)]);
    };
    const fetchType = async () => {
      const thedata = await Promise.all([fetchThemesData()]);
      var temp = thedata[0].types;
      setTypes(temp)
      setRecommendPage([])

    };
    if (props.editMode) {
      fetchData();
    } else {
      fetchType();
    }
  }, []);
  //all fetch function
  const fetchArticlesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/articles")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };
  const fetchThemesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/types")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { types: myJson };
      });
  };
  const fetchTagsData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/tags")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { tags: myJson };
        //setTagsData(temp);
      });
  };
  const fetchCategoriesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/categories")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { categories: myJson };
        //setTagsData(temp);
      });
  };
  const fetchEditData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/types/" + props.id + "?edit=true")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { editData: myJson };
        //setTagsData(temp);
      });
  };

  //submit form
  const handleSubmit = () => {
    {
      var access_token = Cookies.get("access_token");
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + access_token);
      myHeaders.append("Accept", "application/json");
      var uploadData = new FormData();
      //dat.append('file', data.image[0]);
      //dat.append('user', 'hubot');
      uploadData.append("name", data.name);
      uploadData.append(
        "rules",
        data.need_name.toString() +
        "," +
        data.need_era.toString() +
        "," +
        data.need_summary.toString() +
        "," +
        data.need_url.toString() +
        "," +
        data.need_relation.toString() +
        "," +
        data.need_tag.toString() +
        "," +
        data.need_recommend.toString() +
        "," +
        data.need_visible.toString() +
        "," +
        data.need_content.toString()
      );
      uploadData.append("is_themes", data.is_themes);
      uploadData.append("has_timeline", data.has_timeline);
      if (data.recommends !== null)
        uploadData.append("recommends", data.recommends);
      uploadData.append("content", JSON.stringify(timeLineData));

      if (data.thumbnail !== null)
        if (data.thumbnail.length !== 0)
          uploadData.append("thumbnail", data.thumbnail[0]);
      if (deleteThumbnail === 1)
        uploadData.append("deleteThumbnail", 1);
      if (deleteRepresenativeIamge === 1)
        uploadData.append("deleteRepresenativeIamge", 1);
      if (data.represenative_image !== null)
        if (data.represenative_image.length !== 0)
          uploadData.append("represenative_image", data.represenative_image[0]);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: uploadData,
        redirect: "follow",
      };
      var fetchstatus = false;
      fetch(
        "https://uisur.ad.ntust.edu.tw/backend/api/types" +
        (props.editMode === true ? "/" + props.id : ""),
        requestOptions
      )
        .then((response) => {
          if (response.ok) {
            alert(props.editMode === true ? "修改成功" : "上傳成功");
            fetchstatus = true;
            //resolve("");
          } else {
            alert(props.editMode === true ? "修改失敗" : "上傳失敗");
            fetchstatus = false;
          }
          return response.text();
        })
        .then((result) => {
          console.log(result);
          var objJsonArray = JSON.parse(result);
          var tempstring = "";
          var i = 0;
          tempstring += objJsonArray.message + "\n";
          for (var j in objJsonArray.errors) {
            console.log(j);
            i += 1;
            tempstring =
              tempstring + i.toString() + "." + objJsonArray.errors[j] + "\n";
          }
          if (fetchstatus === false) alert(tempstring);
        })
        .catch((error) => console.log("error", error));
    }

    // var status_Number;
    // console.log(JSON.stringify({
    //   type:data.type,
    //   name: data.name,
    //   summary: data.summary,
    //   content: htmlCode,
    //   categories: data.categories,
    //   tags: data.tags,
    //   start_year: data.start_year,
    //   end_year: data.end_year,
    //   recommend:data.recommend,
    //   longitude: data.longitude,
    //   latitude: data.latitude,
    //   relations:data.relations,
    //   title:data.title,
    //   weight: data.weight,
    //   url:data.url,
    //   is_visible:data.is_visible===true?1:0,
    //   is_drafted:data.is_drafted===true?1:0,
    //   available_at:data.available_at,
    //   thubnail:data.thumbnail,
    //   image:data.image,
    // }));

    //  fetch("https://uisur.ad.ntust.edu.tw/backend/api/articles", {
    //   method: "POST",
    //   headers: {
    //     Authorization: `Bearer ${props.access_token}`,
    //     "Content-Type": "multipart/form-data",
    //   },
    //   //'contentType': false, //required
    // //'processData': false, // required

    //   body: uploadData,
    // })
    //   .then(function (response) {
    //     status_Number = response.status;
    //     console.log(response);
    //     if (response.ok) {

    //       alert("新建資料成功");
    //     } else {
    //       alert("新建資料失敗");
    //     }
    //     return response.json();
    //   })
    //   .then(function (myJson) {

    //   });
  };
  const handleHtml = (props) => { };

  const classes = useStyles();

  return (
    <div style={{}}>
      {/* <ManageAppBar /> */}

      <Grid container>
        {/* <Grid item md={1} lg={1} sm={1}>
        <LeftAppBar />
      </Grid> */}
        <Grid
          item
          container
          md={12}
          sm={12}
          lg={12}
          alignContent="center"
          alignItems="center"
          justify="center"
        >
          <Paper className={classes.paper}>
            <Fab
              aria-label="Add"
              variant="extended"
              className={classes.fab1}
              color="primary"
              onClick={() => {
                props.setModalopen(false);
              }}
            >
              <ClearIcon />
            </Fab>
            <Typography variant="h5">名稱</Typography>
            <TextField
              id="title"
              size="small"
              name="name"
              value={data.name}
              onChange={handleDataChange}
              variant="outlined"
            />
            <Divider />

            <Typography variant="h5">推薦頁選擇</Typography>
            <Autocomplete
              multiple
              id="tags-standard"
              options={types}
              getOptionLabel={(option) => option.name}
              //defaultValue={[]}
              fullWidth
              name="recommends"

              value={recommendPage}
              onChange={(e, newValue) => {
                console.log(newValue);
                setRecommendPage([...newValue]);
                var tempstring = "";
                for (var i = 0; i < newValue.length; i++) {
                  tempstring += newValue[i].id.toString() + ",";
                }
                if (tempstring != "") {
                  tempstring = tempstring.substring(
                    0,
                    tempstring.length - 1
                  );
                }
                data.recommends = tempstring;
                console.log(tempstring);
                setData({ ...data });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="推薦頁"
                  placeholder="請輸入推薦頁"
                />
              )}
            />



            <Divider />

            <Typography variant="h5">代表圖片上傳</Typography>
            <Button
              variant="contained"
              color="default"
              className={classes.button}
              startIcon={<CloudUploadIcon />}
              onClick={() => {
                setRepresenative_imageModal(true);
              }}
            >
              檔案上傳
            </Button>
            {nowRepresenative_image != '' &&
              <Button
                variant="contained"
                color="default"
                className={classes.button}
                onClick={() => { window.open('https://uisur.ad.ntust.edu.tw/backend/storage/' + nowRepresenative_image, '_blank'); }}
              >
                當前圖片
              </Button>
            }
            {nowRepresenative_image != '' &&
              <>
                <Typography display="inline">刪除代表圖片</Typography>
                <Checkbox name='deleteRepresenativeIamge' checked={deleteRepresenativeIamge === 0 ? false : true} onChange={(e) => {
                  setdeleteRepresenativeIamge((deleteRepresenativeIamge + 1) % 2)
                }}></Checkbox>
              </>
            }
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={represenative_imageModal}
              onClose={() => {
                setRepresenative_imageModal(false);
              }}
            >
              <DropzoneArea
                acceptedFiles={["image/*"]}
                initialFiles={data.represenative_image}
                filesLimit={1}
                maxFileSize={3000000 * 2}
                dropzoneText="Put the image or click here "
                onChange={(files) => {
                  data.represenative_image = files;
                  setData({ ...data });
                  console.log("Files:", files, data);
                }}
              />
            </Modal>
            <Divider />
            <Typography variant="h5">縮圖上傳</Typography>
            <Button
              variant="contained"
              color="default"
              className={classes.button}
              startIcon={<CloudUploadIcon />}
              onClick={() => {
                setThumbnailModal(true);
              }}
            >
              檔案上傳
            </Button>
            {nowThumbnail != '' &&
              <Button
                variant="contained"
                color="default"
                className={classes.button}

                onClick={() => { window.open('https://uisur.ad.ntust.edu.tw/backend/storage/' + nowThumbnail, '_blank'); }}
              >
                當前圖片
              </Button>
            }
            {nowThumbnail != '' &&
              <>
                <Typography display="inline">刪除縮圖</Typography>
                <Checkbox name='deleteThumbnail' checked={deleteThumbnail === 0 ? false : true} onChange={(e) => {
                  setdeleteThumbnail((deleteThumbnail + 1) % 2)
                }}></Checkbox>
              </>
            }
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={thumbnailModal}
              onClose={() => {
                setThumbnailModal(false);
              }}
            >
              <DropzoneArea
                acceptedFiles={["image/*"]}
                initialFiles={data.thumbnail}
                filesLimit={1}
                maxFileSize={3000000 * 2}
                dropzoneText="Put the image or click here "
                onChange={(files) => {
                  data.thumbnail = files;
                  setData({ ...data });
                  console.log("Files:", files, data);
                }}
              />
            </Modal>
            <Divider />
            <Typography variant="h5">是否為主題(Theme)</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  name={"is_themes"}
                  checked={data.is_themes}
                  onChange={handleDataChange}
                />
              }
              label="is_themes"
            />
            <Divider />
            <Typography variant="h5">是否有時間軸(Timeline)</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  name={"has_timeline"}
                  checked={data.has_timeline}
                  onChange={handleDataChange}
                />
              }
              label="has_timeline"
            />
            <Divider />
            <Typography variant="h5">輸入規則</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  name={"need_name"}
                  onChange={handleDataChange}
                  checked={data.need_name}
                />
              }
              label="name"
            />

            <FormControlLabel
              control={
                <Checkbox
                  name={"need_era"}
                  onChange={handleDataChange}
                  checked={data.need_era}
                />
              }
              label="era"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name={"need_summary"}
                  checked={data.need_summary}
                  onChange={handleDataChange}
                />
              }
              label="summary"
            />

            <FormControlLabel
              control={
                <Checkbox
                  name={"need_url"}
                  onChange={handleDataChange}
                  checked={data.need_url}
                />
              }
              label="url"
            />

            <FormControlLabel
              control={
                <Checkbox
                  name={"need_relation"}
                  onChange={handleDataChange}
                  checked={data.need_relation}
                />
              }
              label="relation"
            />

            <FormControlLabel
              control={
                <Checkbox
                  name={"need_tag"}
                  onChange={handleDataChange}
                  checked={data.need_tag}
                />
              }
              label="tag"
            />

            <FormControlLabel
              control={
                <Checkbox
                  name={"need_recommend"}
                  onChange={handleDataChange}
                  checked={data.need_recommend}
                />
              }
              label="recommend"
            />

            <FormControlLabel
              control={
                <Checkbox
                  name={"need_visible"}
                  onChange={handleDataChange}
                  checked={data.need_visible}
                />
              }
              label="visible"
            />

            <FormControlLabel
              control={
                <Checkbox
                  name={"need_content"}
                  onChange={handleDataChange}
                  checked={data.need_content}
                />
              }
              label="content"
            />
            <Divider />
            <Typography variant="h5">時間軸文章或介紹文章</Typography>
            {timeLineData.map((item, index) => (
              <CreateTimeLine
                className={classes.TimeLine}
                data={item}
                newData={() => {
                  timeLineData.splice(index + 1, 0, {
                    content: "<p></p>",
                    startYear: "",
                    endYear: "",
                    title: "",
                  });
                  setTimeLineData([...timeLineData]);
                }}
                index={index}
                deleteData={() => {
                  if (timeLineData.length === 1) {
                  } else {
                    timeLineData.splice(index, 1);
                    setTimeLineData([...timeLineData]);
                  }
                }}
                dataChange={(inputData) => {
                  timeLineData[index] = inputData;
                  setTimeLineData([...timeLineData]);
                  console.log(timeLineData);
                }}
              />
            ))}

            <Divider />
            <Grid container justify="center">
              <Button
                className={classes.saveButton}
                onClick={handleSubmit}
                justify="center"
              >
                submit
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div >
  );
};

export default CreateTheme;
