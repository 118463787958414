import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import RoomIcon from "@material-ui/icons/Room";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import Cookies from "js-cookie";
import Avatar from "@material-ui/core/Avatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import Collapse from "@material-ui/core/Collapse";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import RestoreIcon from "@material-ui/icons/Restore";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PinDropIcon from "@material-ui/icons/PinDrop";
import NoteIcon from "@material-ui/icons/Note";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import DirectionsIcon from "@material-ui/icons/Directions";
import Map from "./Map";
import { BrowserRouter, Route, Link, HashRouter } from "react-router-dom";
import { Box, Grid, Tab, Tabs, Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
    height: "30px",
  },
  list: {
    width: 250,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  fullList: {
    width: "auto",
  },
  orange: {
    background: "orange",
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  DrawerIndex: {
    zIndex: theme.zIndex.modal - 100,
  },
  ava: {
    fontSize: "10px",
    position: "absolute",
    bottom: "5px",
    right: "43px",
    background: "white",

    color: "black",
    border: "1px black solid",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      position: "absolute",
      bottom: "5px",
      right: "20px",
    },
  },
  ava1: {
    fontSize: "10px",
    position: "absolute",
    bottom: "5px",
    right: "90px",
    background: "white",

    color: "red",
    border: "1px red solid",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      position: "absolute",
      bottom: "5px",
      right: "67px",
    },
  },
  ava2: {
    fontSize: "10px",
    position: "absolute",
    bottom: "5px",
    right: "137px",
    background: "white",

    color: "red",
    border: "1px red solid",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      position: "absolute",
      bottom: "5px",
      right: "114px",
    },
  },
}));

export default function MyMap(props) {
  const classes = useStyles();
  const { drawerState, setDrawerState } = props;
  const [open, setOpen] = React.useState(false);
  const [tabValue, setTabValue] = React.useState("Note");
  let [originData, setOriginData] = React.useState([]);
  const [mymap, setMymap] = React.useState([
    {
      id: 20,
      type_id: 11,
      name: "AAA",
      places: [],
      summary: "b",
      title: "titleHERE",
      url: null,
      relations_uuid: "2283f55b-36e7-4247-95d1-e6f6cf6e21e3",
      content: "7",
      weight: 0,
      thumbnail: "thumbnails/EeCrgGDGr9SIBVi1q8B9lm9iwqbtKcBPKS63bPId.png",
      views: 0,
      is_visible: 0,
      is_drafted: 0,
      is_allowed: 0,
      creator: {
        account: "admin",
        created_at: "2021-02-05T09:16:47.000000Z",
        type: "admin",
        updated_at: "2021-02-05T09:16:47.000000Z",
        uuid: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
        creator_id: "42652e3b-b746-47c4-a3b7-a9962c0c6eac",
      },
      checker_id: null,
      available_at: null,
      created_at: "2021-03-01T07:05:10.000000Z",
      updated_at: "2021-03-01T07:05:10.000000Z",
      start_year: 1911,
      end_year: 1912,
    },
  ]);
  let [access_token, setAccess_token] = React.useState("");
  let [isLogin, setIslogin] = React.useState(false);
  const DateToString = (inputDate) => {
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    var theDate = new Date(inputDate);
    return (
      months[theDate.getMonth()].substr(0, 3) +
      " " +
      theDate.getDate() +
      " , " +
      theDate.getFullYear()
    );
  };
  React.useEffect(() => {
    access_token = Cookies.get("access_token");
    if (access_token === undefined) {
      isLogin = false;
    } else {
      isLogin = true;

      // accountName = Cookies.get("accountName");
    }

    const fetchData = async () => {
      var thedata = await Promise.all([fetchMyMap()]);
      setMymap(thedata[0].articles);
      console.log(thedata);
      originData = [...thedata[0].articles.reverse()];
      setOriginData(thedata[0].articles);
    };
    if (isLogin) fetchData();
    setIslogin(isLogin);
    setAccess_token(access_token);

    console.log(isLogin, access_token);
  }, []);

  const fetchMyMap = (q = {}) => {
    return fetch(
      "https://uisur.ad.ntust.edu.tw/backend/api/maps?" +
        new URLSearchParams(q).toString(),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };
  const map_filter = async (input) => {
    const query = {};
    if (input === "最新") {
      query["order"] = "desc";
      query["orderBy"] = "created_at";
    } else if (input === "官方") {
      query["type"] = "manager";
      // query['order'] = 'desc'
    } else if (input === "熱門") {
      query["order"] = "desc";
      query["orderBy"] = "views";
    } else if (input === "比賽") {
      query["order"] = "desc";
      query["orderBy"] = "views";
    } else if (input === "精選") {
      query["order"] = "desc";
      query["orderBy"] = "views";
      query["chosen"] = "1";
    }
    var thedata = await Promise.all([fetchMyMap(query)]);
    setMymap(thedata[0].articles);
  };
  return (
    <>
      <Paper component="form" className={classes.root}>
        <InputBase
          className={classes.input}
          placeholder="Search Map"
          fullWidth
          onChange={(e) => {
            setMymap(
              originData.filter((item) => {
                console.log(item.name.search(e.target.value));
                return item.name.search(e.target.value) != -1;
              })
            );
          }}
          inputProps={{ "aria-label": "search google maps" }}
        />
      </Paper>
      <Grid container xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid
          xl={2}
          lg={2}
          md={2}
          sm={2}
          xs={2}
          style={
            tabValue === "官方"
              ? {
                  borderLeft: "10px solid black",
                  background: "#eeeceb",
                  marginRight: "10px",
                }
              : { marginRight: "10px" }
          }
        >
          <Link
            onClick={() => {
              setTabValue("官方");
              map_filter("官方");
            }}
            onMouseOut={(e) => {
              e.target.style.textDecoration = "none";
            }}
            onMouseOver={(e) => {
              e.target.style.textDecoration = "underline";
            }}
            style={
              tabValue === "官方"
                ? {
                    textDecoration: "none",
                    fontWeight: "550",
                    color: "black",
                  }
                : { textDecoration: "none", color: "black" }
            }
          >
            <Button fullWidth>
              <Typography style={{ fontSize: "20px", fontWeight: "900" }}>
                官方
              </Typography>
            </Button>
          </Link>
        </Grid>

        <Grid
          xl={2}
          lg={2}
          md={2}
          sm={2}
          xs={2}
          style={
            tabValue === "熱門"
              ? {
                  borderLeft: "10px solid black",
                  background: "#eeeceb",
                  marginRight: "10px",
                }
              : { marginRight: "10px" }
          }
        >
          <Link
            onClick={() => {
              setTabValue("熱門");
              map_filter("熱門");
            }}
            onMouseOut={(e) => {
              e.target.style.textDecoration = "none";
            }}
            onMouseOver={(e) => {
              e.target.style.textDecoration = "underline";
            }}
            style={
              tabValue === "熱門"
                ? {
                    textDecoration: "none",
                    fontWeight: "550",
                    color: "black",
                  }
                : { textDecoration: "none", color: "black" }
            }
          >
            <Button fullWidth>
              <Typography style={{ fontSize: "20px", fontWeight: "900" }}>
                熱門
              </Typography>
            </Button>
          </Link>
        </Grid>
        <Grid
          xl={2}
          lg={2}
          md={2}
          sm={2}
          xs={2}
          style={
            tabValue === "精選"
              ? {
                  borderLeft: "10px solid black",
                  background: "#eeeceb",
                  marginRight: "10px",
                }
              : { marginRight: "10px" }
          }
        >
          <Link
            onClick={() => {
              setTabValue("精選");
              map_filter("精選");
            }}
            onMouseOut={(e) => {
              e.target.style.textDecoration = "none";
            }}
            onMouseOver={(e) => {
              e.target.style.textDecoration = "underline";
            }}
            style={
              tabValue === "精選"
                ? {
                    textDecoration: "none",
                    fontWeight: "550",
                    color: "black",
                  }
                : { textDecoration: "none", color: "black" }
            }
          >
            <Button fullWidth>
              <Typography style={{ fontSize: "20px", fontWeight: "900" }}>
                精選
              </Typography>
            </Button>
          </Link>
        </Grid>
        <Grid
          xl={2}
          lg={2}
          md={2}
          sm={2}
          xs={2}
          style={
            tabValue === "最新"
              ? {
                  borderLeft: "10px solid black",
                  background: "#eeeceb",
                  marginRight: "10px",
                }
              : { marginRight: "10px" }
          }
        >
          <Link
            onClick={() => {
              setTabValue("最新");
              map_filter("最新");
            }}
            onMouseOut={(e) => {
              e.target.style.textDecoration = "none";
            }}
            onMouseOver={(e) => {
              e.target.style.textDecoration = "underline";
            }}
            style={
              tabValue === "最新"
                ? {
                    textDecoration: "none",
                    fontWeight: "550",
                    color: "black",
                  }
                : { textDecoration: "none", color: "black" }
            }
          >
            <Button fullWidth>
              <Typography style={{ fontSize: "20px", fontWeight: "900" }}>
                最新
              </Typography>
            </Button>
          </Link>
        </Grid>
        <Grid
          xl={2}
          lg={2}
          md={2}
          sm={2}
          xs={2}
          style={
            tabValue === "比賽"
              ? {
                  borderLeft: "10px solid black",
                  background: "#eeeceb",
                  marginRight: "10px",
                }
              : { marginRight: "10px" }
          }
        >
          <Link
            onClick={() => {
              setTabValue("比賽");
              map_filter("比賽");
            }}
            onMouseOut={(e) => {
              e.target.style.textDecoration = "none";
            }}
            onMouseOver={(e) => {
              e.target.style.textDecoration = "underline";
            }}
            style={
              tabValue === "比賽"
                ? {
                    textDecoration: "none",
                    fontWeight: "550",
                    color: "black",
                  }
                : { textDecoration: "none", color: "black" }
            }
          >
            <Button fullWidth>
              <Typography style={{ fontSize: "20px", fontWeight: "900" }}>
                比賽
              </Typography>
            </Button>
          </Link>
        </Grid>
      </Grid>
      {/* <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton> */}

      <Grid container xl={12} lg={12} md={12} sm={12} xs={12}>
        {mymap.map((item) => (
          <Grid
            style={{
              paddingLeft: "2.5%",
              paddingRight: "2.5%",
            }}
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            <Link to={"/Mapbox/" + item.id.toString()}>
              <Paper
                style={{
                  height: "250px",
                }}
              >
                <Map points={item.places}></Map>
              </Paper>
            </Link>
            <Paper elevation={0} style={{ position: "relative" }}>
              <div style={{ display: "flex" }}>
                <Typography style={{ display: "inline-block" }}>
                  {item.name +
                    "  /  " +
                    item.places.length.toString() +
                    " places"}{" "}
                </Typography>
                <RoomIcon fontSize="10px"></RoomIcon>
                <div style={{ flexGrow: "1" }}></div>
                <Typography>
                  {"收藏數 " + item.favorites_count + "　"}
                </Typography>
                <Typography>{"瀏覽數 " + item.views}</Typography>
              </div>
              <Typography>
                {"published on " + DateToString(item.created_at)}{" "}
              </Typography>
              <Typography>
                {"Create by account: " + item.creator.account}{" "}
              </Typography>
              <Link to={"/EditMap/" + item.id.toString()}>
                <Avatar className={classes.ava}>Edit</Avatar>
              </Link>
              <Link
                onClick={() => {
                  const access_token = Cookies.get("access_token");
                  //var status_Number;
                  const respond = fetch(
                    "https://uisur.ad.ntust.edu.tw/backend/api/maps/" + item.id,
                    {
                      method: "DELETE",
                      headers: {
                        Authorization: `Bearer ${access_token}`,
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({}),
                    }
                  )
                    .then((res) => {
                      console.log(res.ok);
                      if (res.ok) {
                        alert("刪除成功");

                        //fetchData();
                      } else {
                        alert("刪除失敗");
                      }
                      return res.json();
                    })
                    .then((result) => {
                      //console.log(result);
                    });
                }}
              >
                {" "}
                <Avatar className={classes.ava1}>Delete</Avatar>
              </Link>
              <Link>
                <Avatar
                  className={classes.ava2}
                  onClick={() => {
                    const access_token = Cookies.get("access_token");
                    var myHeaders = new Headers();
                    myHeaders.append("Authorization", "Bearer " + access_token);
                    myHeaders.append("Accept", "application/json");
                    var uploadData = new FormData();
                    uploadData.append("_method", "PATCH");
                    uploadData.append(
                      "is_chosen",
                      item.is_chosen === 0 ? "1" : "0"
                    );
                    var requestOptions = {
                      method: "POST",
                      headers: myHeaders,
                      body: uploadData,
                      redirect: "follow",
                    };
                    const respond = fetch(
                      "https://uisur.ad.ntust.edu.tw/backend/api/maps/" +
                        item.id,
                      requestOptions
                    )
                      .then((res) => {
                        console.log(res.ok);
                        if (res.ok) {
                          alert("操作成功");

                          //fetchData();
                        } else {
                          alert("操作失敗");
                        }
                        return res.json();
                      })
                      .then((result) => {
                        //console.log(result);
                      });
                  }}
                >
                  {item.is_chosen === 0 ? "精選" : "取消精選"}
                </Avatar>
              </Link>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
