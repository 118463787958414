import React from "react";
import { BrowserRouter, Route, Link, HashRouter } from "react-router-dom";

import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { DropzoneArea } from "material-ui-dropzone";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import HtmlEditor from "./HtmlEditor";
import { Card, CardMedia, Divider, Hidden } from "@material-ui/core";

import Paper from "@material-ui/core/Paper";
import { set } from "js-cookie";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 0, 0),
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  threeTitle: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    wordBreak: "keep-all",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  title: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(3),
    wordBreak: "keep-all",
  },
  textEditorGrid: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(3),
    border: "1px black solid",
  },
  newLink: {
    fontWeight: "bold",
    textDecoration: "none",
    color: "#000000",
  },
  media: {
    height: 140,
  },
  buttomMargin: {
    marginBottom: theme.spacing(1),
  },
  seeall: {
    textDecoration: "none",
    color: "#7f7f7f",
  },
  previewButton: {
    color: "#C69C6D",
    borderColor: "#C69C6D",
    marginRight: theme.spacing(1),
    //margin: theme.spacing(0, 2),
    "&:hover": {
      backgroundColor: "#ffffff",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
  saveButton: {
    background: "#C69C6D",
    color: "#ffffff",
    borderColor: "#C69C6D",
    marginRight: theme.spacing(1),
    //margin: theme.spacing(0, 2),
    "&:hover": {
      backgroundColor: "#C69C6D",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
  chooseButton: {
    background: "#4D4D4D",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    color: "#ffffff",
    borderColor: "#4D4D4D",
    "&:hover": {
      backgroundColor: "#4D4D4D",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: "#005cbf",
    },
  },
  publishButton: {
    background: "#4D4D4D",
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    color: "#ffffff",
    borderColor: "#4D4D4D",
    "&:hover": {
      backgroundColor: "#4D4D4D",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: "#005cbf",
    },
  },
  disabled: {
    background: grey,
    color: "#333333",
  },
  LongText: {
    whiteSpace: "nowrap",
  },
  rightflex: {
    flexGrow: 1,
  },
  publishpopper: {
    zIndex: theme.zIndex.modal + 1,
  },
}));

//MapPage
const CreateTimeLine = (props) => {
  //All Data
  var [data, setData] = useState({
    name: "", //文章名稱
    summary: "", // 摘要
    content: "", //內容
    type: "", //型態
    title: "", // 自訂網址
    is_visible: false, //boolean 是否顯示於地圖
    is_drafted: true, // boolean 是否為草稿
    available_at: "", // 什麼時候公開（不填就是直接公開）
    url: "", // 網站
    relations: "", //跟之前一樣填有關的文章的id
    start_year: "",
    end_year: "", //年代
    longitude: "",
    latitude: "", // 經緯度
    weight: "", // 權重
    thumbnail: [], // 縮圖
    categories: "", // 類別id
    tags: "", // tags id
    recommends: [], //推薦頁的id
    images: [], //其他圖片
  });
  const [htmlCode, setHtmlcode] = useState("<p></p>");
  var [tags, setTags] = useState([{ id: 2, name: "23", checked: false }]);
  var [categories, setCategories] = useState([
    { id: 2, name: "23", is_visible: 2, parent_id: 1 },
  ]);
  var [recommendPage, setRecommendPage] = useState([]);
  //varsetDerivativeRelationship
  var [relationship, setRelationship] = useState([]);
  var [types, setTypes] = useState([
    {
      id: 1,
      name: "history",
      thubnail: null,
      is_themes: 1,
      operator_id: "",
      created_at: null,
      updated_at: null,
    },
  ]);
  var [inputRule, setInputRule] = useState({
    id: 1,
    type_id: 1,
    need_name: 1,
    need_era: 1,
    need_summary: 1,
    need_url: 0,
    need_relation: 1,
    need_tag: 1,
    need_recommend: 0,
    need_visible: 0,
    need_content: 1,
  });
  var [articles, setArticles] = useState([
    {
      id: 43,
      name: "dsadd",
      summary: "dfdf",
      title: null,
      thumbnail: "thumbnails/WBWJL7jqrKZtIBsVxRFUmoCDgmeHpjNFDiYawmat.jpeg",
      latitude: null,
      longitude: null,
    },
  ]);

  //modal var
  const [categoryModal, setCategoryModal] = useState(false);
  const [tagModal, setTagModal] = useState(false);
  const [thumbnailModal, setThumbnailModal] = useState(false);
  const [mapModal, setMapModal] = useState(false);
  const [otherImgModal, setOtherImgModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [temp, setTemp] = useState(false);

  const handlePopClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const pop_open = Boolean(anchorEl);
  const id = pop_open ? "transitions-popper" : undefined;
  /* var [TagChip, setTagChip] = useState(
    <Chip
      variant="outlined"
      onClick={{}}
      label="wait"
      color="secondary"
      size="small"
    />
  );*/
  var [CategoryChip, setCategoryChip] = useState(
    <Chip
      variant="outlined"
      onClick={{}}
      label="wait"
      color="secondary"
      size="small"
    />
  );
  const handleDataChange = (event) => {
    const target = event.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (target.type === "radio" && name != "type") {
      if (value === "true") value = true;
      else value = false;
    }

    data = { ...data, [name]: value };

    setData({ ...data });

    console.log(data, htmlCode);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //run once after first render for check login
  useEffect(() => {
    const fetchData = async () => {
      const thedata = await Promise.all([
        fetchTagsData(),
        fetchCategoriesData(),
        fetchThemesData(),
        fetchArticlesData(),
        //fetchEditData(),
      ]);
    };

    fetchData();
  }, []);
  //all fetch function
  const fetchArticlesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/articles")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };
  const fetchThemesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/types")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { types: myJson };
      });
  };
  const fetchTagsData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/tags")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { tags: myJson };
        //setTagsData(temp);
      });
  };
  const fetchCategoriesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/categories")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { categories: myJson };
        //setTagsData(temp);
      });
  };
  const fetchEditData = () => {
    return fetch(
      "https://uisur.ad.ntust.edu.tw/backend/api/articles/" + props.id.toString() + "?edit=true"
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { editData: myJson };
        //setTagsData(temp);
      });
  };

  //submit form
  const handleSubmit = () => {
    {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + props.access_token);

      var uploadData = new FormData();
      //dat.append('file', data.image[0]);
      //dat.append('user', 'hubot');
      uploadData.append("type", data.type);
      uploadData.append("name", data.name);
      uploadData.append("summary", data.summary);
      uploadData.append("content", htmlCode);
      uploadData.append("categories", data.categories);
      uploadData.append("tags", data.tags);
      if (data.start_year !== null)
        uploadData.append("start_year", data.start_year);
      if (data.end_year !== null) uploadData.append("end_year", data.end_year);
      if (data.recommends !== null)
        uploadData.append("recommends", data.recommends);
      if (data.longitude !== null)
        uploadData.append("longitud", data.longitude);
      if (data.latitude !== null) uploadData.append("latitude", data.latitude);
      if (data.relations !== null)
        uploadData.append("relations", data.relations);
      if (data.title !== null) uploadData.append("title", data.title);

      uploadData.append("weight", data.weight);
      if (data.url !== null) uploadData.append("url", data.url);

      uploadData.append(
        "is_visible",
        JSON.stringify(data.is_visible === true ? 1 : 0)
      );
      uploadData.append(
        "is_drafted",
        JSON.stringify(data.is_drafted === true ? 1 : 0)
      );

      //uploadData.append("is_visible", JSON.stringify(1));
      //uploadData.append("is_drafted", JSON.stringify(0));
      if (data.available_at !== null)
        uploadData.append("available_at", data.available_at);
      //uploadData.append("thubnail",data.thumbnail);
      if (data.thumbnail !== null)
        if (data.thumbnail.length !== 0)
          uploadData.append("thumbnail", data.thumbnail[0]);
      if (data.images !== null)
        if (data.images.length !== 0)
          for (var i = 0; i < data.images.length; i++)
            uploadData.append("image", data.images[i]);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: uploadData,
        redirect: "follow",
      };

      fetch(
        "https://uisur.ad.ntust.edu.tw/backend/api/articles/" + props.id.toString(),
        requestOptions
      )
        .then((response) => {
          if (response.ok) {
            alert("上傳成功");
          } else {
            alert("上傳失敗");
          }
          return response.text();
        })
        .then((result) => {
          console.log(result);
        })
        .catch((error) => console.log("error", error));
    }

    // var status_Number;
    // console.log(JSON.stringify({
    //   type:data.type,
    //   name: data.name,
    //   summary: data.summary,
    //   content: htmlCode,
    //   categories: data.categories,
    //   tags: data.tags,
    //   start_year: data.start_year,
    //   end_year: data.end_year,
    //   recommend:data.recommend,
    //   longitude: data.longitude,
    //   latitude: data.latitude,
    //   relations:data.relations,
    //   title:data.title,
    //   weight: data.weight,
    //   url:data.url,
    //   is_visible:data.is_visible===true?1:0,
    //   is_drafted:data.is_drafted===true?1:0,
    //   available_at:data.available_at,
    //   thubnail:data.thumbnail,
    //   image:data.image,
    // }));

    //  fetch("https://uisur.ad.ntust.edu.tw/backend/api/articles", {
    //   method: "POST",
    //   headers: {
    //     Authorization: `Bearer ${props.access_token}`,
    //     "Content-Type": "multipart/form-data",
    //   },
    //   //'contentType': false, //required
    // //'processData': false, // required

    //   body: uploadData,
    // })
    //   .then(function (response) {
    //     status_Number = response.status;
    //     console.log(response);
    //     if (response.ok) {

    //       alert("新建資料成功");
    //     } else {
    //       alert("新建資料失敗");
    //     }
    //     return response.json();
    //   })
    //   .then(function (myJson) {

    //   });
  };
  const handleHtml = () => { };

  const classes = useStyles();

  return (
    <div>
      <Paper className={classes.paper}>
        <Grid container justify="center">
          <Button className={classes.saveButton} onClick={props.deleteData}>
            刪除
          </Button>
        </Grid>
        <Typography>標題</Typography>
        <TextField
          required
          placeholder="標題"
          helperText="必填*"
          name="title"
          value={props.data.title}
          onChange={(e) => {
            var temp = props.data;

            temp.title = e.target.value;
            props.dataChange(temp);
          }}
        />
        <Divider />
        <Typography>年代</Typography>
        <TextField
          required
          placeholder="年代(先)"
          helperText="必填*"
          type="number"
          name="startYear"
          value={props.data.startYear}
          onChange={(e) => {
            var temp = props.data;

            temp.startYear = e.target.value;
            props.dataChange(temp);
          }}
        />
        ~
        <TextField
          placeholder="年代(後)"
          helperText="選填"
          type="number"
          name="endYear"
          value={props.data.endYear}
          onChange={(e) => {
            var temp = props.data;
            temp.endYear = e.target.value;

            props.dataChange(temp);
          }}
        />
        <Divider />
        <Typography>內文</Typography>
        <Divider />
        <HtmlEditor
          htmlHandle={(html) => {
            var temp = props.data;
            temp.content = html;
            props.dataChange(temp);
          }}
          content={props.data.content}
          index={props.index}
        ></HtmlEditor>
        <Divider />
        <Grid container justify="center">
          <Button className={classes.publishButton} onClick={props.newData}>
            向下新增
          </Button>
        </Grid>
      </Paper>
    </div>
  );
};

export default CreateTimeLine;
