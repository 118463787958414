import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {
  HashRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import MapIcon from "@material-ui/icons/Map";
import Cookies from "js-cookie";
import AppBar from "@material-ui/core/AppBar";
import SearchIcon from "@material-ui/icons/Search";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import _ from "underscore";
import AddBoxIcon from "@material-ui/icons/AddBox";
import PersonIcon from "@material-ui/icons/Person";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Botton from "@material-ui/core/Button";
import GolfCourseIcon from '@material-ui/icons/GolfCourse';
import IconButton from "@material-ui/core/IconButton";
import { deepOrange, deepPurple } from "@material-ui/core/colors";
import Avatar from "@material-ui/core/Avatar";
import PublishIcon from "@material-ui/icons/Publish";
import Typography from "@material-ui/core/Typography";
import { makeStyles, fade } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Select from "@material-ui/core/Select";
import SettingsIcon from "@material-ui/icons/Settings";
import NativeSelect from "@material-ui/core/NativeSelect";
import ListSubheader from "@material-ui/core/ListSubheader";
import Drawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import BarChartIcon from "@material-ui/icons/BarChart";
import { Grid, Paper } from "@material-ui/core";
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {},
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  icon: {
    //flexGrow: 1,
  },
  drawer: {
    //width: drawerWidth,
    //flexShrink: 0,
  },
  buttontext: {
    //variant: "h2",
  },
  buttonImg: {
    flexDirection: "row",
    flexGrow: 1,
    textTransform: "none",
    textAlign: "center",
  },
  drawerPaper: {
    //width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const LeftAppBar = (props) => {
  const [themeAnchorEl, setThemeAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setThemeAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setThemeAnchorEl(null);
  };

  const isLogin = props.isLogin;
  //const [isLogin, setIslogin] = useState(false);
  const [activeItem, setActiveItem] = useState("");
  //const [username, setUsername] = useState("");
  const [access_token, setAccess_token] = useState("");
  const [drawerState, setDrawerState] = useState(false);
  const { username } = props;
  const Logout = () => {
    var status_Number = 0;
    fetch("https://uisur.ad.ntust.edu.tw/backend/api/auth/logout", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then(function (response) {
        //console.log(response);
        if (response.ok) {
          alert("成功登出");
        }
        //isLogin = false;
        //accountName = "";
        // access_token = "";

        Cookies.remove("access_token"); Cookies.remove('access_token', { sameSite: 'Lax', secure: true, path: '', domain: '.uisur.ad.ntust.edu.tw' })
        Cookies.remove("accountName");
        window.location.reload();
        return response.json();
      })
      .then(function (myJson) { });
  };

  const classes = useStyles();
  useEffect(() => {
    //document.title = `大稻埕管理系統`;
    // Run! Like go get some data from an API.
    // let name = "access_token";
    // let value = parseCookie()[name];
    // if (value) value = decodeURIComponent(value);
    // if (typeof value != "undefined") {
    //   setIslogin(true);
    //   setAccess_token(value);
    //   //console.log("查詢值為: " + value);
    // } else {
    //   setIslogin(false);
    //   setAccess_token("");
    // }
  }, []);

  const handleLogin = (flag, username, access_token) => { };

  const handleLogout = () => { };

  return (
    // <Drawer
    //   className={classes.drawer}
    //   variant="permanent"
    //   classes={{
    //     paper: classes.drawerPaper,
    //   }}
    // >
    //   <Toolbar />
    //   <div className={classes.drawerContainer}>
    //     <List>
    //       <ListItem button>
    //         <ListItemIcon>
    //           <BarChartIcon
    //             style={{ right: "50%", transform: "translateX(-50%)" }}
    //           ></BarChartIcon>
    //         </ListItemIcon>
    //         <ListItemText primary={"dsd"} />
    //       </ListItem>
    //     </List>

    //     <Divider />
    //     <List>
    //       {["All mail", "Trash", "Spam"].map((text, index) => (
    //         <ListItem button key={text}>
    //           <ListItemIcon>
    //             {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
    //           </ListItemIcon>
    //           <ListItemText primary={text} />
    //         </ListItem>
    //       ))}
    //     </List>
    //   </div>
    // </Drawer>
    <Paper square elevation={12} className={classes.root}>
      <Button
        fullWidth
        component={Link}
        to="/ManageInterface/InsightsPage"
        className={classes.buttonImg}
      >
        <Grid container>
          <Grid item md={12} xs={12} lg={12}>
            <BarChartIcon />
          </Grid>
          <Grid item md={12} xs={12} lg={12}>
            <Typography variant="subtitle2" className={classes.buttontext}>
              Insights
            </Typography>
          </Grid>
        </Grid>
      </Button>
      <Button
        fullWidth
        component={Link}
        to="/ManageInterface/MenuPage"
        className={classes.buttonImg}
      >
        <Grid container>
          <Grid item md={12} xs={12} lg={12}>
            <MenuIcon />
          </Grid>
          <Grid item md={12} xs={12} lg={12}>
            <Typography variant="subtitle2" className={classes.buttontext}>
              menu
            </Typography>
            <Typography variant="subtitle2" className={classes.buttontext}>
              pages
            </Typography>
          </Grid>
        </Grid>
      </Button>
      <Button
        fullWidth
        component={Link}
        to="/ManageInterface/ContestPage"
        className={classes.buttonImg}
      >
        <Grid container>
          <Grid item md={12} xs={12} lg={12}>
            < GolfCourseIcon />
          </Grid>
          <Grid item md={12} xs={12} lg={12}>
            <Typography variant="subtitle2" className={classes.buttontext}>
              contest
            </Typography>
            <Typography variant="subtitle2" className={classes.buttontext}>
              page
            </Typography>
          </Grid>
        </Grid>
      </Button>

      <Button
        fullWidth
        component={Link}
        to="/ManageInterface/ViewDataPage"
        className={classes.buttonImg}
      >
        <Grid container>
          <Grid item md={12} xs={12} lg={12}>
            <AddBoxIcon />
          </Grid>
          <Grid item md={12} xs={12} lg={12}>
            <Typography variant="subtitle2" className={classes.buttontext}>
              data
            </Typography>
            <Typography variant="subtitle2" className={classes.buttontext}>
              pages
            </Typography>
          </Grid>
        </Grid>
      </Button>
      <Divider variant="middle" />
      <Button
        fullWidth
        component={Link}
        to="/ManageInterface/MediaPage"
        className={classes.buttonImg}
      >
        <Grid container>
          <Grid item md={12} xs={12} lg={12}>
            <PermMediaIcon />
          </Grid>

          <Grid item md={12} xs={12} lg={12}>
            <Typography variant="subtitle2" className={classes.buttontext}>
              media
            </Typography>
          </Grid>
        </Grid>
      </Button>
      <Button
        fullWidth
        component={Link}
        to="/ManageInterface/MapboxPage"
        className={classes.buttonImg}
      >
        <Grid container>
          <Grid item md={12} xs={12} lg={12}>
            <MapIcon />
          </Grid>
          <Grid item md={12} xs={12} lg={12}>
            <Typography variant="subtitle2" className={classes.buttontext}>
              mapbox
            </Typography>
          </Grid>
        </Grid>
      </Button>
      <Divider variant="middle" />
      <Button
        fullWidth
        component={Link}
        to="/ManageInterface/UserPage"
        className={classes.buttonImg}
      >
        <Grid container>
          <Grid item md={12} xs={12} lg={12}>
            <PersonIcon />
          </Grid>
          <Grid item md={12} xs={12} lg={12}>
            <Typography variant="subtitle2" className={classes.buttontext}>
              users
            </Typography>
          </Grid>
        </Grid>
      </Button>
      <Button
        fullWidth
        component={Link}
        to="/ManageInterface/SubmissionsPage"
        className={classes.buttonImg}
      >
        <Grid container>
          <Grid item md={12} xs={12} lg={12}>
            <PublishIcon />
          </Grid>
          <Grid item md={12} xs={12} lg={12}>
            <Typography variant="subtitle2" className={classes.buttontext}>
              submissions
            </Typography>
          </Grid>
        </Grid>
      </Button>
      <Divider variant="middle" />
      <Button
        fullWidth
        component={Link}
        to="/ManageInterface/ManagePage"
        className={classes.buttonImg}
      >
        <Grid container>
          <Grid item md={12} xs={12} lg={12}>
            <SettingsIcon />
          </Grid>
          <Grid item md={12} xs={12} lg={12}>
            <Typography variant="subtitle2" className={classes.buttontext}>
              manage
            </Typography>
          </Grid>
        </Grid>
      </Button>
      <Button
        fullWidth
        onClick={() => {
          Logout();
        }}
        className={classes.buttonImg}
      >
        <Grid container>
          <Grid item md={12} xs={12} lg={12}>
            <ExitToAppIcon />
          </Grid>
          <Grid item md={12} xs={12} lg={12}>
            <Typography variant="subtitle2" className={classes.buttontext}>
              log out
            </Typography>
          </Grid>
        </Grid>
      </Button>
    </Paper>
  );
};

export default LeftAppBar;
