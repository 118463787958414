import React from "react";
import { BrowserRouter, Route, Link, HashRouter } from "react-router-dom";
import TheMap from "./MapboxComponent/Map.js";
import SightCard from "./MapboxComponent/SightCard.js";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Chip from "@material-ui/core/Chip";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import RoomIcon from "@material-ui/icons/Room";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import FrontAppBar from "../FrontAppBar/FrontAppBar.js";
import {
  Divider,
  Hidden,
  Icon,
  IconButton,
  Modal,
  Paper,
} from "@material-ui/core";
import PhoneSightCard from "./MapboxComponent/PhoneSightCard.js";
import Cookies, { set } from "js-cookie";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { ArrowRightAlt } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 0, 0),
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      position: "relative",
      paddingRight: 24,
      paddingLeft: 24,
      paddingTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      paddingRight: 8,
      paddingLeft: 8,
      paddingTop: "10px",
    },
  },
  summaryText: {
    [theme.breakpoints.up("md")]: {
      paddingRight: 10,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
      paddingLeft: 0,
      paddingTop: "0",
    },
  },
  sumModalcss: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "25%",
      paddingRight: "25%",
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: "10%",
      paddingLeft: "10%",
    },
  },
  cardbutton: {
    marginLeft: "auto",
  },
  firstaccordion: {
    margin: theme.spacing(1, 0, 0, 0),
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  gridFirst: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  phoneGridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    boxShadow: 1,
    //height: "20vh",
    width: "100vw",
    //padding: theme.spacing(3)
  },
  gridList: {
    //width: 500,
    boxShadow: 1,
    height: "80vh",
  },
  saveButton: {
    background: "#ff7348",
    color: "white",
    borderColor: "#ff7348",
    marginRight: theme.spacing(1),
    //margin: theme.spacing(0, 2),
    "&:hover": {
      backgroundColor: "#ff7348",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
  showAllPlacebutton: {
    width: "100%",
    color: "#ffffff",
    backgroundColor: "#424242",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#000000",
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      //backgroundColor: '#0062cc',
      //borderColor: '#005cbf',
    },
  },
}));

//MapPage
const MapPage = (props) => {
  const [data, setData] = React.useState({
    id: 2,
    name: "name",
    summary: "content here",
    places: [
      {
        id: 4,
        name: "azzza1sssawzza",
        thumbnail: "thumbnails/7YucFolItbn72SevdIQLZHf1h7wy6jwoioOl4YoL.jpeg",
        latitude: 12.123111,
        longitude: 25.255,
      },
      {
        id: 8,
        name: "TEST23",
        thumbnail: null,
        latitude: 12.123111,
        longitude: 25.255,
      },
      {
        id: 9,
        name: "TEST231",
        thumbnail: null,
        latitude: 12.123111,
        longitude: 25.255,
      },
    ],
    views: 0,
    creator_id: "42652e3b-b746-47c4-a3b7-a9962c0c6eac",
    is_public: 1,
    created_at: "2021-02-05T16:10:54.000000Z",
    updated_at: "2021-02-05T16:10:54.000000Z",
    creator: {
      uuid: "42652e3b-b746-47c4-a3b7-a9962c0c6eac",
      account: "admin",
      type: "user",
      created_at: "2021-02-05T16:10:54.000000Z",
      updated_at: "2021-02-05T16:10:54.000000Z",
    },
    tags: [
      {
        id: 1,
        name: "a",
      },
    ],
  });
  var [point, setPoint] = useState([
    {
      id: 46,
      note: false,
      type_id: 4,
      name: "dsadsd",
      summary: "dsds",
      title: "dsd",
      thumbnail: null,
      is_visible: 1,
      is_drafted: 1,
      is_allowed: 1,
      views: 72,
      created_at: "2021-02-06T15:45:24.000000Z",
      updated_at: "2021-03-02T14:17:43.000000Z",
      creator_id: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
      latitude: null,
      longitude: null,
      creator: {
        uuid: "c1e4c746-70f8-4f62-9fdc-c449e8471ae6",
        account: "admin",
        type: "admin",
        created_at: "2021-02-05T09:16:47.000000Z",
        updated_at: "2021-02-05T09:16:47.000000Z",
      },
    },
  ]);
  const [open, setOpen] = useState(false);
  const [theAlert, setTheAlert] = useState({ text: "", severity: "error" });
  var [access_token, setAccess_token] = useState("");
  var [note, setNote] = useState(false);
  var [refrash, SetRefrash] = useState(false);
  const [summarymodal, setSummaryModal] = useState(false);
  useEffect(() => {
    if (refrash) {
      window.location.reload();
    } else {
      SetRefrash(true);
    }
    access_token = Cookies.get("access_token");
    if (access_token === undefined) {
      access_token = "";
    }
    setAccess_token(access_token);
    const fetchData = async () => {
      var thedata = await Promise.all([
        fetchTagsData(),
        fetchCategoriesData(),
        fetchThemesData(),
        fetchArticlesData(),
        // fetchEditData(),
        fetchMymap(),
        fetchViewData(),
      ]);

      if (access_token !== "")
        setNote(
          thedata[4].articles.some((item) => {
            return item.id.toString() === thedata[5].viewData.id.toString();
          })
        );

      //console.log(thedata[4].articles, access_token, props.id);
      // if (access_token !== "")
      //   thedata[3].articles.map((item) => {
      //     item.note = thedata[4].articles.some((it) => {
      //       return it.id === item.id;
      //     });
      //   });

      setData(thedata[5].viewData);
      setPoint(thedata[5].viewData.places);
      console.log(point, thedata[5].viewData.places);
    };
    fetchData();
  }, [props.id]);
  function copyCurrentUrl() {
    var dummy = document.createElement("input"),
      text = window.location.href;

    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    setTheAlert({ text: "複製成功", severity: "success" });
    setOpen(true);
  }
  //all fetch function
  const fetchArticlesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/articles")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };
  const fetchThemesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/types")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { types: myJson };
      });
  };
  const fetchTagsData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/tags")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { tags: myJson };
        //setTagsData(temp);
      });
  };
  const fetchMymap = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/favorites/maps", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { articles: myJson };
        //setTagsData(temp);
      });
  };
  const fetchCategoriesData = () => {
    return fetch("https://uisur.ad.ntust.edu.tw/backend/api/categories")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { categories: myJson };
        //setTagsData(temp);
      });
  };
  const fetchViewData = () => {
    return fetch(
      "https://uisur.ad.ntust.edu.tw/backend/api/maps/" + props.id.toString(),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return { viewData: myJson };
        //setTagsData(temp);
      });
  };

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  var i = 0;
  var j = 0;
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const DateToString = (inputDate) => {
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    var theDate = new Date(inputDate);
    return (
      months[theDate.getMonth()].substr(0, 3) +
      " " +
      theDate.getDate() +
      " , " +
      theDate.getFullYear()
    );
  };
  return (
    <>
      <FrontAppBar />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={theAlert.severity}
        >
          {theAlert.text}
        </MuiAlert>
      </Snackbar>
      <div style={{}}>
        <Grid
          container
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.root}
        >
          <Grid
            container
            style={{ paddingBottom: 24 }}
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            <Link to="/MapPond">
              <img
                width="25px"
                height="25px"
                src={require("./MapboxAsset/arrow.png")}
              />
            </Link>
            {/* <Link to="/MapPond">
              <IconButton aria-label="delete" size='small' style={{ fontSize: 30, height: 20, left: 0, transform: 'rotate(180deg)', top: 0 }} >
                <ArrowRightAltIcon fontSize="inherit" />
              </IconButton>
            </Link> */}

            <div style={{ flexGrow: 1 }}></div>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                if (access_token === "") {
                  setTheAlert({
                    text: "登入以啟用收藏功能",
                    severity: "info",
                  });
                  setOpen(true);
                } else if (!note) {
                  //var status_Number;
                  const respond = fetch(
                    "https://uisur.ad.ntust.edu.tw/backend/api/favorites/maps",
                    {
                      method: "POST",
                      headers: {
                        Authorization: `Bearer ${access_token}`,
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        map_id: data.id,
                      }),
                    }
                  )
                    .then((res) => {
                      console.log(res.ok);
                      if (res.ok) {
                        setTheAlert({
                          text: "收藏成功",
                          severity: "success",
                        });
                        setOpen(true);
                        setNote(true);
                        //fetchData();
                        //setTagEditModal(false);
                      } else {
                        setTheAlert({ text: "收藏失敗", severity: "error" });
                        setOpen(true);
                      }
                      return res.json();
                    })
                    .then((result) => {
                      //console.log(result);
                    });
                } else if (note) {
                  {
                    //var status_Number;
                    const respond = fetch(
                      "https://uisur.ad.ntust.edu.tw/backend/api/favorites/maps/" +
                        data.id,
                      {
                        method: "DELETE",
                        headers: {
                          Authorization: `Bearer ${access_token}`,
                          "Content-Type": "application/json",
                        },
                      }
                    )
                      .then((res) => {
                        console.log(res.ok);
                        if (res.ok) {
                          setTheAlert({
                            text: "刪除收藏成功",
                            severity: "success",
                          });
                          setOpen(true);
                          setNote(false);
                          //fetchData();
                          //setTagEditModal(false);
                        } else {
                          setTheAlert({
                            text: "刪除收藏失敗",
                            severity: "error",
                          });
                          setOpen(true);
                        }
                        return res.json();
                      })
                      .then((result) => {
                        //console.log(result);
                      });
                  }
                }
              }}
              className={classes.saveButton}
            >
              {!note ? "收藏" : "取消收藏"}
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={copyCurrentUrl}
              className={classes.saveButton}
            >
              分享
            </Button>
            <Link
              style={{ textDecoration: "none" }}
              to={"/MapPdf/" + props.id.toString()}
            >
              <Button
                variant="outlined"
                size="small"
                className={classes.saveButton}
              >
                列印
              </Button>
            </Link>
          </Grid>
          <Grid
            container
            item
            alignContent="flex-start"
            className={classes.summaryText}
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
          >
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h3">{data.name}</Typography>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h6" style={{ display: "inline-block" }}>
                {data.places.length.toString() + " 個地點"}
              </Typography>
              <RoomIcon fontSize="20px"></RoomIcon>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Link
                to={"/user/" + data.creator_id}
                style={{ textDecoration: "none", color: "black" }}
              >
                <Typography variant="body" display="inline">
                  {" " +
                    (data.creator.name != null
                      ? data.creator.name
                      : data.creator.account)}
                </Typography>
              </Link>
              <Typography variant="body" display="inline">
                {" 發布於 " + DateToString(data.created_at)}
              </Typography>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              {data.tags.map((item) => (
                <Link
                  style={{ textDecoration: "none" }}
                  to={"/SearchPage/tags=" + item.name}
                >
                  <Chip
                    style={{ background: "#ff7348", marginLeft: 2 }}
                    label={item.name}
                  />
                </Link>
              ))}
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              <Typography
                display="inline"
                style={{
                  height: "100%",
                  paddingRight: "8px",
                  overflow: "hidden",
                  fontSize: "18px",
                  whiteSpace: "pre-line",
                }}
              >
                {data.summary.slice(0, 400) +
                  (data.summary.length > 400 ? "..." : "")}
              </Typography>
              {data.summary.length > 400 && (
                <Typography
                  display="inline"
                  onClick={() => {
                    setSummaryModal(true);
                  }}
                  style={{
                    color: "#fb724e",
                    fontSize: "16px",
                    fontWeight: "900",
                  }}
                >
                  閱讀全文
                </Typography>
              )}
            </Grid>
            <Modal
              open={summarymodal}
              className={classes.sumModalcss}
              onClose={() => {
                setSummaryModal(false);
              }}
              aria-labelledby="simple"
              aria-describedby="simple"
            >
              <Paper
                style={{
                  position: "relative",
                  fontWeight: "900",
                  paddingLeft: "35px",
                  paddingRight: "35px",
                  paddingTop: "20px",
                  overflowX: "hidden",
                  overflow: "scroll",
                  height: "100vh",
                }}
              >
                <Typography
                  variant="h6"
                  style={{ paddingBottom: "15px", whiteSpace: "pre-line" }}
                >
                  {data.name}
                </Typography>
                <Typography
                  style={{ whiteSpace: "pre-line", paddingBottom: "30px" }}
                >
                  {data.summary}
                </Typography>
                <Button
                  onClick={() => {
                    setSummaryModal(false);
                  }}
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    maxWidth: "30px",
                    minWidth: "30px",
                    height: "30px",
                    transform: "translate(-100%,50%)",
                  }}
                >
                  ✕
                </Button>
              </Paper>
            </Modal>
          </Grid>
          {/* <Hidden smDown>
            <Grid item xs={false} sm={false} md={2} lg={2}></Grid>
          </Hidden> */}
          <Grid item container xl={9} lg={9} md={9} sm={12} xs={12}>
            <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
              <TheMap points={point} />
            </Grid>
            <Grid
              item
              style={{ paddingLeft: "10px" }}
              xl={3}
              lg={3}
              md={3}
              sm={12}
              xs={12}
            >
              <Hidden smDown>
                <GridList
                  cellHeight={225}
                  className={classes.gridList}
                  cols={1}
                >
                  {point.map((item, index) => (
                    <>
                      <SightCard
                        conponent="div"
                        name={"data" + item.id.toString()}
                        id={"data" + item.id.toString()}
                        point={item}
                        index={(1 + index).toString()}
                        key={item.id.toString()}
                      />
                      <div id={"data" + item.id.toString()} />
                    </>
                  ))}
                </GridList>
              </Hidden>

              <Hidden mdUp>
                <GridList className={classes.phoneGridList} cols={1}>
                  {point.map((item, index) => (
                    <>
                      <PhoneSightCard
                        conponent="div"
                        index={(1 + index).toString()}
                        point={item}
                        handlePointClick={() => {
                          if (access_token === "") {
                            setTheAlert({
                              text: "登入以啟用收藏功能",
                              severity: "info",
                            });
                            setOpen(true);
                          } else if (!item.note) {
                            //var status_Number;
                            const respond = fetch(
                              "https://uisur.ad.ntust.edu.tw/backend/api/favorites/articles",
                              {
                                method: "POST",
                                headers: {
                                  Authorization: `Bearer ${access_token}`,
                                  "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                  article_id: item.id,
                                }),
                              }
                            )
                              .then((res) => {
                                console.log(res.ok);
                                if (res.ok) {
                                  setTheAlert({
                                    text: "收藏成功",
                                    severity: "success",
                                  });
                                  setOpen(true);
                                  item.note = true;
                                  setPoint([...point]);
                                  //fetchData();
                                  //setTagEditModal(false);
                                } else {
                                  setTheAlert({
                                    text: "收藏失敗",
                                    severity: "error",
                                  });
                                  setOpen(true);
                                }
                                return res.json();
                              })
                              .then((result) => {
                                //console.log(result);
                              });
                          } else if (item.note) {
                            {
                              //var status_Number;
                              const respond = fetch(
                                "https://uisur.ad.ntust.edu.tw/backend/api/favorites/articles/" +
                                  item.id.toString(),
                                {
                                  method: "DELETE",
                                  headers: {
                                    Authorization: `Bearer ${access_token}`,
                                    "Content-Type": "application/json",
                                  },
                                }
                              )
                                .then((res) => {
                                  console.log(res.ok);
                                  if (res.ok) {
                                    setTheAlert({
                                      text: "刪除收藏成功",
                                      severity: "success",
                                    });
                                    setOpen(true);
                                    item.note = false;
                                    setPoint([...point]);
                                    //fetchData();
                                    //setTagEditModal(false);
                                  } else {
                                    setTheAlert({
                                      text: "刪除收藏失敗",
                                      severity: "error",
                                    });
                                    setOpen(true);
                                  }
                                  return res.json();
                                })
                                .then((result) => {
                                  //console.log(result);
                                });
                            }
                          }
                        }}
                        key={item.id.toString()}
                      />
                      <div id={"data" + item.id.toString()} />
                    </>
                  ))}
                </GridList>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default MapPage;
